<template>
  <!-- <div class="login" v-loader.opacity="loading"> -->
  <div class="login">
    <AuthHeader></AuthHeader>

    <div class="login-form ease-all" :class="!login ? 'increase-width' : ''">
      <form class="">
        <div v-if="login">
          <input class="form-control ease-all" type="email" name="identifier" :placeholder="$t('login.email')" v-model="identifier">
          <div class="">
            <input class="form-control ease-all" :type="showPassword ? 'text' : 'password'" id="password"
                   name="password" :placeholder="$t('login.password')" v-model="password" @keyup.enter="doLogin()">
            <button class="no-btn" type="button" name="button" @click="toggleShowPassword()">
                <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>

            <p class="alert" :class="alert.class" v-if="alert.message">{{ alert.message }}</p>
          </div>

          <div>
            <vue-recaptcha
              v-if="isProduction"
              ref="recaptcha"
              @verify="onLoginVerify"
              @expired="onExpired"
              :sitekey=sitekey
              size="invisible"
              :load-recaptcha-script="true">
            </vue-recaptcha>
            <button class="btn btn-default btn-green" type="button" name="button" @click="doLogin()">{{ $t('login.login') }}</button>
          </div>

          <button class="btn btn-default" type="button" name="button" @click="doSwitch">{{ $t('login.sendRecoveryEmail') }}</button>
        </div>

        <div v-else>
          <p class="alert" :class="alert.class" v-if="alert.message">{{ alert.message }}</p>
          <label for="identifier">{{ $t('login.askEmailForPasswordRecovery') }}</label>
          <input class="form-control ease-all" type="email" name="recoveryEmail" :placeholder="$t('login.email')" v-model="recoveryEmail" @keydown.enter="$event.preventDefault(); doRecoverPass()">

          <div>
            <vue-recaptcha
              v-if="isProduction"
              ref="recaptcha"
              @verify="onPwdRecoverVerify"
              @expired="onExpired"
              :sitekey=sitekey
              size="invisible"
              :load-recaptcha-script="true">
            </vue-recaptcha>
            <button class="btn btn-default btn-green" type="button" name="button" @click.stop.prevent="doRecoverPass()">{{ $t('login.sendRecoveryEmail') }}</button>
          </div>

          <button class="btn btn-default" type="button" name="button" @click="doSwitch">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <circle cx="370" cy="346" r="20"/> <path d="M460,362c11.046,0,20-8.954,20-20v-74c0-44.112-35.888-80-80-80h-24.037v-70.534C375.963,52.695,322.131,0,255.963,0 s-120,52.695-120,117.466V188H112c-44.112,0-80,35.888-80,80v164c0,44.112,35.888,80,80,80h288c44.112,0,80-35.888,80-80 c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20c0,22.056-17.944,40-40,40H112c-22.056,0-40-17.944-40-40V268 c0-22.056,17.944-40,40-40h288c22.056,0,40,17.944,40,40v74C440,353.046,448.954,362,460,362z M335.963,188h-160v-70.534 c0-42.715,35.888-77.466,80-77.466s80,34.751,80,77.466V188z"/> <circle cx="219" cy="346" r="20"/> <circle cx="144" cy="346" r="20"/> <circle cx="294" cy="346" r="20"/> </svg>
            {{ $t('login.access') }}
          </button>
        </div>
      </form>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<style lang="scss" scoped>
  .login-form {
    @include                                border_radius(9px);
    text-shadow:                            0 1px 0 white;
    background-color:                       white;
    padding:                                15px 25px;
    animation-duration:                     0.5s;
    animation-timing-function:              ease;
    animation-fill-mode:                    both;
    width:                                  100%;
    max-width:                              350px;
    margin:                                 0 auto;
    z-index:                                1;
    &,
    > form div {
      position:                             relative;
    }
    > form > div {
      @include                              animation(fadein 2s);
      padding:                              22px 0;
      p.alert:last-child {
        top:                                unset;
        bottom:                             -19px;
      }
    }
    &.increase-width {
      max-width:                            450px;
    }
    .form-control,
    .btn.btn-default {
      @include                              border_radius(3px);
      @include                              box_shadow(unset);
    }
    .form-control {
      position:                             relative;
      font-size:                            14px;
      color:                                $granite-gray;
      margin:                               0 0 22px;
      padding:                              25px 15px;
      border:                               1px solid $chinese-silver;
      height:                               34px;
    }
    .no-btn {
      position:                             absolute;
      font-size:                            1.4em;
      top:                                  12px;
      right:                                18px;
      padding:                              0;
    }
    label {
      color:                                $light-slate;
    }
    .btn.btn-default {
      font-size:                            15px;
      font-weight:                          400px;
      text-transform:                       uppercase;
      color:                                $mint;
      width:                                100%;
      padding:                              15px;
      border:                               1px solid $mint;
      outline:                              0;
      &:hover,
      &:focus {
        background-color:                   $light-platinum;
        text-decoration:                    none;
      }
      svg {
        fill:                               $mint;
        width:                              20px;
        vertical-align:                     sub;
        margin-right:                       10px;
      }
      &.btn-green {
        letter-spacing:                     3px;
        color:                              aliceblue;
        background-color:                   $mint;
        margin-bottom:                      22px;
        &:hover,
        &:focus {
          background-color:                 $celadon;
        }
      }
    }
    .btn,
    p.alert {
      text-align:                           center;
    }
    p.alert {
      font-size:                            0.8em;
      // font-size:                            0.65em;
      font-weight:                          bold;
      /* color:                                #842029; */
      /* background-color:                     #f8d7da; */
      border:                               0;
      padding:                              0;
      position:                             absolute;
      margin:                               0 auto;
      top:                                  1px;
      left:                                 0;
      right:                                0;
      width:                                100%;
    }
  }

  @media (max-width: 500px) {
    .login-form {
      label {
        font-size:                          0.79em;
      }
      p.alert {
        // font-size:                          0.5em;
        width:                              230px;
        &:first-child {
          top:                              6px;
        }
      }
      > form > div p.alert:last-child {
       bottom:                              -17px;
     }
    }
    .login {
      height:                                100vh;
      .footer {
        position:                            absolute;
      }
    }
  }
</style>

<script>
  import { VueRecaptcha } from 'vue-recaptcha';
  import AuthFooter from './AuthFooter.vue';
  import AuthHeader from './AuthHeader.vue';
  import { useUserStore } from '@/store/user'

  export default {
    name: 'auth-login',
    components: {
      AuthFooter,
      AuthHeader,
      'vue-recaptcha': VueRecaptcha,
    },
    data: () => ({
      loading: false,
      isProduction: false,
      login: true,
      msgBrowser: null,
      alert: {
        message: null,
        class: null,
      },
      identifier: null,
      password: null,
      timezone: null,
      recoveryEmail: null,
      sitekey: process.env.VUE_APP_SITE_KEY,
      showPassword: false
    }),
    mounted() {
      console.log('axios base url', this.$axios.defaults.baseURL);
      console.log(">>>>>>>>>>>>>>>>> ENV >>>>>>>>>>>>>>>", process.env)
      this.isProduction = process.env.NODE_ENV != "development"

      const userStore = useUserStore()
      userStore.reset() // avoid reusing other clinic info
    },
    methods: {
      doSwitch() {
        this.alert.message = null;
        this.login = !this.login;
      },
      async onLoginVerify(gtoken) {
        this.resetRecaptcha()
        this.alert.message = null;
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let params = {
          DEFAULT_TZ: this.timezone,
          identifier: this.identifier,
          password: this.password,
          recaptcha: gtoken
        }

        this.loading = true

        try {
          let sessionInfo = await this.$authService.doLogin(params)

          setTimeout(() => {
            this.loading = false
          }, 500)

          // this.allowSynchronization = sessionInfo.currentClinic.synchronizePatientData
          // if (!this.allowSynchronization) {
          //   this.$router.push("/clinic")
          // } else {
            this.$router.push("/exam")
          // }
        } catch(err) {
          if (err.response && err.response.status == 418) {
            this.$router.push("/home/limbo")
            return
          }

          this.$message.popup(this, 'login.wrongData', 'operationFail', 'error')
        }

      },
      onPwdRecoverVerify: async function(gtoken) {
        this.resetRecaptcha()
        this.alert.message = null;
        let params = {
          recoveryEmail: this.recoveryEmail,
          recaptcha: gtoken
        };

        if(!this.recoveryEmail) {
          this.$message.popup(this, 'login.emptyEmail', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'login.emptyEmail', 'danger')
          return;
        }

        this.loading = true

        try {
          await this.$authService.resetPassword(params);
          this.$message.popup(this, 'login.checkEmailMessage', 'operationSuccess', 'success')
        } catch(err) {
          this.$message.popup(this, 'operationFail', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'operationFail', 'danger')
        }

        this.loading = false
      },
      doLogin: async function() {
        if (!this.identifier || !this.password) {
          this.$message.popup(this, 'login.emptyData', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'login.emptyData', 'danger')
          return
        }

        if (!this.isProduction) {
          this.onLoginVerify()
          return
        }

        this.$refs.recaptcha.execute();
      },
      doRecoverPass: async function() {
        if (!this.recoveryEmail) {
          this.$message.popup(this, 'login.emptyEmail', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'login.emptyEmail', 'danger')
          return;
        }

        if (!this.isProduction) {
          this.onPwdRecoverVerify()
          return
        }
        this.$refs.recaptcha.execute();
      },
      onExpired: function () {
        console.log('Recaptcha Expired')
      },
      resetRecaptcha() {
        if (!this.isProduction)
          return

        this.$refs.recaptcha.reset();
      },
      toggleShowPassword() {
        this.showPassword = !this.showPassword;
        console.log('pass show', this.showPassword);
      },
    }
  }
</script>
