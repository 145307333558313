<template>
  <div class="create-clinic-box" v-loader.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(doSave)">
        <div class="action-buttons rounded text-end">
          <button class="btn btn-outline-danger" type="button" name="button" @click="$router.back()">{{ $t('createClinic.cancel') }}</button>
          <button class="btn btn-outline-primary" type="submit" name="button">{{ $t('createClinic.save') }}</button>
        </div>

        <ClinicFormData v-if="clinic != null" :clinic="clinic" :disabled="false" @option-selected="setSelectedOptions"></ClinicFormData>
      </form>
    </vee-form>

  </div>
</template>

<style lang="scss" scoped>
.action-buttons {
  padding-top:                   10px;
}
</style>

<script>
import ClinicFormData from '../ClinicFormData.vue'

export default {
  name: 'ClinicCreateTab',
  props: ['parent_clinic', 'clinic_template'],
  components: {
    ClinicFormData
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    clinic: null,
  }),
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.clinic = this.clinic_template
      console.log("INITIALIZE >>>", this.clinic_template)
    },
    setSelectedOptions(value) {
      this.clinic.country = value.country
      this.clinic.mandatoryDocument = value.mandatoryDocument
      this.clinic.locale = value.locale
    },
    doSave: async function() {
      try {
        let params = {
          clinic: this.clinic
        }

        if (this.parent_clinic) {
          params['parentClinic'] = this.parent_clinic
        }

        this.loading = true
        NProgress.start()
        let response = await this.$clinicService.saveClinic(params);
        this.$message.popup(this, 'clinic.createdSuccess', 'operationSuccess', 'success')

        this.$router.push({name: 'clinic-show', params: {clinicID: response.result.id}, query: {tab: 'data'}})
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    }
  }
}
</script>
