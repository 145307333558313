<template>
  <div class="login">
    <AuthHeader></AuthHeader>

    <div class="login-form ease-all">
      <form class="">
        <div>
          <input class="form-control ease-all" type="text" name="username" :placeholder="$t('externalPatient.accessCode')" v-model="username">
          <div class="">
            <input class="form-control ease-all" type="password" name="password" :placeholder="$t('externalPatient.accessPassword')" ref="password" v-model="password" @keyup.enter="doLogin()">
            <p class="alert" :class="alert.class" v-if="alert.message">{{ alert.message }}</p>
          </div>

          <div>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onAccessVerify"
              @expired="onExpired"
              :sitekey=sitekey
              size="invisible"
              :load-recaptcha-script="true">
            </vue-recaptcha>
            <button class="btn btn-default btn-green" type="button" name="button" @click="doLogin()">{{ $t('login.login') }}</button>
          </div>
        </div>
      </form>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<style lang="scss" scoped>
.login-form {
  @include                                border_radius(9px);
  text-shadow:                            0 1px 0 white;
  background-color:                       white;
  padding:                                15px 25px;
  animation-duration:                     0.5s;
  animation-timing-function:              ease;
  animation-fill-mode:                    both;
  width:                                  100%;
  max-width:                              350px;
  margin:                                 0 auto;
  z-index:                                1;
  &,
  > form div {
    position:                             relative;
  }
  > form > div {
    @include                              animation(fadein 2s);
    padding:                              22px 0;
    p.alert:last-child {
      top:                                unset;
      bottom:                             -19px;
    }
  }
  .form-control,
  .btn.btn-default {
    @include                              border_radius(3px);
    @include                              box_shadow(unset);
  }
  .form-control {
    font-size:                            14px;
    color:                                $granite-gray;
    margin:                               0 0 22px;
    padding:                              25px 15px;
    border:                               1px solid $chinese-silver;
    height:                               34px;
  }
  label {
    color:                                $light-slate;
  }
  .btn.btn-default {
    font-size:                            15px;
    font-weight:                          400px;
    text-transform:                       uppercase;
    color:                                $mint;
    width:                                100%;
    padding:                              15px;
    border:                               1px solid $mint;
    outline:                              0;
    &:hover,
    &:focus {
      background-color:                   $light-platinum;
      text-decoration:                    none;
    }
    svg {
      fill:                               $mint;
      width:                              20px;
      vertical-align:                     sub;
      margin-right:                       10px;
    }
    &.btn-green {
      letter-spacing:                     3px;
      color:                              aliceblue;
      background-color:                   $mint;
      margin-bottom:                      22px;
      &:hover,
      &:focus {
        background-color:                 $celadon;
      }
    }
  }
  .btn,
  p.alert {
    text-align:                           center;
  }
  p.alert {
    font-size:                            0.8em;
    font-weight:                          bold;
    border:                               0;
    padding:                              0;
    position:                             absolute;
    margin:                               0 auto;
    top:                                  1px;
    left:                                 0;
    right:                                0;
    width:                                100%;
  }
}

@media (max-width: 500px) {
  .login-form {
    label {
      font-size:                          0.79em;
    }
    p.alert {
      // font-size:                          0.5em;
      width:                              230px;
      &:first-child {
        top:                              6px;
      }
    }
    > form > div p.alert:last-child {
     bottom:                              -17px;
   }
  }
}

</style>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import AuthFooter from '@/views/auth/AuthFooter.vue';
import AuthHeader from '@/views/auth/AuthHeader.vue';

export default {
  name: 'external-access',
  components: {
    AuthFooter,
    AuthHeader,
    'vue-recaptcha': VueRecaptcha,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null // TODO REFACTOR change to 'type' instead
    },
    username: null,
    password: null,
    timezone: null,
    sitekey: process.env.VUE_APP_SITE_KEY,
  }),
  mounted() {
    if (this.$route.query._data) {
      this.username = this.$route.query._data
      this.$refs.password.focus()
    }
  },
  methods: {
    onAccessVerify: async function (gtoken) {
      try {
        this.$refs.recaptcha.reset();
        this.alert.message = null;
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let params = {
          DEFAULT_TZ: this.timezone,
          username: this.username,
          password: this.password,
          recaptcha: gtoken
        }

        this.loading = true

        let sessionInfo = await this.$externalService.login(params)
        console.log("sessionInfo >>>", sessionInfo)
        setTimeout(() => {
          this.loading = false
        }, 500)

        // this.allowSynchronization = sessionInfo.patient
        // if (!this.allowSynchronization) {
        //   this.$router.push("/first-access");
        // } else {
        this.$router.push("/external/home");
        // }
      } catch(err) {
        console.log("OUCH >>>>", err)
        this.$message.popup(this, 'login.wrongData', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'login.wrongData', 'danger')
      }

    },
    doLogin: async function() {
      if (!this.username || !this.password) {
        this.$message.popup(this, 'login.emptyData', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'login.emptyData', 'danger')
        return
      }
      this.$refs.recaptcha.execute();
    },
    onExpired: function () {
      console.log('Recaptcha Expired')
      this.$refs.recaptcha.reset();
    },
  }
}

</script>
