<template>
  <div class="">
    <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title">{{ $t('clinic.clinic') }} |
        <span>
          {{ this.clinic ? this.clinic.name : '' }}
          <small v-if="clinic && clinic.parentClinic">{{ $t('createClinic.parentClinic') }}: <span>{{ this.clinic.parentClinic }}</span></small>
        </span>
      </h2>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('clinic.data') }}
      </template>
      <template v-slot:data>
        <ClinicInfoTab v-if="tabActive === 'data' && clinic && clinic.id"
          :clinic_id="clinic.id"
          :logomsg="alert ? alert : undefined">
        </ClinicInfoTab>
      </template>

      <template v-slot:tab-head-templates>
        {{ $t('clinic.template') }} ({{ $t('clinic.report') }})
      </template>
      <template v-slot:templates>
        <ClinicTemplateTab v-if="tabActive === 'templates'"></ClinicTemplateTab>
      </template>

      <template v-slot:tab-head-mutirao v-if="showMutirao">
        {{ $t('clinic.attendance') }}
      </template>
      <template v-slot:mutirao v-if="showMutirao">
        <ClinicMutiraoTab v-if="tabActive === 'mutirao'" :clinic="clinic"></ClinicMutiraoTab>
      </template>

      <template v-slot:tab-head-config>
        {{ $t('createClinic.configuration') }}
      </template>
      <template v-slot:config v-if="clinic.id">
        <ClinicConfigurationTab v-if="tabActive === 'config'" :clinic_id="clinic.id"></ClinicConfigurationTab>
      </template>
    </BaseTab>
  </div>
</template>

<style lang="scss" scoped>
.btn-back {
  margin-right:                       20px;
}

.title-box {
  margin-bottom:                      15px;
  .title {
    max-width:                        unset;
  }
}

@media (max-width: 500px) {
  .tab-box .nav-item {
    width:                            100%;
    flex-grow:                        unset;
    flex-basis:                       unset;
  }
}

@media (max-width: 767px) {
  .title-box {
    text-align:                       left;
  }
}

@media (max-width: 991px) {
  .title-box .title {
    display:                          inline-block;
    width:                            calc(100% - 60px);
    margin-left:                      0;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ClinicConfigurationTab from './ClinicConfigurationTab.vue'
import ClinicInfoTab from './ClinicInfoTab.vue'
import ClinicMutiraoTab from './ClinicMutiraoTab.vue'
import ClinicTemplateTab from './ClinicTemplateTab.vue'

export default {
  name: 'clinic-show',
  components: {
    ClinicConfigurationTab,
    ClinicInfoTab,
    ClinicMutiraoTab,
    ClinicTemplateTab,
  },
  data: () => ({
    clinic: {}, // TODO NEXT
    alert: {},
    msgError: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isSpecialist: null,
    showMutirao: false,
    isCurrentClinic: false,
    selectedNotification: null,
    tabActive: 'data',
    tabs: ['data'],
  }),
  computed: {
    tab() {
      return this.$route.query.tab;
    },
  },
  created() {
    // watch param clinicID coming from notifications to force reload data
    this.$watch( // TODO REFACTOR KISS
      () => this.$route.params.clinicID,
      (toParams, previousParams) => {
        if (toParams != previousParams && this.$route.name == 'clinic-show')
          this.fetchData()
      }
    )
  },
  async mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.isSpecialist = this.session.isSpecialist
    this.$activeLink.value = 'clinic'

    this.fetchData()

    if (!this.isSpecialist)
      this.tabs.push('templates')

    this.showMutirao = this.session.features.includes('mutirao')
    if (this.showMutirao && !this.isSpecialist) {
      this.tabs.push('mutirao')
      this.tabs.push('config')
    }

    if (this.tab) {
      this.tabActive = this.tab;
    }
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      this.$router.push({name: 'clinic-list'})
    },
    async fetchData() {
      let params = {
        id: this.$route.params.clinicID
      }

      try {
        let response = await this.$clinicService.getClinic(params);
        this.clinic = response.clinic

        const _clinic = this.clinic
        this.isCurrentClinic = _clinic.id === this.session.currentClinic.id
        if (!this.isCurrentClinic)
          return
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    }
  }
}
</script>
