<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="action-buttons rounded text-end">
      <button class="btn btn-outline-primary" type="button" name="button" @click.prevent="doDownload">
        <i class="fa fa-download"></i>
        <span>{{ $t("download") }}</span>
      </button>
      <button class="btn btn-outline-danger" v-show="!isSpecialist" type="button" name="button" @click="confirmRemoval">
        <i class="fa fa-trash-o"></i>
        <span>{{ $t("remove") }}</span>
      </button>
      <button class="btn btn-outline-danger" v-show="!disabled" type="button" name="button" @click="disabled = !disabled">
        <i class="fa fa-ban"></i>
        <span>{{ $t("cancel") }}</span>
      </button>
      <button class="btn btn-outline-warning" v-show="disabled && !isSpecialist" type="button" name="button" @click="editFields">
        <i class="fa fa-pencil"></i>
        <span>{{ $t("edit") }}</span>
      </button>
      <button class="btn btn-outline-success" v-show="!disabled" type="button" name="button" @click="doUpdate">
        <i class="fa fa-check"></i>
        <span>{{ $t("updateAction") }}</span>
      </button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message || alert_msg.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div v-if="patient">
      <div class="exam-info-box">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="mydriasis" class="">{{ $t('exam.mydriasis') }}</label>
              <input type="checkbox" id="mydriasis" v-model="exam.mydriasis" :disabled="disabled">
            </div>
            <div class="form-group">
              <label for="observation">{{ $t('exam.observation') }}</label>
              <textarea name="name" class="form-control" ref="observation" id="observation" v-model="exam.observation" autofocus :disabled="disabled"></textarea>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="dateTime">{{ $t('exam.dateAndTime') }}</label>
              <input type="text" class="form-control" id="dateTime" :value="$filters.dateTime(exam.createdAt)" readonly>
            </div>
            <div class="form-group">
              <label for="status">{{ $t('exam.status') }}</label>
              <v-select
                class="v-select form"
                v-model="statusSelected"
                :reduce="x => x.value"
                :options="statusOptions"
                :disabled="disabled">
              </v-select>
            </div>
          </div>
        </div>
        <div class="row" v-if="debug">
          <div class="form-group">
              <label for="metadata">=== METADATA ===</label>
              <textarea name="name" class="form-control" ref="observation" id="metadata" v-model="exam.metadata" :disabled="disabled"></textarea>
            </div>
        </div>
      </div>

      <ExamInfoPatient :patient="patient"></ExamInfoPatient>

      <div class="ia-box" v-show="hasMCRAS(exam)">
        <h3 class="sub-title">Phelcom AI</h3>
        <div class="form-group" v-if="score">
          <div class="d-inline-block">
            <i class="icon-ai" :class="score.status"></i>
          </div>
          <label for="alterationScore" class="">{{ $t('exam.alterationScore') }}</label>
        </div>
        <div class="form-group" v-for="c of this.activeAiClasses(exam)" :key="c">
          <div class="d-inline-block">
            <i class="icon-phelcom-ai" :class="`phelcomAI_${c}`">{{$t(`phelcomAI.icon_${c}`)}}</i>
          </div>
          <label for="">{{ $t(`phelcomAI.title_${c}`) }}</label>
        </div>
      </div>
    </div>

    <teleport to="body">
      <BaseGenericModal :modal_id="'modalExamInfoGeneric'" ref="modalGeneric"></BaseGenericModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
  .exam-info-box {
    .form-group input[type="checkbox"] {
      width:                        auto;
    }
    button.dropdown-toggle {
      margin-bottom:                0;
    }
  }

  .ia-box {
    .form-group {
      margin-bottom:                0;
      label {
        font-size:                  13px;
        font-weight:                bold;
        color:                      #9dabba;
        letter-spacing:             normal;
        text-transform:             unset;
        width:                      calc(100% - 30px);
      }
      .d-inline-block {
        margin-right:               10px;
        i {
          margin:                   0 auto;
        }
      }
      .icon-ai, .icon-no_ai {
        font-size:                  1.3em;
      }
    }
  }

  @media (max-width: 991px) {
    .exam-info-box {
      .form {
        width:                      100%;
      }
      .col-lg-6:first-child .form-group label {
        width:                      100px;
      }
    }
  }

  @media (min-width: 992px) {
    .exam-info-box .form {
      display:                      inline-block;
      width:                        calc(100% - 200px);
      max-width:                    unset;
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'
import ExamInfoPatient from './ExamInfoPatient.vue'
import {useRoute} from 'vue-router'
import {computed} from 'vue'


// TODO EC-337 ENH deal programatically with modal control (open, close, cancel and the action)

export default {
  name: 'ExamInfoTab',
  props: ['exam', 'patient', 'alert_msg'],
  components: {
    ExamInfoPatient,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: null,
    disabled: true,
    statusOptions: [],
    statusSelected: 'open',
    score: null,
    isSpecialist: null,
    debug: false
  }),
  watch: {
    alert_msg: function(newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
      } else {
        this.alert.message = null
      }
    },
  },
  mounted: function() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.isSpecialist = this.session.isSpecialist
    this.statusOptions = [
      {value: 'open', label: this.$i18n.t('exam.open')},
      {value: 'closed', label: this.$i18n.t('exam.closed')}
    ];

    this.score = this.$aiService.alterationScore(this.exam.metadata)
    this.statusSelected = this.exam.status
  },
  methods: {
    editFields() {
      this.disabled = !this.disabled;
      this.$nextTick(() => {
        this.$refs.observation.focus()
      })
    },
    doUpdate: async function() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        let params = {
          exam: this.exam
        }

        params.exam.status = this.statusSelected
        delete params.exam.examType
        await this.$examService.update(params)
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
      this.disabled = !this.disabled;
    },
    doCancel() {
      if (!this.disabled)
        this.disabled = !this.disabled;
      else {
        this.$router.push({name: 'exam-list'})
      }
    },
    async confirmRemoval() {
      const ok = await this.$refs.modalGeneric.show({
        modalType: 'danger',
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('exam.confirmExamRemoval'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.doDelete()
      }
    },
    doDelete: async function() {
      this.alert.message = null;

      let params = {
        id: this.exam.id,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examService.remove(params)
        this.$message.popup(this, 'general.removedSuccess', 'operationSuccess', 'success')

        // TODO ENH change behavior:
        // when comming from patient > exams, go back to patient/exams/list
        // when comming from home > exams, go back to exams
        this.$router.push({name: 'exam-list'})
      } catch (err) {
        this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.removedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    doDownload() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        this.$examService.download(this.exam.id)
      } catch(err) {
        // TODO log to bugsnag
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    activeAiClasses(exam) {
      return this.$aiService.mcRASClasses(exam)
    },
    hasMCRAS(exam) {
      return this.$aiService.hasMCRAS(exam)
    }
  }
}
</script>
