<template>
  <div class="report-box" v-loader.opacity="loading">
    <div class="title-box clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title sub">
        <span v-if="currentReport.id">{{ $t('exam.reportEdition') }} | </span>
        <span v-else>{{ $t('report.newReport') }} | </span>
        <span>
          <a href="" v-if="patient">{{ patient.fullName }}</a>
        </span>
      </h2>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div class="diagnosis-box">

      <div class="content-images">
        <div class="action-box text-end">
          <span>{{ $t('exam.select') }} </span>

          <input type="radio" class="btn-check" name="options-outlined" id="select-all" autocomplete="off">
          <label class="btn btn-outline-primary" for="select-all" @click="selectAll('check')">{{ $t('exam.all') }}</label>

          <input type="radio" class="btn-check" name="options-outlined" id="select-none" autocomplete="off">
          <label class="btn btn-outline-primary" for="select-none" @click="selectAll">{{ $t('exam.none') }}</label>
        </div>

        <div class="alert" :class="alertImages.class" role="alert" v-if="alertImages.message">
          <span v-html="alertImages.message"></span>
          <button type="button" class="btn-close" aria-label="Close" @click="alertImages.message = null"></button>
        </div>

        <div class="diagnosis-gallery" v-else>
          <lightgallery-vue class="ease-all" ref="lGalleryDiagnosis" id="lGalleryDiagnosis" :settings="lGalleryDiagnosisSettings" :onInit="onInit" :onAfterOpen="extraCustomization">
             <template v-for="(item, index) in examImages" :key="index">
               <a v-show="!item.type.endsWith('VIDEO')" class="thumbnail-box loading-eye" onclick="return false" :href="dataPath +'/'+ item.name">
                 <img class="lg-selector ease-all" alt="" :src="thumbDataPath +'/'+ item.name" @load="removeLoading($event)"/>
                 <div class="checkbox">
                   <div class="base-check-box" :class="{'checked': selectedImages.includes(item.name)}" :id="`checkImg_${item.name}`"
                        :value="item.uuid" @click.stop.prevent="doCheck(item, 'checkImg_'+item.name, $event)"></div>
                   <!-- <input class="form-check-input" type="checkbox" :id="`checkImg_${item.name}`" :value="item.uuid" aria-label=""
                          :checked="selectedImages.includes(item.name)" @click.stop.prevent="doCheck(item, 'checkImg_'+item.name, $event)"> -->
                 </div>
               </a>
             </template>
          </lightgallery-vue>
        </div>
      </div>

      <div class="content-diagnosis">
        <form class="" id="formCreateDiagnosis">
          <div class="form-group" v-if="!currentReport.isQuickReport">
            <label for="">{{ $t('exam.diagnosisCID10') }}</label>
            <select id="diagnosis_select" class="js-example-basic-multiple js-states form-control" name="diagnosis_select" multiple="multiple" style="width:100%;"></select>
            <div id="selectedDiagnosis" style="margin: 10px; display: flex; flex-wrap: wrap;"></div>
          </div>

          <div class="toolbar">
            <div class="">
              <v-select v-if="!currentReport.isQuickReport"
                class="v-select columns"
                v-model="selectedTemplate"
                label="name"
                :reduce="x => x.id"
                :options="templateOptions"
                @option:selected="switchTemplate">
              </v-select>
              <v-select
                class="v-select columns"
                v-model="currentReport.columns"
                :reduce="x => x.value"
                :options="columnOptions">
              </v-select>

              <button v-if="!currentReport.isQuickReport" class="btn btn-success" type="button" name="button"
                @click="openModalObservations">
                {{ $t('exam.observations') }}
              </button>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="fullWidthPanoramic" v-model="currentReport.fullWidthPanoramic">
                <label class="form-check-label" for="fullWidthPanoramic">{{ $t('exam.panoProcessOnReport') }}</label>
              </div>
            </div>

            <div>
              <button class="btn btn-danger" type="button" name="button" @click="goBack">{{ $t('cancel') }}</button>
              <button class="btn btn-primary" type="button" name="button" @click="doCreate">{{ $t(currentReport.id ? 'updateAction' : 'create') }}</button>
            </div>
          </div>

          <ReportQuick v-if="currentReport.isQuickReport && isLoaded"
            :exam="exam"
            :observation="currentReport.observation"
            :loaded_entries="loadedDiagnosisEntries"
            @entries-changed="setQuickReportData">
          </ReportQuick>

          <ReportForm v-if="!currentReport.isQuickReport && isLoaded" ref="reportForm"
            :content="customTemplate"
            @custom-template="setCustomTemplate">
          </ReportForm>

          <teleport to="body">
            <ReportObservationModal v-if="showModalObservations"
              :observation="currentReport.observation"
              @close="closeModalObservations">
            </ReportObservationModal>
          </teleport>

        </form>
      </div>
    </div>

  </div>
</template>

<style src="./report.scss" lang="scss" scoped></style>
<script src="./report.js"></script>
