<template>
  <div class="" v-loader-eyerweb.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('patient.generalData') }}</h3>
        <div class="form-group">
          <label for="firstName">{{ $t('patient.firstName') }}*</label>
          <vee-field name="firstName" v-model="patient.firstName" rules="required" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="firstName" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="lastName">{{ $t('patient.lastName') }}*</label>
          <vee-field name="lastName" v-model="patient.lastName" rules="required" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="lastName" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="mrn">
            {{ $t('mrn') }}
            <span v-if="mandatoryDocument === 'mrn'">*</span>
          </label>
          <vee-field name="mrn" v-model="patient.mrn" :rules="`${mandatoryDocument === 'mrn' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="mrn" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="birthday">{{ $t('patient.birthday') }}</label>
          <vee-field name="birthday" v-model="patient.birthdayAux" :rules="`date:${$t('dateFormat')}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control"  v-maska :data-maska="patient.birthdayAux ? $t('inputMask.date') : ''" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
      </div>

      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('patient.documents') }}</h3>
        <div class="form-group">
          <label for="document0">
            {{ $t('document0') }}
            <span v-if="mandatoryDocument === 'document0'">*</span>
          </label>
          <vee-field name="document0" v-model="patient.document0" :rules="`${mandatoryDocument === 'document0' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document0" v-maska :data-maska="$t('inputMask.document0')" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="document1">
            {{ $t('document1') }}
            <span v-if="mandatoryDocument === 'document1'">*</span>
          </label>
          <vee-field name="document1" v-model="patient.document1" :rules="`${mandatoryDocument === 'document1' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document1" v-maska :data-maska="$t('inputMask.document1')" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="document2">
            {{ $t('document2') }}
            <span v-if="mandatoryDocument === 'document2'">*</span>
          </label>
          <vee-field name="document2" v-model="patient.document2" :rules="`${mandatoryDocument === 'document2' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document1" v-maska :data-maska="$t('inputMask.document2')" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="document3">
            {{ $t('document3') }}
            <span v-if="mandatoryDocument === 'document3'">*</span>
          </label>
          <vee-field name="document3" v-model="patient.document3" :rules="`${mandatoryDocument === 'document3' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document3" v-maska :data-maska="$t('inputMask.document3')" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useEyerwebStore } from '@/store/eyerweb'

export default {
  name: 'eyerweb-patient-create',
  props: ['patient_id'],
  data: () => ({
    loading: false,
    disabled: true,
    alert: {
      message: null,
      class: null,
    },
    session: {
      collaborator: {},
    },
    patient: {
      id: null,
      firstName: '',
      lastName: '',
      mrn: '',
      birthday: '',
      birthdayAux: '',
      document0: '',
      document1: '',
      document2: '',
      document3: '',
    },
    mandatoryDocument: null,
    DATE_FORMAT: ''
  }),
  watch: {
    patient_id: {
      immediate: true,
      handler: function (patient_id) {
        console.log('patient_id: ', patient_id)

        if (patient_id) {
          this.fetchData()
        }
    }, deep: true }
  },
  mounted() {
    this.DATE_FORMAT  = this.$i18n.t('dateFormat')
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo
    this.$eyerwebService.patientSubject.subscribe((result) => {
      this.loadData(result)
      this.loading = false
      NProgress.done(true)
    })
    //this.mandatoryDocument = this.session.currentClinic.mandatoryDocument
  },
  methods: {
    async fetchData() {

      this.alert.message = null

      let params = {
        id: this.patient_id
      }

      this.loading = true
      NProgress.start()
      try {
        this.$eyerwebService.getPatient(params)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }

    },
    loadData(response){
      this.patient = response.patient

      this.patient.birthdayAux = this.patient.birthday ? moment.utc(this.patient.birthday).format(this.DATE_FORMAT) : ""

      let rawName = this.patient.firstName + ' ' + this.patient.lastName
      this.$emit('patient_name', rawName)
    }
  }
}
</script>
