<template>
  <div class="col-md-6">
    <h3 class="sub-title">{{ $t('patient.ophthalmicDiseases') }}</h3>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="diabeticRetinopathy" v-model="ophthalmic.diabeticRetinopathy" :disabled="disabled">
      <label class="form-check-label" for="diabeticRetinopathy">
        {{ $t('disease.diabeticRetinopathy') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="dmri" v-model="ophthalmic.dmri" :disabled="disabled">
      <label class="form-check-label" for="dmri">
        {{ $t('disease.amd') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="glaucoma" v-model="ophthalmic.glaucoma" :disabled="disabled">
      <label class="form-check-label" for="glaucoma">
        {{ $t('disease.glaucoma') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="cataract" v-model="ophthalmic.cataract" :disabled="disabled">
      <label class="form-check-label" for="cataract">
        {{ $t('disease.cataract') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="pterygium" v-model="ophthalmic.pterygium" :disabled="disabled">
      <label class="form-check-label" for="pterygium">
        {{ $t('disease.pterygium') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="lowVisualAcuity" v-model="ophthalmic.lowVisualAcuity" :disabled="disabled">
      <label class="form-check-label" for="lowVisualAcuity">
        {{ $t('disease.lowVisualAcuity') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="nwn_ophthalmic" v-model="ophthalmic.nwn_ophthalmic" :disabled="disabled">
      <label class="form-check-label" for="nwn_ophthalmic">
        {{ $t('disease.nwn_ophthalmic') }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatientCreateOphthalmicDiseases',
  props: ['disabled', 'ophthalmic'],
  data: () => ({
  }),
  mounted() {
  },
  methods: {
  }
}
</script>
