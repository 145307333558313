<template>
  <div class="profile">
    <div class="avatar-box">
      <button class="btn-icon" type="button" name="button" @click="logout()">
        <i class="icon-logout"></i>
      </button>

      <div class="avatar" @click="profileMenu()">
        <img :src="`${avatar}`" alt="" data-title="">
      </div>

      <button class="btn-icon btn-notif" :class="{'btn-spinner': isLoading}" type="button" name="button" id="notifications" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="resetNotifications">
        <i class="icon-alert"></i>
        <span id="notification-count" class="notification-count">{{ notificationCount }}</span>
      </button>

      <Notifications :notification="notification"></Notifications>
    </div>

    <div class="info-box">
      <p>{{ $t('welcome') }}, </p>
      <p class="no-wrap">{{ session.collaborator.firstName }}</p>
      <p>
        <router-link :to="`/clinic/${session.currentClinic.id}`" class="text-uppercase">
          {{ session.currentClinic.name }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile {
  border-top:                         1px solid $bdazzled;
  border-bottom:                      1px solid $bdazzled;
  padding:                            10px;
  .avatar-box {
    display:                          flex;
    justify-content:                  space-around;
    align-items:                      center;
    button {
      // @include                                transition(all 0.5s ease);
    }
    .btn-icon {
      margin:                         0;
      &.btn-notif {
        position:                     relative;
        .notification-count {
          @include                    border_radius(50%);
          position:                   absolute;
          top:                        -6px;
          background-color:           $arylide;
          padding:                    3px 8px 2px;
          right:                      -11px;
          width:                      23px;
          height:                     23px;
        }
      }
      i {
        font-size:                    1.4em;
      }
    }
    .btn-spinner:after {
      content:                        "";
      position:                       absolute;
      width:                          35px;
      height:                         35px;
      top:                            0;
      right:                          0;
      left:                           0;
      bottom:                         0;
      margin:                         auto;
      border:                         2px solid transparent;
      border-top-color:               white;
      @include                        border_radius(50%);
      animation:                      button-loading-spinner 1s ease infinite;

    }
    .avatar {
      // @include                                transition(all 0.5s ease);
      cursor:                         pointer;
      &:hover,
      &:focus {
        @include                      transition(all .5s ease-in-out);
        transform:                    scale(1.1);
      }
      img {
        max-height:                   85px;
        max-width:                    85px;
        height:                       auto;
        width:                        auto;
      }
    }
  }
  .info-box {
    margin-top:                       15px;
    p {
      text-align:                     center;
      color:                          white;
      margin:                         0;
      &:first-child {
        font-size:                    13px;
        color:                        $meadow;
      }
      &:nth-child(2) {
        font-size:                    18px;
        line-height:                  1.45;
      }
      &:last-child {
        font-size:                    10px;
        line-height:                  1.4;
        letter-spacing:               2.4px;
        cursor:                       pointer;
      }
    }
    a {
      color:                          white;
      text-decoration: none;
    }
  }
}

.main.retract {
  .info-box {
    display:                        none;
  }
  .avatar-box {
    // -webkit-box-orient:             vertical;
    // -moz-box-orient:                vertical;
    -webkit-flex-direction:         column-reverse;
    -ms-flex-direction:             column-reverse;
    flex-direction:                 column-reverse;
    button {
      width:                        30px;
      height:                       30px;
      padding:                      5px 0;
      margin:                       5px 0;
      display: block;
      &:first-child {
        // -webkit-box-ordinal-group:  2;
        // -moz-box-ordinal-group:     2;
        // -ms-flex-order:             2;
        // -webkit-order:              2;
        // order:                      2;
        margin:                     5px 0 0;
      }
      &.btn-notif {
        // -webkit-box-ordinal-group:  3;
        // -moz-box-ordinal-group:     3;
        // -ms-flex-order:             3;
        // -webkit-order:              3;
        // order:                      3;
        &:after {
          width:                    30px;
          height:                   30px;
        }
      }
    }
  }
  .avatar {
    display: block;
    -webkit-box-ordinal-group:      1;
    -moz-box-ordinal-group:         1;
    -ms-flex-order:                 1;
    -webkit-order:                  1;
    order:                          1;
    margin-bottom:                  10px;
    img {
      width:                        40px;
      height:                       100%;
    }
  }
}

@media (max-width: 767px) {
  .profile {
    .avatar-box {
      -webkit-box-orient:             vertical;
      -moz-box-orient:                vertical;
      -webkit-flex-direction:         column;
      -ms-flex-direction:             column;
      flex-direction:                 column;
      button {
        width:                        30px;
        height:                       30px;
        padding:                      5px 6px;
        margin:                       0;
        &:first-child {
          -webkit-box-ordinal-group:  2;
          -moz-box-ordinal-group:     2;
          -ms-flex-order:             2;
          -webkit-order:              2;
          order:                      2;
          margin:                     5px 0 !important;
        }
        &:last-child {
          -webkit-box-ordinal-group:  3;
          -moz-box-ordinal-group:     3;
          -ms-flex-order:             3;
          -webkit-order:              3;
          order:                      3;
        }
      }
      .avatar {
        -webkit-box-ordinal-group:    1;
        -moz-box-ordinal-group:       1;
        -ms-flex-order:               1;
        -webkit-order:                1;
        order:                        1;
        margin-bottom:                10px;
        img {
          width:                      40px;
          height:                     100%;
        }
      }
    }
    .info-box {
      display:                        none;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 991px) {
  @media screen and (min-height: 600px) and (max-height: 768px) {
    .profile {
      .avatar {
        img {
          height:                     60px;
        }
      }
      .info-box {
        p {
          &:first-child {
            font-size:                11px;
          }
          &:nth-child(2) {
            font-size:                16px;
          }
          &:last-child {
            font-size:                8px;
          }
        }
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import Notifications from './Notifications.vue'
import profile_img from "@/assets/images/phelcom/profile_img.png";

export default {
  name: 'MenuProfile',
  components: {
    Notifications
  },
  data: () => ({
    profileAvatar: false,
    notificationData: {
      count: 0,
      events: []
    },
    currentClinicID: null,
    key: null,
    notificationCount: 0,
    notification: [],
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isLoading: false
  }),
  computed: {
    avatar() {
      return this.session.userPhoto ? this.session.userPhoto : profile_img;
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.currentClinicID = this.session.currentClinic.id
    const uuid = this.session.currentClinic.uuid
    this.key = `clinic_${this.currentClinicID}_${uuid}`
    // this.listenNotificationEvents()

    this.$nextTick(() => {
      this.reloadNotifications()
    })

    this.emitter.on("profilePhotoChanged", imagePath => {
      this.session.userPhoto = imagePath
    })

    // called from notificationService (logic is there)
    this.emitter.on("newNotification", event => {
      this.notification.push(event)
      this.notificationCount += 1 // view logic
    })

  },
  methods: {
    profileMenu: async function() {
      this.session.activeLink = 'users'
      this.$router.push(`/user/${this.session.user.id}`)
    },
    async clinicDetails() {
      NProgress.start()
      try {
        let params = {
          id: this.session.currentClinic.id
        }

        let response = await this.$clinicService.getClinic(params);
        const freshClinic = response.clinic
        this.session.activeLink = 'clinic'

        let data = this.$utils.dataQuery({
          clinic: JSON.stringify(freshClinic)
        })

        this.$router.push({name: 'clinic-show', params: {clinicID: freshClinic.id}, query: {tab: 'data', data: data}})
      } catch (err) {
        console.log("unexpectedError", err)
      }
      NProgress.done(true)
    },

    async logout() {
      await this.$authService.logout(this)
      window.open('/', '_self');
    },

    reloadNotifications() {
      if (!this.currentClinicID)
        return

      const clinicData = this.$notificationService.reloadNotifications(this.currentClinicID)
      for (let e of clinicData.events) {
        this.addNotification(e)
      }

      this.notificationCount = clinicData.count
    },

    // bell icon clicked
    resetNotifications() {
      this.notificationCount = 0
      this.$notificationService.resetCount(this.currentClinicID)
      this.$notificationService.enable() // force notification activation
    },

    addNotification(data) {
      try {
        this.notification.unshift(data)
      } catch (e) {
        console.log('error(addNotification)', e);
      }
    },

    notificationClicked: async function(examid) {
      try {
        let params = {
          id: examid,
        }

        let response = await this.$examService.get(params);

        let data = this.$utils.dataQuery({
          exam: JSON.stringify(response.exam)
        })

        this.$router.push({name: 'exam-show', params: {examID: examid}, query: {tab: 'images', data: data}})
      } catch(err) {
        this.alert = {
          message: `${this.$i18n.t('request_error')} <strong>${err}</strong>`,
          class: 'alert-danger'
        }
      }
    },
  }
}

</script>
