<template>
  <div class="">
    <ul class="menu-links">
      <div>
        <!--<li :class="[active === 'patient' ? 'active' : '', {'disabled': disabled}]"> -->
       <li :class="active === 'patient' ? 'active' : ''">
         <a @click="patientMenu()">
           <!-- <i class="icon-icon_patient"></i> -->
           <img src="@/assets/images/icon_patient.svg" alt="">
           <span>{{ $t('menu.patients') }}</span>
         </a>
       </li>
       <!-- <li :class="[active === 'exam' ? 'active' : '', {'disabled': disabled}]"> -->
        <li :class="active === 'exam' ? 'active' : ''">
          <a @click="examMenu()">
            <!-- <i class="icon-icon_exam"></i> -->
            <img src="@/assets/images/icon_exam.svg" alt="">
            <span>{{ $t('menu.exams') }}</span>
          </a>
        </li>
      </div>
      <!-- <li :class="active === 'backup' ? 'active' : ''">
        <a @click="backupMenu()">
          <i class="fa fa-download" aria-hidden="true"></i>
          <span>{{ $t('menu.backup') }}</span>
        </a>
      </li> -->
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .menu-links {
    font-family:                        'Ubuntu', 'Arial', sans-serif;
    list-style:                         none;
    // margin-top:                         10px;
    padding:                            unset;
    li {
      &:hover,
      &:focus,
      &:active,
      &.active {
        a {
          color:                        var(--shades-w, #FFF);
          background:                   var(--brand-secondary, #3CB391);
          border:                       1px solid var(--brand-secondary, #3CB391);
          border-left:                  5px solid var(--shades-w, #FFF);
        }
      }
      &:first-child {
        a {
          img {
            height:                     36px;
            margin-right:               31px;
          }
        }
      }
      &:nth-child(2) {
        a {
          img {
            height:                     42px;
            margin-right:               29px;
          }
        }
      }
      a {
        @include                        border_radius(5px);
        border:                         1px solid rgba(27, 41, 74, 0.89);
        background:                     rgba(27, 41, 74, 0.15);
        height:                         65px;
        font-size:                      14px;
        font-weight:                    500;
        text-transform:                 uppercase;
        letter-spacing:                 3px;
        display:                        flex;
        align-items:                    center;
        padding:                        11px 17px !important;
        margin-bottom:                  16.68px;
        text-decoration:                none;
        cursor:                         pointer;
        img {
        }
      }
      &.disabled {
        opacity:                        0.7;
        cursor:                         not-allowed;
        a {
          pointer-events:               none;
          &:hover,
          &:focus {
            color:                      inherit;
          }
        }
      }
    }
  }

  .main.retract {
    .menu {
      .menu-links {
        a > span {
          display:                      none;
        }
        li a {
          padding:                      10px !important;
        }
      }
      .level-box {
        a {
          display:                      block !important;
        }
        p {
          display:                      none !important;
        }
      }
      .wizard-level {
        width:                          auto !important;
        margin-bottom:                  10px !important;
        li {
          &.active {
            display:                    block !important;
            margin:                     0 auto;
          }
          &,
          &.past,
          &:before,
          & small {
            display:                    none !important;
          }
        }
      }
    }
  }

  // @media (max-width: 767px) {
  //   .menu-links {
  //     a span {
  //       display:                        none;
  //     }
  //     li a {
  //       padding:                        10px;
  //     }
  //   }
  // }
  //
  // @media screen and (min-width: 768px) and (max-width: 1500px) {
  //   .menu-links {
  //     li a {
  //       padding:                        7px 15px;
  //     }
  //     img, svg {
  //       max-height:                     22px;
  //     }
  //   }
  // }
  //
  // @media screen and (min-width: 800px) and (max-width: 991px) {
  //   @media screen and (min-height: 600px) and (max-height: 768px) {
  //     .menu-links {
  //       li a {
  //         font-size:                    10px;
  //         padding:                      10px 15px;
  //       }
  //       img, svg {
  //         max-height:                   18px;
  //       }
  //     }
  //   }
  // }
</style>

<script>
import { useEyerwebStore } from '@/store/eyerweb'

export default {
  name: 'EyerwebMenuDesktopLinks',
  data: () => ({
    active: null,
    session: null,
    disabled: false,
  }),
  watch: {
    '$activeLink.value': {
      handler: function () {
        this.active = this.$activeLink.value
    }, deep: true },
  },
  mounted: function() {
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo
    this.active = this.$activeLink.value ? this.$activeLink.value : null
  },
  methods: {
    examMenu: async function() {
      this.session.examCurrentPage = 1
      this.goToLink(this, 'exam', 'eyerweb-exam-list')
    },
    patientMenu: async function() {
      this.session.patientCurrentPage = 1
      this.goToLink(this, 'patient', 'eyerweb-patient-list')
    },
    backupMenu: async function() {
      // this.goToLink(this, 'backup', 'eyerweb-full-backup')
    },
    async logout() {
      this.$emit('logout')
    },
    goToLink(self, active, link) {
      self.active = active

      self.$router.push({name: link, query: { ...self.$route.query, t: Date.now() }})
      // self.$router.push({name: 'proxy-router', params: {
      //   target: link,
      //   data: {}
      // }})
    }
  }
}
</script>
