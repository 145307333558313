<template>
  <div class="maintenance-main outside-box">
    <AuthHeader></AuthHeader>

    <div class="maintenance-box">
      <div class="">
        <h1>Oops!</h1>
        <h3>{{ $t('maintenance.underMaintenance') }}</h3>
        <p>
          {{ $t('maintenance.tryMaintenance') }} <br>
          {{ $t('maintenance.infoMaintenance') }}
        </p>

        <div class="btn-group">
          <a href="https://phelcom.com/pt-br/suporte/" target="_blank" class="btn btn-outline-primary">{{ $t('eyerweb.supportTeam') }}</a>
          <router-link to="/login" class="btn btn-primary text-uppercase">
            {{ $t('login.login') }}
          </router-link>
        </div>
      </div>
      <img src="@/assets/images/erjart/maintenance.png" alt="Maintenance icon">
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<style lang="scss">
  .maintenance-main {
    width:                              100%;
    .maintenance-box {
      @include                          border_radius(10px);
      display:                          flex;
      justify-content:                  space-between;
      align-items:                      center;
      background-color:                 white;
      text-align:                       center;
      padding:                          30px;
      margin-left:                      auto;
      margin-right:                     auto;
      max-width:                        900px;
      img {
        margin-left:                    50px;
        width:                          250px;
      }
      h1 {
        color:                          $charcoal;
        font-weight:                    bold;
      }
      h3 {
        color:                          $light-slate;
      }
      p {
        font-size:                      1.2em;
        margin-top:                     20px;
        max-width:                      400px;
      }
      .btn-group {
        display:                        flex;
        justify-content:                space-between;
      }
      .btn {
        @include                        border_radius(8px);
        text-transform:                 uppercase;
        letter-spacing:                 3px;
        font-size:                      1.2em;
        padding:                        0.375rem 1.5rem;
        margin-top:                     30px;
        .btn-outline-primary {
          border-color:                 $phelcom-blue;
        }
        .btn-primary {
          background-color:             $phelcom-blue;
          border-color:                 $phelcom-blue;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .maintenance-main {
      .maintenance-box {
        flex-direction:                  column-reverse;
        padding:                         15px;

        img {
          margin-left:                   0;
          margin-bottom:                 15px;
          width:                         150px;
        }
      }
      .btn-group {
        flex-direction:                  column;
      }
    }
  }
</style>

<script>
  import AuthFooter from './auth/AuthFooter.vue';
  import AuthHeader from './auth/AuthHeader.vue';

  export default {
    name: 'maintenance',
    components: {
      AuthFooter,
      AuthHeader,
    },
  }
</script>
