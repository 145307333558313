<template>
  <div class="tab-box">
    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="tab in tabs" :key="tab">
        <button class="nav-link" :class="{'active': activeTab === tab}" :id="tab" type="button" @click="switchTab(tab)">
          <slot :name="tabHeadName(tab)">{{ tab }}</slot>
        </button>
      </li>
    </ul>

    <div class="tab-content">
      <div class="tab-pane fade" :class="{'show active':  activeTab === tabSlotName}" :id="tabSlotName">
        <slot :name="tabSlotName"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['initialtab', 'tabs'],
  name: 'BaseTab',
  data() {
    return {
      activeTab: this.initialtab
    };
  },
  computed: {
    tabSlotName() {
      return `${this.activeTab}`;
    }
  },
  methods: {
    tabHeadName(tab) {
      return `tab-head-${tab}`;
    },

    switchTab(tab) {
      this.activeTab = tab;
      this.$emit('tab-activated', tab);
    }
  }
};
</script>
