<template>
  <div class="outside-box">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-8 col-lg-7">
        <div class="registration-info">
          <h2 class="registration-title">{{ $t('createAccount.createAccount') }}</h2>

          <hr>

          <h3>
            {{ $t('createAccount.success') }}
          </h3>

          <h2>
            <span>{{ user.collaborator.firstName }}</span>,
            {{ $t('createAccount.emailSent') }}
            <span> {{ user.email }}</span>.
          </h2>
        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<style lang="scss" scoped>
.registration-info h2 span {
  font-weight:                  500;
}
</style>

<script>
  import AuthFooter from '../../auth/AuthFooter.vue'
  import AuthHeader from '../../auth/AuthHeader.vue'

  export default {
    name: 'registration-account-created',
    components: {
      AuthFooter,
      AuthHeader,
    },
    data: () => ({
      user: {
        collaborator: {}
      }
    }),

    mounted() {
      try {
        let data = this.$utils.parseDataQuery(this.$route.query.data)
        this.user = data.user
      } catch(err) {
        this.$message.popup(this, err.message, 'operationFail', 'error')
        console.log("error:", err)
      }
    },
  }
</script>
