<template>
  <div class="">
    <div class="mb-3 row">
      <label for="phone" class="col-md-3 col-form-label">{{ $t('createAccount.telephone') }}*</label>
      <div class="col-md-6">
        <vee-field name="phone" v-model="account.telephone" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" id="phone" ref="phone">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="mobile" class="col-md-3 col-form-label">{{ $t('createAccount.mobile') }}</label>
      <div class="col-md-6">
        <input class="form-control" type="text" id="mobile" name="mobile" v-model="account.mobile">
      </div>
    </div>
    <div class="mb-3 row">
      <label for="telephone2" class="col-md-3 col-form-label">{{ $t('createAccount.telephone2') }}</label>
      <div class="col-md-6">
        <input class="form-control" type="text" id="telephone2" name="telephone2" v-model="account.telephone2">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationUserFormStepTwo',
  props: ['account'],
  mounted() {
    this.$nextTick(() => {
      this.$refs.phone.focus()
    })
  }
}
</script>
