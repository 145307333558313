<template>
  <div class="" v-loader.opacity="loading">
    <div class="title-box clear">
      <button class="btn-back" type="button" name="button" @click="$router.back()">
        <i class="icon-back"></i>
      </button>

      <h2 class="title">{{ $t('createDevice.newDevice') }}</h2>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
    </div>

    <div class="text-center">
      <div id="qrCodeDiv" @dblclick="copyQRCode()"></div>
      <h3>{{ $t('createDevice.waiting') }}</h3>
      <img src="@/assets/images/loading_points.gif"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-center img {
  margin:                  20px auto 40px;
}

@media (max-width: 767px) {
  .title-box .title {
    width:                 auto !important;
  }
}
</style>

<script>
import kjua from 'kjua'

export default {
  name: 'device-create',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    qrCode: null,
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$deviceService.registerDevice()

        this.initializeWebsocket(response.qrcode);
        this.bindQRCode(response.qrcode);
      } catch (err) {
        console.log(err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },

    initializeWebsocket(data) {
      const io = this.$io
      console.log("webSocketInitialization() isConnected?", io.socket.isConnected())

      for (let e of ['registrationStarted', 'deviceRegistration']) {
        console.log('[new-device] unlisten to ws event:', e)
        io.socket.off(e);
      }

      if (io.socket.isConnected()) {
        console.log("io socket connection active, listening now!")
        this.listenToWebsocketEvents(data)
        return
      }

      io.socket.on('connect', () => {
        this.listenToWebsocketEvents(data);
      });

      io.socket.on('disconnect', () => {
        console.log("DICONNECT")
      });

      this.listenToWebsocketEvents(data)
    },
    listenToWebsocketEvents(data) {
      const io = this.$io

      io.socket.get('/online/deviceRegistration?id=' + data.sessionKey, function gotResponse(body, response) {
        console.log('device registration response > ', response);
        console.log('device registration body > ', body);
      });

      io.socket.on('registrationStarted', function(data) {
        console.log("Registration started: " + JSON.stringify(data));
  		});

      io.socket.on('deviceRegistration', data => {
        console.log("deviceRegistration data:", data);

        if (data.error) {
          this.$message.popup(this, 'operationFail', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'operationFail', 'danger')
        } else {
          this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
          this.$router.push({name: 'device-list', query: { ...this.$route.query, t: Date.now() }})
        }
  		});
    },
    bindQRCode(data) {
      this.qrCode = JSON.stringify(data)
      let options = {
        render: 'canvas', // render method: 'canvas', 'image' or 'div'
        crisp: true,
        // version range somewhere in 1 .. 40
        minVersion: 1,
        maxVersion: 40,
        // error correction level: 'L', 'M', 'Q' or 'H'
        ecLevel: 'L',
        // size in pixel
        size: 200,
        // pixel-ratio, null for devicePixelRatio
        ratio: null,
        // code color or image element
        fill: '#000',
        // background color or image element, null for transparent background
        background: null,
        // content
        text: this.qrCode,
        rounded: 0,
        // quiet zone in modules
        quiet: 0,

        mode: "label",

        mSize: 15,
        mPosX: 50,
        mPosY: 50,

        label: 'Eyer',
        fontname: 'sans',
        fontcolor: '#60bda2'
      }

      const qrCodeElement = kjua(options);
      qrCodeDiv.appendChild(qrCodeElement);
    },
    copyQRCode() {
      navigator.clipboard.writeText(this.qrCode);
    }
  },
}
</script>
