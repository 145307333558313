<template>
  <div class="">
    <div class="mb-3 row">
      <label for="clinicName" class="col-md-3 col-form-label">{{ $t('createClinic.clinicName') }}*</label>
      <div class="col-md-6">
        <vee-field name="clinicName" v-model="clinic.clinicName" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="clinicName" id="clinicName">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="mandatoryDocument" class="col-md-3 col-form-label">{{ $t('createClinic.mandatoryDocument') }}*</label>
      <div class="col-md-6">
        <v-select
          class="v-select form"
          v-model="mandatorySelected"
          :reduce="x => x.value"
          :options="mandatoryOptions"
          @option:selected="refreshMandatoryDocument">
        </v-select>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="language" class="col-md-3 col-form-label">{{ $t('createClinic.language') }}*</label>
      <div class="col-md-6">
        <v-select
          class="v-select form"
          v-model="clinicLocale"
          :reduce="x => x.value"
          :options="localeOptions"
          @option:selected="setLocale">
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationClinicStepOne',
  props: ['clinic'],
  data: () => ({
    mandatoryOptions: [],
    localeOptions: [],
    mandatorySelected: 'none',
    clinicLocale: null,
  }),
  created() {
    this.mandatoryOptions = [
      {value: 'none', label: this.$i18n.t('none')},
      {value: 'mrn', label: this.$i18n.t('mrn')},
      {value: 'document0', label: this.$i18n.t('document0')},
      {value: 'document1', label: this.$i18n.t('document1')},
      {value: 'document2', label: this.$i18n.t('document2')},
      {value: 'document3', label: this.$i18n.t('document3')},
    ];
    this.localeOptions = [
      {value: 'pt-br', label: this.$i18n.t('locales.pt-br')},
      {value: 'en', label: this.$i18n.t('locales.en')},
      {value: 'es', label: this.$i18n.t('locales.es')},
    ];
  },
  mounted() {
    let language = this.currentLanguage()
    this.clinicLocale = language
    if (this.clinic.mandatoryDocument)
      this.mandatorySelected = this.clinic.mandatoryDocument

    if (this.clinic.locale)
      this.clinicLocale = this.clinic.locale

    this.$nextTick(() => {
      this.$refs.clinicName.focus()
    })
  },
  methods: {
    refreshMandatoryDocument(document) {
      if (document == undefined)
        return

      this.clinic.mandatoryDocument = document.value
    },
    setLocale(locale) {
      if (locale == undefined)
        return

      this.clinic.locale = locale.value
    },
    currentLanguage() {
      return this.$utils.getCurrentLanguage()
    }
  }
}
</script>
