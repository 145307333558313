<template>
  <div class="modal fade" :id="modal_id"
       data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="modal_generic_label" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header danger">
          <h5 class="modal-title" id="modal_generic_label">{{ title }}</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="mb-2">{{ $t('csv.errorInfoMessage') }}</p>

          <div id="errorsBox">
            <p v-for="(item, index) in text">
              <b>{{ $t('csv.row') }}</b>
              <span class="mr-3">{{ item.row }}</span>
              <b>{{ $t('csv.error') }}</b>
              <span>{{ $t(`csv.${item.error}`) }}</span>
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="downloadObjectAsJson()">{{ $t('csv.downloadErrors') }}</button>
          <button type="button" class="btn btn-primary" :class="okButtonClass" data-bs-dismiss="modal" @click="doConfirm">{{ okButton }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-dialog {
  .modal-header {
    display:                    flex;
    background-color:           $yellow-phelcom;
    border-bottom:              1px solid $platinum;
    h5 {
      font-size:                18px;
    }
  }
  .modal-body {
    max-height:                 300px;
    overflow-y:                 scroll;
    p {
      font-size:                14px;
      // margin:                   0;
      span {
        padding-right:          10px;
      }
    }
  }
  .modal-footer {
    .btn-outline-secondary {
      border-color:             $gray;
    }
  }
}
</style>

<script>
export default {
  name: 'BaseGenericModal',
  props: ['modal_id'],
  data: () => ({
    title: null,
    text: null,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    modal_generic: null,
  }),
  mounted() {
  },
  methods: {
    show(options = {}) {
      this.title = options.title
      this.text = options.text
      this.okButton = options.okButton
      this.okButtonClass = options.okButtonClass
      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      this.modal_generic = new bootstrap.Modal(document.getElementById(this.modal_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.modal_generic = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.modal_generic = null;
      this.resolvePromise(false)
    },

    downloadObjectAsJson(){
      let elHtml = document.getElementById('errorsBox');
      let text = elHtml.innerText || elHtml.textContent;
      let link = document.createElement('a');
      let mimeType = 'text/plain' || 'text/html';

      link.setAttribute('download', 'csv-errors.txt');
      link.setAttribute('href', `data:${mimeType};charset=utf-8,${encodeURIComponent(text)}`);
      link.click();
    }
  }
}
</script>
