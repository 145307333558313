<template>
  <div class="main-box patient-list-box" v-loader.opacity="loading">
    <div class="title-box rounded clear">
      <h2 class="title">{{ $t('patient.patientList') }}</h2>

      <button class="btn btn-outline-primary" type="button" name="button" @click="newPatient" v-if="!isSpecialist">
        {{ $t('patient.create') }}
      </button>

      <div class="input-group">
        <input type="text" class="form-control" v-model="search" :placeholder="$t('patient.searchMessage')"
               :aria-label="$t('patient.searchMessage')" aria-describedby="searchPatient" @keypress.enter="fetchData">
        <span class="input-group-text" id="searchPatient" @click="fetchData">{{ $t('patient.search') }}</span>
      </div>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable v-if="tablePatient" :tabledata="tablePatient" @item-opened="toDetails">
    </BaseTable>

    <div class="row align-items-center page-row" v-if="tablePatient">
      <div class="col">
        <p>{{ $t("pagination.totalEntries") }} {{ tablePatient.total }}</p>
      </div>
      <div class="col-auto">
        <BasePagination :total-pages="totalPages" :total="tablePatient.total" :per-page="perPage" :current-page="currentPage" @pagechanged="onPageChange"/>
      </div>
    </div>

    <BaseGenericModal :modal_id="'modalPatientGeneric'" ref="modalGeneric"></BaseGenericModal>
  </div>
</template>

<style lang="scss" scoped>
.input-group,
.title-box,
.title-box .btn {
  margin-bottom:                         0;
}

.page-row,
.page-row p,
.table {
  margin-bottom:                         0 !important;
}

.page-row {
  margin-top:                            20px;
  p,
  .page-row .pagination {
    padding-right:                       10px;
    padding-left:                        10px;
  }
}

.title-box {
  .input-group {
    display:                             flex !important;
    width:                               50%;
    max-width:                           550px;
    input {
      @include                           border_radius(20px 0 0 20px);
    }
    span {
      @include                           border_radius(0 20px 20px 0);
      font-size:                         1.2em;
      color:                             inherit;
      padding:                           0 15px;
      cursor:                            pointer;
    }
  }
}

@media (max-width: 767px) {
  .patient-list-box .title-box .title {
    width:                               calc(100% - 90px);
  }
}

@media (max-width: 991px) {
  .input-group {
    margin-top:                          10px;
    width:                               100% !important;
    max-width:                           unset !important;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'patient-list',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    tablePatient: null,
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    search: null
  }),
  computed: {
    isSpecialist() {
      return this.session.permissions === 'specialist'
    }
  },
  mounted() {
    this.$activeLink.value = 'patient'
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.currentPage = this.session.patientCurrentPage ? this.session.patientCurrentPage : 1;
    this.fetchData()
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.session.patientCurrentPage = page;
      this.fetchData();
    },
    fetchData: async function() {
      this.alert = {}

      let params = {
        page: this.currentPage
      }
      if (this.search)
        params.q = this.search

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getList(params)

        this.tablePatient = {
          'items': response.result,
          'total': response.totalCount,
          'columns': [
            {name: this.$i18n.t('patient.name'), type: 'string', value: 'rawName', width: '60%'},
            {name: this.$i18n.t('patient.mrn'), type: 'string', value: 'mrn', width: '15%'},
            {name: this.$i18n.t('document0'), type: 'string', value: 'document0', width: '25%'}
          ]
        }

        this.totalPages = Math.ceil(response.totalCount / this.perPage);

        if (response.result.length === 0) {
          this.tablePatient = {};
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async newPatient() {
      let text = this.$i18n.t('termsPatient.infoPatient') + this.$i18n.t('termsPatient.authPatient')

      const ok = await this.$refs.modalGeneric.show({
        modalType: 'warning',
        title: this.$i18n.t('warning'),
        text: text,
        okButton: this.$i18n.t('termsPatient.accept'),
        okButtonClass: 'btn-primary',
        cancelButton: this.$i18n.t('termsPatient.doNotAccept'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.$router.push({name: 'patient-create', params: {patientID: ''}})
      }
    },
    toDetails(item) {
      this.$router.push({name: 'patient-show', params: {patientID: item.id}, query: {tab: 'data'}})
    },
  },
  // unmounted() {
  //   this.session = null
  // }
}
</script>
