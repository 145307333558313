<template>
  <div class="outside-box">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-8 col-lg-7">
        <div class="registration-info created">
          <h2 class="registration-title">
            {{ $t('createAccount.clinicRegistration') }}
            <small>{{ $t('createAccount.firstAccessTitle') }}</small>
          </h2>

          <hr>

          <h3>
            <span>{{ firstName }}</span>,
            {{ $t('createAccount.congratulation') }}
          </h3>

          <h2>{{ $t('createAccount.clickBelow') }}</h2>

          <center>
            <a href="/login" class="btn btn-success">{{ $t('login.login') }}</a>
          </center>
        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<script>
  import AuthFooter from '../../auth/AuthFooter.vue'
  import AuthHeader from '../../auth/AuthHeader.vue'

  export default {
    name: 'registration-clinic-created',
    components: {
      AuthFooter,
      AuthHeader,
    },
    data: () => ({
      firstName: ""
    }),
    mounted() {
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      this.firstName = data.firstName
    },
  }
</script>
