<template>
  <div class="footer">
    <p>
      <span>{{ $t('terms.allRights') }}</span> <span>-</span> {{ $t('terms.meetOur') }}
      <a href="javascript:;" @click="openModalTerms">{{ $t('terms.privacyPolicy') }}</a>
       {{ $t('terms.and') }}
      <a href="javascript:;" @click="openModalTerms">{{ $t('terms.termsOfUse') }}</a>
    </p>

    <teleport to="body">
      <AcceptanceTermModal v-if="showModalTerms" @close="closeModalTerms"></AcceptanceTermModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  position:                  relative;
  display:                   flex;
  justify-content:           center;
  align-items:               center;
  background:                #e6e6e6;
  border-top:                1px solid rgb(0 0 0 / 5%);
  height:                    45px;
  width:                     calc(100% - 230px);
  min-height:                45px;
  padding:                   0;
  float:                     right;
  z-index:                   3;
  & p {
    margin:                  0;
    & a {
      text-decoration:       underline;
      cursor:                pointer;
    }
  }
}

@media (max-width: 992px) {
  .footer {
    text-align:              center;
  }
}

@media (max-width: 767px) {
  .footer {
    float:                   unset;
    width:                   100%;
    padding:                 0 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .footer p span {
    display:                 none;
    &:first-child {
      display:               block;
    }
  }
}
</style>

<script>
import AcceptanceTermModal from '@/components/AcceptanceTermModal.vue';

export default {
  name: 'HomeFooter',
  components: {
    AcceptanceTermModal
  },
  data: () => ({
    showModalTerms: false,
  }),
  methods: {
    openModalTerms() {
      this.showModalTerms = true;

      setTimeout(function() {
        // let modal_terms = document.getElementById('modal_terms')
        new bootstrap.Modal(document.getElementById('modal_terms')).show();
      }, 100);
    },
    closeModalTerms() {
      setTimeout(function() {
        this.showModalTerms = false
      }, 0);
    }
  }
}
</script>
