<template>
  <div class="" v-loader.opacity.tab="loading">
    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(doSave)" @keydown.enter="$event.preventDefault()">
        <div class="title-box rounded">
          <h2 class="title">{{ $t('clinic.editTemplate') }}</h2>
          <button class="btn btn-outline-danger rounded" type="button" name="button" @click="$emit('cancel-edit')">
            <i class="fa fa-plus"></i>
            <span>{{ $t('cancel') }}</span>
          </button>
          <button class="btn btn-outline-warning rounded" v-if="disabled" type="button" name="button" @click="editFields">
            <i class="fa fa-pencil"></i>
            <span>{{ $t("edit") }}</span>
          </button>
          <button class="btn btn-outline-success rounded" v-if="!disabled" type="submit" name="button">
            <i class="fa fa-check"></i>
            <span>{{ $t("save") }}</span>
          </button>
        </div>

        <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
          <span v-html="alert.message"></span>
          <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
        </div>
        
        <div class="row">
          <div class="col-lg-5">
            <div class="form-group">
              <label for="name">{{ $t('name') }}*</label>
              <vee-field name="name" v-model="name" rules="required" v-slot="{ field, errorMessage }">
                <input v-bind="field" type="text" class="form-control" ref="name" id="name" autofocus :disabled="disabled">
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <!-- <input class="form-control" type="text" name="" ref="name" v-model="name" autofocus :disabled="disabled"> -->
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label for="columnSelected"># {{ $t('columns') }}*</label>
              <v-select
              class="v-select form"
              v-model="columnSelected"
              :reduce="x => x.value"
              :options="columnOptions"
              :disabled="disabled">
            </v-select>
          </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label for="description">{{ $t('description') }}</label>
              <vee-field name="description" v-model="description" rules="required" v-slot="{ field, errorMessage }">
                <input v-bind="field" type="text" class="form-control" ref="description" id="description" autofocus :disabled="disabled">
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <!-- <input class="form-control" type="text" name="description" v-model="description" :disabled="disabled"> -->
            </div>
          </div>
        </div>

        <div class="">
          <quill-editor
            ref="quillEditor"
            class="editor"
            v-model:content="content"
            :options="editorOption"
            content-type="html"
            @blue="checkRequired"
          />
          <span class="vee-error" v-show="required">{{ $t('validate.required') }}</span>

          <!-- <div class="content ql-editor" v-html="content"></div> -->
        </div>
      </form>
    </vee-form>
  </div>
</template>

<style lang="scss" scoped>
.title-box {
  .title {
    max-width:                  unset;
  }
  button {
    display:                    inherit;
  }
}

.form-group {
  display:                      block;
}

.vee-error {
  width:                        100% !important;
}

// .ql-editor {
//   height:                       500px !important;
//   white-space:                  pre-wrap;
//   color:                        black;
// }

@media (max-width: 540px) {
  .title-box {
    text-align:                 center;
    button {
      display:                  inline-block;
    }
  }
}

@media screen and (min-width: 541px) and (max-width: 991px) {
  .title-box .title {
    width:                      calc(100% - 235px);
  }
}

@media (min-width: 320px) {
  .panel-content .form-group input[type="text"],
  .panel-content .form-group label,
  .panel-content .form-group .form {
    display:                    block !important;
    width:                      100% !important;
  }
}
</style>

<script>
const toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }]
];

export default {
  props: ['template_id'],
  name: 'ClinicTemplateEdit',
  components: {
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    disabled: false,
    columnOptions: [],
    columnSelected: '1',
    editorOption: {
      modules: {
         toolbar: toolbarOptions
      },
      theme: 'snow',
    },
    template: {
      id: null,
      name: null,
      description: null,
      columnsDefault: null,
      content: null,
    },
    content: null,
    description: null,
    name: null,
    required: false,
  }),
  computed: {
    editor() {
      return this.$refs.quillEditor.quill
    }
  },
  mounted: function() {
    this.columnOptions = [
      {value: 1, label: this.$i18n.t('exam.oneColumn')},
      {value: 2, label: this.$i18n.t('exam.twoColumn')},
      {value: 3, label: this.$i18n.t('exam.threeColumn')},
      {value: 4, label: this.$i18n.t('exam.fourColumn')}
    ];

    if (this.template_id) {
      this.template.id = this.template_id
      this.disabled = true
      this.fetchData()
    }
  },
  methods: {
    editFields() {
      this.disabled = !this.disabled;
      this.$nextTick(() => {
        this.$refs.name.focus()
      })
    },
    fetchData: async function() {
      this.alert.message = undefined;

      this.loading = true
      NProgress.start()
      try {
        let params = {
          id: this.template_id,
        }

        let response = await this.$clinicService.getTemplate(params);

        this.setTemplate(response.result)

      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    doSave: async function() {
      console.log('oi');
      this.alert.message = undefined;

      let params = {
        template: {
          name: this.name,
          description: this.description,
          columnsDefault: this.columnSelected,
          content: this.content,
        }
      }

      if (this.template.id) {
        params.template.id = this.template.id
      }

      if (!this.content) {
        this.checkRequired()
        return
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.saveTemplate(params);

        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')
        this.setTemplate(response.result)

        this.disabled = !this.disabled;

        this.$nextTick(() => {
          this.$router.push({name: 'clinic-show', params: {clinicID: response.result.clinic}, query: {tab: 'templates'}})
        })
      } catch(err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false

    },
    setTemplate(template) {
      this.template = template
      this.name = this.template.name
      this.description = this.template.description
      this.columnSelected = this.template.columnsDefault
      this.content = this.template.content
      this.$refs.quillEditor.setHTML(this.content)
    },
    checkRequired() {
      if (!this.content)
        this.required = !this.required
    }
  },
}

</script>
