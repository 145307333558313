<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message || logomsg.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(saveOrUpdate)">
        <div class="action-buttons rounded text-end" v-if="isCurrentClinic">
          <button class="btn btn-outline-danger" v-show="!disabled" type="button" name="button" @click="disabled = !disabled">
            <i class="fa fa-ban"></i>
            <span>{{ $t("cancel") }}</span>
          </button>
          <button class="btn btn-outline-primary" type="button" v-show="!isSpecialist" name="button" @click="openModalChangelogo">
            <i class="fa fa-refresh"></i>
            <span>{{ $t('clinic.changeLogoSmall') }}</span>
          </button>
          <button class="btn btn-outline-warning" name="button" v-show="disabled && !isSpecialist" @click.prevent="disabled = !disabled">
            <i class="fa fa-pencil"></i>
            <span>{{ $t('clinic.edit') }}</span>
          </button>
          <button class="btn btn-outline-success" type="submit" name="button" v-show="!disabled">
            <i class="fa fa-check"></i>
            <span>{{ $t('updateAction') }}</span>
          </button>
        </div>

        <ClinicFormData v-if="clinic"
          :clinic="clinic"
          :disabled="disabled"
          @option-selected="setConfiguration"
          @security-changed="securityChanged">
        </ClinicFormData>

        <div class="clinic-form-box" v-if="isCurrentClinic">
          <h3 class="sub-title">{{ $t('csv.csvPatients') }}</h3>

          <p>{{ $t('csv.csvMakeSure') }}</p>
          <p class="mb-0"><b>{{ $t('csv.csvWarning') }} </b>{{ $t('csv.csvWarning1') }}</p>
          <p><b>{{ $t('csv.csvWarning') }} </b>{{ $t('csv.csvWarning2') }}</p>
          <p @click="open = !open"><a>{{ $t('csv.csvClickHereGuidelines') }}</a></p>
          <div class="expand" :class="{'open': open}">
            <p class="mb-0">{{ $t('csv.csvInstructions') }}</p>
            <ul class="mb-0">
              <li>{{ $t('csv.csvRequiredField') }}</li>
              <li>{{ $t('csv.csvBirthday') }}</li>
              <li>{{ $t('csv.mandatoryInfo') }}</li>
              <ul class="mb-0">
                <li>{{ $t('csv.mandatoryInfo1') }}</li>
                <li>{{ $t('csv.mandatoryInfo2') }}</li>
                <li>{{ $t('csv.mandatoryInfo3') }}</li>
                <li>{{ $t('csv.mandatoryInfo4') }}</li>
                <li>{{ $t('csv.mandatoryInfo5') }}</li>
                <li>{{ $t('csv.mandatoryInfo6') }}</li>
              </ul>
            </ul>
          </div>

          <p class="mb-3"><a @click="doDownloadDefaultCsv()">{{ $t('csv.csvClickHere') }}</a>{{ $t('csv.csvTemplateDownload') }}</p>

          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="formFile" class="form-label">{{ $t('csv.csvFile') }}</label>
                <input class="form-control" type="file" ref="formFile" accept=".csv" v-on:change="handleFileUpload()">
              </div>
            </div>
            <div class="col-lg-6">
              <button class="btn btn-primary" type="button" name="button" :disabled="!isValidCsv || !formData.file" @click="canStartNewUpload()">Upload CSV</button>
            </div>
          </div>
        </div>

      </form>
    </vee-form>

    <teleport to="body">
      <BaseCropperModal ref="modalCropper"></BaseCropperModal>
      <ClinicCsvModal :modal_id="'modalImageGeneric'" ref="modalGeneric"></ClinicCsvModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
  p {
    a {
      text-decoration:              underline !important;
      color:                        blue !important;
      cursor:                       pointer;
    }
  }

  .expand {
    width:                          100%;
    max-height:                     0;
    overflow:                       hidden;

    &.open {
      @include                      transition(opacity .5s);
      max-height:                   100%;
      opacity:                      1;
      background-color:             $platinum;
      padding:                      10px;
      margin-bottom:                20px;
    }
  }

</style>

<script>
import { useUserStore } from '@/store/user'
import ClinicFormData from '../ClinicFormData.vue'
import ClinicCsvModal from './ClinicCsvModal.vue'

export default {
  name: 'ClinicInfoTab',
  props: ['clinic_id', 'logomsg'],
  components: {
    ClinicFormData,
    ClinicCsvModal
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isSpecialist: null,
    statusOptions: [],
    statusSelected: 'open',
    clinic: null,
    clinicLogo: null,
    disabled: true,
    isCurrentClinic: false,
    systemConfiguration: {},
    formData: {},
    isValidCsv: true,
    open: false,
  }),
  watch: {
    logomsg: function(newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
        this.fetchData();
      } else {
        this.alert.message = null
      }
    },
  },
  computed: {
    csvTooltip() {
      let tooltip = [this.$i18n.t('csv.mandatoryInfo1'), this.$i18n.t('csv.mandatoryInfo2'), this.$i18n.t('csv.mandatoryInfo3'),
                     this.$i18n.t('csv.mandatoryInfo4'), this.$i18n.t('csv.mandatoryInfo5'), this.$i18n.t('csv.mandatoryInfo6')];

      return tooltip
      // return tooltip.join('\n')
    }
  },
  mounted: function() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.isCurrentClinic = this.clinic_id === this.session.currentClinic.id
    this.isSpecialist = this.session.isSpecialist
    this.statusOptions = [
      {value: 'open', name: this.$i18n.t('open')},
      {value: 'closed', name: this.$i18n.t('closed')}
    ];
    this.fetchData();
  },
  methods: {
    fetchData: async function() {
      let params = {
        id: this.clinic_id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.getClinic(params);
        this.clinic = response.clinic
        this.clinicLogo = response.clinicLogo

        this.systemConfiguration = this.$clinicService.getSystemConfiguration(this.clinic)
      } catch (err) {
        this.$message.popup(this, `Error: ${err.message}`, 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    saveOrUpdate: async function() {
      this.disabled = !this.disabled;

      let params = {
        clinic: this.clinic
      }
      params.clinic.id = this.clinic.id

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.updateClinic(params)
        if (response.result.locale)
          this.$i18n.locale = response.result.locale

        await this.$authService.refreshSession(this)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
        this.disabled = true
      } catch (e) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    setConfiguration(val) {
      if (this.clinic) {
        this.clinic.country = val.country
        this.clinic.mandatoryDocument = val.mandatoryDocument
        this.clinic.locale = val.locale
      }
    },
    securityChanged(value) {
      console.log("securityChanged()", value)

      // TODO move to clinic-service
      let systemConfiguration = this.clinic.systemConfiguration ? this.clinic.systemConfiguration : {}
      let security = systemConfiguration.security ? systemConfiguration.security : {}
      security.mfaMode = value.mfaMode
      this.clinic.systemConfiguration.security = security
    },
    async openModalChangelogo() {
      this.alert.message = null;

      this.clinicLogo ? this.clinicLogo : '@/assets/images/phelcom/profile_img.png'

      const ok = await this.$refs.modalCropper.show({
        title: this.$i18n.t('clinic.changeLogo'),
        imgSrc: this.clinicLogo,
        okButton: this.$i18n.t('save'),
        okButtonClass: 'btn-primary',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        let canvas = ok.canvas
        canvas.toBlob(blob => {
          var formData = new FormData()
          formData.append('data', blob)
          this.doSaveLogo(formData)
        }, 'image/png')
      }
    },
    doSaveLogo: async function(value) {
      let params = {
        id: this.clinic_id,
        logo: value.get('data')
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.uploadLogo(params)
        this.clinicLogo = response.clinicLogo

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    handleFileUpload() {
      this.isValidCsv = true
      this.formData.file = this.$refs.formFile.files[0]
      this.formData.clinic = this.clinic
      let regexCsv = new RegExp("(.*?)\.(csv)$");

      console.log('this.formData.file.name.toLowerCase()', this.formData.file.name);

      if (!regexCsv.test(this.formData.file.name)) {
        this.isValidCsv = false
        this.$message.popup(this, 'csv.invalidCsv', 'operationFail', 'error')
      }

      if (this.formData.file.size > 1048576) { // validando 1mb
        this.isValidCsv = false
        this.$message.popup(this, 'csv.fileMaxSize', 'operationFail', 'error')
      }
    },
    
    canStartNewUpload: async function() {
        this.loading = true
        NProgress.start()       
        let check
        try{
            check = await this.$clinicService.canStartNewImport({clinicId: this.clinic.id})
            if (check.status == true){
                let uploadResult
                try {
                    uploadResult = await this.$clinicService.uploadPatientCsvFile(this.formData)
                    this.doImportCSV(uploadResult.filename)
                }catch (error){
                    this.$message.popup(this, 'csv.errorUpload', 'operationFail', 'error')
                }
            }else{
                this.$message.popup(this, 'csv.errorForbidenMessage', 'operationFail', 'error')
            }
        }catch(error){
            this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        }
        NProgress.done(true)
        this.loading = false
    },
    
    doImportCSV: async function(filename) {
      this.loading = true
      NProgress.start()       
      try{  
        let params = {
            clinic:  this.clinic,
            filename: filename
        }
        let responseValidate = await this.$clinicService.validateCsvFile(params)
        if (responseValidate){
            this.$message.popup(this, 'csv.validData', 'operationSuccess', 'success')
            try {
                await this.$clinicService.importPatientsToClinic(params)
                this.$message.popup(this, 'csv.updateTable', 'operationSuccess', 'success')
            } catch (err) {
                this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
            }
        }

      } catch (err) {
        NProgress.done(true)
        this.loading = false

        const ok = await this.$refs.modalGeneric.show({
            title: this.$i18n.t('csv.errorInfoTitle'),
            text: err.response.data.invalidRows,
            okButton: 'OK',
            okButtonClass: 'btn-primary',
        })
        if (ok) {
            this.$refs.formFile.value = ''
            this.formData = {}
        }
      }
      NProgress.done(true)
      this.loading = false
    },
    
    
    async doDownloadDefaultCsv() {
      //let row = ["first_name", "last_name", "birthday", "cellphone", "email", "medical_record_number", "document0", "document1", "document2", "document3"];
      let row = this.getRowbyLocale(this.clinic.locale)
      let csvContent = row.join(",");

      let encodedUri = await encodeURI(csvContent)
      console.log('csv', csvContent);
      let blob = new Blob([encodedUri], { type: "data:text/csv;charset=utf-8," });
      let url = URL.createObjectURL(blob);

      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',`template_import_patients_phelcom_${this.clinic.locale}.csv`);
      link.click();
    },
    getRowbyLocale(locale){
      if (locale == 'pt-br')
          return ["nome", "sobrenome", "data_de_nascimento", "telefone", "email", "prontuario", "cns", "rg", "cpf", "certidao_nascimento"]
      if (locale == 'es')
          return ["nombre", "apellido", "fecha_de_nacimiento", "telefono", "email", "informe_medico", "registro_nacional_de_la_salud", "documento_de_identidad", "certificado_de_nacimiento", "otro_documento"]

      return ["firstname", "surname", "birthday", "telephone", "email", "medical_record_number", "identification_document_1", "identification_document_2", "identification_document_3", "identification_document_4"]
    }
  }
}
</script>
