<template>
  <div class="" v-loader.opacity="loading">
    <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title">
        {{ $t('user.user') }} |
        <span v-if="user">{{ user.collaborator.rawName }}</span>
      </h2>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('user.data') }}
      </template>
      <template v-slot:data>
        <UserDataTab v-if="tabActive === 'data' && user" :user="user" :alertmsg="alert ? alert : undefined"></UserDataTab>
      </template>
    </BaseTab>
  </div>
</template>

<style lang="scss" scoped>
  .title-box {
    margin-bottom:                        15px;
    .title {
      max-width:                          unset;
    }
    .btn-back {
      margin-right:                       20px;
    }
  }

  @media (max-width: 500px) {
    .tab-box .nav-item {
      width:                              100%;
      flex-grow:                          unset;
      flex-basis:                         unset;
    }
  }

  @media (max-width: 767px) {
    .title-box {
      text-align:                         left;
    }
  }

  @media (max-width: 991px) {
    .title-box .title {
      display:                            inline-block;
      width:                              calc(100% - 60px);
      margin-left:                        0;
    }
  }
</style>

<script>
import UserDataTab from './UserDataTab.vue'

export default {
  name: 'user-show',
  // props: ['userid'],
  components: {
    UserDataTab,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tabActive: 'data',
    tabs: ['data'],
    photo: null,
    signature: null,
    user: null,
  }),
  computed: {
    userId() {
      return this.$route.params.userID
    },
    tab() {
      return this.$route.query.tab;
    },
  },
  mounted: function() {
    this.$activeLink.value = 'users'
    this.fetchData();
    if (this.tab) {
      this.tabActive = this.tab;
    }
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      this.$router.push({name: 'user-list'})
    },
    fetchData: async function() {
      let params = {
        id: this.userId,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$userService.getUser(params);

        this.user = response.result
        this.photo = this.user.collaborator.photo ? response.result.collaborator.photo : '/images/phelcom/profile_img.png'
        this.signature = this.user.collaborator.signature
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
  }
}
</script>
