<template>
  <div class="">
    <div class="title-box clear">
      <button class="btn-back" type="button" name="button" @click="$router.back()">
        <i class="icon-back"></i>
      </button>

      <h2 class="title">{{ $t('patient.newPatient') }}</h2>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" @tab-activated="switchTab">
      <template v-slot:tab_head_data>
        {{ $t('patient.data') }}
      </template>
      <template v-slot:data>
        <PatientCreateTab v-if="tabActive == 'data'"></PatientCreateTab>
      </template>
    </BaseTab>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import PatientCreateTab from './PatientCreateTab.vue'

export default {
  name: 'patient-create',
  components: {
    PatientCreateTab,
  },
  data: () => ({
    tabActive: 'data',
    tabs: ['data'],
  }),
  mounted: function() {
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
  }
}
</script>
