<template>
  <div class="general-report" v-loader.opacity="loading">
    <div class="title-box clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title sub">
        <span>{{ $t('report.newReport') }} | </span>
        <span>
          <a href="" v-if="patient">{{ patient.fullName }}</a>
        </span>
      </h2>
    </div>

    <div class="action-buttons rounded text-end">
      <button class="btn btn-outline-primary" type="button" name="button" @click="doPrint">
        <span>{{ $t('grapesEditor.printPreview') }}</span>
      </button>
      <button class="btn btn-outline-primary" type="submit" name="button" @click="doCreate">{{ $t('save') }}</button>
    </div>


    <div class="gjs-box">
      <div class="gjs-content" id="gjs">
        <ReportClinicHeader v-if="clinic" :clinic="clinic"></ReportClinicHeader>

        <h1 class="gjs-title">{{ $t('grapesEditor.patient') }}: {{patient.fullName}}</h1>
        <div class="gjs-page first"></div>
      </div>
      <div class="gjs-blocks" id="blocks"></div>
    </div>

    <teleport to="body">
      <ReportGeneralPrintModal v-if="showModalPrint" @close="closePrintModal"
                          :content_print="contentPrint" :patient_name="patient.fullName"></ReportGeneralPrintModal>
    </teleport>
  </div>
</template>

<style lang="scss">
.general-report {
  height:                              calc(100% - 45px);
  padding-bottom:                      0 !important;
  border-bottom:                       unset !important;
  border-right:                        unset !important;
  border-left:                         unset !important;
  box-shadow:                          unset !important;
  .title.sub span:first-child {
    color:                             $charcoal;
  }
  .icon-four-columns {
    font-size:                         3em;
  }
  .icon-signature {
    font-size:                         4em;
  }
  .custom-icon {
    margin-top:                        9px;
  }
}

.gjs-box {
  display:                             flex;
  background-color:                    #444;
}

.gjs-pn-panel {
  display:                             none !important;
}

.gjs-content.gjs-editor-cont {
  height:                              calc(100vh - 135px) !important;
}

.gjs-blocks {
  height:                              100%;
  max-height:                          calc(100vh - 135px) !important;
  overflow-x:                          auto;
  &::-webkit-scrollbar {
    width:                             10px;
  }
  &::-webkit-scrollbar-track {
    background:                        #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background:                        #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background:                        #555;
  }
}

.gjs-blocks {
  width:                               230px;
  .gjs-blocks-cs.gjs-one-bg {
    height:                            100%;
  }
}

/* Let's highlight canvas boundaries */
#gjs {
  border:                              1px solid $chinese-silver;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top:                                 0 !important;
  bottom:                              unset !important;
  right:                               0;
  width:                               100% !important;
  max-width:                           990px;
  height:                              100% !important;
  margin:                              0 auto;
}

.gjs-block-section {
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display:                           flex;
    flex-wrap:                         wrap;
    margin-top:                        calc(-1 * var(--bs-gutter-y));
    margin-right:                      calc(-0.5 * var(--bs-gutter-x));
    margin-left:                       calc(-0.5 * var(--bs-gutter-x));
    & > * {
      flex-shrink:                     0;
      width:                           100%;
      max-width:                       100%;
      padding-right:                   calc(var(--bs-gutter-x) * 0.5);
      padding-left:                    calc(var(--bs-gutter-x) * 0.5);
      margin-top:                      var(--bs-gutter-y);
    }
  }
}

@media (min-width: 992px) {
  .gjs-block-section {
    .row {
      .col-lg-6 {
        flex:                          0 0 auto;
        width:                         50%;
      }
    }
  }
}
</style>

<script src="./report-general.js"></script>
