<template>
  <div class="" v-loader.opacity="loading">
    <div class="title-box clear">
      <button class="btn-back" type="button" name="button" @click="$router.back()">
        <i class="icon-back"></i>
      </button>

      <h2 class="title">{{ $t('createClinic.newClinic') }}</h2>

      <div class="d-inline-block">
        <h2 class="title">{{ $t('createClinic.parentClinic') }}</h2>
        <v-select
          class="v-select form filter"
          v-model="parentClinicSelected"
          :reduce="x => x.value"
          :options="parentClinicOptions">
        </v-select>
      </div>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" @tab-activated="switchTab">
      <template v-slot:tab_head_data>
        {{ $t('createClinic.data') }}
      </template>
      <template v-slot:data>
        <ClinicCreateTab v-if="tabActive == 'data' && clinicTemplate" :parent_clinic="parentClinicSelected" :clinic_template="clinicTemplate"></ClinicCreateTab>
      </template>
    </BaseTab>
  </div>
</template>

<style lang="scss" scoped>
  .title-box {
    .d-inline-block {
      width:                        100%;
      margin-right:                 10px;
      .title {
        width:                      auto;
        margin-right:               10px;
      }
      .form {
        width:                      calc(100% - 172px);
        vertical-align:             bottom;
      }
    }
  }

  // .dropdown-menu.show {
  //   width:                          100% !important;
  // }

  @media (max-width: 991px) {
    .title-box {
      .title {
        max-width:                  unset;
        width:                      calc(100% - 50px);
      }
      .d-inline-block {
        display:                    block !important;
        margin:                     10px 0 0;
        .form {
          max-width:                300px;
        }
      }
      .title,
      .d-inline-block {
        text-align:                 left;
      }
      .btn-back {
        float:                      left;
      }
    }
  }

  @media (min-width: 992px) {
    .title-box .d-inline-block {
      width:                        100%;
      min-width:                    200px;
      max-width:                    500px;
      margin-right:                 10px;
    }
  }

  @media (max-width: 450px) {
    .title-box {
      .title {
        max-width:                  unset;
        text-align:                 center;
      }
      > .title {
        padding-right:              30px;
      }
      .d-inline-block {
        text-align:                 center;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1230px) {
    .title-box {
      justify-content:              flex-start;
      .title {
        max-width:                  280px;
      }
      .d-inline-block {
        max-width:                  410px;
      }
    }
  }
</style>

<script>
import ClinicCreateTab from './ClinicCreateTab.vue'

export default {
  name: 'clinic-create',
  components: {
    ClinicCreateTab,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    parentClinicOptions: [
      {value: null, label: 'None'}
    ],
    clinicTemplate: null,
    parentClinicSelected: null,
    tabActive: 'data',
    tabs: ['data'],
  }),
  mounted: function() {
    this.initialize()
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    initialize: async function() {
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.create();
        this.clinicTemplate = response.clinicTemplate

        response.parentClinics.forEach(o => {
          o.value = o.id;
          o.label = o.name;
          this.parentClinicOptions.push(o)
        })
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      this.loading = false
      NProgress.done(true)
    },
  }
}
</script>
