<template>
  <div class="edit-image-box" v-loader.opacity="loading">
    <div class="title-box clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title">{{ $t('editImage.editImage') }}</h2>
    </div>

    <div class="tab-content">
      <div v-if="alert.message" class="alert alert-dismissible fade show" :class="alert.class" role="alert">
        <span v-html="alert.message"></span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="clearMessage"></button>
      </div>

      <div id="editor_container" class="editor_container" ref="editor_container"></div>

    </div>

  </div>
</template>

<style lang="scss" scoped>
.edit-image-box .btn-back {
  margin-right:                           20px;
}

.editor_container {
  width:                                  calc(100vw - 300px);
  max-width:                              70vw;
  height:                                 100vh;
}


@media (max-width: 767px) {
  .editor_container {
    width:                                auto;
    height:                               70vh;
    max-width:                            unset;
  }
}

@media (max-width: 991px) {
  .title-box .title {
    text-align:                           left;
    width:                                calc(100% - 60px);
  }
}

</style>

<script>
import { useUserStore } from '@/store/user'

import FilerobotImageEditor from 'filerobot-image-editor';

const { TABS, TOOLS } = FilerobotImageEditor;
const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'exam-image-edit',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    examDataId: null,
    examData: null,
    dataPath: null,
    config: {
      source: null,
      onBeforeSave: (imageFileInfo) => {return false},
      onSave: null,
      crossOrigin: 'Anonymous',
      annotationsCommon: {
        fill: '#00000000',
        stroke: '#00ff00',
        strokeWidth: 3,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowBlur: 0,
        shadowColor: '#000000',
        shadowOpacity: 1,
        opacity: 1,
      },
      language: null,
      translations: {
        profile: 'Profile',
        coverPhoto: 'Cover photo',
        facebook: 'Facebook',
        socialMedia: 'Social Media',
        fbProfileSize: '180x180px',
        fbCoverPhotoSize: '820x312px',
      },
      theme: {
        typography: {
          fontFamily: 'Ubuntu, sans-serif',
        },
      },
      Crop: {
        presetsItems: [
          {
            titleKey: 'classicTv',
            descriptionKey: '4:3',
            ratio: 4 / 3,
          },
          {
            titleKey: 'cinemascope',
            descriptionKey: '21:9',
            ratio: 21 / 9,
          },
        ],
      },
      Pen: {
        stroke: '#00FF00',
        strokeWidth: 3,
      },
      Line: {
        stroke: '#00FF00',
        strokeWidth: 3,
      },
      Arrow: {
        strokeWidth: 3,
        lineCap: 'butt',
      },
      tabsIds: [TABS.FINETUNE, TABS.ADJUST, TABS.ANNOTATE],
      defaultTabId: TABS.FINETUNE,
      defaultToolId: TOOLS.TEXT,
    }
  }),
  computed: {
    sessionLanguage() {
      return this.session.currentClinic.locale == 'pt-br' ? 'pt' : this.session.currentClinic.locale
    }
  },
  async mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.examDataId = this.$route.params.examDataID

    await this.fetchData();
  },
  methods: {
    goBack() {
      this.$router.push({name: 'exam-show', params: {examID: this.examData.exam}, query: {tab: 'images'}})
    },
    async fetchData() {
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examDataService.get(this.examDataId);
        this.examData = response.examData

        this.dataPath = response.dataPath

        if (!IS_PRODUCTION) {
          this.dataPath = `http://localhost:1337${this.dataPath}`
        }

        this.filerobotInitialization()
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doSave(editedImageObject, designState) {
      let params = {
        examData: this.examData,
        image: null
      }

      this.loading = true
      NProgress.start()
			try {
        const blob = await fetch(editedImageObject.imageBase64).then(res => {return res.blob()})
        params.image = blob
        console.log(blob, ' blob ');

        let response = await this.$examDataService.save(this.examData, blob)
        console.log(response, ' response ');

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.goBack()
      } catch (err) {
        console.log('error img save', err);
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    filerobotInitialization() {
      this.config.source = `${this.dataPath}/${this.examData.name}`
      this.config.language = this.sessionLanguage
      this.config.translations.annotateTab = this.$i18n.t('editImage.draw')
      this.config.translations.resizeTab = this.$i18n.t('editImage.resize')
      this.config.observePluginContainerSize = true

      this.config.Text = {
        text: this.$i18n.t('editImage.initialText'), // must be here due to the translation
        fill: '#00FF00',
        fontSize: 24,
        strokeWidth: 0
      }

      this.config.onSave = (editedImageObject, designState) => this.doSave(editedImageObject, designState)

      const filerobotImageEditor = new FilerobotImageEditor(
        document.querySelector('#editor_container'),
        this.config
      );

      filerobotImageEditor.render({
        onClose: (closingReason) => {
          console.log('Closing reason', closingReason);
          filerobotImageEditor.terminate();
        }
      });
    },
  }
}
</script>
