<template>
  <div id="quick-report_right-eye" class="form-box">
    <div id="quick-report_right_main-entry">
      <div class="form-group retina-box">
        <label >{{ $t(`quickReport.000_label`) }}</label>
        <v-select
          class="v-select"
          v-model="retinaSelection"
          :options="retinaOptions"
          :reduce="x => x.value"
          :clearable="false"
          :searchable="true"
          :class="retinaSelection != '000_1' ? 'bg-yellow' : ''"
          @option:selected="refreshResultModel"
        >
        </v-select>
      </div>
    </div>
    <div id="right-eye_entries" style="margin-left:0;" v-if="retinaSelection == '000_2'">
      <template v-for="key in Object.keys(diagnosisOptions)" :key="key">
        <div class="form-group">
          <label :for="`key_${key}`">{{ $t(`quickReport.${key}_label`) }}</label>
          <v-select
            class="v-select eye-entries"
            :id="`key_${key}`"
            v-model="result[key]"
            :options="diagnosisOptions[key]"
            :reduce="x => x.value"
            :clearable="false"
            :searchable="true"
            :class="result[key] != '' ? 'bg-yellow' : ''"
            @option:selected="refreshResultModel"
          >
          </v-select>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-box {
  .retina-box {
    &,
    .v-select {
      width:                           100%;
    }
    label:first-child {
      margin-right:                    10px;
    }
    .radio-inline {
      vertical-align:                  initial;
    }
  }
  .form-group {
    display:                           block;
    label {
      width:                           100%;
    }
  }
  .retina-box .v-select,
  #right-eye_entries .v-select,
  #left-eye_entries .v-select {
    height:                            46px;
    font-size:                         18px;
  }
  #right-eye_entries,
  #left-eye_entries {
    display:                           grid;
    grid-template-columns:             repeat(auto-fill, 33%);
    justify-content:                   space-between;
    align-items:                       end;
    width:                             100%;
    .v-select {
      min-width:                       100% !important;
    }
  }
}

@media (max-width: 767px) {
  .form-box .retina-box .v-select {
    min-width:                         255px;
  }
}

@media (min-width: 768px) {
  .form-box .retina-box .v-select {
    min-width:                         220px;
  }
}

@media (max-width: 991px) {
  .form-box {
    #right-eye_entries,
    #left-eye_entries {
      grid-template-columns:           repeat(auto-fill, 100%);
    }
    .retina-box .v-select {
      max-width:                       220px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1125px) {
  .quick-report {
    #right-eye_entries,
    #left-eye_entries {
      grid-template-columns:           repeat(auto-fill, 49%) !important;
    }
    .form-group:nth-child(2n) {
      margin:                          0 0 16px 10px;
    }
  }
}

@media screen and (min-width: 1125px) and (max-width: 1366px) {
  .form-box {
    .form-group {
      &:nth-child(2),
      &:nth-child(5n) {
        margin:                        0 13px 16px;
      }
    }
  }
}

@media (min-width: 850px) {
  .form-box .retina-box .v-select {
    min-width:                         300px;
  }
}

@media (min-width: 1367px) {
  .form-box {
    .form-group {
      &:nth-child(2),
      &:nth-child(5n) {
        margin:                        0 20px 16px;
      }
    }
  }
}
</style>

<script>
const NORMAL_RETINA = "000_1"
const ALTERED_RETINA = "000_2"
const RETINA_OPTIONS = ['000_1', '000_2', '000_3']
const DIAGNOSIS_OPTIONS = {
  "001": ['absent', '001_1', '001_2', '001_3', '001_4'],
  "002": ['absent', '002_1'],
  "003": ['absent', '003_1', '003_2', '003_3', '003_4', '003_5', '003_6', '003_7'],
  "004": ['absent', '004_1'],
  "005": ['absent', '005_1', '005_2', '005_3'],
  "006": ['absent', '006_1']
}

export default {
  name: 'ReportQuickDiagnosisSelectionTab',
  props: ['laterality', 'exam', 'entries'],
  components: {
  },
  data: () => ({
    retinaOptions: [],
    diagnosisOptions: {},
    retinaSelection: '',
    result: {},
  }),
  mounted() {
    this.retinaOptions = this.generateEntries(RETINA_OPTIONS)
    for (let key of Object.keys(DIAGNOSIS_OPTIONS)) {
      this.diagnosisOptions[key] = this.generateEntries(DIAGNOSIS_OPTIONS[key])
      this.result[key] = ""
    }

    if (_.isEmpty(this.entries)) {
      this.retinaSelection = NORMAL_RETINA
    } else {
      this.loadInformedEntries()
    }

    let eyeHealth = this.exam[`${this.laterality}EyeHealth`]
    if (eyeHealth > 0)
      this.retinaSelection = `000_${eyeHealth}`

  },
  methods: {
    generateEntries(options) {
      let result = []
      for (let x of options) {
        result.push({
          label: this.$i18n.t(`quickReport.${x}`),
          value: x != "absent" ? x : ""
        })
      }
      return result
    },
    refreshResultModel(source) {
      if (this.retinaSelection != ALTERED_RETINA) {
        for (let key of Object.keys(DIAGNOSIS_OPTIONS))
          this.result[key] = ""
      }

      this.result['000'] = this.retinaSelection
      this.$emit("selected-entries", Object.values(this.result))
    },
    loadInformedEntries() {
      for (let e of this.entries) {
        let key = e.split('_').shift()
        if (key == "000") {
          this.retinaSelection = e
          continue
        }

        this.result[key] = e
      }
    }
  },
  // unmounted() {
  //   this.result = {}
  // }
}
</script>
