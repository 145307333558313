<template>
  <div class="dropdown">
    <button class="form-control dropdown-toggle" :class="notallowed ? 'disabled' : ''" :style="bgClass" type="button"
            name="dropdown" aria-expanded="true" data-bs-toggle="dropdown">
      <div class="selected word-wrap-dropdown">{{ selected && getItemName(selected) || '' }}</div>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdown">
      <li class="dropdown-item" v-for="item in options" v-show="options">
        <a href="javascript:;" @click="selectItem(item)">{{ getItemName(item) }}</a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .dropdown {
    &,
    .form-control .selected {
      display:                          inline-block;
    }
    &,
    .dropdown-menu.show {
      width:                            100px;
      min-width:                        100px;
    }
    .dropdown-item {
      a {
        width:                          100%;
        display:                        block;
        text-decoration:                none;
        padding:                        3px;
      }
      &:focus,
      &:hover {
        &, a {
          background-color:             $light-platinum;
        }
        a {
          color:                        $police;
          background:                   unset;
        }
      }
    }
    .dropdown-menu,
    .form-control:focus {
      background-color:                 white;
    }
    .dropdown-menu a,
    .form-control:focus {
      color:                            $granite-gray;
    }
    .dropdown-menu {
      &.show {
        width:                          100%;
      }
    }
    .form-control {
      text-align:                       left;
      &:focus {
        @include                        box_shadow(unset);
        border-color:                   inherit;
        outline:                        0;
      }
      &.dropdown-toggle {
        @include                        border_radius(0);
        &.bg-yellow {
          background-color:             $champagne;
        }
        &:after {
          float:                        right;
          margin-top:                   10px;
        }
      }
    }
  }

  .report-box {
    .dropdown .form-control.dropdown-toggle {
      font-size:                        18px;
      height:                           46px;
      margin-bottom:                    5px;
    }
  }
</style>

<script>
export default {
  props: ['value', 'options', 'fieldvalue', 'fieldname', 'notallowed', 'quick'],
  name: 'BaseSelect',
  data: () => ({
    currentValue: null,
    bgClass: null
  }),
  watch: {
    value: function(newVal) {
      this.currentValue = newVal;
      this.selectItem(this.selected);
    }
  },
  computed: {
    selected: function() {
      // return _.find(this.options, o => {
      return this.options.find(o => {
        if (!o)
          return false;

        return this.getItemValue(o) == this.currentValue;
      });
    }
  },
  mounted() {
    if (this.value) {
      // console.log(this.value, ' this value mounted ');
      this.currentValue = this.value;
      if (this.quick) {
        this.switchSelect(this.value)
      }
    }

    this.$emit('input', this.currentValue);
    this.$emit('item-selected', this.selected);
  },
  methods: {
    selectItem(item) {
      if (item && this.getItemValue(item) != this.currentValue) {
        if (this.quick) {
          this.switchSelect(item.value)
        }
        this.currentValue = this.getItemValue(item);
        this.$emit('input', this.getItemValue(item));
        this.$emit('item-selected', item);
      }
    },

    getItemValue(item) {
      if (this.fieldvalue)
        return item[this.fieldvalue];

      return  item.value;
    },

    getItemName(item) {
      if (this.fieldname)
        return item[this.fieldname];

      return item.name;
    },

    switchSelect(val) {
      this.bgClass = val && val !== '000_1' ? 'background-color: #f9ed9f' : ''
    }
  }
}
</script>
