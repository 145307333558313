<template>
  <div class="base-check-box" :class="{'checked': checked}" @click.stop.prevent="doCheck"></div>
</template>

<style scoped lang="scss">
</style>

<script>
export default {
  name: 'BaseCheckbox',
  props: ['id', 'item'],
  data: () => ({
    checked: false,
  }),
  methods: {
    doCheck() {
      this.checked = !this.checked
      
      let value = {
        img: this.item,
        uuid: this.id
      }

      if (this.item.type)
        value.type = this.item.type

      if (this.item.imageLaterality)
        value.imageLaterality = this.item.imageLaterality

      this.$emit('checked', value)
    }
  }
}
</script>
