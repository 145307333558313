<template>
  <div class="modal fade" id="access_info_modal"
       data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="access_info_modal_label" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="access_info_modal_label">{{ $t('externalPatient.shareInformation') }}</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body" id="contentPrint">
          <!-- <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
            <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
          </div> -->

          <div class="alert alert-warning" role="alert" v-show="!patient.email || !patient.telephone2">
            <span class="d-block" v-if="!patient.email">{{ $t('externalPatient.emailWarning') }}</span>
            <span class="d-block" v-if="!patient.telephone2">{{ $t('externalPatient.phoneWarning') }}</span>
            <a href="javascript:;" @click="showFields = !showFields">{{ $t('externalPatient.clickToRegister') }}</a>
          </div>

          <div class="clinic-header" v-if="clinic">
            <img :src="clinicLogo" alt="" width="180">
            <div class="clinic-header-content">
              <div class="content-data">
                <p>{{ date }}</p>
                <p>{{ time }}</p>
              </div>
              <p>{{ clinic.name }}</p>
              <p>
                {{ clinic.address }}
                <span v-show="clinic.addressNumber">, {{ clinic.addressNumber }}</span>
              </p>
              <p>{{ clinic.district }}</p>
              <p>{{ clinic.city }}/{{ clinic.state }}</p>
              <p>{{ clinic.telephone1 ? clinic.telephone1 : clinic.telephone2 }}</p>
            </div>
          </div>

          <p>
            {{ $t('patient.patient') }}:
            <strong>{{patient.fullName}}</strong>
          </p>
          <p>
            {{ $t('externalPatient.accessCode') }}:
            <strong>{{user.username}}</strong>
          </p>
          <p v-if="user.accessCode">
            {{ $t('externalPatient.accessPassword') }}:
            <strong>{{user.accessCode}}</strong>
          </p>
          <p>
            {{ $t('login.access') }}:
            <strong>{{accessURL}}</strong>
          </p>

          <div v-show="showFields" class="fadein">
            <vee-form v-slot="{ handleSubmit }" as="div">
              <form @submit.prevent.stop="handleSubmit(doUpdate)" @keydown.enter="$event.preventDefault()">
                <div class="row">
                  <div class="col-md-6" v-show="!patient.email">
                    <div class="form-group">
                      <!-- <label for="email">{{ $t('createClinic.email') }}</label>
                      <input type="text" class="form-control" ref="email" id="email" v-model="newEmail"> -->

                      <label for="email">{{ $t('createClinic.email') }}</label>
                      <vee-field name="email" v-model="newEmail" rules="email" v-slot="{ field, errorMessage }">
                        <input v-bind="field" type="text" class="form-control" ref="email" id="email" :disabled="disabled">
                        <span class="vee-error">{{ errorMessage }}</span>
                      </vee-field>
                    </div>
                  </div>
                  <div class="col-md-6" v-show="!patient.telephone2">
                    <div class="form-group">
                      <label for="telephone2">{{ $t('patient.telephone2') }}</label>
                      <input type="text" class="form-control" ref="telephone2" id="telephone2" v-model="newTelephone2">
                    </div>
                  </div>
                </div>
                <button class="btn btn-primary float-end" type="submit" name="button">{{ $t('save') }}</button>
              </form>
            </vee-form>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="close">{{ $t('terms.close') }}</button>
          <button type="button" class="btn btn-outline-primary" name="button" @click="sendEmail" v-show="patient.email">{{ $t('externalPatient.sendEmail') }}</button>
          <a :href="`https://api.whatsapp.com/send?phone=${countryDdi}${patient.telephone2}&text=${whatsAppText}`"
             class="btn btn-outline-primary" id="whatsapp-share-btt" rel="nofollow" target="_blank" v-show="patient.telephone2">WhatsApp</a>
          <button type="button" class="btn btn-primary" v-print="printData">{{ $t('externalPatient.print') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-dialog {
  margin-top:                       unset !important;
  .modal-content {
    .modal-header {
      background-color:             $phelcom-light-blue;
      border-bottom:                1px solid $platinum !important;
      &.d-flex {
        display:                    flex !important;
      }
      h5 {
        color:                      white;
        font-size:                  18px;
      }
    }
    .modal-body {
      padding:                      30px 20px 15px;
      p {
        strong {
          font-family:              'Courier new';
        }
      }
    }
    .form-group {
      display:                      block;
      .form-control {
        display:                    block;
        width:                      100% !important;
      }
    }
    .modal-footer {
      .btn-outline-secondary {
        border-color:               $gray;
      }
    }
    .modal-header
    .modal-body p {
      font-size:                    14px;
      margin:                       0;
    }
  }
}

.vee-error {
  width:                            100% !important;
}

.clinic-header {
  padding:                          15px;
  img {
    width:                          180px;
    display:                        inline-block;
  }
  .clinic-header-content {
    width:                          calc(100% - 185px);
    display:                        inline-block;
    padding-left:                   20px;
    vertical-align:                 top;
    p {
      margin:                       0;
    }
    .content-data {
      float:                        right;
      p {
        display:                    inline-block;
        margin:                     0 10px;
      }
    }
  }
}

@media not print {
  @media (max-width: 650px) {
    .clinic-header {
      img,
      .clinic-header-content {
        display:                    block;
      }
      img {
        margin:                     0 auto;
      }
      .clinic-header-content {
        text-align:                 center;
        width:                      100%;
        padding:                    0;
        margin-top:                 10px;
        .content-data {
          float:                    unset;
          p {
            margin:                 0 10px 0 0;
          }
        }
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'PatientAccessInfoModal',
  props: ['patient'],
  data: () => ({
    printData: {
      id: 'contentPrint',
      popTitle: '',
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    clinic: null,
    clinicLogo: null,
    date: null,
    time: null,
    user: {},
    accessURL: "",
    alert: {},
    whatsAppText: null,
    countryDdi: null,
    showFields: false,
    newEmail: null,
    newTelephone2: null,
  }),
  async mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    const now = moment(new Date())
    this.date = now.format(this.$i18n.t('dateFormat'))
    this.time = now.format(this.$i18n.t('timeFormat'))
    console.log('thisanamnesis', this.anamnesis);

    await this.fetchData()
  },
  methods: {
    async fetchData() {
      NProgress.start()
      try {
        let response = await this.$patientService.getAccessInfo(this.patient.id)
        this.clinic = response.clinic
        this.clinicLogo = response.clinicLogo

        const accessData = response.accessData ? response.accessData : {}
        this.user = accessData.user
        this.accessURL = `${window.location.host}/acesso`

        this.countryDdi = this.$utils.getCountryDdi(this.clinic.country).substring(1)
        let messageText = `${this.$i18n.t('externalPatient.messageSubject')}\r\n\r\n${this.$i18n.t('user.clinic')}: ${this.clinic.name}\r\n${this.$i18n.t('patient.patient')}: ${this.patient.fullName}\r\n${this.$i18n.t('externalPatient.accessCode')}: ${this.user.username}\r\n${this.$i18n.t('externalPatient.accessPassword')}: ${this.user.accessCode}\r\n${this.$i18n.t('login.access')}: ${this.accessURL}?_data=${this.user.username}`.toString()
        this.whatsAppText = encodeURIComponent(messageText)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
    },
    async sendEmail() {
      NProgress.start()
      try {
        await this.$externalService.sendEmailPatientAccessData(this.patient.id)
        this.$message.popup(this, 'operationSuccess', 'operationSuccess', 'success')
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
      NProgress.done(true)
    },
    async doUpdate() {
      let patient = this.patient

      if (this.newEmail)
        patient.email = this.newEmail
      if (this.newTelephone2)
        patient.telephone2 = this.newTelephone2

      let params = {
        patient: patient
      }

      NProgress.start()
      try {
        let response = await this.$patientService.updatePatient(params)
        this.$message.popup(this, 'operationSuccess', 'operationSuccess', 'success')
        this.showFields = false
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
      }
      NProgress.done(true)
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>
