import { useUserStore } from '@/store/user'
import { useExternalStore } from '@/store/external'
import { useEyerwebStore } from '@/store/eyerweb'
import {app} from '@/main.js'

import MainLayout from '@/layout/layout.vue'
import Dev from '@/views/account/dev/dev.vue'

import AuthLogin from '@/views/auth/auth-login.vue'
import AuthWololo from '@/views/auth/auth-wololo.vue'
import AuthExpiredSession from '@/views/auth/auth-expired-session.vue'
import AuthMFA from '@/views/auth/auth-mfa.vue'
import Home from '@/views/account/home.vue'
import HomeLimbo from '@/views/account/HomeLimbo.vue'
import Maintenance from '@/views/maintenance.vue'

// audit
import AuditList from '@/views/account/audit/audit-list.vue'

// clinic
import ClinicList from '@/views/account/clinic/clinic-list.vue'
import ClinicShow from '@/views/account/clinic/clinic-show/clinic-show.vue'
import ClinicCreate from '@/views/account/clinic/clinic-create/clinic-create.vue'

// dashboard
import Dashboard from '@/views/account/dashboard/dashboard.vue'

// dashboard mutirao
import DashboardMutirao from '@/views/account/clinic/dashboard-mutirao/dashboard-mutirao.vue'

// device
import DeviceList from '@/views/account/device/device-list.vue'
import DeviceShow from '@/views/account/device/device-show/device-show.vue'
import DeviceCreate from '@/views/account/device/device-create/device-create.vue'

// exam
import ExamList from '@/views/account/exam/exam-list.vue'
import ExamShow from '@/views/account/exam/exam-show/exam-show.vue'
import ExamImageEdit from '@/views/account/exam/exam-show/exam-image-edit.vue'

// patient
import PatientCreate from '@/views/account/patient/patient-create/patient-create.vue'
import PatientMedicalRecord from '@/views/account/patient/patient-medical-record/patient-medical-record.vue'
import PatientList from '@/views/account/patient/patient-list.vue'
import PatientShow from '@/views/account/patient/patient-show/patient-show.vue'
import PatientProgression from '@/views/account/patient/patient-progression/patient-progression.vue'

// registration account
import RegistrationAccountCreate from '@/views/registration/registration-account/registration-account-create.vue'
import RegistrationAccountCreated from '@/views/registration/registration-account/registration-account-created.vue'
import RegistrationAccountActivation from '@/views/registration/registration-account/registration-account-activation.vue'

// registration clinic
import RegistrationClinic from '@/views/registration/registration-clinic/registration-clinic.vue'
import RegistrationClinicCreated from '@/views/registration/registration-clinic/registration-clinic-created.vue'

// registration user
import InvitationIndex from '@/views/registration/invitation/invitation-index.vue'
import RegistrationUserForm from '@/views/registration/invitation/registration-user-form/registration-user-form.vue'
import InvitationDone from '@/views/registration/invitation/invitation-done.vue'

// report
import Report from '@/views/account/report/report.vue'
import ReportGeneral from '@/views/account/report/report-general/report-general.vue'

// reset password
import AuthPasswordReset from '@/views/auth/auth-password-reset.vue'

// users
import UserList from '@/views/account/user/user-list.vue'
import UserShow from '@/views/account/user/user-show/user-show.vue'
import UserMFA from '@/views/account/user/user-mfa/user-mfa.vue'

// EXTERNAL (patient access)
import ExternalAccess from '@/views/external/external-access.vue'
import ExternalExamList from '@/views/external/external-exam/external-exam-list.vue'
import ExternalExamShow from '@/views/external/external-exam/external-exam-show/external-exam-show.vue'
import ExternalHome from '@/views/external/external-home.vue'
import ExternalPatientShow from '@/views/external/external-patient/external-patient-show.vue'

// external (eyer web)
import Eyerweb from '@/views/eyerweb/eyerweb.vue'
import EyerwebHome from '@/views/eyerweb/eyerweb-home.vue'
import EyerwebExamList from '@/views/eyerweb/eyerweb-exam/eyerweb-exam-list.vue'
import EyerwebExamShow from '@/views/eyerweb/eyerweb-exam/eyerweb-exam-show/eyerweb-exam-show.vue'
import EyerwebFullBackup from '@/views/eyerweb/eyerweb-fullbackup.vue'
import EyerwebPatientList from '@/views/eyerweb/eyerweb-patient/eyerweb-patient-list.vue'
import EyerwebPatientShow from '@/views/eyerweb/eyerweb-patient/eyerweb-patient-show/eyerweb-patient-show.vue'

import ProxyRouter from './proxy-router.vue';

const authGuard = (to, from, next) => {
  const userStore = useUserStore()
  if (!userStore.isAuthenticated) {
    next("/login")
    return
  }

  const securityService = app.config.globalProperties.$security // TODO improve
  const isBlockedByMFA = securityService.isBlockedByMFA(userStore.sessionInfo)
  const mfaFreeTargets = ['clinic-list', 'user-mfa']
  const isDmzMFAZone = _.includes(mfaFreeTargets, to.name)
  // allow changing clinic and configure MFA only
  if (isBlockedByMFA && !isDmzMFAZone) {
    next("/clinic")
    return
  }

  next();
};

const authGuardExternal = (to, from, next) => {
  const externalStore = useExternalStore()

  if (externalStore.isAuthenticated) {
    next();
  } else {
    next("/acesso")
  }
};

const authGuardEyerweb = (to, from, next) => {
  const eyerwebStore = useEyerwebStore()
  const session = eyerwebStore.sessionInfo

  console.log('session: ', session)
  if (session){
    if (session.eyerwebStatus.connected) {
      next();
    }
  }else {
    next('/link')
  }

};


/**
 * when the user is already authenticated, just go to the main page
 */
const maySkipLoginGuard = (to, from, next) => {
  const userStore = useUserStore()
  // EC-517 avoid login loop
  // console.log("maySkipLoginGuard >>>", "isAuthenticated? " + userStore.isAuthenticated)
  if (userStore.isAuthenticated) {
    next('/')
  } else {
    next()
  }
};

const wololoGuard = (to, from, next) => {
  const userStore = useUserStore()
  userStore.reset()
  next()
};

const routes = [
  { path: '/', name: 'app', component: MainLayout },
  { path: '/dev', name: 'dev', component: Dev },
  { path: '/proxy-router/:target/:data?', name: 'proxy-router', params: true, component: ProxyRouter},
  { path: '/maintenance', name: 'maintenance', component: Maintenance },

  { path: '/login', name: 'login', component: AuthLogin, beforeEnter: maySkipLoginGuard },
  { path: '/auth/mfa', name: 'auth-mfa', component: AuthMFA },
  { path: '/auth/expired', name: 'expired', component: AuthExpiredSession },
  { path: '/wololo', name: 'wololo', component: AuthWololo, beforeEnter: wololoGuard },

  { path: '/password-reset', name: 'password-reset', component: AuthPasswordReset },

  // limbo
  { path: '/home/limbo', name: 'home-limbo', component: HomeLimbo },
  // mutirao
  { path: '/dashboard-mutirao', name: 'dashboard-mutirao', component: DashboardMutirao },

  // registration account
  { path: '/create', name: 'registration-account-create', component: RegistrationAccountCreate },
  { path: '/registration/account-created', name: 'registration-account-created', component: RegistrationAccountCreated },
  { path: '/account-activation', name: 'registration-account-activation', component: RegistrationAccountActivation },

  // registration clinic
  { path: '/registration/clinic', name: 'registration-clinic', component: RegistrationClinic },
  { path: '/registration/clinic/created', name: 'registration-clinic-created', component: RegistrationClinicCreated },

  // registration user
  { path: '/invitation', name: 'invitation-index', component: InvitationIndex },
  { path: '/invitation-form', name: 'invitation-form', component: RegistrationUserForm },
  { path: '/invitation-done', name: 'invitation-done', component: InvitationDone },

  { path: '/home', name: 'home', component: Home , beforeEnter: authGuard,
    children: [
      // audit
      { path: '/audit-list', name: 'audit-list', component: AuditList },
      // clinic
      { path: '/clinic/', name: 'clinic-list', component: ClinicList },
      { path: '/clinic/:clinicID', name: 'clinic-show', params: true, component: ClinicShow },
      { path: '/clinic/create', name: 'clinic-create', component: ClinicCreate },
      // dashboard
      // OMIT DASHBOARD
      //{ path: '/dashboard', name: 'dashboard', params: true, component: Dashboard },
      // device
      { path: '/device/', name: 'device-list', component: DeviceList },
      { path: '/device/:deviceID', name: 'device-show', params: true, component: DeviceShow },
      { path: '/device/create', name: 'device-create', params: true, component: DeviceCreate },
      // exam
      { path: '/exam/', name: 'exam-list', component: ExamList },
      { path: '/exam/:examID', name: 'exam-show', params: true, component: ExamShow },
      // exam data
      { path: '/exam/image/edit/:examDataID', name: 'exam-image-edit', params: true, component: ExamImageEdit },
      // patient
      { path: '/patient/', name: 'patient-list', component: PatientList },
      { path: '/patient/:patientID?', name: 'patient-show', params: true, component: PatientShow },
      { path: '/patient/create/', name: 'patient-create', params: true, component: PatientCreate },
      { path: '/patient/:patientID/medical-record/:recordID?', name: 'patient-medical-record', params: true, component: PatientMedicalRecord },
      { path: '/patient/progression/:patientID', name: 'patient-progression', params: true, component: PatientProgression },
      // report
      { path: '/report/:examID/:id?', name: 'report', params: true, component: Report },
      { path: '/report/general/:examID/:id?', name: 'report-general', params: true, component: ReportGeneral },
      // user
      { path: '/user/', name: 'user-list', component: UserList },
      { path: '/user/:userID', name: 'user-show', params: true, component: UserShow },
      { path: '/user/mfa/:userID', name: 'user-mfa', params: true, component: UserMFA },
    ]
  },

  // external (patient access)
  { path: '/acesso', name: 'external-access', component: ExternalAccess},
  { path: '/external/home', name: 'external-home', component: ExternalHome, beforeEnter: authGuardExternal,
    children: [
      { path: '/external/exam', name: 'external-exam-list', component: ExternalExamList },
      { path: '/external/exam/:examID', name: 'external-exam-show', component: ExternalExamShow },
      { path: '/external/patient/', name: 'external-patient-show', component: ExternalPatientShow },
    ]
  },

  // external (eyerlink)
  { path: '/link', name: 'eyerweb', component: Eyerweb},
  { path: '/link/home', name: 'eyerweb-home', component: EyerwebHome, beforeEnter: authGuardEyerweb,
    children: [
      { path: '/link/exam', name: 'eyerweb-exam-list', component: EyerwebExamList },
      { path: '/link/exam/:examID', name: 'eyerweb-exam-show', component: EyerwebExamShow },
      { path: '/link/backup', name: 'eyerweb-full-backup', component: EyerwebFullBackup },
      { path: '/link/patient/', name: 'eyerweb-patient-list', component: EyerwebPatientList },
      { path: '/link/patient/:patientID', name: 'eyerweb-patient-show', component: EyerwebPatientShow },
    ]
  },

]

export default routes;
