<template>
  <div class="" :key="examID">
    <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title sub" v-if="examID">{{ $t("exam.examData") }} |
        <span @click="toPatient">{{ patientName }}</span>
      </h2>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-images>
        {{ $t("exam.images") }}
      </template>
      <template v-slot:images>
        <ExternalExamImageTab v-if="tabActive === 'images' && examID" :exam_id="examID" :alert_msg="alert ? alert : null"></ExternalExamImageTab>
      </template>

      <template v-slot:tab-head-report>
        {{ $t("exam.report") }}
      </template>
      <template v-slot:report>
        <ExternalExamReportTab v-if="tabActive === 'report' && examID" :exam_id="examID" :alert_msg="alert ? alert : null"></ExternalExamReportTab>
      </template>
    </BaseTab>

  </div>
</template>

<style lang="scss" scoped>
  .title-box {
    margin-top:                  30px;
    .btn-back {
      margin-right:              20px;
    }
    .title.sub {
      cursor:                    pointer;
    }
  }
</style>

<script>
import ExternalExamImageTab from './ExternalExamImageTab.vue'
import ExternalExamReportTab from './ExternalExamReportTab.vue'

export default {
  name: 'external-exam-show',
  components: {
    ExternalExamImageTab,
    ExternalExamReportTab,
  },
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    loading: null,
    msgError: null,
    tabActive: 'images',
    tabs: ['images', 'report'],
    exam: null,
    patientName: null,
    examID: null,
    tab: null
  }),
  mounted: function() {
    this.tab = this.$route.query.tab

    const data = this.$utils.parseDataQuery(this.$route.query.data)
    this.patientName = data.patient_name
    this.examID = this.$route.params.examID

    // TODO EC-360
    // retrieve exam details using this endpoint -> /api/v2/eyercloud/external/exam/get [param -> id: exam.id]
    // this endpoint will return the examDataList too

    this.$activeLink.value = 'exam'

    if (this.tab) {
      this.switchTab(this.tab)
    }
  },
  methods: {
    goBack() {
      this.$router.push({name: 'external-exam-list'})
    },
    switchTab(tab) {
      this.tabActive = tab;
    },
    toPatient() {
      this.$router.push({name: 'external-patient-show'})
    }
  },
  // unmounted() {
  //   this.tabActive = 'info'
  // }
}
</script>
