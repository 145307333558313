<template>
  <div class="modal fade terms-modal" id="modal_external_terms" ref="modal_terms" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="externalTermsModalLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" :class="{'d-flex' : wasAccepted}">
          <h5 class="modal-title" id="externalTermsModalLabel">{{ $t('externalTerms.termsOfUse') }}</h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" :class="{'d-none' : !wasAccepted}" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
            <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
          </div>

          <p>
            {{ $t('externalTerms.content') }}
          </p>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-outline-secondary" name="button" data-bs-dismiss="modal" @click="close">{{ $t('no') }}</button>
          <button type="button" class="btn btn-primary" @click="doAccept">{{ $t('yes') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.terms-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background-color:             $phelcom-light-blue;
        border-bottom:                1px solid $platinum !important;
        &.d-flex {
          display:                    flex !important;
        }
        h5 {
          color:                      white;
          font-size:                  18px;
        }
      }
      .modal-body {
        padding:                      20px;
        a {
          color:                      #052440;
          text-decoration:            underline;
          cursor:                     pointer;
        }
        a,
        b {
          font-weight:                bold;
        }
        .check-box {
          margin-top:                 15px;
          cursor:                     pointer;
          label {
            font-weight:              normal;
          }
          input {
            margin-top:               0;
            margin-right:             5px;
            vertical-align:           text-bottom;
          }
        }
      }
      .modal-footer {
        .btn-outline-secondary {
          border-color:               $gray;
        }
      }
      .modal-header
      .modal-body p {
        font-size:                    14px;
        margin:                       0;
      }
    }
  }
}
</style>

<script>
import { useExternalStore } from '@/store/external'

export default {
  name: 'ExternalTermsModal',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    acceptanceTerm: null,
    wasAccepted: false,
    session: {
      patient: {},
    },
  }),
  mounted() {
    this.reloadSessionInfo()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async doAccept() {
      let params = {
        accepted: true
      }
      NProgress.start()
      try {
        let data = await this.$externalService.acceptTerms(params)
        this.session = data

        const externalStore = useExternalStore()
        externalStore.termAccepted(data)
        this.reloadSessionInfo()

        this.$nextTick(() => {
          $('#closeBtn').click();
        });
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)

    },
    reloadSessionInfo() {
      const externalStore = useExternalStore()
      this.session = externalStore.sessionInfo

      this.acceptanceTerm = this.session.patient.acceptanceTerm // is null when not toggled
      this.wasAccepted = this.acceptanceTerm ? this.acceptanceTerm.accepted : false
    }
  },
  destroyed() {
    this.acceptanceTerm = null
  }
}
</script>
