<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="action-buttons rounded text-end">
      <button class="btn btn-outline-secondary" type="button" name="button" @click="openSignatureModal()" v-if="checkUser">
        <span>{{ $t('user.signature') }}</span>
      </button>
      <button class="btn btn-outline-secondary" type="button" name="button" @click="openChangePhotoModal()" v-if="checkUser">
        <span>{{ $t('user.changePhoto') }}</span>
      </button>
      <button class="btn btn-outline-secondary" type="button" name="button" @click="openChangePassword()" v-if="checkUser">
        <span>{{ $t('user.changePassword') }}</span>
      </button>
      <button class="btn btn-outline-secondary" type="button" name="button" @click="openChangePin()" v-if="checkUser">
        <span>{{ $t('user.changePIN') }}</span>
      </button>
      <button class="btn btn-outline-danger" type="button" name="button" v-if="!disabled" @click="disabled = !disabled">
        <i class="fa fa-ban"></i>
        <span>{{ $t('user.cancel') }}</span>
      </button>
      <button class="btn btn-outline-warning" type="button" name="button" v-if="disabled && checkUser" @click="editFields">
        <i class="fa fa-pencil"></i>
        <span>{{ $t('user.edit') }}</span>
      </button>
      <button class="btn btn-outline-success" type="button" name="button" v-if="!disabled && checkUser" @click="doUpdate">
        <i class="fa fa-check"></i>
        <span>{{ $t('user.modify') }}</span>
      </button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div class="row" v-if="userData">
      <div class="col-md-6">
        <div class="form-group">
          <label for="firstName">{{ $t('user.firstName') }}</label>
          <input type="text" class="form-control" name="user[firstName]" ref="firstName" id="firstName" v-model="userData.collaborator.firstName" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="email">{{ $t('user.email') }}</label>
          <input type="text" class="form-control" id="email" name="user[email]" v-model="userData.email" disabled>
        </div>
        <div class="form-group">
          <label for="npi">{{ $t('user.npi') }}</label>
          <input type="text" class="form-control" id="npi" name="user[npi]" v-model="userData.collaborator.npi" :disabled="disabled">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="lastName">{{ $t('user.lastName') }}</label>
          <input type="text" class="form-control" id="lastName" name="user[lastName]" v-model="userData.collaborator.lastName" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="document">{{ $t('user.document') }}</label>
          <input type="text" class="form-control" id="document" name="user[document]" v-model="userData.collaborator.document" :disabled="disabled">
        </div>
        <div class="form-group d-block">
          <label for="document">{{ $t('user.permissions') }}</label>
          <span class="label label-success">{{ $t(`${user.permissions}`) }}</span>
          <button id="changePermission" type="button" class="btn btn-danger float-end" @click="openModalPermissions" v-show="session.isPowerfull && !checkUser">{{ $t('user.changePermission') }}</button>
        </div>
      </div>
      <div class="row">
        <hr/>
        <div class="col-md-6">
          <div class="form-group">
            <label for="mfa">{{ $t('user.mfa') }}</label>
            <span class="label" :class="mfaStatus(user)">MFA</span>
            <button class="btn btn-info" v-if="checkUser" @click="mfaSetup()">{{$t('user.mfaSetup')}}</button>
          </div>
        </div>
      </div>
    </div>

    <teleport to="body">
      <UserChangePinModal v-if="showChangePin" @close="closeModal"></UserChangePinModal>

      <UserChangePasswordModal v-if="showChangePassword" @close="closeModal"></UserChangePasswordModal>

      <BaseCropperModal ref="modalCropper"></BaseCropperModal>

      <UserPermissionsModal v-if="showModalPermissions" :user="user" @close="closeModalPermissions"></UserPermissionsModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.btn-info {
  margin-left:                     10px;
}
</style>

<script>
import { useUserStore } from '@/store/user'
import UserChangePinModal from './UserChangePinModal.vue'
import UserChangePasswordModal from './UserChangePasswordModal.vue'
import UserPermissionsModal from './UserPermissionsModal.vue'

export default {
  name: 'UserDataTab',
  props: ['user', 'alertmsg'],
  components: {
    UserChangePinModal,
    UserChangePasswordModal,
    UserPermissionsModal,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    showChangePin: false,
    showModalPermissions: false,
    showModalCropper: false,
    showChangePassword: false,
    disabled: true,
    userData: null,
    cropperType: null,
    imageSource: null,
    mfa: {
      email: {},
      mobile: {}
    }
  }),
  watch: {
    alertmsg: function(newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
      } else {
        this.alert.message = null
      }
    },
  },
  computed: {
    checkUser() { // TODO rename method
      return this.session.collaborator.uuid === this.user.uuid
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.userData = this.user
  },
  methods: {
    mfaStatus(user) {
      return this.$userService.mfaStatus(user)
    },
    editFields() {
      this.disabled = !this.disabled;
      this.$nextTick(() => {
        this.$refs.firstName.focus()
      })
    },
    doUpdate: async function() {
      this.alert.message = null
      let params = {
        user: this.userData
      }

      params.user.npi = this.userData.collaborator.npi
      params.user.document = this.userData.collaborator.document

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$userService.updateUser(params)
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
        this.disabled = !this.disabled
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async openSignatureModal() {
      let signatureURL = await this.$userService.getSignature()
      let params = {
        type: "user.changeSignature",
        image: signatureURL.result
      }
      this.openCropperModal(params)
    },
    async openChangePhotoModal() {
      let photoURL = await this.$userService.getPhoto()
      let params = {
        type: "user.changePhoto",
        image: photoURL.result
      }

      this.openCropperModal(params)
    },
    openChangePin() {
      this.showChangePin = true

      setTimeout(function() {
        // REMEMBER => let modal_change_pin = document.getElementById("modal_change_pin");
        new bootstrap.Modal(modal_change_pin).show();
      }, 100);
    },
    openChangePassword() {
      this.showChangePassword = true

      setTimeout(function() {
        new bootstrap.Modal(change_password_modal).show();
      }, 100);
    },
    async openCropperModal(params) {
      this.alert.message = null;

      const ok = await this.$refs.modalCropper.show({
        title: this.$i18n.t(params.type),
        imgSrc: params.image,
        aspectRatio: params.type == 'user.changePhoto' ? 0 : null,
        okButton: this.$i18n.t('save'),
        okButtonClass: 'btn-primary',
        removeButton: params.type == 'user.changeSignature' ? true : false,
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        let canvas = ok.canvas

        canvas.toBlob(blob => {
          var formData = new FormData()
          formData.append('data', blob)
          this.doSaveCropper(formData, params.type, ok.removeSignature)
        }, 'image/png')
      }
    },
    openModalPermissions() {
      this.showModalPermissions = true

      setTimeout(function() {
        new bootstrap.Modal(modal_permissions).show();
      }, 100);
    },
    closeModal(values) {
      values.type === 'password' ? this.showChangePassword = false : this.showChangePin = false
      this.setAlert(values.alert)
    },
    closeModalPermissions() {
      this.$nextTick(() => {
        this.showModalPermissions = false

      })
    },
    async doSaveCropper(value, type, removeSignature) {
      let params = {
        id: this.user.id,
        data: value.get('data'),
        removeSignature: removeSignature
      }

      this.loading = true
      NProgress.start()
      try {
        if (type == 'user.changePhoto') {
          this.changePhoto(params)
        } else {
          this.changeSignature(params)
        }
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async changePhoto(params) {
      let response = await this.$userService.uploadPhoto(params)
      this.imageSource = response.result
      this.emitter.emit("profilePhotoChanged", this.imageSource);
    },
    async changeSignature(params) {
      let response = await this.$userService.uploadSignature(params)
    },
    setAlert(value) {
      if (value) {
        this.alert = value
      }
    },
    mfaSetup() {
      this.$router.push(`/user/mfa/${this.user.id}`)
    },
    showMFASetup() {

    }
  }
}
</script>
