<template>
  <div class="">
    <h4 class="sub-title">{{ $t('varFields.pre') }}</h4>
    <div class="action-info-box">
      <div class="" v-if="alterationScore && alterationScore.status">
        <i class="icon-ai " :class="alterationScore.status"></i>
        <!-- <h4>
          {{ $t('od') }}: {{ alterationScore.odText }} {{ $t('os') }}: {{ alterationScore.osText }}
        </h4> -->
      </div>
      <div v-else></div>
      <div class="rd-box">
        <div class="">
          <h4>{{ $t('varFields.rightEye') }}</h4>
          <div class="btn-group" role="group">
            <input class="btn-check" type="radio" name="rightEye" id="rightEye1" autocomplete="off" v-model="options.rightEye" value="1">
            <label class="btn btn-outline-primary" :class="healthyEyeClass('od')" for="rightEye1" >{{ $t('varFields.yes') }}</label>
            <input class="btn-check" type="radio" name="rightEye" id="rightEye2" autocomplete="off" v-model="options.rightEye" value="2">
            <label class="btn btn-outline-primary" :class="unhealthyEyeClass('od')" for="rightEye2" >{{ $t('varFields.no') }}</label>
            <input class="btn-check" type="radio" name="rightEye" id="rightEye3" autocomplete="off" v-model="options.rightEye" value="3">
            <label class="btn btn-outline-primary" :class="lowQualityClass('od')" for="rightEye3" >{{ $t('varFields.impossibleToSee') }}</label>
          </div>
        </div>
        <div class="">
          <h4>{{ $t('varFields.leftEye') }}</h4>
          <div class="btn-group" role="group">
            <input class="btn-check" type="radio" name="leftEye" id="leftEye1" autocomplete="off" v-model="options.leftEye" value="1">
            <label class="btn btn-outline-primary" :class="healthyEyeClass('os')" for="leftEye1" >{{ $t('varFields.yes') }}</label>
            <input class="btn-check" type="radio" name="leftEye" id="leftEye2" autocomplete="off" v-model="options.leftEye" value="2">
            <label class="btn btn-outline-primary" :class="unhealthyEyeClass('os')" for="leftEye2" >{{ $t('varFields.no') }}</label>
            <input class="btn-check" type="radio" name="leftEye" id="leftEye3" autocomplete="off" v-model="options.leftEye" value="3">
            <label class="btn btn-outline-primary" :class="lowQualityClass('os')" for="leftEye3" >{{ $t('varFields.impossibleToSee') }}</label>
          </div>
        </div>
        <div class="">
          <h4>{{ $t('varFields.referred') }}</h4>
          <div class="btn-group" role="group">
            <input class="btn-check" type="radio" name="referred" id="referred1" autocomplete="off" v-model="options.referred" value="1">
            <label class="btn btn-outline-primary" :class="referrerClass()" for="referred1" >{{ $t('varFields.yes') }}</label>
            <input class="btn-check" type="radio" name="referred" id="referred2" autocomplete="off" v-model="options.referred" value="2">
            <label class="btn btn-outline-primary" :class="unreferrerClass()" for="referred2" >{{ $t('varFields.no') }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="text-end">
      <button class="btn btn-success" type="button" name="button" @click="doSave">{{ $t('save') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action-info-box {
  display:                          flex;
  align-items:                      center;
  > div:first-child h4 {
    font-weight:                    normal;
    margin:                         0 20px 0 0;
    vertical-align:                 middle;
  }
  .rd-box {
    width:                          auto;
    h4 {
      font-size:                    14px;
      text-align:                   right;
      margin-right:                 5px;
      min-width:                    150px;
    }
  }
  .btn-group,
  > div:first-child h4,
  .rd-box h4,
  .rd-box {
    display:                        inline-block;
  }
  .btn-group {
    .btn-outline-primary {
      @include                      border_radius(3px);
      width:                        auto;
      min-width:                    100px;
      margin:                       0 5px 5px;
      &.pre-check {
        background-color:           rgb(191 217 255);
      }
    }
  }
  .btn-group .btn-outline-primary.pre-check:hover,
  .btn-group .btn-outline-primary.pre-check:focus,
  .btn-group .btn-outline-primary.pre-check:active,
  .btn-check:checked + .btn-outline-primary.pre-check {
    background-color:               $brandeis;
  }
}

@media (max-width: 1049px) {
  .action-info-box {
    text-align:                     center;
    &,
    .btn-success,
    .rd-box,
    .rd-box h4 {
      display:                      block;
    }
    .rd-box {
      width:                        100%;
      h4 {
        font-size:                  14px;
        text-align:                 center;
        margin-right:               0;
        min-width:                  unset;
      }
      > div {
        display:                    inline-block;
        margin:                     0;
        width:                      33%;
        vertical-align:             top;
      }
      .btn {
        font-size:                  12px;
        margin:                     0 auto 5px;
        float:                      unset;
      }
    }
    .btn-success {
      margin:                       0 auto;
    }
  }
}

@media (max-width: 500px) {
  .action-info-box .rd-box > div {
    display:                        inline-block;
    margin:                         0;
    width:                          49%;
  }
}

@media (min-width: 991px) {
  .icon-ai {
    font-size:                      2em;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'ExamImagePreAnalysis',
  props: ['exam', 'patient'],
  data: () => ({
    options: {
      rightEye: null,
      leftEye: null,
      referred: null,
    },
    alterationScore: {},
    fullScore: {
      od: {},
      os: {}
    },
    threshold: 0.3, // DEFAUlt
    systemConfiguration: {
      preAnalysis: {
        autoReferrerMode: null, // "auto"
      }
    }
  }),
  computed: {
  },
  mounted() {
    const userStore = useUserStore()
    const session = userStore.sessionInfo

    const clinic = session.currentClinic
    this.systemConfiguration = this.$clinicService.getSystemConfiguration(clinic)

    this.threshold = this.systemConfiguration.preAnalysis.threshold

    this.options.referred = this.exam.referred ? this.exam.referred : null
    this.options.rightEye = this.exam.rightEyeHealth ? this.exam.rightEyeHealth : null
    this.options.leftEye = this.exam.leftEyeHealth ? this.exam.leftEyeHealth : null

    this.alterationScore =  this.$aiService.alterationScore(this.exam.metadata)
    this.fullScore['od'] = {} //alterationScoreByEye(this.exam.metadata, 'od')
    this.fullScore['os'] = {} // alterationScoreByEye(this.exam.metadata, 'os')

    this.keepRetroCompatibility()
  },
  methods: {
    doSave: async function() {
      let params = {
        exam: this.exam.id,
        options: this.options
      }

      NProgress.start()
      try {
        await this.$mutiraoService.savePreAnalysis(params)
        this.emitAlert(this.$i18n.t('varFields.saveSuccess'), 'alert-success')
        this.exam.rightEyeHealth = this.options.rightEye
        this.exam.leftEyeHealth = this.options.leftEye

        const targetView = this.systemConfiguration.preAnalysis.nextScreen
        switch(targetView) {
          case 'quickReport':
            let data = this.$utils.dataQuery({
              patient: JSON.stringify(this.patient),
              isQuickReport: true
            })

            this.$router.push({name: 'report', params: {examID: this.exam.id}, query: {data: data}})
            break
          case 'examList':
            this.$router.push({name: 'exam-list'})
            break
        }
      } catch (err) {
        this.emitAlert(`${this.$i18n.t('varFields.saveError')} <strong>${err}</strong>`, 'alert-danger')
      }
      NProgress.done(true)
    },
    emitAlert(alertMsg, alertClass) {
      let alert = {
        message: alertMsg,
        class: alertClass
      }
      this.$emit('alertmsg', alert)
    },
    healthyEyeClass(laterality) {
      const eyeScore = this.fullScore[laterality]
      if (eyeScore && eyeScore.value <= this.threshold)
        return 'pre-check'
    },
    unhealthyEyeClass(laterality) {
      const eyeScore = this.fullScore[laterality]
      if (eyeScore && eyeScore.value > this.threshold)
        return 'pre-check'
    },
    lowQualityClass(laterality) {
      const eyeScore = this.fullScore[laterality]
      if (eyeScore && eyeScore.value === 'LQ')
        return 'pre-check'
    },
    referrerClass() {
      const referrerMode = this.systemConfiguration.preAnalysis.autoReferrerMode
      if (referrerMode != 'auto')
        return ""

      const doIt = this.alterationScore && this.alterationScore.status != 'green'
      return doIt ? 'pre-check' : ''

      // if (this.alterationScore && (this.alterationScore.od > this.threshold || this.alterationScore.os > this.threshold))
      //  return 'pre-check'
    },
    unreferrerClass() {
      const referrerMode = this.systemConfiguration.preAnalysis.autoReferrerMode
      if (referrerMode != 'auto')
        return ""

      // if (this.alterationScore && (this.alterationScore.od <= this.threshold && this.alterationScore.os <= this.threshold))
      //   return 'pre-check'

      const doIt = this.alterationScore && this.alterationScore.status == 'green'
      return doIt ? 'pre-check' : ''
    },
    /**
     * keep retro compatibility (old mutirao)
     */
    keepRetroCompatibility() {
      if (this.patient.referred > 0) {
        this.options.referred = this.patient.referred
      }

      if (this.exam.alteredRetina > 0) {
        let eyeHealth = (this.exam.alteredRetina == 1) ? 2 : 1 // healthy x unhealthy
        this.options.rightEye = eyeHealth
        this.options.leftEye = eyeHealth
      }

      if (this.exam.satisfactoryQuality == 2) {
        this.options.rightEye = 3 // impossible to observe
        this.options.leftEye = 3 // impossible to observe
      }
    }
  }
}
</script>
