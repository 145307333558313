<template>
  <div class="medical-record-tabs">
    <div class="form-group">
      <label for="">{{ $t("medicalRecord.s6") }}</label>
      <BaseQLEditor :content="medicalRecordData.s6.e1" @content-changed="setTextForS6E1"></BaseQLEditor>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'PrescriptionsSection',
  props: ["medicalRecordData"],
  mounted() {
  },
  methods: {
    setTextForS6E1(value) {
      this.medicalRecordData.s6.e1 = value
    }
  }
}
</script>
