<template>
  <div class="sidebar ease-all" id="sidebarMenu">
    <div class="menu ease-width desk">
      <div class="header-logo">
        <i class="logo-full icon-logo_eyercloud_horizontal"></i>
        <i class="logo-retract icon-logo_eyercloud"></i>
        <button id="btnCollapseMenu" ref="btnCollapseMenu" class="no-btn" type="button" name="button" @click="$emit('docollapse')">
          <i class="icon-back"></i>
        </button>
      </div>

      <MenuProfile></MenuProfile>

      <MenuDesktopLinks></MenuDesktopLinks>

      <div class="level-box" v-if="session && session.currentClinic.ranking && session.currentClinic.ranking > -1">
        <p>{{ $t('eyerLevel.handlingLevel') }}</p>
        <WizardLevel></WizardLevel>
        <div v-if="true">
          <p>
            {{ $t('eyerLevel.training') }} <a href="#">{{ $t('eyerLevel.clickHere') }}</a> {{ $t('eyerLevel.toStart') }}
          </p>
          <a href="#">
            <img src="/images/logical-thinking.png" alt="Skills" width="32px">
          </a>
        </div>
      </div>


      <div class="menu-footer">
        <MenuStorageBar v-if="false"></MenuStorageBar>

        <div class="phelcom-info">
          <p class="version">J {{ appVersion }}</p>
          <i class="fa fa-wifi" :class="{'red': !isWebSocketConnected}"></i>
        </div>
      </div>
    </div>

    <div class="menu ease-width mob">
      <MenuMobileLinks></MenuMobileLinks>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .menu-footer {
    position:                           absolute;
    width:                              100%;
    padding:                            10px 10px 3px;
    bottom:                             0;
    .version {
      color:                            white;
      display:                          inline-block;
      margin-bottom:                    0;
    }
    .fa-wifi {
      color:                            green;
      &.red {
        color:                          red;
      }
    }
    .phelcom-info {
      display:                          flex;
      align-items:                      center;
      justify-content:                  space-between;
    }
  }

  .sidebar {
    width:                              230px;
    display:                            inline-block;
    .menu {
      width:                            100%;
      min-height:                       100%;
      z-index:                          999999;
      display:                          block;
      background:                       url('@/assets/images/erjart/bg_menu.jpg') center top / 125vh no-repeat;
      overflow:                         hidden;
      &.mob {
        display:                        none;
      }
      .header-logo {
        display:                        flex;
        justify-content:                space-between;
        align-items:                    center;
        padding:                        10px;
        .logo-full,
        .logo-retract {
          color:                        white;
          font-size:                    2.3em;
        }
        .logo-retract {
          display:                      none;
        }
      }
      .no-btn {
        padding:                        0 0 0 7px;
        .icon-back {
          font-size:                    1.3em;
          vertical-align:               bottom;
        }
      }
      #btnCollapseMenu.no-btn {
        margin:                         0;
      }
      .level-box {
        p {
          color:                        white;
          a {
            color:                      $cetacean;
            text-decoration:            underline;
            &:hover,
            &:focus {
              color:                    white;
            }
          }
        }
        > p {
          font-size:                    1.2em;
          margin:                       15px 0 0;
        }
        div {
          > a {
            display:                    none;
          }
          > p {
            width:                      80%;
            margin:                     30px auto 0;
          }
        }
        p,
        div > a {
          text-align:                   center;
        }
      }
    }
  }

  .main.retract {
    .header-logo {
      display:                          block;
      padding:                          10px 0 0 !important;
      .logo-retract {
        display:                        block;
        margin:                         0 5px 13px;
      }
      .logo-full {
        display:                        none;
      }
      .no-btn {
        display:                        block;
        width:                          100%;
        padding:                        10px 0 !important;
        border-top:                     1px solid rgb(51, 92, 154);
        transform:                      rotateY(180deg);
      }
    }
    .menu-footer {
      display:                          none;
    }
  }

  @media (max-width: 767px) {
    .sidebar {
      position:                         relative;
      display:                          block;
      width:                            100%;
      height:                           100%;
      min-height:                       50px;
      max-height:                       340px;
      z-index:                          auto;
      top:                              0;
      .menu {
        // position:                       fixed;
        width:                          100%;
        background:                     unset;
        top:                            0;
        right:                          0;
        left:                           0;
        z-index:                        99999;
        &.desk {
          display:                      none;
        }
        &.mob {
          display:                      block;
          min-height:                   50px;
          max-height:                   100%;
          z-index:                      9999999;
        }
        .header-logo {
          padding:                      10px 13px;
        }
        .level-box {
          a {
            display:                    block;
          }
          p {
            display:                    none;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .sidebar {
      position:                         fixed;
      height:                           100vh;
      z-index:                          1039;
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'
import MenuDesktopLinks from './MenuDesktopLinks.vue'
import MenuMobileLinks from './MenuMobileLinks.vue'
import MenuProfile from './MenuProfile.vue'
import MenuStorageBar from './MenuStorageBar.vue'
import WizardLevel from '@/components/WizardLevel.vue'

export default {
  name: 'menu',
  components: {
    MenuDesktopLinks,
    MenuMobileLinks,
    MenuProfile,
    MenuStorageBar,
    WizardLevel,
  },
  data: () => ({
    collapse: false,
    session: null,
    isWebSocketConnected: false,
    appVersion: process.env.VUE_APP_VERSION,
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.webSocketInitialization()
  },
  methods: {
    checkMobile() {
    	this.isMobile = false;
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) this.isMobile = true;
    },
    doCollapse(value) {
      this.collapse = !this.collapse;

      if (this.collapse) {
        $('body').addClass('collap')
      } else {
        $('body').removeClass('collap')
      }

      // if ($('body.collap').length > 0) {
      //   $('body').removeClass('collap')
      //   console.log($('body'));
      // }
    },
    webSocketInitialization() {
      // TODO REFACTOR duplicated code -> use event bus
      const io = this.$io
      this.isWebSocketConnected = io.socket.isConnected()

      if (this.isWebSocketConnected) {
        io.socket.disconnect()
        io.socket.reconnect()
      }

      io.socket.on('connect', () => {
        this.isWebSocketConnected = true
      })

      io.socket.on('disconnect', () => {
        this.isWebSocketConnected = false
      })
    },
  }
}

</script>
