<template>
  <div class="expired-main">
    <div class="expired-box">
      <img src="@/assets/images/erjart/expired-2.svg" alt="Expired icon">

      <h3>{{ $t('expiredSession.expiredSession') }}</h3>
      <h6>{{ $t('expiredSession.relogin') }}</h6>

      <a href="/login" class="btn btn-primary">{{ $t('login.login') }}</a>
    </div>
    <!-- <marquee behavior="alternate"><h1>Sua sessão expirou...  para logar novamente...</h1></marquee> -->
  </div>
</template>

<style lang="scss">
  .expired-main {
    min-height:                         500px;
    width:                              100%;
    display:                            flex;
    justify-content:                    center;
    align-items:                        center;
    .expired-box {
      @include                          border_radius(10px);
      background-color:                 white;
      text-align:                       center;
      padding:                          30px;
      margin:                           0 10px;
      img {
        margin:                         10px auto 30px;
        width:                          120px;
      }
      h3 {
        color:                          $charcoal;
        font-weight:                    bold;
      }
      h6 {
        color:                          $light-slate;
      }
      .btn.btn-primary {
        font-size:                      1.2em;
        margin:                         20px 0 10px;
        padding:                        8px 30px;
      }
    }
  }

  @media (max-width: 767px) {
    .expired-main {
      .expired-box {
        padding:                         15px;
      }
    }
  }
</style>

<script>
  name: 'auth-expired-session'
</script>

<!-- TODO userStore.logout! -->
