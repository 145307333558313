<template>
  <div class="main-img imgTab" v-loader-eyerweb.opacity.tab="loading">
    <div v-if="examImages">
      <div v-if="alert.message" class="alert alert-dismissible fade show" :class="alert.class" role="alert">
        <span v-html="alert.message"></span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="clearMessage"></button>
      </div>

      <div class="">
        <div v-if="examImages.length">
          <lightgallery-vue
            class="image-info-box grid-wrap-between ease-all" ref="lGallery" id="lightgallery"
            :settings="lightGallerySettings">
            <template v-for="(item, index) in examImages" :key="index">

              <a v-if="item.type.endsWith('VIDEO')" onclick="return false" class="thumbnail-box loading-eye" :class="item.type" :key="`video_${index}`" data-lg-size="1280-720"
                :data-video="getVideoSource(item)" data-download-url="" data-sub-html="<h4></h4><p></p>">
                <img class="lg-selector ease-all" src="@/assets/images/default-video2.png" alt="" @load="removeLoading($event)"/>
                <div class="thumb-actions">
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="doDownload(item)">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </a>

              <a v-else class="thumbnail-box loading-eye" onclick="return false" :class="item.type" :href="dataPath +'/'+ item.name">
                <img class="lg-selector ease-all" :src="thumbDataPath +'/'+ item.name" alt="" @load="removeLoading($event)"/>
                <div class="thumb-actions">
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="doDownload(item)">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </a>
            </template>
          </lightgallery-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-img {
  min-height:                          60vh;
}

@media (max-width: 991px) {
  .main-img {
    margin-top:                        65px;
  }
}

@media (max-width: 1199px) {
  .main-img {
    min-height:                        100%;
  }
}

@media (min-width: 1500px) {
  .main-img {
    min-height:                        100%;
  }
}
</style>

<script>
import { useEyerwebStore } from '@/store/eyerweb'

import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from 'lightgallery/plugins/zoom';

const TAG = '[EyerwebExamImageTab]'
const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'EyerwebExamImageTab',
  components: {
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    exam: null,
    examImages: [],
    thumbDataPath: null,
    dataPath: null,
    session: null,
    selectedImages: [],
    exam_id: null,
    lightGallerySettings: {
      autoplayVideoOnSlide: true,
      speed: 300,
      controls: true,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [],
    },
  }),
  watch: {
    alert_msg: function (newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
        this.fetchData()
      } else {
        this.alert.message = null
      }
    },
  },
  computed: {
    alertClass() {
      return this.alert.class
    }
  },
  created() {
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo
    window.addEventListener("resize", this.getAndSetWidth);
    this.lightGallerySettings.plugins = [lgAutoplay, lgFullscreen, lgThumbnail, lgVideo, lgZoom]
  },
  async mounted() {
    await this.fetchData()
    // this.webSocketInitialization()
  },
  destroyed() {
    window.removeEventListener("resize", this.getAndSetWidth);
  },
  methods: {
    fetchData: async function () {
      this.examImages = []
      this.loading = true
      NProgress.start()
      try {
        let examResponse = await this.$eyerwebService.getExam();
        this.exam = examResponse.exam

        let response = await this.$eyerwebService.getExamData();
        this.examImages = response.examDataList

        this.thumbDataPath = `${response.thumbDataPath}`
        this.dataPath = `${response.dataPath}`

        // TODO move to service
        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

        // this.extraCustomization()
      } catch (err) {
        console.log("OPSSS...", err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doDownload(item) {
      this.loading = true
      NProgress.start()
      try {
        let params = {}
        if (item.id)
          params['id'] = item.id

        if (item.uuid)
          params['uuid'] = item.uuid

        let response = await this.$eyerwebService.downloadExamData(params)
        window.open(response.downloadURL, '_blank')

      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    doEdit(item) {
      this.$router.push({ name: 'exam-image-edit', params: { examDataID: item.id } })
    },
    extraCustomization() {
      let self = this
      let lg = $('#lightgallery');

      lg.on('onAfterOpen.lg', function () {
        // lg.data('lightGallery').modules.fullscreen.requestFullscreen();
        $("#lg-download").on("click", function (event) {
          let examDataUUID = this.href.split('/').pop()
          let item = { uuid: examDataUUID }
          self.doDownload(item, event)
        })
      });
    },
    getAndSetWidth() {
      let element = document.querySelectorAll('.image-info-box > .thumbnail-box');
      if (element.length) {
        let cw = element[0].offsetWidth;
        element.forEach(o => {
          o.style.height = cw + 'px';
        });
      }
    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;

      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
        this.getAndSetWidth();
      });
    },
    webSocketInitialization() {
      console.log(`${TAG} webSocketInitialization()`)
      const io = this.$io
      this.isWebSocketConnected = io.socket.isConnected()
      if (this.isWebSocketConnected)
        this.webSocketForGallery()

      io.socket.on('connect', () => {
        this.isWebSocketConnected = true
        this.webSocketForGallery()
      })

      io.socket.on('disconnect', () => {
        this.isWebSocketConnected = false
      })
    },
    webSocketForGallery() {
      console.log(`${TAG} webSocketForGallery()`)
      const io = this.$io

      for (let e of ['gallery_listening', 'gallery_event']) {
        console.log('[app] unlisten to ws event:', e)
        io.socket.off(e);
      }

      const params = {
        id: this.exam.id
      }

      // TODO one listen function for clinic?
      io.socket.post('/api/v2/eyercloud/ws/listen-gallery', params, function gotResponse(body, response) {
        console.log(`${TAG} ws/listen-gallery:`, body);
      })

      io.socket.on('gallery_listening', function (data) {
        console.log(`${TAG} gallery_listening`, data);
      })

      io.socket.on('gallery_event', data => {
        console.log("gallery_event >>> >> >", data)
        NProgress.done(true)
        this.loading = false

        if (data.successfull) {
          this.$message.popup(this, `examImages.createdSuccess`, 'operationSuccess', 'success')
          this.fetchData()
          this.selectedImages = new Array()
        } else {
          this.$message.popup(this, `examImages.createdError`, `examImages.operationError`, 'error')
        }
      })
    },
    getVideoSource(item) {
      let result = {
        "source": [
          {
            "src": `${this.dataPath}/${item.name}?autoplay=1`,
            "type": "video/mp4"
          }
        ],
        "attributes": {
          "preload": false,
          "playsinline": false,
          "controls": true,
          "loop": true
        }
      }

      return JSON.stringify(result)
    },
    locale() {
      let locale = this.session.currentClinic.locale
      if (locale){
        if (locale == 'pt-br') {
          return 'pt-BR'
        } else if (locale == 'es') {
          return 'es'
        } else {
          return 'en-US'
        }
      }
      return 'en-US'
    }
  }
}

</script>
