import eyercloud from '@/config/eyercloud'
import User from '@/model/User'

class UserService {

  async list(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/user/list`, params)
    return response.data
  }

  async getUser(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/user/get`, params)
    return response.data
  }

  async invitation(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/user/invitation`, params)
    return response.data
  }

  async saveUser(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/user/save`, params)
    return response.data
  }

  async updateUser(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/user/update`, params)
    return response.data
  }

  async remove(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/user/remove`, params)
    return response.data
  }

  async uploadSignature(params) {
    let formData = new FormData()
    formData.append('id', params.id)
    if (params.removeSignature)
      formData.append('removeSignature', params.removeSignature)
    formData.append('data',  params.data) // binary data ALWAYS at the end

    let response = await eyercloud.post(`/api/v2/eyercloud/user/signature/upload`, formData)
    return response.data
  }

  async getSignature(params) {
    let response = await eyercloud.get(`/api/v2/eyercloud/user/signature/get`, {})
    return response.data
  }

  async uploadPhoto(params) {
    let formData = new FormData()
    formData.append('id', params.id)
    formData.append('data',  params.data) // binary data ALWAYS at the end

    let response = await eyercloud.post(`/api/v2/eyercloud/user/photo/upload`, formData)
    return response.data
  }

  async getPhoto(params) {
    let response = await eyercloud.get(`/api/v2/eyercloud/user/photo/get`, {})
    return response.data
  }

  async changePassword(newPassword, currentPassword) {
    const params = {
      currentPassword: currentPassword,
      newPassword: newPassword
    }
    let response = await eyercloud.post(`/api/v2/eyercloud/user/change-password`, params)
    return response.data
  }

  async changePin(loginPassword, newPin) {
    const params = {
      loginPassword: loginPassword,
      newPin: newPin
    }
    let response = await eyercloud.post(`/api/v2/eyercloud/user/change-pin`, params)
    return response.data
  }

  async changePermission(userID, permissionType) {
    let params = {
      userID: userID,
      permissionType: permissionType
    }
    let response = await eyercloud.post(`/api/v2/eyercloud/user/change-permission`, params)
    return response.data
  }

  async acceptTerms(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/user/accept-term`, params)
    return response.data
  }

  async mfaSetup(type, value) {
    const params = {
      type: type, // email or mobile
      value: value // email or mobile number
    }
    let response = await eyercloud.post(`/api/v2/eyercloud/user/mfa/setup`, params)
    return response.data
  }

  async confirmMFACode(type, transactionCode, confirmationCode) {
    const params = {
      type: type, // email or mobile
      transactionCode: transactionCode,
      confirmationCode: confirmationCode // sent to email or mobile number
    }
    let response = await eyercloud.post(`/api/v2/eyercloud/user/mfa/setup-confirmation`, params)
    return response.data
  }

  async getMFAInformation(userID) {
    const params = {
      id: userID
    }
    let response = await eyercloud.post(`/api/v2/eyercloud/user/mfa/information`, params)
    return response.data // mfa only
  }

  mfaStatus(user) {
    const mfa = user.mfa ? user.mfa : {}
    const targets = ['email', 'mobile']
    let count = 0
    for (let t of targets) {
      let entry = mfa[t] ? mfa[t] : {}
      if (entry.wasVerified)
        count++
    }

    if (count >= 2)
      return "label-success"

    if (count == 1)
      return "label-warning"

    return "label-danger" 
  }

  /**
   * Remove a specific MFA option from user.
   *
   * @param {*} type email or mobile
   * @returns User the updated User reference
   */
  async removeMFA(type) { // TODO rename to MFADevice
    const params = {
      type: type
    }

    let response = await eyercloud.post(`/api/v2/eyercloud/user/mfa/remove`, params)
    return response.data
  }

  async removeMFADevice(id) {
    const params = {
      id: id
    }

    let response = await eyercloud.post(`/api/v2/eyercloud/user/mfa/remove-device`, params)
    return response.data
  }

  async sendMFACode(type) {
    const params = {
      type: type
    }

    let response = await eyercloud.post(`/api/v2/eyercloud/user/mfa/send-code`, params)
    return response.data
  }

  async validateMFACode(type, transactionCode, confirmationCode) {
    const params = {
      type: type,
      transactionCode: transactionCode,
      confirmationCode: confirmationCode
    }

    let response = await eyercloud.post(`/api/v2/eyercloud/user/mfa/validate-code`, params)
    return response.data
  }

}
const userService = new UserService();
export default userService;
