<template>
  <div class="outside-box" :class="{full: step === 0}" v-loader.opacity="loading">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-10 col-lg-7">
        <div class="registration-form">
          <h2 class="registration-title">
            {{ $t('createAccount.colaboratorRegistration') }}
            <small>{{ fullName }}</small>
          </h2>

          <hr>

          <p>{{ $t('createAccount.completeTheSequenceCollab') }}</p>

          <ul class="wizard-step">
            <li :class="{active: step === 0, past: step > 0, full: step > 1}">
              <p>1</p>
              <small>{{ $t('createAccount.identification') }}</small>
            </li>
            <li :class="{active: step === 1, past: step > 1}">
              <p>2</p>
              <small>{{ $t('createAccount.contact') }}</small>
            </li>
            <li :class="{active: step === 2, past: step > 2}">
              <p>3</p>
              <small>{{ $t('login.access') }}</small>
            </li>
          </ul>

          <vee-form v-slot="{ handleSubmit }" as="div">
            <form @submit.prevent.stop="handleSubmit(doSave)">
              <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                <span v-html="alert.message"></span>
                <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
              </div>

              <div :key="0" v-if="step === 0">
                <h2>{{ $t('createAccount.identification') }}</h2>
                <RegistrationUserStepOne :account="account"></RegistrationUserStepOne>
              </div>

              <div :key="1" v-if="step === 1">
                <h2>{{ $t('createAccount.contact') }}</h2>
                <RegistrationUserStepTwo :account="account"></RegistrationUserStepTwo>
              </div>

              <div :key="2" v-if="step === 2">
                <h2>{{ $t('login.access') }}</h2>
                <RegistrationUserStepThree :account="account" :email="email"></RegistrationUserStepThree>
              </div>

              <hr>

              <div class="action-buttons">
                <button class="btn btn-default" type="button" name="button" v-if="step > 0" @click="step -= 1">{{ $t('pagination.previous') }}</button>
                <button class="btn btn-primary" type="submit" name="button" v-if="step < 2">{{ $t('pagination.next') }}</button>
                <button class="btn btn-success" type="submit" name="button" v-if="step === 2" :disabled="loading">{{ $t('pagination.finish') }}</button>
              </div>
            </form>
          </vee-form>
        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<script>
import AuthFooter from '../../../auth/AuthFooter.vue'
import AuthHeader from '../../../auth/AuthHeader.vue'
import RegistrationUserStepOne from './RegistrationUserStepOne.vue'
import RegistrationUserStepTwo from './RegistrationUserStepTwo.vue'
import RegistrationUserStepThree from './RegistrationUserStepThree.vue'
import utilService from '../../../../services/util-service.js';

export default {
  name: 'invitation-form',
  components: {
    AuthFooter,
    AuthHeader,
    RegistrationUserStepOne,
    RegistrationUserStepTwo,
    RegistrationUserStepThree,
  },
  data: () => ({
    alert: {
      message: null,
      class: null,
    },
    loading: false,
    step: 0,
    // code: null,
    // email: "",
    account: {
      firstName: "",
      lastName: "",
      document: "",
      npi: "", // physician professional ID number
      password: "",
      confirmPassword: "",
      pin: "",

      telephone: "",
      telephone2: "",
      mobile: ""
    },
    code: "",
    email: "",
    fullName: ""
  }),
  mounted() {
    let data = this.$utils.parseDataQuery(this.$route.query.data)
    this.code = data.code
    this.email = data.email
    this.fullName = data.fullName
  },
  methods: {
    doSave: async function() {
      let params = {
        code: this.code,
        account: this.account
      }

      try {
        if (this.step != 2) {
          this.step += 1;
          return
        }
        let responseValidate = await utilService.validatePassword(params.account)

        if (responseValidate.valid == false){
            this.$message.popup(this, 'zxcvbnMessages.error', 'operationFail', 'error')
            return
        }

        this.loading = true
        NProgress.start()
        await this.$registrationService.createCollaborator(params)
        this.$router.push({name: 'invitation-done', params: {}})
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
  }
}
</script>
