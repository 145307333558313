<template>
  <div class="main-box" v-loader.opacity="loading">
    <div class="title-box rounded clear">
      <h2 class="title">{{ $t('user.list') }}</h2>

      <button class="btn btn-outline-primary" type="button" name="button" @click="openModalInvitation">
        {{ $t('user.invite') }}
      </button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable v-if="tableUsers" :tabledata="tableUsers" @item-opened="toDetails">
      <template  v-slot:column_security="{item}">
        <span class="label" :class="mfaStatus(item)">
          MFA
        </span>
      </template>

      <template v-slot:column_permissions="{item}">
        <span class="label label-warning">
          {{ $t(item.permissions) }}
        </span>
      </template>
      <template v-slot:column_actions="{item}" v-if="session.isPowerfull">
        <button class="btn btn-danger btn-sm float-end" type="button" name="button" data-title="Delete"
                v-if="!item.permissions.includes('owner')" @click.stop="openModalDelete(item.id)">
        <i class="fa fa-trash-o"></i>
      </button>
    </template>
    </BaseTable>

    <div class="row align-items-center page-row" v-if="tableUsers">
      <div class="col">
        <p>{{ $t("pagination.totalEntries") }} {{ tableUsers.total }}</p>
      </div>
      <div class="col-auto">
        <BasePagination :total-pages="totalPages" :total="tableUsers.total" :per-page="perPage" :current-page="currentPage" @pagechanged="onPageChange"/>
      </div>
    </div>

    <teleport to="body">
      <UserInvitationModal v-if="showModalInvitation" @alertmsg="setAlert" @close="showModalInvitation = false"></UserInvitationModal>

      <BaseGenericModal :modal_id="'modalUserGeneric'" ref="modalGeneric"></BaseGenericModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
  .title-box,
  .title-box .btn,
  .vtable > tbody > tr > td .btn.btn-danger.btn-sm {
    margin-bottom:                         0;
  }

  .vtable {
    > tbody > tr > td {
      span.label-warning {
        text-transform:                    uppercase;
        vertical-align:                    middle;
      }
      .btn.btn-danger.btn-sm {
        line-height:                       1;
      }
      &:first-child {
        span {
          text-transform:                  capitalize;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .main-box .alert {
      margin:                              0 5px;
    }
  }

  @media (min-width: 768px) {
    .main-box .alert {
      margin:                              0 15px;
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'
import UserInvitationModal from './UserInvitationModal.vue'

export default {
  name: 'users-list',
  components: {
    UserInvitationModal,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    tableUsers: null,
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    showModalInvitation: false,
  }),
  mounted: async function() {
    this.$activeLink.value = 'users'
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.fetchData();
  },
  methods: {
    mfaStatus(user) {
      return this.$userService.mfaStatus(user)
    },
    onPageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    fetchData: async function() {
      this.alert = {}
      let params = {
        page: this.currentPage,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$userService.list(params);
        console.log("THE USERS >>>", response.result)

        let hideMFA = !this.session.isPowerfull // TODO retrieve from clinic.security.mfaMode [optional or mandatory]

        this.tableUsers = {
          'items': response.result,
          // 'total': response.totalCount,
          'total': response.result.length,
          'columns': [
            {name: this.$i18n.t('user.name'), type: 'string', value: 'collaborator.rawName', width: '25%'},
            {name: this.$i18n.t('user.email'), type: 'string', value: 'email', width: '25%'},
            {name: this.$i18n.t('user.document'), type: 'string', value: 'collaborator.document', width: '20%', align: 'text-center'},
            {key: 'permissions', name: this.$i18n.t('user.permissions'), type: 'custom', value: 'permissions', width: '10%', align: 'text-center'},
            {key: 'security', hide: hideMFA, name: this.$i18n.t('user.security'), type: 'custom', value: 'security', width: '10%', align: 'text-center'},
            {key: 'actions', name: this.$i18n.t('user.administration'), type: 'custom', value: 'actions', width: '10%', align: 'text-center'},
          ]
        };
        this.totalPages = Math.ceil(response.result.length / this.perPage);
        // this.totalPages = Math.ceil(response.totalCount / this.perPage);

        if (response.result.length === 0) {
          this.tableUsers = {};
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toDetails(item) {
      this.$router.push({name: 'user-show', params: {userID: item.id}, query: {tab: 'data'}})
    },
    doDelete: async function(deleteId) {
      this.alert.message = null

      let params = {
        id: deleteId
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$userService.remove(params);
        this.$message.popup(this, 'general.removedSuccess', 'operationSuccess', 'success')
        if (response.forceLogout) {
          window.location = '/logout'
          return
        }

        this.fetchData()
      } catch (err) {
        this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.removedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    openModalInvitation() {
      this.showModalInvitation = true

      setTimeout(function() {
        new bootstrap.Modal(modal_invitation).show();
      }, 100);
    },
    async openModalDelete(deleteId) {
      const ok = await this.$refs.modalGeneric.show({
        modalType: 'danger',
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('user.removalConfirmation'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.doDelete(deleteId)
      }
    },
    setAlert(val) {
      this.alert = {
        message: val.message,
        class: val.class
      };
    }
  }
}
</script>
