<template>
  <div class="outside-box">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-8 col-lg-7">
        <div class="registration-info">
          <h2 class="registration-title">
            {{ $t('createAccount.confirmedRegistry') }}
            <small>{{ $t('createAccount.firstAcess') }}</small>
          </h2>

          <hr>
          <div v-if="isVerifying">
            <center>
              <img src="@/assets/images/loading_points.gif"/>
            </center>
          </div>

          <div v-if="isTicketValid == true">
            <h3>
              <span>{{ firstName }}</span>,
              {{ $t('createAccount.congratulation') }}
            </h3>

            <h2>{{ $t('createAccount.toCreateClinic') }}:</h2>
            <center>
              <button class="btn btn-success" @click="confirm()">{{ $t('createAccount.createClinic') }}</button>
            </center>
          </div>

          <div v-if="isTicketValid == false">
            <h3>
              <span>{{ firstName }}</span>,
              {{ $t('createAccount.invalidTicket') }}
            </h3>
          </div>

        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<script>
  import AuthFooter from '../../auth/AuthFooter.vue'
  import AuthHeader from '../../auth/AuthHeader.vue'

  export default {
    name: 'registration-account-activation',
    components: {
      AuthFooter,
      AuthHeader,
    },
    data: () => ({
      firstName: "",
      ticket: {},
      isTicketValid: null,
      isVerifying: true,
    }),
    async mounted() {
      const data = this.$utils.parseDataQuery(this.$route.query._data)
      this.firstName = data.firstName
      this.ticket = data.ticket

      let params = {
        activationCode: this.ticket.activationCode
      }
      try {
        await this.$registrationService.checkTicket(params)
        this.isTicketValid = true
      } catch(err) {
        this.isTicketValid = false
      }
      this.isVerifying = false
    },
    methods: {
      confirm: async function() {
        let data = this.$utils.dataQuery({
          firstName: this.firstName,
          ticket: this.ticket
        })

        this.$router.push({name: 'registration-clinic', query: {data: data}})
      }
    }
  }
</script>
