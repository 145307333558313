<template>
  <div class="tab-box d-flex align-items-start">
    <ul class="nav nav-tabs flex-column nav-pills" aria-orientation="vertical">
      <li class="nav-item" v-for="tab in tabs" :key="tab">
        <button class="nav-link" :class="{'active': activeTab === tab}" :id="tab" type="button" @click="switchTab(tab)">
          <slot :name="tabHeadName(tab)">{{ tab }}</slot>
        </button>
      </li>
    </ul>

    <div class="tab-content">
      <div class="tab-pane fade" :class="{'show active':  activeTab === tabSlotName}" :id="tabSlotName">
        <slot :name="tabSlotName"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tab-box {
  .nav.nav-tabs {
    max-width:                  270px;
    padding:                    0;
    // margin-left:                14px;
    background-color:           $light-gray;
    .nav-item {
      display:                  block;
      .nav-link {
        font-size:              11px;
        padding:                15px;
        width:                  100%;
      }
    }
  }
  .tab-content {
    width:                      100%;
  }
  .nav.nav-tabs,
  .tab-content {
    height:                     100%;
    min-height:                 480px;
  }
}
</style>

<script>
export default {
  props: ['initialtab', 'tabs'],
  name: 'VerticalTab',
  data() {
    return {
      activeTab: this.initialtab
    };
  },
  computed: {
    tabSlotName() {
      return `${this.activeTab}`;
    }
  },
  methods: {
    tabHeadName(tab) {
      return `tab-head-${tab}`;
    },

    switchTab(tab) {
      this.activeTab = tab;
      this.$emit('tab-activated', tab);
    }
  }
};
</script>
