<template>
  <div class="col-md-6">
    <h3 class="sub-title">{{ $t('patient.underlyingDisease') }}</h3>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="diabetes" v-model="underlying.diabetes" :disabled="disabled">
      <label class="form-check-label" for="diabetes">
        {{ $t('disease.diabetesMellitus') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="hypertension" v-model="underlying.hypertension" :disabled="disabled">
      <label class="form-check-label" for="hypertension">
        {{ $t('disease.hypertension') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="cholesterol" v-model="underlying.cholesterol" :disabled="disabled">
      <label class="form-check-label" for="cholesterol">
        {{ $t('disease.highCholesterol') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="smoker" v-model="underlying.smoker" :disabled="disabled">
      <label class="form-check-label" for="smoker">
        {{ $t('disease.smoker') }}
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="nwn_underlying" v-model="underlying.nwn_underlying" :disabled="disabled">
      <label class="form-check-label" for="nwn_underlying">
        {{ $t('disease.nwn_ophthalmic') }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatientCreateUnderlyingDiseases',
  props: ['disabled', 'underlying'],
  data: () => ({
  }),
  mounted() {
  },
  methods: {
  }
}
</script>
