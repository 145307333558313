<template>
  <div class="login">
    <!-- <AuthHeader></AuthHeader> -->
    <div class="logo">
      <img src="@/assets/images/phelcom/wololo.png" alt="Wololo">
      <img src="@/assets/images/phelcom/logo_eyer_cloud_horizontal_color-1.svg" alt="EyerCloud Logo">
    </div>
    <div class="login-form ease-all">
      <center style="color: red; font-size:large; font-weight: bold;">WOLOLO</center>
      Clínica
      <input class="form-control" type="text" name="" v-model="clinicName" disabled>
      Usuário
      <input class="form-control" type="text" name="" v-model="userEmail" disabled>

      <button class="btn btn-default btn-green" type="button" name="button" @click="wololo()">{{ $t('login.login') }}</button>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  padding:                                78px 0 30px !important;
}

.login-form {
  @include                                border_radius(9px);
  text-shadow:                            0 1px 0 white;
  background-color:                       white;
  padding:                                15px 25px;
  animation-duration:                     0.5s;
  animation-timing-function:              ease;
  animation-fill-mode:                    both;
  width:                                  100%;
  max-width:                              350px;
  margin:                                 0 auto;
  z-index:                                1;
  position:                               relative;
  .form-control,
  .btn.btn-default {
    @include                              border_radius(3px);
    @include                              box_shadow(unset);
  }
  .form-control {
    font-size:                            14px;
    color:                                $granite-gray;
    margin:                               0 0 22px;
    padding:                              25px 15px;
    border:                               1px solid $chinese-silver;
    height:                               34px;
  }
  .btn.btn-default {
    text-align:                           center;
    font-size:                            15px;
    font-weight:                          400px;
    text-transform:                       uppercase;
    color:                                $mint;
    width:                                100%;
    padding:                              15px;
    border:                               1px solid $mint;
    outline:                              0;
    &:hover,
    &:focus {
      background-color:                   $light-platinum;
      text-decoration:                    none;
    }
    svg {
      fill:                               $mint;
      width:                              20px;
      vertical-align:                     sub;
      margin-right:                       10px;
    }
    &.btn-green {
      letter-spacing:                     3px;
      color:                              aliceblue;
      background-color:                   $mint;
      margin-bottom:                      22px;
      &:hover,
      &:focus {
        background-color:                 $celadon;
      }
    }
  }
}
</style>

<script>
  import AuthFooter from './AuthFooter.vue';
  import AuthHeader from './AuthHeader.vue';

  export default {
    name: 'auth-wololo',
    components: {
      AuthFooter,
      AuthHeader,
    },
    data: () => ({
      clinicName: "",
      userEmail: "",
      token: "",
      accessCode: "?", // TODO get from EMAIL
      alert: {
        message: null,
        class: null,
      },
    }),
    mounted() {
      try {
        const data = this.$utils.parseDataQuery(this.$route.query._data)
        console.log("THE DATA >>>", data)
        this.clinicName = data.clinicName
        this.userEmail = data.userEmail
        this.token = data.token
      } catch(err) {
        this.$message.popup(this, 'operationFail', 'operationFail', 'error')
        // TODO show notification
        console.log("ARGH...", err)
      }
    },
    methods: {
      async wololo() {
        try {
          // TODO use RECAPTCHA
          const params = {
            token: this.token,
            accessCode: "foobar"
          }
          await this.$authService.doWololo(params)
          this.$router.push("/exam")
        } catch(err) {
          this.$message.popup(this, 'operationFail', 'operationFail', 'error')
          console.log("ouch!!", err)
        }
      },
    }
  }
</script>
