<template>
  <div class="" v-loader.opacity="loading">
    <div class="title-box rounded clear">
      <h2 class="title">{{ $t('clinicIndex.list') }}</h2>

      <router-link class="btn btn-outline-primary" to="/clinic/create">
        {{ $t('clinicIndex.create') }}
      </router-link>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable v-if="tableClinic && session" :tabledata="tableClinic" @item-opened="toDetails">
      <template v-slot:column_actions="{item}">
        <div class="table-actions">
          <button class="btn btn-success" type="button" name="button" :data-title="$t('tooltips.selectClinic')"
                  v-show="session.currentClinic && session.currentClinic.id !== item.id" @click.stop="useClinic(item)">
            <i class="fa fa-home"></i>
            {{ $t('createClinic.select') }}
          </button>
          <button class="btn btn-info" type="button" name="button" :data-title="$t('tooltips.details')" @click="toDetails(item)">
            <i class="fa fa-folder-open"></i>
          </button>
        </div>
      </template>
    </BaseTable>
  </div>
</template>

<style lang="scss" scoped>
.title-box,
.title-box .btn {
  margin-bottom:                     0;
}

.vtable > tbody > tr > td {
  button {
    &:first-child {
      margin-right:                  5px;
    }
  }
}

.vtable-mob > ul > li p span.glyphicon {
  display:                           none;
}

@media (max-width: 400px) {
  .vtable-mob > ul > li {
    p {
      > span:first-child {
        display:                     none;
      }
      span:last-child {
        float:                       unset !important;
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'clinic-list',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableClinic: null,
    perPage: 20, // TODO take this from profile-service (we may allow customization later)
    currentPage: 1,
    totalPages: 0,
    session: {
      collaborator: {},
      currentClinic: {}
    },
  }),
  created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  mounted: async function() {
    this.$activeLink.value = 'clinic'
    this.$nextTick(() => {
      this.fetchData();
    })
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    fetchData: async function() {
      this.tableClinic = null

      this.loading = true
      NProgress.start()
      try {
        let params = {
          page: this.currentPage,
        }

        let response = await this.$clinicService.getList(params);
        this.loadTableData(response)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
      this.loading = false
    },
    useClinic: async function(item) {
      NProgress.start()
      try {
        let params = {
          id: item.id,
        }

        const sessionInfo = await this.$clinicService.changeClinic(params);
        this.session = sessionInfo;
        this.session.examCurrentPage = 1
        this.$authService.sessionChanged(sessionInfo)

        let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
        });

        setTimeout(async _ => {
          await this.$router.push({name: 'app'})
          loader.hide()
        }, 300)

      } catch (err) {
        this.$message.popup(this, 'clinic.errorChangeClinic', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'clinic.errorChangeClinic', 'danger')
      }
      NProgress.done(true);
    },
    loadTableData(response) {
      this.alert = {}

      this.tableClinic = {
        'items': response.result,
        // 'total': response.totalCount,
        'columns': [
          {name: this.$i18n.t('createClinic.name'), type: 'string', value: 'name', width: '35%'},
          {name: this.$i18n.t('createClinic.parentClinic'), type: 'string', value: 'parent.name', width: '30%'},
          {name: this.$i18n.t('createClinic.permission'), type: 'string', value: 'userPermissionLabeled', width: '15%', align: 'text-center'},
          {key:"actions", name: '<span class="glyphicon glyphicon-cog"></span>', type: 'custom', value: 'actions', width: '20%', align: 'text-center'},
        ]
      };

      this.tableClinic.items.forEach(o => {
        o.userPermissionLabeled = `<span class="label label-warning">${this.$i18n.t(o.userPermission)}</span>`;
      })

      this.$nextTick(() => {
        let element = $(`.tr_${this.session.currentClinic.id} td:first-child`)
        if (element.find('span.label-success').length === 0) {
          element.prepend(`<span class="label label-success">${this.$i18n.t('createClinic.currentClinic')}</span> `)
        }
      })

      if (response.result.length === 0) {
        this.tableClinic = {};
        this.alert = this.$message.alert(this, 'request_notfound', 'warning')
        // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    toDetails(item) {
      this.$router.push({name: 'clinic-show', params: {clinicID: item.id}, query: {tab: 'data'}})
    },
  }
}
</script>
