<template>
  <div class="main-box" v-loader.opacity="loading">
    <div class="title-box clear">
      <h2 class="title">{{ $t("exam.examsList") }}</h2>
      <ExamFilter @do-filter="doFilter"></ExamFilter>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable v-if="tableExam" :tabledata="tableExam" :table_page="'exams'" @item-opened="examSelected">
      <!-- <template v-slot:column_favorite="{item}">
        <button class="no-btn favorite" type="button" name="button" :data-title="'Favoritar'" @click.stop="doFavorite">
          <i class="fa" :class="item.favorite ? 'fa-star' : 'fa-star-o'"></i>
        </button>
      </template> -->
      <template v-slot:column_content="{item}">
        <div class="">
          <span v-show="item.examType.key == 'patient_progression'" class="label label-blue-phelcom" :data-title="$t(`exam.examTypeName_${item.examType.key}`)">{{ $t(`exam.examType_${item.examType.key}`) }}</span>
          <i v-show="item.referred > 0" class="fa" :class="item.referred == 1 ? 'fa-tint' : 'fa-check-circle-o'" aria-hidden="true"></i>
          <button class="no-btn" type="button" name="button" data-title="Download" @click.stop.prevent="doDownload(item)">
            <i class="fa fa-download"></i>
          </button>
          <button v-show="item.hasImage" class="no-btn" type="button" name="button" :data-title="$t('tooltips.image')">
            <i class="fa fa-image"></i>
          </button>
          <button v-show="item.hasReport" class="no-btn" type="button" name="button" :data-title="$t('tooltips.report')">
            <i class="fa fa-file-pdf-o"></i>
          </button>
          <span v-show="item.scoreResult && item.scoreResult.status && !this.examHasDragas(item)" :data-title="$t('exam.iaText')">
            <i class="icon-ai" :class="item.scoreResult ? item.scoreResult.status : ''"></i>
          </span>
          <!-- <span v-for="c of this.activeAiClasses(item)" :key="c" :data-title="$t(`phelcomAI.title_${c}`)">
            {{$t(`phelcomAI.icon_${c}`)}}
          </span> -->
        </div>
        <div class="d-block">
          <span v-if="this.useHeader(item) && !this.examHasDragas(item)">{{$t(`phelcomAI.${this.aiHeader(item)}_header`)}}</span>
          <p class="m-0" v-for="c of this.activeAiClasses(item)" :key="c">
            <i class="icon-phelcom-ai" :class="`phelcomAI_${c}`">{{$t(`phelcomAI.icon_${c}`)}}</i>
            {{ $t(`phelcomAI.title_${c}`) }}
          </p>

          <!-- dragas -->
          <BaseFeature feature="mutirao">
            <span v-if="this.examHasDragas(item)">
            <div class="m-0 dragas-box" v-for="(item, index) in this.getDragas(item)" :key="index">
              <div class="" v-for="(dragasSide, indexSide) of item" :key="indexSide">
                <p class="m-0" v-if="indexSide != item.length - 1">
                  <i class="icon-phelcom-ai" :class="`phelcom-dragas ${dragasSide.text}`">{{ $t(`dragas.title_${dragasSide.text}`) }}</i>
                  <strong>{{ $t(index) }} - </strong>
                  {{ $t(`dragas.${dragasSide.text}`) }}
                </p>

                <div class="dragas" :class="index" v-else>
                  <span class="label" :class="dragasSide.os">{{ $t('os') }}</span>
                  <span class="label" :class="dragasSide.od">{{ $t('od') }}</span>
                </div>
              </div>
            </div>
           </span>
          </BaseFeature>

        </div>
      </template>
      <template v-slot:column_status="{item}">
        <div class="">
          <span class="label" :class="checkExamStatus(item.status)">
            {{ $t(item.status) }}
          </span>
        </div>
      </template>
    </BaseTable>

    <div v-show="tableExam" class="row align-items-center page-row">
      <div class="col">
        <p>{{ $t("pagination.totalEntries") }} {{ tableExam.total }}</p>
      </div>
      <div class="col-auto">
        <BasePagination :total-pages="totalPages" :total="tableExam.total" :per-page="perPage" :current-page="currentPage" @pagechanged="onPageChange"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dragas-box {
  strong {
    font-size:                   73%;
    vertical-align:              middle;
  }
}

.dragas {
  display:                       inline-block;
  span {
    font-size:                   73%;
    color:                       black;
    background-color:            $gray;
    @include                     border_radius(0.25rem 0 0 0.25rem);
    &:first-child {
      border-right:              1px solid white;
    }
    &:last-child {
      @include                   border_radius(0 0.25rem 0.25rem 0);
      border-left:               1px solid white;
    }
    &.red {
      background-color:          $light-coral;
    }
    &.yellow {
      background-color:          $topaz;
    }
    &.green {
      background-color:          $phelcom-green;
    }
  }
  &.os {
    display:                     none;
  }
}

.icon-phelcom-ai.phelcom-dragas {
  color:                         white;
}

.table {
  margin-bottom:                 0;
  > tfoot > tr > td,
  > tfoot > tr > th,
  > thead > tr > td,
  > thead > tr > th,
  > tbody > tr > td,
  > tbody > tr > td .fa,
  > tbody > tr > th {
    vertical-align:              middle;
  }
}

.label.label-blue-phelcom {
  margin-right:                  5px;
}

.no-btn {
  padding:                       0 4px;
  margin:                        0;
  &.favorite {
    position: relative;
    z-index: 0;
    &:after {
      @include border_radius(50%);
      @include transition(height 0.5s ease);
      content: "";
      display: block;
      opacity: 0;
      position: absolute;
      // transition-duration: .5s;
      // transition-timing-function: cubic-bezier(0.4,0,0.2,1);
      z-index: -1;
      bottom: -10px;
      left: -10px;
      right: -10px;
      top: -10px;
      background: none;
      box-sizing: border-box;
      transform: scale(0);
      transition-property: transform,opacity;
      bottom: -4.5px;
      left: -4.5px;
      right: -4.5px;
      top: -4.5px;
      width: 30px;
      height: 30px;
      background-color: rgba(32,33,36,0.059);
    }
    .fa-star-o {
      color:                     grey;
    }
    .fa-star {
      @include                   animation(fadein .5s);
      color:                     #e5c04d;
    }
    &:hover,
    &:focus {
      &:after {
        border: none;
        box-shadow: none;
        opacity: 1;
        transform: scale(1);
      }
      .fa-star-o {
        @include                 scale(1.2);
        color:                   black;
      }
    }
  }
}

.fa-tint {
  color:                         red;
}

.fa-check-circle-o {
  color:                         green;
}

.m-0 {
  font-size:                     0.9em;
  i {
    vertical-align:              top;
  }
}

@media (max-width: 767px) {
  .vtable-mob p .no-btn {
    margin-top:                  2px;
    vertical-align:              middle;
  }

  div.d-block {
    float:                       unset;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ExamFilter from './ExamFilter.vue'

export default {
  name: 'exam-list',
  components: {
    ExamFilter,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableExam: {
      items: [],
      total: 0,
      columns: []
    },
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    session: null,
    filter: {},
    customColumns: ["foo", "bar"],
    dragasEyes: {
      os: null,
      od: null
    }
  }),
  mounted() {
    this.$activeLink.value = 'exam'
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.currentPage = this.session.examCurrentPage ? this.session.examCurrentPage : 1;

    // this.currentPage > 1 ? this.doFilter() : this.fetchData()
    this.doFilter(this.filter)
    // this.currentPage > 1 ? this.onPageChange(this.currentPage) : this.fetchData()

  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.session.examCurrentPage = page;
      this.filter.pagination = true
      this.filter.newRequest = false
      this.doFilter(this.filter);
    },
    async fetchData() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examService.list();
        // console.log('response fetch', response);
        this.loadTableData(response)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doFilter(filter) {
      this.alert.message = null;
      let params = {}

      if (filter) {
        this.filter = filter;

        if (this.filter.startDate)
          params.startDate = this.filter.startDate

        if (this.filter.endDate)
          params.endDate = this.filter.endDate

        if (this.filter.filterSelected)
          params.statusFilter = this.filter.filterSelected

        if (!filter.pagination)
          this.currentPage = this.session.examCurrentPage ? this.session.examCurrentPage : 1;

        if (filter.newRequest) {
          this.session.examCurrentPage = 1
          this.currentPage = 1
        }
      }

      params.page = this.currentPage

      // console.log('params doFilter', params);

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examService.filteredList(params);
        // console.log('response filter', response);
        this.loadTableData(response)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    loadTableData(response) {
      this.alert = {}

      response.result.forEach(o => {
        try {
          o.scoreResult = this.$aiService.alterationScore(o.metadata);
          // o.metadata = o.metadata.concat('{dragas: {noAlteration: 0.4, others: 0.3, dr: 0.3, gl: 0.1, hasEdema: true}');
        } catch(error) {
          o.scoreResult = 'error'
        }
      });

      this.tableExam = {
        'items': response.result,
        'total': response.totalCount,
        'columns': [
          // {key:"favorite", name: '', type: 'custom', value: 'actions', width: '2%'},
          {name: this.$i18n.t('exam.name'), type: 'string', value: 'patient.fullName', width: '35%'},
          {name: this.$i18n.t('exam.mrn'), type: 'string', value: 'patient.mrn', width: '15%'},
          {name: this.$i18n.t('exam.date'), type: 'date', value: 'date', width: '20%'},
          {key:"content", name: this.$i18n.t('exam.content'), type: 'custom', value: 'actions', width: '20%'},
          {key:"status", name: this.$i18n.t('exam.status'), type: 'custom', value: 'status', width: '10%'}
        ]
      };
      this.totalPages = Math.ceil(response.totalCount / this.perPage);

      if (response.result.length === 0) {
        this.tableExam = {};
        this.alert = this.$message.alert(this, 'request_notfound', 'warning')
        // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    examSelected(item) {
      if (!item) {
        return
      }
      let data = null

      if (!_.isEmpty(this.filter)) {
        const startDate = moment(this.filter.startDate, this.$i18n.t('dateFormat'))
        const endDate = moment(this.filter.endDate, this.$i18n.t('dateFormat'))

        data = this.$utils.dataQuery({
          startDate: startDate.format(),
          endDate: endDate.format(),
          filterSelected: this.filter.filterSelected
        })
      }

      this.$router.push({name: 'exam-show', params: {examID: item.id}, query: {tab: 'images', data: data}})
    },
    formatDate(date) {
      return moment(date).valueOf();
    },
    checkExamStatus(value) {
      return value == 'closed' ? 'label-closed' : 'label-open'
    },
    doDownload(exam) {
      this.alert.message = null;
      try {
        NProgress.start()
        this.$examService.download(exam.id)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
    },
    useHeader(exam) {
      return this.$aiService.mcRASHeader(exam) != ""
    },
    aiHeader(exam) {
      return this.$aiService.mcRASHeader(exam)
    },
    activeAiClasses(exam) {
      return this.$aiService.mcRASClasses(exam)
    },
    doFavorite(item) {
      item.favorite = true
    },
    examHasDragas(item){
        let res = false
        try{
            let data = JSON.parse(item.metadata)
            if (data.dragas)
                res = true
        } catch (err){
           // do nothing
        }
        return res
    },
    getDragas(exam) {

      let dragas = this.$aiService.dragasClasses(exam)

      return dragas
    }
  },
  // unmounted() {
  //   this.alert.message = null;
  //   this.tableExam = [];
  // }
}
</script>
