import eyercloud from '@/config/eyercloud'

class ExamService {

  async list(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/exam/list`, {params: params})
    return response.data
  }

  async filteredList(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/exam/filter`, params)
    return response.data
  }

  async get(params) {
    let response = await eyercloud.get(`/api/v2/eyercloud/exam/get`, {params: params})
    return response.data
  }

  async update(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/exam/update`, params)
    return response.data
  }

  async remove(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/exam/remove`, params)
    return response.data
  }

  download(examID) {
    const BASE_URL = process.env.VUE_APP_API_URL
    const targetURL = `${BASE_URL}/api/v2/eyercloud/exam/download?id=${examID}`
    window.open(targetURL, '_blank')
  }

  async downloadMultiple(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/exam/download-multiple`, params)
    return response.data
  }

  async listExamsForProgression(patientID) {
    const params = {id: patientID}
    let response = await eyercloud.post(`/api/v2/eyercloud/patient/progression/list`, params)
    return response.data
  }
}
const examService = new ExamService();
export default examService;
