<template>
  <div class="" v-loader.opacity="loading">
    <div class="">
      <div class="title-box rounded">
        <button class="btn-back" type="button" name="button" @click="goBack">
          <i class="icon-back"></i>
        </button>

        <h2 class="title">{{ $t("medicalRecord.newRecord") }}</h2>
      </div>

      <div class="summary-box">
        <div class="summary-content">
          <h3>{{ rawName }}</h3>
          <div class="row">
            <div class="col-9">
              <p v-show="patientAge">{{ $t("medicalRecord.age") }}: <strong>{{ patientAge }} {{ $t("medicalRecord.years") }}</strong></p>
              <p>{{ $t("medicalRecord.firstConsultation") }}: <strong>{{ firstConsultation }}</strong></p>
            </div>

            <div class="col-3 text-end align-self-end">
              <button class="btn btn-success" type="button" @click="save()">{{ isUpdate ? $t('updateAction') : $t('save') }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-desktop" v-if="!isMobile">
        <VerticalTab :tabs="tabs" :initialtab="activeTab" :key="activeTab" @tab-activated="switchTab">
          <template v-slot:tab-head-s1>
            {{ $t("medicalRecord.s1") }}
          </template>
          <template v-slot:s1>
            <AnamneseSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's1'"></AnamneseSection>
          </template>

          <template v-slot:tab-head-s2>
            {{ $t("medicalRecord.s2") }}
          </template>
          <template v-slot:s2>
            <OphthalmologicalExaminationSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's2'"></OphthalmologicalExaminationSection>
          </template>

          <template v-slot:tab-head-s3>
            {{ $t("medicalRecord.s3") }}
          </template>
          <template v-slot:s3>
            <ComplementaryExamsSection @files="filesChanged" :loadedFiles="files" :fileData="fileData" :medicalRecordData="medicalRecordData" v-if="activeTab === 's3'"></ComplementaryExamsSection>
          </template>

          <template v-slot:tab-head-s4>
            {{ $t("medicalRecord.s4") }}
          </template>
          <template v-slot:s4>
            <DiagnosticHypothesisSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's4'"></DiagnosticHypothesisSection>
          </template>

          <template v-slot:tab-head-s5>
            {{ $t("medicalRecord.s5") }}
          </template>
          <template v-slot:s5>
            <ConductSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's5'"></ConductSection>
          </template>

          <template v-slot:tab-head-s6>
            {{ $t("medicalRecord.s6") }}
          </template>
          <template v-slot:s6>
            <PrescriptionsSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's6'"></PrescriptionsSection>
          </template>

          <template v-slot:tab-head-s7>
            {{ $t("medicalRecord.s7") }}
          </template>
          <template v-slot:s7>
            <DocumentsCertificatesSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's7'"></DocumentsCertificatesSection>
          </template>

          <template v-slot:tab-head-s8>
            {{ $t("medicalRecord.s8") }}
          </template>
          <template v-slot:s8>
            <ReportGeneralSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's8'"></ReportGeneralSection>
          </template>

        </VerticalTab>
      </div>

      <div class="tab-mobile" v-else>
        <MobileTab :tabs="tabs" :initialtab="activeTab" :key="activeTab" @tab-activated="switchTab">
          <template v-slot:tab-head-s1>
            {{ $t("medicalRecord.s1") }}
          </template>
          <template v-slot:s1>
            <AnamneseSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's1'"></AnamneseSection>
          </template>

          <template v-slot:tab-head-s2>
            {{ $t("medicalRecord.s2") }}
          </template>
          <template v-slot:s2>
            <OphthalmologicalExaminationSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's2'"></OphthalmologicalExaminationSection>
          </template>

          <template v-slot:tab-head-s3>
            {{ $t("medicalRecord.s3") }}
          </template>
          <template v-slot:s3>
            <ComplementaryExamsSection @files="filesChanged" :loadedFiles="files" :medicalRecordData="medicalRecordData" v-if="activeTab === 's3'"></ComplementaryExamsSection>
          </template>

          <template v-slot:tab-head-s4>
            {{ $t("medicalRecord.s4") }}
          </template>
          <template v-slot:s4>
            <DiagnosticHypothesisSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's4'"></DiagnosticHypothesisSection>
          </template>

          <template v-slot:tab-head-s5>
            {{ $t("medicalRecord.s5") }}
          </template>
          <template v-slot:s5>
            <ConductSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's5'"></ConductSection>
          </template>

          <template v-slot:tab-head-s6>
            {{ $t("medicalRecord.s6") }}
          </template>
          <template v-slot:s6>
            <PrescriptionsSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's6'"></PrescriptionsSection>
          </template>

          <template v-slot:tab-head-s7>
            {{ $t("medicalRecord.s7") }}
          </template>
          <template v-slot:s7>
            <DocumentsCertificatesSection :medicalRecordData="medicalRecordData" v-if="activeTab === 's7'"></DocumentsCertificatesSection>
          </template>

          <template v-slot:tab-head-s8>
            <span class="d-none">{{ $t("medicalRecord.s8") }}</span>
          </template>
          <template v-slot:s8>
            <ReportGeneralSection class="d-none" :medicalRecordData="medicalRecordData" v-if="activeTab === 's8'"></ReportGeneralSection>
          </template>

        </MobileTab>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
.title-box {
  .title {
    max-width:                   unset;
  }
  .btn-back {
    margin-right:                20px;
  }
}

.summary-box {
  @include                       border_radius(3px);
  display:                       flex;
  align-items:                   center;
  margin:                        0 auto 30px;
  padding:                       20px;
  border:                        1px solid $chinese-silver;
  width:                         98%;
  background-color:              white;
  .summary-avatar {
    @include                     border_radius(50%);
    color:                       white;
    padding:                     18px 0;
    margin-right:                20px;
    width:                       100px;
    height:                      100px;
    background-color:            $chinese-silver;
    h1 {
      font-size:                 4em;
      text-align:                center;
      margin:                    0;
    }
  }
  .summary-content {
    // width:                       calc(100% - 120px);
    width:                       100%;
    div {
      p {
        margin:                  0;
      }
    }
  }
}

.toolbar {
  width:                         95%;
  margin:                        15px auto;
}

.tab-mobile {
  display:                       none;
}

@media (max-width: 500px) {
  .summary-box {
    text-align: center;
    &,
    .summary-avatar,
    .summary-content {
      display:                   block;
    }
    .summary-avatar {
      margin:                    0 auto 15px;
    }
    .summary-content {
      width:                     100%;
      .row {
        .col-9, .col-3 {
          width:                 100%;
        }
        .col-3 {
          margin-top:            10px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .tab-mobile {
    display:                     block;
  }
  .tab-desktop {
    display:                     none;
  }
  .title-box {
    .title {
      width:                     unset;
    }
  }
}

@media (max-width: 991px) {
  .summary-box {
    margin-bottom:               10px;
  }
}

</style>

<script>
import VerticalTab from '@/components/VerticalTab.vue'
import MobileTab from '@/components/MobileTab.vue'
import AnamneseSection from './AnamneseSection.vue'
import OphthalmologicalExaminationSection from './OphthalmologicalExaminationSection.vue'
import ComplementaryExamsSection from './ComplementaryExamsSection.vue'
import DiagnosticHypothesisSection from './DiagnosticHypothesisSection.vue'
import ConductSection from './ConductSection.vue'
import PrescriptionsSection from './PrescriptionsSection.vue'
import DocumentsCertificatesSection from './DocumentsCertificatesSection.vue'
import ReportGeneralSection from './ReportGeneralSection.vue'

export default {
  name: 'patient-medical-record',
  components: {
    AnamneseSection, // section 1
    OphthalmologicalExaminationSection, // section 2
    ComplementaryExamsSection, // section 3
    DiagnosticHypothesisSection, // section 4
    ConductSection, // section 5
    PrescriptionsSection, // section 6
    DocumentsCertificatesSection, // section 7
    ReportGeneralSection, // section 8
    MobileTab,
    VerticalTab,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    isMobile: false,
    activeTab: 's1',
    tabs: ['s1',"s2",'s3','s4','s5','s6','s7', 's8'],
    patient: null,
    rawName: null,
    patientAge: null,
    patientID: null,
    recordID: null,
    firstConsultation: null,
    medicalRecordData: {
      s1: {},
      s2: {},
      s3: {},
      s4: {},
      s5: {},
      s6: {},
      s7: {},
      s8: {}
    },
    files: {},
    fileData: null,
    isUpdate: false,
  }),
  computed: {
    activeTabName() {
      return this.$i18n.t(`medicalRecord.${this.activeTab}`)
    }
  },
  async mounted() {
    if (this.$route.params.patientID)
      this.patientID = this.$route.params.patientID

    if (this.$route.params.recordID) {
      this.isUpdate = true
      this.recordID = this.$route.params.recordID

      let response = await this.$medicalRecordService.get(this.recordID)
      this.medicalRecordData = response.result.content
      this.fileData = response.result.recordData
    }

    if (this.$route.params.recordContent) {
      this.isUpdate = true
      this.medicalRecordData = JSON.parse(this.$route.params.recordContent)
    }

    this.doCollapse()
    this.fetchData()
  },
  methods: {
    filesChanged(value) {
      this.files = value
    },
    async save() { // TODO refactor saveOrUpdate()
      const params = {
        patient: {id: this.patientID},
        content: this.medicalRecordData,
      }

      this.loading = true
      NProgress.start()
      try {
        if (this.isUpdate) {
          params.id = this.$route.params.recordID
          await this.$medicalRecordService.update(params, this.files)
        } else {
          await this.$medicalRecordService.save(params, this.files)
        }

        let data = this.$utils.dataQuery({
          patientName: this.rawName
        })

        this.$router.push({name: 'patient-show',
          params: {patientID: this.patientID}, query: {tab: 'records', data: data}})
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    async fetchData() {
      this.alert.message = null

      let params = {
        id: this.patientID
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getPatient(params)
        this.patient = response.patient
        this.rawName = this.patient.firstName + ' ' + this.patient.lastName
        this.firstConsultation = moment(this.patient.createdAt).format(this.$i18n.t('dateFormat'));
        if (this.patient.birthday)
          this.patientAge = this.getAge(this.patient.birthday)

      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    goBack() {
      this.$router.push({name: 'patient-show', params: {patientID: this.patientID}, query: {tab: 'records'}})
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
    doCollapse() {
      if (window.screen.width > 767) {
        $('#btnCollapseMenu').click()
        this.isMobile = false
      } else {
        this.isMobile = true
      }
    },
    getAge(birthDay) {
      let age = Math.floor(moment(new Date()).diff(moment(birthDay),'years',true));
      return age
    }
  }
}
</script>
