<template>
  <div class="modal" id="modalobservations" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('exam.observations') }}</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="">{{ $t('exam.observations') }}</label>
            <textarea class="form-group" name="name" rows="3" v-model="obsValue"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="close">{{ $t('cancel') }}</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="close">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal-dialog {
    .modal-header {
      background-color:             $phelcom-light-blue;
    }
    .modal-body {
      .form-group {
        textarea {
          height:                   auto;
          width:                    100%;
          margin-bottom:            0;
          &:focus {
            outline:                0;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .modal-dialog {
      margin-top:                   7em;
    }
  }
</style>

<script>
export default {
  name: 'ReportObservationModal',
  props: ['observation'],
  data: () => ({
    obsValue: undefined,
  }),
  mounted() {
    this.obsValue = this.observation ? this.observation : ""
  },
  methods: {
    close() {
      this.$emit('close', this.obsValue)
    },
  }
}
</script>
