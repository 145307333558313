<template>
  <div class="dashboard-bar progress-box">
    <div class="progress" role="progressbar" aria-label="Basic example" :aria-valuenow="usagePercentage" aria-valuemin="0" aria-valuemax="100">
      <div class="progress-bar" :class="getPercentageClass()" :style="`width: ${usagePercentage}%`"></div>
    </div>
    <!-- <router-link to="/dashboard"> -->
    <!-- 
    <a href="" class="custom-link">
      {{ clinicUsage.imageTotal }} {{ $t('dashboard.imageTotalCountPercentage', {quotaAvailable: clinicUsage.quotaAvailable, usagePercentage: usagePercentage}) }}
    </a> 
    -->
    <!-- </router-link> -->
  </div>
</template>

<style lang="scss" scoped>  
.progress-box {
  text-align:                           center;
  margin-bottom:                        5px;
  .progress {
    height:                             10px;
    border:                             0.5px solid $gainsboro;
  }
  a {
    font-size:                          0.83em;
  }
}
a.custom-link {
    &,
    &:hover,
    &:focus {
    text-decoration: unset !important;
    color: white !important;
    cursor: auto !important;
    pointer-events: none !important;
    }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'MenuStorageBar',
  data: () => ({
    clinicUsage: {},
    usagePercentage: null,
    session: {
      collaborator: {},
      currentClinic: {}
    }
  }),
  created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let params = {
        id: this.session.currentClinic.id
      }

      NProgress.start()
      try {
        let response = await this.$dashboardService.getClinicUsage(params);
        this.clinicUsage = response.clinicUsage
        //debug > 80%
        //let randomImageTotal = Math.floor(Math.random() * (6000 - 4500) + 4500)
        //this.clinicUsage.imageTotal = randomImageTotal
         
        this.getPercentageValue(this.clinicUsage)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
    },
    getPercentageValue(clinicUsage) {
      this.usagePercentage = this.$dashboardService.getPercentageValue(clinicUsage)
    },
    getPercentageClass() {
      return this.$dashboardService.getPercentageClass(this.usagePercentage)
    }
  },
}
</script>
