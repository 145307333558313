<template>
  <div class="outside-box">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-8 col-lg-7">
        <div class="registration-info">
          <h2 class="registration-title">{{ $t('createAccount.colaboratorRegistration') }}</h2>

          <hr>

          <h3>
            {{ $t('createAccount.collaborationStablished') }}
          </h3>

          <h2>{{ $t('createAccount.accessAnotherClinic') }}</h2>

          <center>
            <a href="/" class="btn btn-success">{{ $t('continue') }}</a>
          </center>
        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<script>
  import AuthFooter from '../../auth/AuthFooter.vue'
  import AuthHeader from '../../auth/AuthHeader.vue'

  export default {
    name: 'invitation-done',
    components: {
      AuthFooter,
      AuthHeader,
    },
  }
</script>
