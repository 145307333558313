<template>
  <div class="dashboard" v-loader.opacity="loading">
    <div class="title-box">
      <h2 class="title">dashboard</h2>
      <button class="btn-default" type="button" name="button">
        {{ session.currentClinic.name }}
      </button>
    </div>

    <div class="dashboard-box">
      <div class="text-center">
        <h4 class="">{{ $t('dashboard.usage', {usagePercentage: usagePercentage}) }}</h4>
            <p v-if="isPaidService">
                {{ $t('dashboard.usageInfoPaid', {quotaAvailable: clinicUsage.quotaAvailable}) }}</p>
            <p v-else>
                {{ $t('dashboard.usageInfo') }}     
            </p>
           
        <a href="https://phelcom.com/pt-br/suporte/" target="_blank" class="btn btn-primary btn-support">
          {{ $t('eyerweb.supportTeam') }}
        </a>

        <div class="dashboard-bar progress-box">
          <h3 class="text-start">{{ clinicUsage.imageTotal }} {{ $t('dashboard.imageTotalCount', {quotaAvailable: clinicUsage.quotaAvailable}) }}</h3>
          <div class="progress" role="progressbar" aria-label="Basic example" :aria-valuenow="usagePercentage" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar" :class="getPercentageClass()" :style="`width: ${usagePercentage}%`"></div>
          </div>
        </div>
      </div>

      <div class="statistics-box">
        <h3>
          <span>{{ $t('dashboard.patients') }}</span>
          <span>{{ clinicUsage.patientTotal }}</span>
        </h3>

        <h3>
          <span>{{ $t('dashboard.exams') }}</span>
          <span>{{ clinicUsage.examTotal }}</span>
        </h3>

        <h3>
          <span>{{ $t('dashboard.reports') }}</span>
          <span>{{ clinicUsage.reportTotal }}</span>
        </h3>

        <h3>
          <span>{{ $t('dashboard.images') }}</span>
          <span>{{ clinicUsage.imageTotal }}</span>
        </h3>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title-box {
  justify-content:                      space-between;
  // .title {
  //   text-align:                         unset;
  // }
  .btn-default {
    letter-spacing:                     2px;
    font-size:                          11px;
    text-transform:                     uppercase;
  }
}

.dashboard {
  background-color:                     white;
  padding:                              20px;
}

.title {
  margin-bottom:                        1rem;
}

.progress-box {
  margin:                               3em 0;
  // .progress {
  //   background-color:                   $gainsboro;
  //   .progress-bar {
  //     background-color:                 $phelcom-green;
  //     &.warning {
  //       background-color:               $yellow-phelcom;
  //     }
  //     &.danger {
  //       background-color:               $phelcom-red;
  //     }
  //   }
  // }
  // a {
  //   color:                              white;
  //   text-decoration:                    none;
  // }
}

h4,
h3 {
  color:                                $dark-charcoal;
  font-size:                            1.125rem;
  font-weight:                          normal;
  line-height:                          1.5rem;
}

h4 {
  font-size:                            1.5rem;
  // text-transform:                       unset;
}

p {
  line-height:                          1.25rem;
  font-size:                            .875rem;
  font-weight:                          400;
  color:                                rgb(95,99,104);
  margin:                               1.5em auto 0 !important;
  max-width:                            100%;
}

.statistics-box {
  h3 {
    font-size:                          1rem;
    text-transform:                     capitalize;
    span {
      &:last-child {
        float:                          right;
      }
    }
    &:before {
      content:                          '';
      @include                          border_radius(50%);
      display:                          inline-block;
      width:                            18px;
      height:                           18px;
      margin-right:                     10px;
      background-color:                 $phelcom-green;
      vertical-align:                   sub;
    }
    &:nth-child(1) {
      &:before {
        background-color:               $phelcom-blue;
      }
    }
    &:nth-child(2) {
      &:before {
        background-color:               $topaz;
      }
    }
    &:last-child {
      &:before {
        background-color:               $crayola;
      }
    }
  }
}

.btn-primary.btn-support {
  text-transform:                       uppercase;
  letter-spacing:                       3px;
  font-size:                            1.3em;
  padding:                              0.375rem 1.5rem;
  margin-top:                           30px;
  background-color:                     $phelcom-blue;
  border-color:                         $phelcom-blue;
  @include                              border_radius(8px);
}

@media (max-width: 767px) {
  .title-box {
    .btn-default {
      display:                          none;
    }
  }
}

@media (min-width: 1200px) {
  .dashboard-box {
    width:                              60%;
    margin:                             0 auto;
  }
  p {
    width:                              80%;
  }
}

@media (min-width: 1400px) {
  p {
    width:                              70%;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'dashboard',
  data: () => ({
    loading: false,
    clinicUsage: {},
    usagePercentage: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isPaidService: false,
  }),
  created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.isPaidService = this.$utils.getIsPaidService()
  },
  mounted() {
    this.$activeLink.value = 'dashboard'
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let params = {
        id: this.session.currentClinic.id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$dashboardService.getClinicUsage(params);
        this.clinicUsage = response.clinicUsage
        
        //debug > 80%
        //let randomImageTotal = Math.floor(Math.random() * (6000 - 4500) + 4500)
        //this.clinicUsage.imageTotal = randomImageTotal
        //this.usagePercentage = this.session.currentClinic.percentageValue

        this.getPercentageValue(this.clinicUsage)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
      this.loading = false
    },
    getPercentageValue(clinicUsage) {
      this.usagePercentage = this.$dashboardService.getPercentageValue(clinicUsage)
    },
    getPercentageClass() {
      return this.$dashboardService.getPercentageClass(this.usagePercentage)
    }
  },
}
</script>
