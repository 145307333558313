<template>
  <div class="outside-box" v-loader.opacity="loading">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-10 col-lg-7">
        <div class="registration-form">
          <h2 class="registration-title">{{ $t('mfa.mfaVerification') }}</h2>
          <hr>

          <p class="sub-title">{{ $t('mfa.verificationDevices') }}</p>

          <table>
            <tbody>
              <tr v-for="option of mfaOptions" :key="option.type">
                <td>
                  <p class="no-wrap">
                    <strong>{{ option.value }}</strong>
                    <span>({{ option.type }})</span>
                  </p>
                </td>
                <td>
                  <button class="btn btn-primary" @click="sendMFACode(option.type)">{{ $t('mfa.sendCode') }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<style lang="scss" scoped>
.sub-title {
  color:                              $light-slate;
  font-size:                          16px !important;
  border:                             unset;
}

table {
  tbody {
    tr {
      td {
        padding:                      5px 5px 5px 0;
        p {
          margin:                     0;
        }
        .btn-primary {
          margin-left:                10px;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1366px) {
  .outside-box .footer {
    position:                         absolute !important;
  }
}
</style>

<script>
import AuthFooter from './AuthFooter.vue'
import AuthHeader from './AuthHeader.vue'

export default {
  name: "auth-mfa",
  components: {
    AuthFooter,
    AuthHeader,
  },
  data: () => ({
    loading: false,
    mfaOptions: [],
  }),
  created() {
    try {
      let data = this.$utils.parseDataQuery(this.$route.query.data);
      console.log("OU YEAH", data);
      this.mfaOptions = data.mfaOptions;
    } catch (err) {
      console.log("ops...", err);
    }
  },
  methods: {
    async sendMFACode(type) {
      let response = await this.$userService.sendMFACode(type)
      const transactionCode = response.transactionCode

      const enterConfirmationCodeMessage = this.$i18n.t('mfa.enterConfirmationCode')
      const {value: confirmationCode} = await this.$swal.fire({
        title: 'MFA',
        input: 'text',
        inputLabel: enterConfirmationCodeMessage,
        inputValue: "",
        confirmButtonColor: '#0d6efd',
        confirmButtonText: this.$i18n.t('save'),
        showCancelButton: true,
        customClass: {
          inputLabel: 'normal-label',
          title: 'info-title',
          cancelButton: 'btn-outline-secondary'
        },
        inputValidator: (value) => {
          if (!value)
            return this.$i18n.t('mfa.codeWasNotProvided')
        }
      })

      // let confirmationCode = prompt(enterConfirmationCodeMessage)
      response = await this.$userService.validateMFACode(type, transactionCode, confirmationCode)
      this.$authService.sessionChanged(response.sessionInfo)
      console.log(response)
      this.$router.push('/exam')
    },
  },
};
</script>
