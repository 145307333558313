<template>
  <div class="main-box" v-loader.opacity="loading">
    <div class="title-box clear">
      <h2 class="title">
        {{ $t("exam.examsList") }} |
        <span v-if="patientName" @click="toPatient">{{ patientName }}</span>
      </h2>
    </div>

    <div class="external-content">
      <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
        <span v-html="alert.message"></span>
        <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
      </div>

      <BaseTable v-if="tableExam" :tabledata="tableExam" @item-opened="examSelected">
        <template v-slot:column_content="{item}">
          <div class="">
            <button class="no-btn" type="button" name="button" data-title="Download" @click.stop.prevent="doDownload(item)">
              <i class="fa fa-download"></i>
            </button>
            <button v-show="item.hasReport" class="no-btn" type="button" name="button" :data-title="$t('tooltips.report')">
              <i class="fa fa-file-pdf-o"></i>
            </button>
          </div>
        </template>
      </BaseTable>
    </div>

    <!-- <div v-show="tableExam" class="row align-items-center page-row">
      <div class="col">
        <p>{{ $t("pagination.totalEntries") }} {{ tableExam.total }}</p>
      </div>
      <div class="col-auto">
        <BasePagination :total-pages="totalPages" :total="tableExam.total" :per-page="perPage" :current-page="currentPage" @pagechanged="onPageChange"/>
      </div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.title-box {
  margin-top:                    30px;
  .title {
    max-width:                   unset;
  }
}

.table {
  margin-bottom:                 0;
  > tfoot > tr > td,
  > tfoot > tr > th,
  > thead > tr > td,
  > thead > tr > th,
  > tbody > tr > td,
  > tbody > tr > td .fa,
  > tbody > tr > th {
    vertical-align:              middle;
  }
}

.no-btn {
  padding:                       0 4px;
  margin:                        0;
  .fa-file-pdf-o {
    vertical-align:              unset !important;
  }
}

.row.align-items-center.page-row {
  --bs-gutter-x:                 unset;
  margin-bottom:                 20px !important;
}

.external-content {
  height:                        100%;
  min-height:                    calc(100vh - 198px);
}

@media (max-width: 767px) {
  .vtable-mob p .no-btn {
    margin-top:                  2px;
    vertical-align:              middle;
  }
}
</style>

<script>
import { useExternalStore } from '@/store/external'

export default {
  name: 'external-exam-list',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableExam: {
      items: [],
      total: 0,
      columns: []
    },
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    filter: {},
    patientName: null,
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.session.examCurrentPage = page;
      this.doFilter(null);
    },
    fetchData: async function() {
      this.alert = {}

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$externalService.listExams()

        const externalStore = useExternalStore()
        let session = externalStore.sessionInfo
        this.patientName = session.patient.fullName

        this.loadTableData(response)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    loadTableData(response) {
      this.tableExam = {
        'items': response.result,
        'total': response.result.length,
        'columns': [
          {name: this.$i18n.t('exam.date'), type: 'date', value: 'createdAt', width: '50%'},
          {key:"content", name: this.$i18n.t('exam.content'), type: 'custom', value: 'actions', width: '50%'},
        ]
      };
      this.totalPages = Math.ceil(response.totalCount / this.perPage);

      if (response.result.length === 0) {
        this.tableExam = {};
        this.alert = this.$message.alert(this, 'request_notfound', 'warning')
        // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    formatDate(date) {
      return moment(date).valueOf();
    },
    examSelected(item) {
      if (!item) {
        return
      }

      let data = this.$utils.dataQuery({
        patient_name: item.patient.rawName
      })

      this.$router.push({name: 'external-exam-show', params: {examID: item.id}, query: {tab: '', data: data}})
    },
    doDownload(item) {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        window.open(
          `/api/v2/eyercloud/exam/download?id=${item.id}`,
          '_blank'
        );
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toPatient() {
      this.$router.push({name: 'external-patient-show'})
    },
  },
  // unmounted() {
  //   this.alert.message = null;
  //   this.tableExam = [];
  // }
}
</script>
