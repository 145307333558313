<template>
  <div class="">
    <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title sub">
        {{ $t('patient.patient') }} |
        <span>{{ patientName }}</span>
      </h2>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('patient.data') }}
      </template>
      <template v-slot:data>
        <PatientInfoTab v-if="tabActive === 'data' && isDataReady" :patient_id="patientId" @patient_name="addPatientName"></PatientInfoTab>
      </template>

      <template v-slot:tab-head-exams>
        {{ $t('patient.exams') }}
      </template>
      <template v-slot:exams>
        <PatientExamTab v-if="tabActive === 'exams'" :patient_id="patientId" @patient_name="addPatientName"></PatientExamTab>
      </template>

      <template v-slot:tab-head-records>
        {{ $t('patient.medicalRecord') }}
      </template>
      <template v-slot:records>
        <PatientMedicalRecordListTab v-if="tabActive === 'records'" :patient_id="patientId" :patient_name="patientName"></PatientMedicalRecordListTab>
      </template>
    </BaseTab>

  </div>
</template>

<style lang="scss">
.title-box {
  .btn-back {
    margin-right:              20px;
  }
  .title.sub {
    cursor:                    pointer;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import PatientMedicalRecordListTab from './PatientMedicalRecordListTab.vue'
import PatientInfoTab from './PatientInfoTab.vue'
import PatientExamTab from './PatientExamTab.vue'

export default {
  name: 'patient-show',
  components: {
    PatientMedicalRecordListTab,
    PatientInfoTab,
    PatientExamTab
  },
  data: () => ({
    loading: null,
    alert: {
      message: null,
      class: null
    },
    isSpecialist: null,
    tabActive: 'data',
    tabs: ['data'],
    patientName: null,
    isDataReady: false,
  }),
  watch: {
    patientId: {
      immediate: true,
      handler: function (patient_id) {
        if (patient_id){
          this.addTabs()
        }
    }, deep: true }
  },
  computed: {
    patientId() {
      return this.$route.params.patientID;
    },
    tab() {
      return this.$route.query.tab;
    },
  },
  mounted() {
    this.$activeLink.value = 'patient'

    if (this.tab) {
      this.tabActive = this.tab;
    }

    this.isDataReady = true
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      this.$router.push({name: 'patient-list'})
    },
    addTabs() {
      const userStore = useUserStore()
      this.session = userStore.sessionInfo
      this.isSpecialist = this.session.isSpecialist

      if (this.tabs.includes('exams') && this.tabs.includes('records'))
        return

      if (!this.tabs.includes('exams'))
        this.tabs.push('exams')

      const showMedicalRecord = this.$utils.isFeatureEnabled('medicalRecord')
      if (!this.isSpecialist && showMedicalRecord) {
        this.tabs.push('records')
      }
    },
    addPatientName(value) {
      this.patientName = value
    }
  }
}
</script>
