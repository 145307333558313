<template>
  <div class="modal fade" id="modaldownload" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="modalDownloadLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalDownloadLabel">{{ $t('exam.examsDownload') }}</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </div>
        <div class="modal-body" :class="loadingDownload || downloadReadyUrl ? 'no-footer' : ''">
          <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
            <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
          </div>

          <div class="download-dates" v-show="!loadingDownload && !downloadReadyUrl">
            <div class="input-group">
              <label for="">{{ $t('exam.selectDay') }}</label>
              <date-picker
                v-model="examDate"
                :locale="locale"
                value-type="date"
                :selectText="$t('exam.select')"
                :cancelText="$t('cancel')"
                :format="$t('dateFormatFns')"
                :inputFormat="$t('dateFormatFns')"
                :enableTimePicker="false"
                :disabled-date="disabledDate">
              </date-picker>
            </div>
            <div class="input-group">
              <label for="">{{ $t('exam.selectStartTime') }}</label>
              <date-picker
                menuClassName="dp-time"
                v-model="startTime"
                :minute-step="30"
                timePicker
                :selectText="$t('exam.select')"
                :cancelText="$t('cancel')"
                placeholder="HH:mm">
              </date-picker>
            </div>
            <div class="input-group">
              <label for="">{{ $t('exam.selectEndTime') }}</label>
              <date-picker
                menuClassName="dp-time"
                v-model="endTime"
                :minute-step="30"
                timePicker
                :selectText="$t('exam.select')"
                :cancelText="$t('cancel')"
                placeholder="HH:mm">
              </date-picker>
            </div>
          </div>

          <div class="text-center" v-show="loadingDownload && !downloadReadyUrl">
            <h3>{{ $t('exam.preparingFileForDownload') }}</h3>
            <img src="@/assets/images/loading_points.gif" alt="">
            <div v-if="downloadInfo.examsCount">
              <p><strong>{{ $t('exam.examsCount') }}</strong> {{ downloadInfo.examsCount }}</p>
              <p><strong>{{ $t('exam.imagesCount') }}</strong> {{ downloadInfo.imageCount }}</p>
              <p><strong>{{ $t('exam.estimatedSize') }}</strong> {{ downloadInfo.payloadStimatedSize }} Mb</p>
            </div>
          </div>

          <div class="text-center" v-show="!loadingDownload && downloadReadyUrl">
            <h3>{{ $t('exam.fileReadyToDownload') }}</h3>
            <a class="download-btn" :href="downloadReadyUrl" target="_blank">{{ $t('exam.clickHereToDownload') }}</a>
          </div>
        </div>
        <div class="modal-footer" v-if="!loadingDownload && !downloadReadyUrl">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="close()">{{ $t('cancel') }}</button>
          <button type="button" class="btn btn-primary" @click="doDownload">Download</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-header {
      background-color:                  $phelcom-light-blue;
    }
    .modal-body {
      &.no-footer {
        padding-bottom:                  35px;
      }
      label {
        font-weight:                     bold;
        color:                           unset;
        text-align:                      left;
      }
      .alert {
        margin:                          0 0 10px;
      }
      .input-group,
      .download-btn {
        width:                           100%;
      }
      .input-group {
        .mx-datepicker {
          width:                         100% !important;
        }
      }
      .download-btn {
        display:                         block;
        margin-top:                      20px;
        padding:                         20px 0;
        border-top:                      1px solid $chinese-silver;
        border-bottom:                   1px solid $chinese-silver;
      }
    }
  }

  @media (min-width: 676px) {
    .modal-body .input-group {
      display:                           inline-block;
      width:                             32% !important;
    }
  }

  @media (max-width: 675px) {
    .download-dates {
      label, .dp__main {
        width:                           100%;
      }
    }
  }

  @media (min-width: 676px) {
    .download-dates {
      display:                           flex;
      justify-content:                   space-between;
    }
  }
</style>

<script>
  import { ref } from 'vue'
  import { useUserStore } from '@/store/user'
  import { enUS, ptBR, es } from 'date-fns/locale'

  export default {
    name: 'ExamDownloadModal',
    data: () => ({
      alert: {
        message: null,
        class: null
      },
      loadingDownload: false,
      downloadReadyUrl: null,
      downloadInfo: {
        examsCount: null,
        imageCount: null,
        payloadStimatedSize: null
      },
      examDate: new Date(),
      session: {
        collaborator: {},
        currentClinic: {}
      },
    }),
    setup() {
      let initialDate = new Date().setHours(8,0,0)
      let finishDate = new Date().setHours(20,0,0)
      const startTime = ref({
        hours: new Date(initialDate).getHours(),
        minutes: new Date(initialDate).getMinutes()
      });

      const endTime = ref({
        hours: new Date(finishDate).getHours(),
        minutes: new Date(finishDate).getMinutes()
      });

      return {
        startTime, endTime
      }
    },
    computed: {
      locale() {
        return this.$utils.datePickerLocale()
      }
    },
    mounted() {
      const userStore = useUserStore()
      this.session = userStore.sessionInfo

      // let date = new Date()
      // date.setHours(8,0,0)
      // this.startTime = {
      //   hours: date.getHours()
      //   minutes: date.getMinutes()
      // }
    },
    methods: {
      close() {
        this.$emit('close')
      },
      disabledDate(date) {
        const today = moment()
        return date > today
      },
      formattDate(date, time) {
        let dateTimeFormat = this.$i18n.t('dateTimeFormat')
        let dateFormat = this.$i18n.t('dateFormat')
        if (date && time) {
          let dateAndTime = moment(date).format(dateFormat) + ' ' + moment(time).format('LT')
          return moment(dateAndTime, dateTimeFormat).format(dateTimeFormat);
        }
      },
      doDownload: async function() {
        this.alert.message = null;

        let params = {
          'startTime': this.formattDate(this.examDate, this.startTime),
          'endTime': this.formattDate(this.examDate, this.endTime)
        };

        this.loadingDownload = true;
        NProgress.start()
        try {
          // alert('TODO não permitir outro clique enquanto a requisição estiver sendo processada...')
          let response = await this.$examService.downloadMultiple(params);
          console.log("DOWNLOAD MULTIPLE >>>", response);
          if (response.noExamsFound) {
            this.loadingDownload = false;
            this.$message.popup(this, 'exam.noExamsFoundForTheSelectedDay', 'operationFail', 'error')
            // this.alert = this.$message.alert(this, 'exam.noExamsFoundForTheSelectedDay', 'danger')
            NProgress.done(true)
            return
          }

          this.processDownload(response)
        } catch(err) {
          this.loadingDownload = false;
          console.log("OUCHHH", err)
          // TODO unexpected error in here?
          this.$message.popup(this, err.message, 'operationFail', 'error')
          // this.alert = this.$message.alert(this, err.message, 'danger')
        }
        NProgress.done(true)
      },
      processDownload: function(response) {
        console.log(">>>>>>>>>>>> DOWNLOAD ID", response.downloadID)
        const io = this.$io
        let payloadInfo = JSON.stringify(response.payloadInfo)

        // unlisten first
        io.socket.off('preparingDownloadExams')
        io.socket.off('downloadFinished')

        io.socket.get('/online/listenToDownloadExams?id=' + response.downloadID, function gotResponse(body, response) {
          console.log('device registration response > ', response);
          console.log('device registration body > ', body);
        });

        io.socket.on('preparingDownloadExams', data => {
          console.log("Preparing DOWNLOAD >> " + JSON.stringify(data));
        });

        io.socket.on('downloadFinished', data => {
          this.loadingDownload = false
          if (data.error) {
            console.log("ERROR", data.error)
            return
          }

          console.log("DOWNLOAD FINISHED", data)
          this.downloadReadyUrl = data.downloadLink
        });

        this.downloadInfo = {
          examsCount: response.payloadInfo.examsCount,
          imageCount: response.payloadInfo.imageCount,
          payloadStimatedSize: (response.payloadInfo.payloadStimatedSize / 1048576).toFixed(2)
        };
      }
    }
  }
</script>
