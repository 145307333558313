<template>
  <div class="main-img imgTab" v-loader.opacity.tab="loading">
    <div class="title-box rounded">
      <h2 class="title">{{ $t('images') }}</h2>
    </div>

    <div v-if="examImages">
      <div v-if="alert.message" class="alert alert-dismissible fade show" :class="alert.class" role="alert">
        <span v-html="alert.message"></span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="clearMessage"></button>
      </div>

      <div class="">

        <div v-if="examImages.length">
          <lightgallery-vue
            class="image-info-box grid-wrap-between ease-all" ref="lGallery" id="lightgallery"
            :settings="lightGallerySettings" :onAfterOpen="extraCustomization">
            <template v-for="(item, index) in examImages" :key="index">

              <a v-if="item.type.endsWith('VIDEO')" onclick="return false" class="thumbnail-box loading-eye" :class="item.type" :key="`video_${index}`" data-lg-size="1280-720"
                :data-video="getVideoSource(item)" data-download-url="" data-sub-html="<h4></h4><p></p>">
                <img class="lg-selector ease-all" src="@/assets/images/default-video2.png" alt="" @load="removeLoading($event)"/>
                <div class="thumb-actions">
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="doDownload(item)">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </a>

              <a v-else class="thumbnail-box loading-eye" onclick="return false" :class="item.type" :href="dataPath +'/'+ item.name">
                <img class="lg-selector ease-all" :src="thumbDataPath +'/'+ item.name" alt="" @load="removeLoading($event)"/>
                <div class="thumb-actions">
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="doDownload(item)">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </a>
            </template>
          </lightgallery-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-img {
  min-height:                          60vh;
}

@media (max-width: 1199px) {
  .main-img {
    min-height:                        100%;
  }
}

@media (min-width: 1500px) {
  .main-img {
    min-height:                        100%;
  }
}
</style>

<script>
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from '@/assets/dependencies/lightGallery/plugins/zoom';
// import lgZoom from 'lightgallery/plugins/zoom';

const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'ExternalExamImageTab',
  props: ['exam_id', 'alert_msg'],
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    exam: null,
    examImages: [],
    thumbDataPath: null,
    dataPath: null,
    lightGallerySettings: {
      showZoomInOutIcons: true,
      actualSize: false,
      autoplayVideoOnSlide: true,
      speed: 300,
      controls: true,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [],
    },
  }),
  watch: {
    alert_msg: function (newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
        this.fetchData()
      } else {
        this.alert.message = null
      }
    },
  },
  computed: {
    alertClass() {
      return this.alert.class
    }
  },
  created() {
    window.addEventListener("resize", this.getAndSetWidth);
    this.lightGallerySettings.plugins = [lgAutoplay, lgFullscreen, lgThumbnail, lgVideo, lgZoom]
  },
  async mounted() {
    await this.fetchData()
  },
  unmounted() {
    window.removeEventListener("resize", this.getAndSetWidth);
  },
  methods: {
    fetchData: async function () {
      this.examImages = []
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$externalService.getExam({id: this.exam_id});
        this.exam = response.exam
        this.examImages = response.examDataList

        this.thumbDataPath = `${response.thumbDataPath}`
        this.dataPath = `${response.dataPath}`

        // TODO move to service
        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

        // this.extraCustomization()
      } catch (err) {
        console.log("OPSSS...", err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    extraCustomization() {
      let self = this

      $(".lg-download").on("click", function (event) {
        event.preventDefault();
        let examDataUUID = this.href.split('/').pop()
        let item = { uuid: examDataUUID }
        self.doDownload(item, event)
      })
    },
    async doDownload(item) {
      this.loading = true
      NProgress.start()
      try {
        let params = {}
        if (item.id)
          params['id'] = item.id

        if (item.uuid)
          params['uuid'] = item.uuid

        let response = await this.$externalService.downloadExamData(params)

        fetch(response.downloadURL)
          .then(res => res.blob())
          .then(blob => {
            let objectURL = URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = objectURL;
            el.download = `${item.uuid}.jpeg`;
            el.click();
            URL.revokeObjectURL(objectURL);
        });
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    getAndSetWidth() {
      let element = document.querySelectorAll('.image-info-box > .thumbnail-box');
      if (element.length) {
        let cw = element[0].offsetWidth;
        element.forEach(o => {
          o.style.height = cw + 'px';
        });
      }
    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;

      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
        this.getAndSetWidth();
      });
    },
    setAlert(val) {
      this.alert.message = val.message
      this.alert.class = val.class
    },
    getVideoSource(item) {
      let result = {
        "source": [
          {
            "src": `${this.dataPath}/${item.name}?autoplay=1`,
            "type": "video/mp4"
          }
        ],
        "attributes": {
          "preload": false,
          "playsinline": false,
          "controls": true,
          "loop": true
        }
      }

      return JSON.stringify(result)
    }
  }
}

</script>
