import eyercloud from '@/config/eyercloud'
import { useExternalStore } from '@/store/external'
import { setLocale } from '@vee-validate/i18n';
class ExternalService {

  async acceptTerms(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/user/accept-term`, params)
    return response.data
  }

  async login(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/auth/login`, params)
    console.log("doAccess", response.data)

    let sessionInfo = response.data
    this.sessionAccessChanged(sessionInfo)
    return sessionInfo
  }

  sessionAccessChanged(sessionInfo) {
    const externalStore = useExternalStore()
    externalStore.loggedIn(sessionInfo)
    setLocale(sessionInfo.patient.locale)
  }

  async accessLogout(appContext) {
    await eyercloud.post(`/api/v2/eyercloud/auth/logout`, {})

    const externalStore = useExternalStore()
    externalStore.logout()

    // const io = appContext.$io
    // console.log("removing all WS listeners...")
    // io.socket.removeAllListeners();
  }

  async listExams() {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/exam/list`, {})
    return response.data
  }

  async getExam(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/exam/get`, params)
    return response.data
  }

  async getReportList(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/report/list`, params)
    return response.data
  }

  async generateReport(reportID) {
    const BASE_URL = process.env.VUE_APP_API_URL
    let downloadURL = `${BASE_URL}/api/v2/eyercloud/external/report/generate-report?id=${reportID}`
    window.open(downloadURL)
  }

  async downloadExamData(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/examData/download-link`, params)
    return response.data
  }

  async checkEmail() {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/auth/check-email`, {})
    return response.data
  }

  async registerNewEmail(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/auth/register-new-email`, params)
    return response.data
  }

  async confirmNewEmail(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/auth/confirm-new-email`, params)
    return response.data
  }

  async changePassword(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/auth/change-password`, params)
    return response.data
  }

  async acceptTerms(params) {
    let response = await eyercloud.post(`/api/v2/eyercloud/external/auth/accept-term`, params)
    return response.data
  }

  /**
   *
   * @param id the patient id
   * @returns
   */
  async sendEmailPatientAccessData(id) {
    let params = {id: id}
    let response = await eyercloud.post(`/api/v2/eyercloud/external/send-email-access-info`, params)
    return response
  }

}
const externalService = new ExternalService();
export default externalService;
