<template>
  <div class="medical-record-tabs">
    <div class="text-center">
      <div class="">
        <label for="file-upload" class="btn btn-primary">
          <i class="glyphicon glyphicon-upload"></i>
          {{$t('medicalRecord.selectFile')}}
        </label>
        <input id="file-upload" type="file" multiple @change="addFile"/>
      </div>
      <!-- <small>O tamanho máximo do arquivo de upload: 64 MB.</small> -->
    </div>

    <ul class="file-box" v-show="Object.keys(files).length > 0">

      <!-- <li class="file-item" v-for="(item, index) in files"> -->
      <li class="file-item" v-for="(value, key) in files" :key="key">
        <div class="file-preview">
          <i class="fa" :class="checkFileType(key)" aria-hidden="true"></i>

          <button class="no-btn" type="button" name="button" @click="removeFile(key)">
            <i class="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
        <div class="form-group">
          <p>
            {{$t('medicalRecord.fileName')}}
            <strong>{{ files[key].info.name }}</strong>
          </p>
          <p>
          {{$t('medicalRecord.fileSize')}}
            <strong>{{ files[key].info.size }}</strong>
          </p>
          <label for="">{{$t('medicalRecord.fileObservation')}}</label>
          <input type=text class="form-control" v-model="files[key].info.observation" @update:modelValue="observationChanged"/>
        </div>
      </li>

    </ul>
  </div>
</template>

<style lang="scss" scoped>
.medical-record-tabs {
  > .text-center {
    input[type="file"] {
      display:                      none;
    }
  }
  .file-box {
    padding:                        0;
    margin-top:                     20px;
    border-top:                     1px solid $chinese-silver;
    .file-item {
      display:                      flex;
      align-items:                  center;
      margin:                       10px 0;
      .file-preview {
        position:                   relative;
        > i {
          &.fa-file-pdf-o   {color: $pastel-red;}
          &.fa-file-text-o  {color: $lapis-lazuli;}
          &.fa-file-image-o {color: $urobilin;}
          &.fa-file-video-o {color: $crayola;}
          &.fa-file-excel-o {color: $light-sea;}
          &.fa-file-o       {color: $champagne;}
        }
        .no-btn {
          color:                    $rusty-red;
          font-size:                1.5em;
          position:                 absolute;
          z-index:                  10;
          top:                      -7px;
          right:                    0;
        }
      }
    }
    .form-group {
      p {
        margin-bottom:              5px;
        display:                    inline-block;
        &:first-child {
          margin-right:             15px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .medical-record-tabs {
    .file-box {
      .file-item {
        .file-preview {
          > i {
            font-size:              5em;
            padding:                10px;
            width:                  80px;
          }
        }
        .form-group {
          display:                  inline-block;
          width:                    calc(100% - 80px);
        }
      }
    }
  }
}
</style>

<script>
const regexImageType = new RegExp("(.*?)\.(apng|avif|gif|jpg|jpeg|jfif|pjpeg|pjp|png|svg|webp|bmp|ico|cur|tif|tiff)$")
const regexTextType = new RegExp("(.*?)\.(docx|doc|txt|dotx|prn|csv|dif|slk)$")
const regexVideoType = new RegExp("(.*?)\.(webm|mkv|flv|vob|ogv|ogg|drc|gifv|mng|avi|MTS|M2TS|TS|mov|qt|wmv|yuv|rm|rmvb|viv|asf|amv|mp4|m4p|m4v|mpg|mp2|mpeg|mpe|mpv|m2v|svi|3gp|3g2|mxf|roq|nsv|f4v|f4p|f4a|f4b)$")
const regexXlsType = new RegExp("(.*?)\.(xlsx|xlsm|xlsb|xltx|xltm|xls|xlt|xml|xml|xlam|xla|xlw|xlr|dbf|ods)$")

const sha1 = require('sha1')

export default {
  name: 'ComplementaryExamsSection',
  props: ['loadedFiles', 'medicalRecordData', 'fileData'],
  data: () => ({
    files: {},
    formData: new FormData()
  }),
  mounted() {
    if (this.loadedFiles)
      this.files = this.loadedFiles

    if (this.fileData) {
      this.fileData.forEach(o => {
        this.loadFile(o)
      })
    }
  },
  methods: {
    async loadFile(data) {
      let response = await this.$medicalRecordService.getMedicalDataURL(data.id)
      let downloadURL = response.downloadURL
      const res = await fetch(downloadURL);
      const buf = await res.arrayBuffer();
      const file = new File([buf], data.filename, { type: 'pdf' });

      this.addFile(file, data.observation)
    },
    addFile(event, observation) {
      let fileEntry;
      let key;

      if (event.target) {
        if (event.target.files.length == 0)
          return

        fileEntry = event.target.files[0]
        key = sha1(fileEntry.name)
      } else {
        if (!event)
          return

        key = sha1(event.name)
        if (this.files[key])
          return

        fileEntry = event
      }

      // const key = sha1(fileEntry.name)
      if (this.files[key]) {
        this.$message.popup(this, 'medicalRecord.repeatedFilename', 'operationWarning', 'warn')
        return
      }

      let fileInfo = {
        uuid: this.$utils.uuidv4(),
        name: fileEntry.name,
        size: this.bytesToSize(fileEntry.size),
        type: fileEntry.type,
        observation: observation ? observation : ''
      }

      this.files[key] = {info: fileInfo, blob: fileEntry}
      this.$emit('files', this.files)
    },
    removeFile(key) {
      delete this.files[key]
      this.$emit('files', this.files)
    },
    observationChanged() {
      this.$emit('files', this.files)
    },
    bytesToSize(fileSize) {
     const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
     let i = 0, size = parseInt(fileSize, 10) || 0;

      while(size >= 1024 && ++i){
        size = size/1024;
      }

      return(size.toFixed(size < 10 && i > 0 ? 1 : 0) + ' ' + units[i]);
    },
    checkFileType(key) {
      let name = this.files[key].info.name
      if (name.endsWith('.pdf')) return 'fa-file-pdf-o'
      else if (regexImageType.test(name)) return 'fa-file-image-o'
      else if (regexTextType.test(name)) return 'fa-file-text-o'
      else if (regexVideoType.test(name)) return 'fa-file-video-o'
      else if (regexXlsType.test(name)) return 'fa-file-excel-o'
      else return 'fa-file-o'
    },
  }
}
</script>
