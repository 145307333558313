import { createApp, ref } from 'vue'
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker'
import { QuillEditor } from '@vueup/vue-quill'
import { Cropper } from 'vue-advanced-cropper'
import { Form, Field } from 'vee-validate'
import _ from 'lodash'
import lightGallery from 'lightgallery.js'
import LightGalleryVue from 'lightgallery/vue'
import selectpicker from '@/assets/dependencies/bootstrap-select/bootstrap-select.js'
import {vMaska} from 'maska'
import mitt from 'mitt';
import grapesjs from 'grapesjs';
// import VuePdf from 'vue3-pdfjs'
import pdfVuer from 'pdfvuer'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Notifications from '@kyvg/vue3-notification'

import Print from 'vue3-print-nb'

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { createPinia } from 'pinia'
import vSelect from 'vue-select'

import App from './layout/layout.vue'

import router from './router'

import * as socketIoClient from 'socket.io-client'
import * as sailsIo from 'sails.io.js'

import 'bootstrap';

import Hotjar from '@hotjar/browser';

// DIRTY STUFF GOES HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
window.bootstrap = require('bootstrap')
window.NProgress = require('nprogress');
window.$ = window.jQuery = require('jquery');
window.moment = require('moment')
import("select2/dist/js/select2.full").then((S) => {
  $.fn.select2.defaults.set("dir", "rtl");
});
window.$titleAlert = require('jquery-titlealert');
// DIRTY STUFF GOES HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// load global styles
import './styles/base.scss'

// load global components
import BaseAccordion from './components/base/BaseAccordion.vue'
import BaseCheckBox from './components/base/BaseCheckBox.vue'
import BaseCropperModal from './components/base/BaseCropperModal.vue'
import BaseDateFilter from './components/base/BaseDateFilter.vue'
import BaseGenericModal from './components/base/BaseGenericModal.vue'
import BasePagination from './components/base/BasePagination.vue'
import BasePatientAccessInfoModal from './components/base/BasePatientAccessInfoModal.vue'
import BaseQLEditor from './components/base/BaseQLEditor.vue'
import BaseSelect from './components/base/BaseSelect.vue'
import BaseTab from './components/base/BaseTab.vue'
import BaseTable from './components/base/BaseTable.vue'
import BaseFeature from './components/base/BaseFeature.vue'

// languages i18n
import { languages } from './config/locales/index.js'
import { defaultLocale } from './config/locales/index.js'
const messages = Object.assign(languages)

// services
import aiService from './services/ai-service.js';
import auditService from './services/audit-service.js';
import authService from './services/auth-service.js';
import clinicService from './services/clinic-service.js';
import dashboardService from './services/dashboard-service.js'
import deviceService from './services/device-service.js';
import examService from './services/exam-service.js';
import examDataService from './services/examdata-service.js';
import externalService from './services/external-service.js';
import eyerwebService from './services/eyerweb-service.js';
import messageService from './services/message-service.js';
import mutiraoService from './services/mutirao-service.js';
import notificationService from './services/notification-service.js';
import patientService from './services/patient-service.js';
import medicalRecordService from './services/medical-record-service.js';

import registrationService from './services/registration-service.js';
import reportService from './services/report-service.js';
import userService from './services/user-service.js';
import utilService from './services/util-service.js';
import securityService from './services/security-service.js';

const app = createApp(App).use(i18n);

// fallbackLocale: 'pt-br',
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  formatFallbackMessages: true,
  fallbackWarn: false,
  fallbackRoot: false,
  missingWarn: false,
  messages
})

// directives
import Loader from './directives/loader.js'
import LoaderEyerweb from './directives/loader-eyerweb.js'
import OnlyNumbers from './directives/only-numbers.js'

// filters
import {date, dateTime, time} from './filters/date.js'
import rules from './filters/rules.js';

const emitter = mitt();

app.use(VueLoading)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(_)
app.use(selectpicker)
app.use(lightGallery)
// app.use(Maska)
app.use(Print)
app.use(grapesjs)
// app.use(VuePdf)

app.use(Notifications)
app.use(VueSweetalert2)


app.component('cropper', Cropper)
app.component('date-picker', Datepicker)
app.component('quill-editor', QuillEditor)
app.component('lightgallery-vue', LightGalleryVue)
app.component('v-select', vSelect)
app.component('vee-form', Form)
app.component('vee-field', Field)
app.component('pdf-vuer', pdfVuer)
// global components
app.component('BaseAccordion', BaseAccordion)
app.component('BaseCheckBox', BaseCheckBox)
app.component('BaseCropperModal', BaseCropperModal)
app.component('BaseDateFilter', BaseDateFilter)
app.component('BaseGenericModal', BaseGenericModal)
app.component('BasePagination', BasePagination)
app.component('BasePatientAccessInfoModal', BasePatientAccessInfoModal)
app.component('BaseQLEditor', BaseQLEditor)
app.component('BaseSelect', BaseSelect)
app.component('BaseTab', BaseTab)
app.component('BaseTable', BaseTable)
app.component('BaseFeature', BaseFeature)

app.directive('loader', Loader)
app.directive('loader-eyerweb', LoaderEyerweb)
app.directive('only-numbers', OnlyNumbers)
app.directive('maska', vMaska)

app.config.globalProperties.$filters = {
  'date': date,
  'dateTime': dateTime,
  'time': time,
}

const axiosExternalInstance = axios.create({
  baseURL: window.location.origin,
  timeout: 16000,
  headers: {
    "Content-type": "application/json"
  }
});

const siteId = 3839366;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

// websocket configuration
// const IS_PRODUCTION = process.env.NODE_ENV == 'production'
let io = sailsIo(socketIoClient)
// io.sails.autoConnect = false // BEWARE... daemons around...
let url = process.env.VUE_APP_API_URL
io.sails.url = url
console.log(`[main] WebSocket LISTENING URL:`, url)
io.sails.environment = process.env.NODE_ENV || 'development'
io.sails.useCORSRouteToGetCookie = false
io.sails.reconnection = true

app.config.globalProperties.$io = io
app.config.globalProperties.$axios = { ...axiosExternalInstance }
app.config.globalProperties.$activeLink = ref('exam')

app.config.globalProperties.$aiService = aiService;
app.config.globalProperties.$auditService = auditService;
app.config.globalProperties.$authService = authService;
app.config.globalProperties.$clinicService = clinicService;
app.config.globalProperties.$dashboardService = dashboardService;
app.config.globalProperties.$deviceService = deviceService;
app.config.globalProperties.$examService = examService;
app.config.globalProperties.$examDataService = examDataService;
app.config.globalProperties.$externalService = externalService;
app.config.globalProperties.$eyerwebService = eyerwebService;
app.config.globalProperties.$message = messageService;
app.config.globalProperties.$mutiraoService = mutiraoService;
app.config.globalProperties.$notificationService = notificationService;
app.config.globalProperties.$patientService = patientService;
app.config.globalProperties.$medicalRecordService = medicalRecordService;
app.config.globalProperties.$registrationService = registrationService;
app.config.globalProperties.$reportService = reportService;
app.config.globalProperties.$userService = userService;
app.config.globalProperties.$utils = utilService;
app.config.globalProperties.$security = securityService;

app.config.globalProperties.emitter = emitter;

app.mount('#app')

export {app};
