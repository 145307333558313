<template>
  <div class="filter-box">
    <BaseDateFilter class="d-inline-block" @change-date="setDates"></BaseDateFilter>

    <v-select
      class="v-select form filter"
      v-model="filterSelected"
      :reduce="x => x.value"
      :options="filterOptions">
    </v-select>

    <button class="btn btn-success" type="button" name="button" @click="doFilter(true)">{{ $t('filter') }}</button>
  </div>
</template>

<style lang="scss" scoped>
  .filter-box {
    button {
      margin-left:                  5px;
      margin-bottom:                0;
    }
    .filter {
      width:                        170px !important;
    }
    .filter,
    .d-inline-block {
      vertical-align:               bottom;
    }
  }

  @media (max-width: 445px) {
    .filter-box .filter {
      display:                      block !important;
      margin:                       0 auto 10px !important;
    }
  }

  @media (min-width: 446px) {
    .filter-box .filter {
      display:                      inline-block !important;
      // margin:                       0 auto 10px !important;
    }
  }

  @media (max-width: 1230px) {
    .filter-box .d-inline-block {
      display:                      block !important;
      margin-bottom:                5px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1230px) {
    .title-box > div {
      text-align:                   right;
    }
  }
</style>

<script>
export default {
  name: 'audit-filter',
  data: () => ({
    filterOptions: [],
    filterSelected: 'ALL',
    filterDates: undefined,
  }),
  mounted: async function() {
    this.filterOptions = [
      {value: 'ALL', label: this.$i18n.t('audit.all')},
      {value: 'LOGIN', label: this.$i18n.t('audit.login')},
      {value: 'LOGOUT', label: this.$i18n.t('audit.logout')},
      {value: 'PATIENT_REMOVED', label: this.$i18n.t('audit.patientRemoved')},
      {value: 'EXAM_REMOVED', label: this.$i18n.t('audit.examRemoved')},
      {value: 'EXAMDATA_REMOVED', label: this.$i18n.t('audit.examDataRemoved')}
    ];
  },
  methods: {
    setDates(val) {
      this.filterDates = val;
    },
    doFilter(newRequest) {
      let filter = {
        startDate: this.filterDates.startDate,
        endDate: this.filterDates.endDate,
        filterSelected: this.filterSelected,
        newRequest: newRequest ? newRequest : false
      };
      this.$emit('do-filter', filter);
    },
  },
  unmounted() {
    this.filterSelected = 'ALL'
    // this.filterDates = undefined
  },
}
</script>
