<template>
  <div class="external-content">
    <div class="title-box clear">
      <h2 class="title">{{ $t('patient.patient') }}</h2>
    </div>

    <div class="tab-content">
      <div class="action-buttons rounded text-end">
        <button class="btn btn-outline-secondary" type="button" name="button" @click="openChangePassword()">
          <span>{{ $t('user.changePassword') }}</span>
        </button>
        <button class="btn btn-outline-secondary" type="button" name="button" @click="openChangeEmail()">
          <span>{{ $t('email.changeEmail') }}</span>
        </button>
      </div>

      <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
        <span v-html="alert.message"></span>
        <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
      </div>

      <div class="row" v-show="patientData">
        <div class="col-md-6">
          <div class="form-group">
            <label for="firstName">{{ $t('user.firstName') }}</label>
            <input type="text" class="form-control" name="patient[firstName]" ref="firstName" id="firstName" v-model="patientData.firstName" disabled>
          </div>
          <div v-if="showEmail" class="form-group">
            <label for="email">{{ $t('user.email') }}</label>
            <input type="text" class="form-control" id="email" name="user[email]" v-model="user.email" disabled>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="lastName">{{ $t('user.lastName') }}</label>
            <input type="text" class="form-control" id="lastName" name="patient[lastName]" v-model="patientData.lastName" disabled>
          </div>
          <div class="form-group">
            <label for="document">{{ $t('user.document') }}</label>
            <input type="text" class="form-control" id="document" name="patient[document]" v-model="patientData.document" disabled>
          </div>
        </div>
      </div>
    </div>

    <teleport to="body">
      <ExternalPatientChangeEmailModal v-if="showChangeEmail" @close="showChangeEmail = false"></ExternalPatientChangeEmailModal>

      <ExternalPatientChangePasswordModal v-if="showChangePassword" @close="showChangePassword = false"></ExternalPatientChangePasswordModal>
    </teleport>
  </div>
</template>

<style lang="css" scoped>
.title-box {
  margin-top:                    30px;
}

.external-content {
  height:                        100%;
  min-height:                    calc(100vh - 125px);
}
</style>

<script>
import { useExternalStore } from '@/store/external'

import ExternalPatientChangeEmailModal from './ExternalPatientChangeEmailModal.vue'
import ExternalPatientChangePasswordModal from './ExternalPatientChangePasswordModal.vue'

export default {
  name: 'external-patient-show',
  components: {
    ExternalPatientChangeEmailModal,
    ExternalPatientChangePasswordModal,
  },
  data: () => ({
    alert: {},
    showChangeEmail: false,
    showChangePassword: false,
    patientData: {},
    user: {},
    showEmail: false
  }),
  mounted() {
    const externalStore = useExternalStore()
    this.patientData = externalStore.sessionInfo.patient
    this.user = externalStore.sessionInfo.user

    this.showEmail = !this.user.email.endsWith("@null.com")
  },
  methods: {
    openChangePassword() {
      this.showChangePassword = true

      setTimeout(function() {
        new bootstrap.Modal(change_password_modal).show();
      }, 100);
    },
    openChangeEmail() {
      this.showChangeEmail = true

      setTimeout(function() {
        new bootstrap.Modal(change_email_modal).show();
      }, 100);
    },
  }
}
</script>
