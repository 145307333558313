<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a href="/" class="navbar-brand">
        <i class="icon-logo_eyercloud_horizontal"></i>
      </a>

      <div class="avatar" @click="profileMenu()" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
        <img :src="`${avatar}`" alt="" data-title="">
      </div>

      <button class="btn-icon btn-notif" type="button" name="button" id="notifications" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="icon-bell"></i>
        <span id="notification-count" class="notification-count">{{ notificationCount }}</span>
      </button>
      <Notifications :notification="notification"></Notifications>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#linksResponsiveMenu"
              aria-controls="linksResponsiveMenu" aria-expanded="false" aria-label="Toggle navigation" @click="open = !open">
        <i class="icon-menu_hamburguer" aria-hidden="true"></i>
      </button>
      <div class="collapse navbar-collapse" id="linksResponsiveMenu">
        <ul class="navbar-nav">
          <li v-if="false" class="nav-item" :class="active === 'dashboard' ? 'active' : ''">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; dashboardMenu()">
              <i class="icon-dashboard"></i>
              <span>{{ $t('menu.dashboard') }}</span>
            </a>
          </li>
          <li class="nav-item" :class="active === 'exam' ? 'active' : ''">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; examMenu();">
              <i class="icon-exam"></i>
              <span>{{ $t('menu.exams') }}</span>
            </a>
          </li>
          <li class="nav-item" :class="active === 'patient' ? 'active' : ''" v-show="allowSynchronization">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; patientMenu()">
              <i class="icon-pacientes"></i>
              <span>{{ $t('menu.patients') }}</span>
            </a>
          </li>
          <li><hr class="dropdown-divider"></li>
          <li class="nav-item" :class="active === 'clinic' ? 'active' : ''">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; clinicsMenu()">
              <i class="icon-clinicas"></i>
              <span>{{ $t('menu.clinics') }}</span>
            </a>
          </li>
          <li class="nav-item" :class="active === 'users' ? 'active' : ''" v-show="isPowerfull">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; usersMenu()">
              <i class="icon-usuarios"></i>
              <span>{{ $t('menu.users') }}</span>
            </a>
          </li>
          <li class="nav-item" :class="active === 'device' ? 'active' : ''" v-show="!isSpecialist">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; deviceMenu()">
              <i class="icon-dispositivos"></i>
              <span>{{ $t('menu.devices') }}</span>
            </a>
          </li>
          <li class="nav-item" :class="active === 'audit' ? 'active' : ''" v-show="!isSpecialist">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; auditMenu()">
              <i class="icon-audit">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
              </i>
              <span>{{ $t('menu.audit') }}</span>
            </a>
          </li>
          <li class="nav-item" :class="active === 'faq' ? 'active' : ''">
            <a class="nav-link" :href="helpcenterUrl" target="_blank" data-bs-toggle="collapse"
               data-bs-target=".navbar-collapse.show" @click="open = false">
              <i class="icon-faq"></i>
              <span>HELPCENTER</span>
            </a>
          </li>
          <li class="nav-item" @click="logout()">
            <a class="nav-link log" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <i class="icon-logout"></i>
              <span>{{ $t('audit.logout') }}</span>
            </a>
          </li>
        </ul>

        <MenuStorageBar v-show="false"></MenuStorageBar>

        <p class="version">J.1.7.4</p>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
  .navbar {
    @include                              background_cover('@/assets/images/erjart/bg_menu.jpg');
    @include                              box_shadow(0 3px 5px rgb(108 108 108));
    position:                             fixed;
    width:                                100vw;
    border:                               0;
    padding:                              0;
    z-index:                              999;
    .navbar-toggler {
      @include                            box_shadow(unset);
      margin:                             0;
      padding:                            0;
      i {
        color:                            white;
        font-size:                        1.35em;
      }
    }
    .navbar-collapse {
      @include                            box_shadow(unset);
      border:                             0;
      .version {
        position:                         absolute;
        bottom:                           0;
        color:                            white;
        margin-bottom:                    0;
        left:                             unset;
        right:                            10px;
      }
    }
    .navbar-brand {
      font-size:                          unset;
      line-height:                        unset;
      height:                             unset;
      width:                              calc(100% - 150px);
      padding:                            12px 0;
      max-height:                         50px;
      i {
        color:                            white;
        font-size:                        2.3em;
      }
    }
    .navbar-nav {
      margin-top:                         0;
      margin:                             7.5px -15px;
      .nav-item {
        .nav-link {
          font-size:                      12px;
          font-weight:                    400;
          color:                          white;
          line-height:                    32px;
          text-transform:                 uppercase;
          letter-spacing:                 3px;
          display:                        block;
          padding:                        5px 15px;
          text-decoration:                none;
          cursor:                         pointer;
          i {
            font-size:                    2em;
            vertical-align:               middle;
            margin-right:                 5px;
          }
        }
        &:hover,
        &:focus {
          .nav-link {
            color:                        $pale-green;
          }
        }
        &:active,
        &.active {
          .nav-link {
            font-weight:                  bold;
            &, i:before {
              color:                      white;
            }
          }
        }
      }
    }
    .btn-icon {
      margin-bottom:                      0;
      padding:                            0;
      border:                             0;
      &.btn-notif {
        padding:                          4px;
        position:                         relative;
        .notification-count {
          @include                        border_radius(50%);
          font-size:                      12px;
          position:                       absolute;
          top:                            -4px;
          background-color:               $arylide;
          right:                          -4px;
          width:                          16px;
          height:                         16px;
        }
      }
      i {
        font-size:                        2em;
      }
    }
  }

  .progress-box {
    margin-bottom:                        15px;
  }

  .avatar {
    cursor:                               pointer;
    &:hover,
    &:focus {
      @include                            transition(all .5s ease-in-out);
      transform:                          scale(1.1);
    }
    img {
      max-height:                         40px;
      max-width:                          40px;
      height:                             auto;
      width:                              auto;
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'
import MenuStorageBar from './MenuStorageBar.vue'
import Notifications from './Notifications.vue'
import profile_img from "@/assets/images/phelcom/profile_img.png";

export default {
  name: 'MenuMobileLinks',
  props: ['isResize'],
  components: {
    MenuStorageBar,
    Notifications
  },
  data: () => ({
    active: null,
    open: true,
    openNotif: false,
    allowSynchronization: true,
    isSpecialist: null,
    isPowerfull: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    notificationData: {
      count: 0,
      events: []
    },
    notificationCount: 0,
    notification: [],
    locale: null,
    helpcenter_url: null
  }),
  watch: {
    '$activeLink.value': {
      handler: function () {
        console.log('watch', this.$activeLink.value);
        this.active = this.$activeLink.value
    }, deep: true }
  },
  computed: {
    avatar() {
      return this.session.userPhoto ? this.session.userPhoto : profile_img;
    },
    helpcenterUrl() {
      if (this.locale == 'pt-br')
        return 'https://phelcom.com/pt-br/helpcenter/'

      return 'https://phelcom.com/helpcenter/'
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.currentClinicID = this.session.currentClinic.id

    this.isSpecialist = this.session.isSpecialist
    this.isPowerfull = this.session.isPowerfull
    this.active = this.$activeLink.value ? this.$activeLink.value : null
    this.locale = this.session.currentClinic.locale.toLowerCase()

    this.allowSynchronization = this.session.currentClinic.synchronizePatientData
    if (this.allowSynchronization == undefined)
      this.allowSynchronization = true

    this.$nextTick(() => {
      this.reloadNotifications()
    })

    // called from notificationService (logic is there)
    this.emitter.on("newNotification", event => {
      this.notification.push(event)
      this.notificationCount += 1 // view logic
    })
  },
  methods: {
    profileMenu: async function() {
      this.session.activeLink = 'users'
      this.$router.push(`/user/${this.session.user.id}`)
    },
    auditMenu: async function() {
      this.goToLink(this, 'audit', 'audit-list')
    },
    clinicsMenu: async function() {
      this.goToLink(this, 'clinic', 'clinic-list')
    },
    dashboardMenu: async function(useRouter) {
      this.goToLink(this, 'dashboard', 'dashboard')
    },
    deviceMenu: async function() {
      this.goToLink(this, 'device', 'device-list')
    },
    examMenu: async function() {
      this.session.examCurrentPage = 1
      this.goToLink(this, 'exam', 'exam-list')
    },
    patientMenu: async function() {
      this.session.patientCurrentPage = 1
      this.goToLink(this, 'patient', 'patient-list')
    },
    usersMenu: async function() {
      this.goToLink(this, 'users', 'user-list')
    },
    toggleMenu(val) {
      this.open = val
    },
    async logout() {
      await this.$authService.logout(this)
      window.open('/', '_self');
    },
    goToLink(self, active, link) {
      this.toggleMenu(false);
      self.active = active

      self.$router.push({name: 'proxy-router', params: {
        target: link,
        data: JSON.stringify({})
      }})
    },

    reloadNotifications() {
      if (!this.currentClinicID)
        return

      const clinicData = this.$notificationService.reloadNotifications(this.currentClinicID)
      for (let e of clinicData.events) {
        this.addNotification(e)
      }

      this.notificationCount = clinicData.count
    },
    // bell icon clicked
    resetNotifications() {
      this.notificationCount = 0
      this.$notificationService.resetCount(this.currentClinicID)
      this.$notificationService.enable() // force notification activation
    },
    addNotification(data) {
      try {
        this.notification.unshift(data)
      } catch (e) {
        console.log('error(addNotification)', e);
      }
    },
    notificationClicked: async function(examid) {
      try {
        let params = {
          id: examid,
        }

        let response = await this.$examService.get(params);

        let data = this.$utils.dataQuery({
          exam: JSON.stringify(response.exam)
        })

        this.$router.push({name: 'exam-show', params: {examID: examid}, query: {tab: 'images', data: data}})
        // this.$router.push({name: 'proxy-router', params:{
        //   target: 'exam-show', data: {exam: response.exam, tab: null, examid: examid}
        // }})
      } catch(err) {
        this.alert = {
          message: `${this.$i18n.t('request_error')} <strong>${err}</strong>`,
          class: 'alert-danger'
        }
      }
    },
  }
}

</script>
