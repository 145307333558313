<template>
  <div class="modal" id="modalreportimages" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('exam.reportImages') }}</h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
            <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
          </div>

          <div class="grid-box">
            <div class="modal-img-box loading-eye" v-for="(item, index) in examImages" v-show="!item.type.endsWith('VIDEO')" :key="index">
              <div class="">
                <label :for="'checkImage_'+index">
                  <img class="ease-all" :src="thumbDataPath +'/'+ item.name" @load="removeLoading($event)"/>
                </label>
                <input type="checkbox" :id="'checkImage_'+index" :key="'image_'+index"
                :checked="selectedimages.includes(item.name)" @click="doCheck(item.name, 'checkImage_'+index)"/>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">{{ $t('cancel') }}</button>
          <button type="button" class="btn btn-primary" @click="doSave">{{ $t('save') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    margin-top:                        100px;
    .modal-header {
      background-color:                $phelcom-light-blue;
      // background-color:                $steel-blue;
    }
    .modal-body {
      height:                          100%;
      max-height:                      490px;
      overflow-y:                      auto;
      .grid-box {
        display:                       grid;
        grid-template-columns:         repeat(auto-fit, 24%);
        justify-content:               space-between;
      }
      .modal-img-box {
        margin-bottom:                 30px;
        label {
          display:                     flex;
          align-items:                 center;
        }
        > div {
          padding-bottom:              10px;
          img {
            width:                     100%;
            height:                    100%;
            min-height:                75px;
            object-fit:                contain;
            margin:                    0 auto;
            &.loaded {
              background-color:        black;
            }
          }
          input[type="checkbox"] {
            margin:                    4px auto 0;
            display:                   block;
          }
          input[type="checkbox"],
          img {
            cursor:                    pointer;
          }
          &:hover img,
          &:focus img {
            transform:                 scale(1.03);
          }
        }
        label,
        > div {
          height:                      100%;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .modal-body {
    grid-template-columns:             repeat(auto-fit, 33%) !important;
    max-height:                        300px;
  }
}
</style>

<script>
const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'ExamReportImagesModal',
  props: ['reportid', 'selectedimages'],
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    examImages: [],
    thumbDataPath: null,
    newSelectedImgs: {},
  }),
  mounted: function() {
    // console.log(this.newSelectedImgs, ' mounted ', this.selectedimages);
    this.fetchData();
  },
  created() {
    window.addEventListener("resize", this.getAndSetWidth);
  },
  methods: {
    doCheck(img, id) {
      this.newSelectedImgs = this.selectedimages;
      if(this.newSelectedImgs.includes(img)) {
        document.getElementById(id).checked = false;
        this.newSelectedImgs.splice(this.newSelectedImgs.indexOf(img), 1);
      } else {
        this.newSelectedImgs.push(img);
      }
    },
    fetchData: async function() {
      this.alert = {}

      NProgress.start()
      try {
        let params = {
          id: this.reportid,
        }

        let response = await this.$reportService.getReport(params);
        this.examImages = response.images
        this.thumbDataPath = `${response.thumbDataPath}`

        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
        }

        if (response.images.length === 0) {
          this.examImages = {};
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
    },
    doSave: async function() {
      this.alert.message = null;

      NProgress.start()
      try {
        let params = {
          id: this.reportid,
          selectedImages: this.newSelectedImgs
        }

        let response = await this.$reportService.updateReportImages(params);

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        setTimeout(() => {
          this.close()
          $('#closeBtn').click();
        }, 1000)
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
    getAndSetWidth() {
      let element = document.querySelectorAll('.modal-body > .grid-box > .modal-img-box');
      if (element.length) {
        let cw = element[0].offsetWidth;
        let cwImg;
        element.forEach(o => {
          o.style.height = cw + 'px';
        });
      }
    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.parentElement.parentElement.classList;
      let currentlyNode = event.target.classList;

      setTimeout(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
        this.getAndSetWidth();
      }, 100);
    },
    close() {
      this.$emit('close')
    },
  },
  unmounted() {
    this.examImages = []
    window.removeEventListener("resize", this.getAndSetWidth);
  },
}
</script>
