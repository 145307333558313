<template>
  <div class="" v-loader.opacity="loading">
    <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title">MFA</h2>
    </div>

    <div class="tab-content">
      <section>
        <h3 class="sub-title">{{ $t('mfa.accountSecurity') }}</h3>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="mfaEmail">{{ $t('user.mfaEmail') }}</label>
              <input type="text" class="form-control" id="mfaEmail" name="mfaEmail" v-model="mfa.email.value" disabled>

              <span class="label" :class="labelMfaDetailsClass.email">{{ mfaDetails('email') }}</span>
            </div>
          </div>
          <div class="col-lg-6">
            <button id="setupMFAEmail" type="button" @click="mfaSetup('email')" class="btn btn-info">{{ $t('user.mfaSetup') }}</button>
            <button id="removeMFAEmail" type="button" v-if="wasVerified('email')" @click="removeMFA('email')" class="btn btn-danger">{{ $t('user.mfaRemoval') }}</button>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="mfaMobile">{{ $t('user.mfaMobile') }}</label>
              <input type="text" class="form-control" id="mfaMobile" name="mfaMobile" v-model="mfa.mobile.value" disabled>

              <span class="label" :class="labelMfaDetailsClass.mobile">{{ mfaDetails('mobile') }}</span>
            </div>
          </div>
          <div class="col-lg-6">
            <button id="setupMFAMobile" type="button" class="btn btn-info" @click="mfaSetup('mobile')">{{ $t('user.mfaSetup') }}</button>
            <button id="removeMFAMobile" type="button" v-if="wasVerified('mobile')" class="btn btn-danger" @click="removeMFA('mobile')">{{ $t('user.mfaRemoval') }}</button>
          </div>
        </div>
      </section>

      <section>
        <h3 class="sub-title">{{ $t('mfa.connectedDevices') }}</h3>

        <table>
          <tbody>
            <tr v-for="device of mfaDevices" :key="device.id">
              <td>
                <p class="no-wrap">
                  <strong>{{ device.browser }} - {{ device.os }}</strong> -
                  <span>{{ formatDate(device.createdAt) }}</span>
                </p>
              </td>
              <td>
                <button class="btn btn-danger btn-sm" @click="removeMFADevice(device.id)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-group {
  display:                            block;
  .btn {
    margin-left:                      10px;
  }
}

.btn.btn-sm {
  line-height:                        1;
  margin-left:                        10px;
}

.label {
  text-transform:                     uppercase;
  margin-left:                        190px;
}

table {
  tbody {
    tr {
      td {
        padding:                      5px 5px 5px 0;
        p {
          margin:                     0;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .title-box .title {
    display:                          inline-block;
    width:                            calc(100% - 60px);
    margin-left:                      0;
  }
  .label {
    margin-left:                      0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .label {
    margin-left:                      152px;
  }
}

</style>

<script>
import { useUserStore } from '@/store/user'
import User from '@/model/User'

export default {
    name: "user-mfa",
    data: () => ({
      loading: false,
      user: new User(),
      mfa: { // TODO REFACTOR to mfaOptions
        email: {},
        mobile: {}
      },
      labelMfaDetailsClass: {
        email: null,
        mobile: null
      },
      mfaDevices: [],
      countryDdi: null,
      session: {
        collaborator: {},
        currentClinic: {}
      },
    }),
    async created() {
      const userStore = useUserStore()
      this.session = userStore.sessionInfo
      this.countryDdi = this.$utils.getCountryDdi(this.session.currentClinic.country)
      console.log('this.session', this.session);
      console.log('ddi', this.countryDdi);

      try {
        const userID = this.$route.params.userID
        let response = await this.$userService.getMFAInformation(userID)

        this.mfa = Object.assign(this.mfa, response.mfa)
        this.user.mfa = this.mfa
        this.mfaDevices = response.mfaDevices
      } catch(err) {
        this.$message.popup(this, err.message , 'Ops...', 'error')
      }
    },
    methods: {
      goBack() {
        const userID = this.$route.params.userID
        this.$router.push({name: 'user-show', params: {userID: userID}, query: {tab: 'data'}})
      },
      async mfaSetup(target) {
        try {
          let _self = this
          const mfaDeviceText = this.$i18n.t(`mfa.${target}`) // mobile or email
          const enterMFADevicePrompt = this.$i18n.t('mfa.enterMFADevicePrompt', {mfaDevice: mfaDeviceText})
          const {value: mfaOptionValue} = await this.$swal.fire({
            title: 'MFA',
            html: `
              <div class="form-group">
                <label>${enterMFADevicePrompt}</label>
                <input type="${mfaDeviceText == 'email' ? 'email' : ''}"
                       class="form-control" ref="${target}" id="${target}"
                       value="${target == 'mobile' ? _self.countryDdi : ""}">
                 <span class="vee-error" id="veeError">${ _self.$i18n.t('invalidEmail') }</span>
              </div>
            `,
            // input: mfaDeviceText == 'email' ? 'email' : 'text', // TODO email x mobile
            inputLabel: enterMFADevicePrompt,
            inputValue: target == 'mobile' ? this.countryDdi : "",
            confirmButtonColor: '#0d6efd',
            confirmButtonText: this.$i18n.t('save'),
            showCancelButton: true,
            customClass: {
              inputLabel: 'normal-label',
              title: 'info-title',
              cancelButton: 'btn-outline-secondary'
            },
            didOpen: () => {
              if (target == 'email') {
               
                document.getElementById('email').addEventListener("keyup", emailValidate);

                function emailValidate(event) {
                  let errorMessage = document.getElementById('veeError')
                  let confirmButton = document.querySelector('.swal2-confirm')

                  if (!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(event.target.value)) {
                    errorMessage.style.display = 'block'
                    confirmButton.disabled = true
                  } else {
                    errorMessage.style.display = 'none'
                    confirmButton.disabled = false
                  }
                }
              }
            },
            inputValidator: async (value) => {
              if (!value)
                return this.$i18n.t(`mfa.invalidInput`)
            },
          })

          if (!mfaOptionValue)
            return
          
          let optionValue
          if (target == 'email')
            optionValue = document.getElementById('email').value
          else if (target == 'mobile')
            optionValue = document.getElementById('mobile').value

          const setupData = await this.$userService.mfaSetup(target, optionValue)
          await this._readConfirmationCode(target, setupData)

        } catch(err) {
          this.$message.popup(this, err.message , 'Ops...', 'error')
        }
      },

      async _readConfirmationCode(target, setupData) {
        console.log('target', target);
        const mfaDeviceText = this.$i18n.t(`mfa.${target}`)
        const enterConfirmationCodeMessage = this.$i18n.t('mfa.enterConfirmationCode', {mfaDevice: mfaDeviceText})
        const {value: confirmationCode} = await this.$swal.fire({
          title: 'MFA',
          input: 'text',
          inputLabel: enterConfirmationCodeMessage,
          inputValue: "",
          confirmButtonColor: '#0d6efd',
          confirmButtonText: this.$i18n.t('save'),
          showCancelButton: true,
          customClass: {
            inputLabel: 'normal-label',
            title: 'info-title',
            cancelButton: 'btn-outline-secondary'
          },
          inputValidator: (value) => {
            if (!value)
              return this.$i18n.t('mfa.codeWasNotProvided')
          }
        })

        if (!confirmationCode)
          return


        try {
          const confirmationData = await this.$userService.confirmMFACode(target, setupData.transactionCode, confirmationCode)

          this.$authService.sessionChanged(confirmationData.sessionInfo)
          this.mfa = confirmationData.mfa
          this.mfaDevices = confirmationData.mfaDevices
          this.$message.popup(this, 'operationSuccess', 'successTitle', 'success')
        } catch(err) {
          this.$message.popup(this, err.message , 'Ouch...', 'error')
        }
      },
      wasVerified(type) {
        return this.mfa[type].wasVerified
      },
      mfaDetails(type) {
        let mfaData = this.mfa[type]
        if (!this.wasVerified(type)) {
          this.labelMfaDetailsClass[type] = 'label-danger'
          return this.$i18n.t('mfa.notActive')
        }

        const DATE_FORMAT  = this.$i18n.t('dateTimeFormat')
        let creationDate = moment(mfaData.createdAt).format(DATE_FORMAT)
        this.labelMfaDetailsClass[type] = 'label-success'
        return this.$i18n.t('mfa.createdAt', {creationDate: creationDate})
      },
      async removeMFA(type) {
        try {
          const mfaDeviceText = this.$i18n.t(`mfa.${type}`) // mobile or email
          const mfaDeviceRemovalPrompt = this.$i18n.t('mfa.mfaDeviceRemovalPrompt', {mfaDevice: mfaDeviceText})

          const {value: confirmed} = await this.$swal.fire({
            title: this.$i18n.t('user.mfaRemoval'),
            text: mfaDeviceRemovalPrompt,
            confirmButtonColor: '#0d6efd',
            confirmButtonText: this.$i18n.t('mfa.yes'),
            cancelButtonText: this.$i18n.t('mfa.no'),
            showCancelButton: true,
            customClass: {
              title: 'danger-title',
              cancelButton: 'btn-outline-danger'
            }
          })

          if (!confirmed)
            return

          let data = await this.$userService.removeMFA(type)

          this.user = new User(data.user)
          this.$authService.sessionChanged(data.sessionInfo)
          this.mfa = this.user.mfa
        } catch(err) {
          this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        }
      },
      async removeMFABrowser(id) {
        try {
          const mfaBrowserRemovalText = this.$i18n.t('mfa.mfaBrowserRemovalText')

          const {value: confirmed} = await this.$swal.fire({
            title: this.$i18n.t('user.mfaRemoval'),
            text: mfaBrowserRemovalText,
            confirmButtonColor: '#0d6efd',
            confirmButtonText: this.$i18n.t('mfa.yes'),
            cancelButtonText: this.$i18n.t('mfa.no'),
            showCancelButton: true,
            customClass: {
              title: 'danger-title',
              cancelButton: 'btn-outline-danger'
            }
          })

          if (!confirmed)
            return

          let data = await this.$userService.removeMFADevice(id)
          this.mfaDevices = data.mfaDevices
        } catch(err) {

        }
      },
      formatDate(date) {
        const DATE_FORMAT  = this.$i18n.t('dateTimeFormat')
        return moment(date).format(DATE_FORMAT)
      }
    }
}

</script>
