<template>
  <div class="medical-record-tabs">
    <!-- Anamnese Oftalmológica -->
    <div class="form-group">
      <label for="">{{ $t("medicalRecord.s1_e1") }}</label> <!-- Queixa principal e duração -->
      <input class="form-control" type="text" name="" v-model="medicalRecordData.s1.e1">
    </div>

    <div class="form-group">
      <label for="">{{ $t("medicalRecord.s1_e2") }}</label> <!-- História pregressa da moléstia atual -->
      <BaseQLEditor :content="medicalRecordData.s1.e2" @content-changed="setTextForS1E2"></BaseQLEditor>
    </div>

    <div class="form-group">
      <label for="">{{ $t("medicalRecord.s1_e3") }}</label> <!-- Interrogatório sobre diversos aparelhos -->
      <input class="form-control" type="text" name="" v-model="medicalRecordData.s1.e3">
    </div>

    <div class="row">
      <div class="col-md-6">
        <h3 class="sub-title">{{ $t('medicalRecord.s1_e4') }}</h3> <!-- Antecedentes pessoais e alergias -->
        <div class="form-check">
          <input id="s1_e4_s1" class="form-check-input" type="checkbox" v-model="medicalRecordData.s1.e4_s1">
          <label class="form-check-label" for="s1_e4_s1"> <!-- HAS -->
            {{ $t('medicalRecord.s1_e4_s1') }}
          </label>
        </div>
        <div class="form-check">
          <input id="s1_e4_s2" class="form-check-input" type="checkbox" v-model="medicalRecordData.s1.e4_s2">
          <label class="form-check-label" for="s1_e4_s2"> <!-- DM -->
            {{ $t('medicalRecord.s1_e4_s2') }}
          </label>
        </div>
        <div class="form-check">
          <input id="s1_e4_s3" class="form-check-input" type="checkbox" v-model="medicalRecordData.s1.e4_s3">
          <label class="form-check-label" for="s1_e4_s3"> <!-- Tabagismo -->
            {{ $t('medicalRecord.s1_e4_s3') }}
          </label>
        </div>
        <div class="form-group">
          <label for="">{{ $t("medicalRecord.s1_e4_s4") }}</label> <!-- Outros -->
          <input class="form-control" type="text" name="" v-model="medicalRecordData.s1.e4_s4">
        </div>
        <div class="form-group">
          <label for="">{{ $t("medicalRecord.s1_e4_s5") }}</label> <!-- Alergias -->
          <input class="form-control" type="text" name="" v-model="medicalRecordData.s1.e4_s5">
        </div>
      </div>

      <div class="col-md-6">
        <h3 class="sub-title">{{ $t('medicalRecord.s1_e5') }}</h3> <!-- Antecedentes oculares -->
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="s1_e5_s1" v-model="medicalRecordData.s1.e5_s1">
          <label class="form-check-label" for="s1_e5_s1"> <!-- Retinopatia diabetica -->
            {{ $t('medicalRecord.s1_e5_s1') }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="s1_e5_s2" v-model="medicalRecordData.s1.e5_s2">
          <label class="form-check-label" for="s1_e5_s2"> <!-- Glaucoma (ou suspeita de) -->
            {{ $t('medicalRecord.s1_e5_s2') }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="s1_e5_s3" v-model="medicalRecordData.s1.e5_s3">
          <label class="form-check-label" for="s1_e5_s3"> <!-- DMRI -->
            {{ $t('medicalRecord.s1_e5_s3') }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="s1_e5_s4" v-model="medicalRecordData.s1.e5_s4">
          <label class="form-check-label" for="s1_e5_s4"> <!-- Trauma ocular -->
            {{ $t('medicalRecord.s1_e5_s4') }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="s1_e5_s5" v-model="medicalRecordData.s1.e5_s5">
          <label class="form-check-label" for="s1_e5_s5"> <!-- Cirurgia ocular -->
            {{ $t('medicalRecord.s1_e5_s5') }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="s1_e5_s6" v-model="medicalRecordData.s1.e5_s6">
          <label class="form-check-label" for="s1_e5_s6"> <!-- Uso de óculos ou lentes de contato -->
            {{ $t('medicalRecord.s1_e5_s6') }}
          </label>
        </div>
        <div class="form-group">
          <label for="">{{ $t("medicalRecord.s1_e5_s7") }}</label> <!-- Outros -->
          <input class="form-control" type="text" name="" v-model="medicalRecordData.s1.e5_s7">
        </div>
      </div>
    </div>

    <div class="">
      <h3 class="sub-title">{{ $t('medicalRecord.s1_e6') }}</h3> <!-- Antecedentes familiares -->
      <div class="row">
        <div class="col-md-6">
          <h6 class="sub-title">{{ $t('medicalRecord.s1_e6n1') }}</h6> <!-- Pai -->
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n1_s1" v-model="medicalRecordData.s1.e6n1_s1">
            <label class="form-check-label" for="s1_e6n1_s1"> <!-- HAS -->
              {{ $t('medicalRecord.s1_e6n1_s1') }}
            </label>
          </div>
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n1_s2" v-model="medicalRecordData.s1.e6n1_s2">
            <label class="form-check-label" for="s1_e6n1_s2"> <!-- DM -->
              {{ $t('medicalRecord.s1_e6n1_s2') }}
            </label>
          </div>
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n1_s3" v-model="medicalRecordData.s1.e6n1_s3">
            <label class="form-check-label" for="s1_e6n1_s3"> <!-- Glaucoma -->
              {{ $t('medicalRecord.s1_e6n1_s3') }}
            </label>
          </div>
          <div class="form-group">
            <label for="">{{ $t("medicalRecord.s1_e6n1_s4") }}</label> <!-- Outros -->
            <input class="form-control" type="text" name="" v-model="medicalRecordData.s1.e6n1_s4">
          </div>
        </div>

        <div class="col-md-6">
          <h6 class="sub-title">{{ $t('medicalRecord.s1_e6n2') }}</h6> <!-- Mãe -->
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n2_s1" v-model="medicalRecordData.s1.e6n2_s1">
            <label class="form-check-label" for="s1_e6n2_s1"> <!-- HAS -->
              {{ $t('medicalRecord.s1_e6n2_s1') }}
            </label>
          </div>
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n2_s2" v-model="medicalRecordData.s1.e6n2_s2">
            <label class="form-check-label" for="s1_e6n2_s2"> <!-- DM -->
              {{ $t('medicalRecord.s1_e6n2_s2') }}
            </label>
          </div>
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n2_s3" v-model="medicalRecordData.s1.e6n2_s3">
            <label class="form-check-label" for="s1_e6n2_s3"> <!-- Glaucoma -->
              {{ $t('medicalRecord.s1_e6n2_s3') }}
            </label>
          </div>
          <div class="form-group">
            <label for="">{{ $t("medicalRecord.s1_e6n2_s4") }}</label> <!-- Outros -->
            <input class="form-control" type="text" name="" v-model="medicalRecordData.s1.e6n2_s4">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h6 class="sub-title">{{ $t('medicalRecord.s1_e6n3') }}</h6> <!-- Irmãos -->
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n3_s1" v-model="medicalRecordData.s1.e6n3_s1">
            <label class="form-check-label" for="s1_e6n3_s1"> <!-- HAS -->
              {{ $t('medicalRecord.s1_e6n3_s1') }}
            </label>
          </div>
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n3_s2" v-model="medicalRecordData.s1.e6n3_s2">
            <label class="form-check-label" for="s1_e6n3_s2"> <!-- DM -->
              {{ $t('medicalRecord.s1_e6n3_s2') }}
            </label>
          </div>
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" value="" id="s1_e6n3_s3" v-model="medicalRecordData.s1.e6n3_s3">
            <label class="form-check-label" for="s1_e6n3_s3"> <!-- Glaucoma -->
              {{ $t('medicalRecord.s1_e6n3_s3') }}
            </label>
          </div>
          <div class="form-group">
            <label for="">{{ $t("medicalRecord.s1_e6n3_s4") }}</label> <!-- Outros -->
            <input class="form-control" type="text" name="" v-model="medicalRecordData.s1.e6n3_s4">
          </div>
        </div>

        <div class="col-md-6">
          <h6 class="sub-title">{{ $t('medicalRecord.s1_e6n4') }}</h6> <!-- Avós -->
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n4_s1" v-model="medicalRecordData.s1.e6n4_s1">
            <label class="form-check-label" for="s1_e6n4_s1"> <!-- HAS -->
              {{ $t('medicalRecord.s1_e6n4_s1') }}
            </label>
          </div>
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n4_s2" v-model="medicalRecordData.s1.e6n4_s2">
            <label class="form-check-label" for="s1_e6n4_s2"> <!-- DM -->
              {{ $t('medicalRecord.s1_e6n4_s2') }}
            </label>
          </div>
          <div class="form-check d-inline-block me-3">
            <input class="form-check-input" type="checkbox" id="s1_e6n4_s3" v-model="medicalRecordData.s1.e6n4_s3">
            <label class="form-check-label" for="s1_e6n4_s3"> <!-- Glaucoma -->
              {{ $t('medicalRecord.s1_e6n4_s3') }}
            </label>
          </div>
          <div class="form-group">
            <label for="">{{ $t("medicalRecord.s1_e6n4_s4") }}</label> <!-- Outros -->
            <input class="form-control" type="text" name="" v-model="medicalRecordData.s1.e6n4_s4">
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
.quill-editor {
  width:                         100%;
}
</style>

<script>
export default {
  name: 'AnamneseSection',
  props: ["medicalRecordData"],
  data: () => ({
  }),
  methods: {
    setTextForS1E2(value) {
      this.medicalRecordData.s1.e2 = value
    }
  }
}
</script>
