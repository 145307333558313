<template>
  <div class="" v-loader.opacity="loading">
    <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" @click="$router.back()">
        <i class="icon-back"></i>
      </button>

      <h2 class="title sub">{{ $t('device.devices') }} |
        <span>
          EYER
          <span v-if="device">{{ device.serialNumber }}</span>
        </span>
      </h2>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('device.data') }}
      </template>
      <template v-slot:data>
        <DeviceDataTab v-if="tabActive === 'data' && device" :serial="device.serialNumber"></DeviceDataTab>
      </template>

      <template v-slot:tab-head-logs>
        Logs
      </template>
      <template v-slot:logs>
        <DeviceLogsTab v-if="tabActive === 'logs'" :device="device"></DeviceLogsTab>
      </template>
    </BaseTab>
  </div>
</template>

<style lang="scss" scoped>
  .title-box {
    margin-bottom:                      15px;
    .btn-back {
      margin-right:                     20px;
    }
    .title {
      max-width:                        unset;
      span span {
        display:                        inline-block;
      }
    }
  }

  @media (max-width: 500px) {
    .tab-box .nav-item {
      width:                            100%;
      flex-grow:                        unset;
      flex-basis:                       unset;
    }
  }

  @media (max-width: 767px) {
    .title-box {
      text-align:                       left;
    }
  }

  @media (max-width: 991px) {
    .title-box .title {
      display:                          inline-block;
      width:                            calc(100% - 60px);
      margin-left:                      0;
    }
  }
</style>

<script>
import DeviceDataTab from './DeviceDataTab.vue'
import DeviceLogsTab from './DeviceLogsTab.vue'

export default {
  name: 'device-show',
  components: {
    DeviceDataTab,
    DeviceLogsTab,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    msgError: null,
    tabActive: 'data',
    tabs: ['data', 'logs'],
    device: null,
    deviceId: null,
  }),
  computed: {
    tab() {
      return this.$route.query.tab;
    },
  },
  created() {
    // watch param clinicID coming from notifications to force reload data
    this.$watch( // TODO REFACTOR KISS
      () => this.$route.params.deviceID,
      (toParams, previousParams) => {
        if (toParams != previousParams && this.$route.name == 'device-show')
          this.fetchData()
      }
    )
  },
  mounted() {
    this.deviceId = this.$route.params.deviceID;
    this.$activeLink.value = 'device'
    this.fetchData()

    if (this.tab) {
      this.switchTab(this.tab)
    }
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    async fetchData() {
      let params = {
        id: this.deviceId
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$deviceService.getDevice(params)
        this.device = response.result
        console.log(response);
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    }
  }
}
</script>
