<template>
  <div class="outside-box" v-loader.opacity="loading">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-8 col-md-6">
        <div class="registration-info">
          <h2>
            {{ $t('resetPassword.title') }}
          </h2>

          <hr>

          <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
          </div>

          <h3>
            <span>{{ firstName }}</span>
            {{ $t('resetPassword.forgotMessage') }}
          </h3>

          <p>{{ $t('resetPassword.instructionsMessage') }}</p>

          <vee-form v-slot="{ handleSubmit }" as="div">
            <form @submit.prevent.stop="handleSubmit(changePassword)">
              <div class="mb-3 row">
                <label for="password" class="col-md-3 col-form-label">{{ $t('user.password') }}*</label>
                <div class="col-md-6">
                    <!--
                    <input class="form-control" type="password" ref="password" id="password" name="password" v-model="password" @keydown.enter="$event.preventDefault()">
                    -->
                  <vee-field name="newPassword" v-model="newPassword" rules="required|min:4" v-slot="{ field, errorMessage }">
                    <input v-bind="field" :type="showPassword ? 'text' : 'password'"
                           class="form-control" ref="newPassword" id="newPassword" @keyup="checkPassword(newPassword)">

                    <button class="no-btn" type="button" name="button" @click="showPassword = !showPassword">
                      <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                    </button>

                    <small class="strength-password" :class="strengthInfo" v-show="strengthInfo">{{ strengthInfo ? $t(`strengthPassword.${strengthInfo}`) : "" }}</small>

                    <span class="vee-error">{{ errorMessage ? errorMessage : $t(`${strengthPasswordMessage}`) }}</span>
                  </vee-field>
                </div>
              </div>
              <div class="mb-3 row">
                <label for="confirmPassword" class="col-md-3 col-form-label">{{ $t('user.passwordConfirmation') }}*</label>
                <div class="col-md-6">
                    <!--
                    <input class="form-control" type="password"
                    id="confirmPassword" name="confirmPassword"
                    v-model="confirmPassword" @keydown.enter="$event.preventDefault(); changePassword()">
                    -->
                  <vee-field name="confirmPassword" v-model="confirmPassword" rules="required|confirmed:newPassword" v-slot="{ field, errorMessage }">
                    <input v-bind="field" :type="showConfirmPassword ? 'text' : 'password'"
                           class="form-control" ref="confirmPassword" id="confirmPassword"
                           @keydown.enter="$event.preventDefault(); changePassword()">
                    <button class="no-btn" type="button" name="button" @click="showConfirmPassword = !showConfirmPassword">
                      <i class="fa" :class="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                    </button>
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                </div>
              </div>

              <center>
                <button class="btn btn-success" type="submit" name="button" :disabled="loading">
                  {{ $t('resetPassword.changePassword') }}
                </button>
              </center>
            </form>
          </vee-form>
        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<style lang="scss" scoped>
.form-control {
  position:                     relative;
}

.no-btn {
  position:                     absolute;
  font-size:                    1.4em;
  top:                          3px;
  right:                        27px;
  padding:                      0;
}

.strength-password {
  position:                     absolute;
  color:                        white;
  font-size:                    0.7em;
  text-align:                   center;
  height:                       12px;
  width:                        calc(100% - 24px);
  top:                          -10px;
  &.danger {
    background:                 $phelcom-red;
  }
  &.warning {
    background:                 $yellow-phelcom;
  }
  &.success {
    background:                 $phelcom-green;
  }
}
</style>

<script>
import AuthFooter from './AuthFooter.vue'
import AuthHeader from './AuthHeader.vue';
import utilService from '../../services/util-service.js';

export default {
  name: 'auth-password-reset',
  components: {
    AuthFooter,
    AuthHeader,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    firstName: "",
    ticket: {},
    newPassword: null,
    confirmPassword: null,
    account: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      pin: "",
    },
    showPassword: false,
    showConfirmPassword: false,
    confirmPassword: "",
    strengthPasswordMessage: 'zxcvbnMessages.default',
    strengthInfo: null,
  }),
  mounted() {
    const data = JSON.parse(this.$utils.b64_to_utf8(this.$route.query._data))
    this.firstName = data.firstName
    this.ticket = data.ticket
    this.account.firstName = this.firstName
    this.disableCopyPaste();
  },
  methods: {
    async checkPassword(newPassword) {
      this.account.password = newPassword

      let params = {
        account: this.account
      }

      let isValidPassword = await utilService.validatePassword(params.account)

      switch (isValidPassword.score) {
        case 0:
        case 1:
        case 2:
          this.strengthInfo = 'danger'
          break;
        case 3:
          this.strengthInfo = 'warning'
          break;
        case 4:
          this.strengthInfo = 'success'
          break;
      }

      if (isValidPassword.valid) {
        this.strengthPasswordMessage = 'zxcvbnMessages.default'
      } else {
        this.strengthPasswordMessage = isValidPassword.warning ? 'zxcvbnMessages.warnings.' + isValidPassword.warning :
                                                            'zxcvbnMessages.suggestions.' + isValidPassword.suggestion[0]
      }
    },
    async changePassword() {
      this.alert.message = null

      let passwordParams = {
          account: this.account,
      }
      let responseValidate = await utilService.validatePassword(passwordParams.account)

      if (responseValidate.valid == false){
        this.$message.popup(this, 'zxcvbnMessages.error', 'operationFail', 'error')
        return
      }

      let params = {
        code: this.ticket.activationCode,
        newPassword: this.newPassword
      }

      this.loading = true
      NProgress.start()
      try {
        await this.$authService.changePassword(params)
        this.$message.popup(this, 'resetPassword.successResetMessage', 'operationSuccess', 'success')

        setTimeout(function(){
          window.location = "/";
        }, 2000);

      } catch (err) {
        err.response.data.code ?
              this.$message.popup(this, err.response.data.code, 'operationFail', 'error')
                : this.$message.popup(this, err.response.data.errorMessage, 'operationFail', 'error')
        // this.alert = err.response.data.code ?
        //               this.$message.alert(this, err.response.data.code, 'danger')
        //                 : this.$message.alert(this, err.response.data.errorMessage, 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    disableCopyPaste() {
      let inputArray = [this.$refs.newPassword, this.$refs.confirmPassword]
      inputArray.forEach((element) => {
        element.addEventListener('contextmenu', (e) => e.preventDefault()); // Disable right-click menu
        element.addEventListener('cut', (e) => e.preventDefault()); // Disable cut
        element.addEventListener('copy', (e) => e.preventDefault()); // Disable copy
        element.addEventListener('paste', (e) => e.preventDefault()); // Disable paste
      })
    },
  }
}
</script>
