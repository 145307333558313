<template>
  <div class="clinic-form-box">
    <div class="row" v-if="clinic">
      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('createClinic.generalData') }}</h3>
        <div class="form-group">
          <label for="name">{{ $t('createClinic.name') }}*</label>
          <vee-field name="name" v-model="clinic.name" rules="required" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" ref="name" id="name" autofocus :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="fantasyName">{{ $t('createClinic.nameExhibition') }}*</label>
          <vee-field name="fantasyName" v-model="clinic.fantasyName" rules="required" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" ref="fantasyName" id="fantasyName" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="document">{{ $t('createClinic.document') }}</label>
          <vee-field name="document" v-model="clinic.document" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" ref="document" id="document" v-maska :data-maska="$t('inputMask.clinicDocument')" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <!-- end verify -->
        <div class="form-group">
          <label for="email">{{ $t('createClinic.email') }}</label>
          <vee-field name="email" v-model="clinic.email" rules="email" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" ref="email" id="email" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="telephone1">{{ $t('createClinic.telephone1') }}</label>
          <input type="text" class="form-control" id="telephone1" v-model="clinic.telephone1" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="telephone2">{{ $t('createClinic.telephone2') }}</label>
          <input type="text" class="form-control" id="telephone2" v-model="clinic.telephone2" :disabled="disabled">
        </div>
      </div>

      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('createClinic.localization') }}</h3>
        <div class="form-group">
          <label for="address">{{ $t('createClinic.address') }}</label>
          <input type="text" class="form-control" id="address" v-model="clinic.address" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="number">{{ $t('createClinic.number') }}</label>
          <input type="text" class="form-control" id="number" v-model="clinic.addressNumber" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="district">{{ $t('createClinic.district') }}</label>
          <input type="text" class="form-control" id="district" v-model="clinic.district" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="city">{{ $t('createClinic.city') }}</label>
          <input type="text" class="form-control" id="city" v-model="clinic.city" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="state">{{ $t('createClinic.state') }}</label>
          <input type="text" class="form-control" id="state" v-model="clinic.state" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="countrySelected">{{ $t('createClinic.country') }}</label>
          <v-select
            class="v-select form"
            v-model="countrySelected"
            :reduce="x => x.value"
            :options="countryOptions"
            @option:selected="emitOptions"
            :disabled="disabled">
          </v-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('createClinic.configuration') }}</h3>
        <div class="form-group">
          <label for="mandatoryDocument">{{ $t('createClinic.mandatoryDocument') }}</label>
          <v-select
            label="label"
            class="v-select form"
            v-model="mandatoryDocument"
            :reduce="x => x.value"
            :options="configOptions"
            @option:selected="emitOptions"
            :disabled="disabled">
          </v-select>
        </div>
      </div>

      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('createClinic.locale') }}</h3>
        <div class="form-group">
          <label for="languageSelected">{{ $t('createClinic.language') }}</label>
          <v-select
            class="v-select form"
            :reduce="x => x.value"
            v-model="languageSelected"
            :options="languageOptions"
            @option:selected="emitOptions"
            :disabled="disabled">
          </v-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('createClinic.security') }}</h3>

        <div class="form-group">
          <label for="mfaMode">{{ $t('createClinic.mfaMode') }}</label>
          <v-select
            label="label"
            class="v-select form"
            v-model="mfaMode"
            :reduce="x => x.value"
            :options="mfaOptions"
            @option:selected="mfaModeChanged"
            :disabled="disabled">
          </v-select>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.clinic-form-box .dropdown-menu.show {
  width:                         100% !important;
}

@media screen and (min-width: 500px) and (max-width: 991px) {
  .clinic-form-box .col-lg-6 > .row > .col-md-6 {
    width:                       50%;
  }
}

@media (max-width: 991px) {
  .clinic-form-box {
    .form-group {
      label {
        width:                   100%;
      }
      .form,
      .clinic-form-box .form-group input[type="text"] {
        width:                   100% !important;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'ClinicFormData',
  props: ['clinic', 'disabled'],
  data: () => ({
    countryOptions: [],
    configOptions: [],
    mfaOptions: [],
    languageOptions: [],
    countrySelected: '',
    mandatoryDocument: 'none',
    languageSelected: '',
    
    mfaMode: null,
    security: {}
  }),
  watch: {
    disabled: function(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.$refs.name.focus()
        })
      }
    },
  },
  created() {

  },
  mounted: function() {
    let locale = this.clinic.locale
    const countryCodeList = this.$utils.countryCodeList(locale)
    for (let code of countryCodeList) {
      this.countryOptions.push({
        value: code,
        label: this.$i18n.t(`country.${code}`)
      })  
    }

    this.configOptions = [
      {value: 'none', label: this.$i18n.t('none')},
      {value: 'mrn', label: this.$i18n.t('mrn')},
      {value: 'document0', label: this.$i18n.t('document0')},
      {value: 'document1', label: this.$i18n.t('document1')},
      {value: 'document2', label: this.$i18n.t('document2')},
      {value: 'document3', label: this.$i18n.t('document3')},
    ];

    this.mfaOptions = [
      {value: 'disabled', label: this.$i18n.t('mfa.disabled')},
      {value: 'optional', label: this.$i18n.t('mfa.optional')},
      {value: 'mandatory', label: this.$i18n.t('mfa.mandatory')}
    ]

    this.languageOptions = [
      {value: 'pt-br', label: this.$i18n.t('locales.pt-br')},
      {value: 'en', label: this.$i18n.t('locales.en')},
      {value: 'es', label: this.$i18n.t('locales.es')},
    ];

    // from clinic template or current clinic
    this.countrySelected = this.clinic.country
    this.mandatoryDocument = this.clinic.mandatoryDocument
    this.languageSelected = this.clinic.locale
    
    let security = this.$clinicService.getSecurityConfiguration(this.clinic)
    this.mfaMode = security.mfaMode
  },
  methods: {
    emitOptions() {
      let options = {
        country: this.countrySelected,
        mandatoryDocument: this.mandatoryDocument,
        locale: this.languageSelected,
      }
      if (!this.disabled) {
        this.$emit('option-selected', options)
      }
    },
    mfaModeChanged() {
      this.$emit('security-changed', {
        mfaMode: this.mfaMode,
      })
    }
  },
}
</script>
