<template>
  <div class="sidebar ease-all" id="sidebarMenu">
    <div class="menu ease-width desk">
      <div class="header-logo">
        <!-- <img src="@/assets/images/phelcom/logo_eyerlink.svg" class="logo-eyerweb" alt="Logo EyerLink" width="155"> -->
        <i class="icon-logo_eyerlink"></i>
      </div>

      <div class="device-info">
        <img src="@/assets/images/phelcom/eyer.png" alt="Eyer" width="48">
        <div class="d-inline-block">
          <p>{{ $t('eyerweb.linkedDevice') }}</p>
           <!-- <p>clinica: </p>> -->
          <p>{{this.clinicName}}</p>


        </div>

        <button class="btn btn-primary" type="button" name="button" @click="logout()">
          <span>{{ $t('menu.disconnect') }}</span>
          <i class="icon-logout_eyerweb"></i>
          <!-- <img src="@/assets/images/logout_eyerweb.svg" alt="Logout icon" width="15"> -->
        </button>
      </div>

      <EyerwebMenuDesktopLinks @logout="logout"></EyerwebMenuDesktopLinks>

      <p class="version">{{ appVersion }}-beta</p>
    </div>

    <div class="menu ease-width mob">
      <EyerwebMenuMobileLinks @logout="logout"></EyerwebMenuMobileLinks>
    </div>

    <teleport to="body">
      <BaseGenericModal :modal_id="'modalLogoutGeneric'" ref="modalGeneric"></BaseGenericModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.device-info {
  @include                              border_radius(5px);
  font-family:                          'Ubuntu', 'Arial', sans-serif;
  text-transform:                       uppercase;
  // width:                                314px;
  display:                              block;
  margin:                               26px auto 33px;
  padding:                              8px;
  background:                           linear-gradient(135deg, #D8D8D8 0%, #FFF 100%);
  > img {
    margin-right:                       28px;
  }
  > div {
    width:                              calc(100% - 76px);
    // margin-bottom:                      16px;
    vertical-align:                     top;
    p {
      color:                            var(--shades-80, #232323);
      font-weight:                      500;
      line-height:                      normal;
      &:first-child {
        @include                        border_radius(16px);
        color:                          var(--brand-primary, #1B294A);
        font-size:                      10px;
        letter-spacing:                 3.1px;
        text-align:                     center;
        padding:                        5px;
        margin-bottom:                  5px;
        border:                         1px solid #C1C1C1;
      }
      &:nth-child(2) {
        font-size:                      15px;
        margin:                         10px 0;
      }
      &:last-child {
        font-size:                      12px;
        letter-spacing:                 1.44px;
        margin-bottom:                  0;
      }
    }
  }
  button {
    @include                            border_radius(5px);
    font-size:                          10px;
    line-height:                        normal;
    letter-spacing:                     2.95px;
    text-transform:                     uppercase;
    text-align:                         center;
    width:                              100%;
    padding:                            10px;
    margin-top:                         16px;
    .icon-logout_eyerweb {
      font-size:                        1.2em;
      margin-left:                      10px;
      vertical-align:                   bottom;
    }
    &,
    &:hover,
    &:focus {
      color:                            var(--shades-w, #FFF);
      border:                           1px solid #1B294A;
      background:                       #1B294A;
    }
  }
}

  .sidebar {
    width:                              342px;
    display:                            inline-block;
    .version,
    .fa-wifi {
      position:                         absolute;
    }
    .version {
      color:                            white;
      left:                             10px;
      bottom:                           0;
      margin-bottom:                    0;
    }
    .fa-wifi {
      color:                            green;
      right:                            10px;
      bottom:                           3px;
      &.red {
        color:                          red;
      }
    }
    .menu {
      width:                            100%;
      min-height:                       100%;
      z-index:                          999999;
      display:                          block;
      background:                       url('@/assets/images/erjart/bg_menu.jpg') center top / 125vh no-repeat;
      overflow:                         hidden;
      &.desk {
        padding:                        0 11px;
      }
      &.mob {
        display:                        none;
      }
      .header-logo {
        display:                        flex;
        justify-content:                space-between;
        align-items:                    center;
        padding:                        10px;
        .icon-logo_eyerlink {
          font-size:                    3.1em;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .device-info {
      display:                          none;
    }
    .sidebar {
      position:                         relative;
      display:                          block;
      width:                            100%;
      height:                           100%;
      min-height:                       50px;
      max-height:                       340px;
      z-index:                          auto;
      top:                              0;
      .menu {
        // position:                       fixed;
        width:                          100%;
        background:                     unset;
        top:                            0;
        right:                          0;
        left:                           0;
        z-index:                        99999;
        &.desk {
          display:                      none;
        }
        &.mob {
          display:                      block;
          min-height:                   50px;
          max-height:                   100%;
          z-index:                      9999999;
        }
        .header-logo {
          padding:                      10px 13px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .sidebar {
      position:                         fixed;
      height:                           100vh;
      z-index:                          1039;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1450px) {
    .sidebar {
      width:                            300px;
    }
  }
</style>

<script>
import { useEyerwebStore } from '@/store/eyerweb'
import EyerwebMenuDesktopLinks from './EyerwebMenuDesktopLinks.vue'
import EyerwebMenuMobileLinks from './EyerwebMenuMobileLinks.vue'

export default {
  name: 'eyerweb-menu',
  components: {
    EyerwebMenuDesktopLinks,
    EyerwebMenuMobileLinks,
  },
  data: () => ({
    collapse: false,
    session: null,
    isWebSocketConnected: false,
    appVersion: process.env.VUE_APP_VERSION,
    clinicName: '',
  }),
  mounted() {
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo

    if (this.session.currentClinic)
      this.clinicName = this.session.currentClinic.name

  },
  methods: {
    doCollapse(value) {
      this.collapse = !this.collapse;

      if (this.collapse) {
        $('body').addClass('collap')
      } else {
        $('body').removeClass('collap')
      }
    },
    async logout() {
      const ok = await this.$refs.modalGeneric.show({
        modalType: 'warning',
        title: this.$i18n.t('warning'),
        text: this.$i18n.t('eyerweb.disconnect'),
        okButton: this.$i18n.t('yes'),
        okButtonClass: 'btn-primary',
        cancelButton: this.$i18n.t('no'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        //await this.$authService.logout(this)
        await this.$eyerwebService.disconnect()
        window.open('/link', '_self');
        //this.$router.push('/link/')
      }
    }
  }
}

</script>
