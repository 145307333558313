<template>
  <div class="logo">
    <a href="https://phelcom.com/pt-br/" target="_blank" class="">
      <img src="@/assets/images/phelcom/logo_eyer_cloud_horizontal_color-1.svg" alt="EyerCloud Logo">
    </a>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  padding:                                78px 0 30px !important;
}

.logo .icon-phelcom_eyer {
  font-size:                              10.35em;
}

@media (max-width: 500px) {
  .logo .icon-phelcom_eyer {
    font-size:                            9em;
  }
}
</style>

<script>
export default {
  name: 'AuthHeader'
}
</script>
