<template>
  <div class="diagnosis-box">
    <div class="title-box rounded">
      <h2 class="title">{{ $t('exam.reportList') }}</h2>
      <button @click="createReport()" class="btn btn-outline-primary rounded" type="button">
        <i class="fa fa-plus"></i><span role="link">{{ $t('add') }}</span>
      </button>

      <BaseFeature feature="generalReport">
        <button @click="createGeneralReport()" class="btn btn-outline-primary rounded" type="button">
          <i class="fa fa-plus"></i><span>{{ $t('report.generalReport') }}</span>
        </button>
      </BaseFeature>

      <button @click="createQuickReport()" class="btn btn-outline-primary rounded" type="button">
        <i class="fa fa-plus"></i>
        <span>{{ $t('report.quickReport') }}</span>
      </button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message || alert_msg.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <ExamReportList :patient="patient" :exam="exam" @alertmsg="setAlert" :key="alert_msg"></ExamReportList>
  </div>
</template>

<style lang="scss" scoped>
  .diagnosis-box {
    .title-box {
      border-bottom:                     2px solid $bright-gray;
      padding:                           1px 5px 6px;
      margin-bottom:                     10px;
      .title {
        width:                           auto;
        margin-right:                    20px;
      }
      .btn {
        margin-bottom:                   0;
        max-height:                      29px;
        span {
          vertical-align:                top;
        }
      }
    }
  }

  @media (max-width: 370px) {
    .diagnosis-box {
      .title-box {
        .btn {
          display:                       block;
          max-width:                     200px;
          margin:                        0 auto 10px;
          &:last-child {
            margin-bottom:               0;
          }
        }
      }
    }
  }

  @media screen and (min-width: 371px) and (max-width: 600px) {
    .diagnosis-box {
      .title-box {
        .title {
          display:                       block;
        }
        .btn:last-child {
          float:                         right;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .diagnosis-box {
      .title-box {
        .title {
          width:                         100%;
          max-width:                     unset;
          text-align:                    center;
          margin-right:                  5px;
        }
      }
    }
  }

  @media (max-width: 660px) {
    .diagnosis-box {
      .title-box {
        .title {
          margin-right:                  5px !important;
        }
        .btn {
          // margin-right:                  0;
          padding:                       6px 15px;
          &:first-child {
            margin-right:                10px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .title-box {
      .btn-outline-primary:nth-child(3) {
        display:                         none;
      }
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'
import ExamReportList from './ExamReportList.vue'

export default {
  name: 'ExamReportTab',
  props: ['exam', 'patient', 'alert_msg'],
  components: {
    ExamReportList
  },
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    session: null,
  }),
  watch: {
    alert_msg: function(newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
      } else {
        this.alert.message = null
      }
    },
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  methods: {
    setAlert(val) {
      this.alert = {
        message: val.message,
        class: val.class
      };
    },
    createReport() {
      let examDataQuery = {}
      if (this.$route.query.data) {
        examDataQuery = this.$utils.parseDataQuery(this.$route.query.data)
      }

      let data = this.$utils.dataQuery({
        patient: JSON.stringify(this.patient),
        isQuickReport: false,
        startDate: examDataQuery.startDate,
        endDate: examDataQuery.endDate,
        filterSelected: examDataQuery.filterSelected
      })

      this.$router.push({name: 'report', params: {examID: this.exam.id}, query: {data: data}})
    },
    createQuickReport() {
      let examDataQuery = {}
      if (this.$route.query.data) {
        examDataQuery = this.$utils.parseDataQuery(this.$route.query.data)
      }

      let data = this.$utils.dataQuery({
        patient: JSON.stringify(this.patient),
        isQuickReport: true,
        startDate: examDataQuery.startDate,
        endDate: examDataQuery.endDate,
        filterSelected: examDataQuery.filterSelected
      })

      this.$router.push({name: 'report', params: {examID: this.exam.id}, query: {data: data}})
    },
    createGeneralReport() {
      let examDataQuery = {}
      if (this.$route.query.data) {
        examDataQuery = this.$utils.parseDataQuery(this.$route.query.data)
      }

      let data = this.$utils.dataQuery({
        startDate: examDataQuery.startDate,
        endDate: examDataQuery.endDate,
        filterSelected: examDataQuery.filterSelected
      })

      this.$router.push({name: 'report-general', params: {examID: this.exam.id, patientID: this.patient.id}, query: {data: data}})
    },
  },
  // unmounted() {
  //   this.alert.message = null
  // }
}
</script>
