<template>
  <div class="outside-box" v-loader.opacity="loading">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-10 col-lg-7">
        <div class="registration-form">
          <h2 class="registration-title">{{ $t('createAccount.createAccount') }}</h2>
          <hr>
          <vee-form v-slot="{ handleSubmit }" as="div">
            <form @submit.prevent.stop="handleSubmit($event, doSave)">
              <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                <span v-html="alert.message"></span>
                <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
              </div>

              <div class="mb-3 row">
                <label for="firstName" class="col-md-3 col-form-label">{{ $t('createAccount.name') }}*</label>
                <div class="col-md-6">
                  <vee-field name="firstName" v-model="account.firstName" rules="required" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="text" class="form-control" ref="firstName" id="firstName">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                </div>
              </div>

              <div class="mb-3 row">
                <label for="lastName" class="col-md-3 col-form-label">{{ $t('createAccount.surname') }}*</label>
                <div class="col-md-6">
                  <vee-field name="lastName" v-model="account.lastName" rules="required" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="text" class="form-control" ref="lastName" id="lastName">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                </div>
              </div>

              <div class="mb-3 row">
                <label for="email" class="col-md-3 col-form-label">{{ $t('createAccount.email') }}*</label>
                <div class="col-md-6">
                  <vee-field name="email" v-model="account.email" rules="required|email" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="email" class="form-control" ref="email" id="email">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                </div>
              </div>

              <div class="mb-3 row">
                <label for="password" class="col-md-3 col-form-label">{{ $t('createAccount.password') }}*</label>
                <div class="col-md-6">
                  <vee-field name="password" v-model="password" rules="required|min:4" v-slot="{ field, errorMessage }">
                    <input v-bind="field" :type="showPassword ? 'text' : 'password'"
                           new-password class="form-control" ref="password" id="password" @keyup="checkPassword(password)">

                    <button class="no-btn" type="button" name="button" @click="showPassword = !showPassword">
                      <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                    </button>

                     <small class="strength-password" :class="strengthInfo" v-show="strengthInfo">{{ strengthInfo ? $t(`strengthPassword.${strengthInfo}`) : "" }}</small>

                    <span class="vee-error">{{ errorMessage ? errorMessage : $t(`${strengthPasswordMessage}`) }}</span>
                  </vee-field>
                </div>
              </div>

              <div class="mb-3 row">
                <label for="confirmPassword" class="col-md-3 col-form-label">{{ $t('createAccount.confirmPassword') }}*</label>
                <div class="col-md-6">
                  <vee-field name="confirmPassword" v-model="account.confirmPassword" rules="required|confirmed:password" v-slot="{ field, errorMessage }">
                    <input v-bind="field" :type="showConfirmPassword ? 'text' : 'password'"
                           class="form-control" ref="confirmPassword" id="confirmPassword">
                    <button class="no-btn" type="button" name="button" @click="showConfirmPassword = !showConfirmPassword">
                      <i class="fa" :class="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                    </button>
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                </div>
              </div>

            <!-- <div class="mb-3 row">
              <label for="password" class="col-md-3 col-form-label">{{ $t('createAccount.password') }}*</label>
              <div class="col-md-6">
                <input v-if="showPassword" type="text" class="input" v-model="account.password" />
                <input v-else type="password" class="input" v-model="account.password">
                <input v-if="showPassword" type="text" class="input" v-model="inputPassword" />
                <input v-else type="password" class="input" v-model="inputPassword">
                <input v-if="showPassword" type="text" class="input"  v-model="password" @keyup="checkPassword(password)" />
                <input v-else type="password" class="input" v-model="password" @keyup="checkPassword(password)">
                <button class="button" @click="toggleShow">
                  <span class="icon is-small is-right">
                    <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                  </span>
                </button>
                <small class="help-block" id="password-text"></small>
                <span>{{ $t(this.passwordSuggestion) }}</span>
                <br>
                <span>{{ $t(this.passwordWarning) }}</span>
              </div>
            </div> -->
            <!-- <div class="mb-3 row">
              <label for="confirmPassword" class="col-md-3 col-form-label">{{ $t('createAccount.confirmPassword') }}*</label>
              <div class="col-md-6">
                <input v-if="showConfirmPassword" type="text" class="input"  v-model="confirmPassword" @keyup="checkConfirmPassword(confirmPassword)" />
                <input v-else type="password" class="input" v-model="confirmPassword" @keyup="checkConfirmPassword(confirmPassword)">
                <button class="button" @click="toggleShowConfirm">
                  <span class="icon is-small is-right">
                    <i class="fas" :class="{ 'fa-eye-slash': showConfirmPassword, 'fa-eye': !showConfirmPassword }"></i>
                  </span>
                </button>
                <br>
                <span>{{ passwordConfirmStatus }}</span>
              </div>

            </div> -->

              <div class="mb-3 row">
                <label for="pin" class="col-md-3 col-form-label">{{ $t('user.devicePIN') }}*</label>
                <div class="col-md-6">
                  <vee-field name="pin" v-model="account.pin" rules="required|min:4" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="text" class="form-control" ref="pin" id="pin" v-only-numbers>
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                </div>
              </div>

              <hr>

              <div class="action-buttons">
                <div class="">
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onSaveVerify"
                    @expired="onExpired"
                    :sitekey=sitekey
                    size="invisible"
                    :load-recaptcha-script="true">
                  </vue-recaptcha>
                  <button class="btn btn-success" type="submit" name="button" :disabled="loading">{{ $t('createAccount.register') }}</button>
                </div>
              </div>
            </form>
          </vee-form>
        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<style lang="scss" scoped>
.form-control {
  position:                     relative;
}

.no-btn {
  position:                     absolute;
  font-size:                    1.4em;
  top:                          3px;
  right:                        27px;
  padding:                      0;
}

.strength-password {
  position:                     absolute;
  color:                        white;
  font-size:                    0.7em;
  text-align:                   center;
  height:                       12px;
  width:                        calc(100% - 24px);
  top:                          -3px;
  &.danger {
    background:                 $phelcom-red;
  }
  &.warning {
    background:                 $yellow-phelcom;
  }
  &.success {
    background:                 $phelcom-green;
  }
}
</style>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import AuthFooter from '../../auth/AuthFooter.vue'
import AuthHeader from '../../auth/AuthHeader.vue'
import { useUserStore } from '@/store/user'
import utilService from '../../../services/util-service.js';

export default {
  name: 'registration-account-create',
  components: {
    AuthFooter,
    AuthHeader,
    'vue-recaptcha': VueRecaptcha,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    account: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      pin: "",
    },
    accountType: "online",
    showPassword: false,
    showConfirmPassword: false,
    password: "",
    confirmPassword: "",
    strengthPasswordMessage: 'zxcvbnMessages.default',
    strengthInfo: null,
    sitekey: process.env.VUE_APP_SITE_KEY,
  }),
  mounted() {
    const userStore = useUserStore()
    userStore.reset() // avoid reusing other clinic info

    this.$refs.firstName.focus()
    if (this.$route.params.accountType)
      this.accountType = this.$route.params.accountType

    if (this.$route.meta.accountType)
      this.accountType = this.$route.meta.accountType
    
    this.disableCopyPaste();
  },
  methods: {
    async doSave() {
      this.$refs.recaptcha.execute();
    },
    async checkPassword(password) {
      this.account.password = password
      let params = {
        account: this.account
      }

      let isValidPassword = await utilService.validatePassword(params.account)

      switch (isValidPassword.score) {
        case 0:
        case 1:
        case 2:
          this.strengthInfo = 'danger'
          break;
        case 3:
          this.strengthInfo = 'warning'
          break;
        case 4:
          this.strengthInfo = 'success'
          break;
      }

      if (isValidPassword.valid) {
        this.strengthPasswordMessage = 'zxcvbnMessages.default'
      } else {
        this.strengthPasswordMessage = isValidPassword.warning ? 'zxcvbnMessages.warnings.' + isValidPassword.warning :
                                                            'zxcvbnMessages.suggestions.' + isValidPassword.suggestion[0]
      }
    },
    onSaveVerify: async function (gtoken){
      this.$refs.recaptcha.reset();
      let params = {
        account: this.account,
        accountType: this.accountType,
        recaptcha: gtoken,
      }
      let responseValidate = await utilService.validatePassword(params.account)

      if (responseValidate.valid == false){
        this.$message.popup(this, 'zxcvbnMessages.error', 'operationFail', 'error')
        return
      }
      this.loading = true
      NProgress.start()
      try {
          let response = await this.$registrationService.createAccount(params)
          let data = this.$utils.dataQuery({user: response.user})
          this.$router.push({name: 'registration-account-created', query: {data: data}})
      } catch (err) {
        this.$message.popup(this, `createAccount.${err.response.data.errorMessage}`, 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirm() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    disableCopyPaste() {
      let inputArray = [this.$refs.password, this.$refs.confirmPassword, this.$refs.pin]
      inputArray.forEach((element) => {
        element.addEventListener('contextmenu', (e) => e.preventDefault()); // Disable right-click menu
        element.addEventListener('cut', (e) => e.preventDefault()); // Disable cut
        element.addEventListener('copy', (e) => e.preventDefault()); // Disable copy
        element.addEventListener('paste', (e) => e.preventDefault()); // Disable paste
      })
    },
  },

}
</script>
