import { defaultLocale } from '@/config/locales/index.js'

import { defineRule, configure } from 'vee-validate';
import { setLocale, localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import pt_BR from '@vee-validate/i18n/dist/locale/pt_BR.json';


configure({
  generateMessage: localize({
    'en': en,
    'es': es,
    'pt-br': pt_BR,
  }),
});

localize({
  'en': {
    messages: {
      confirmed: "The passwords don't match.",
      date: 'Invalid format date.',
      email: 'The email must be in a valid format.',
      max: 'This field cannot be longer than 0:{max} characters.',
      min: 'This field must be longer than 0:{min} characters.',
      password: 'Password confirmation does not match.',
      required: 'This field is required.',
    },
  },
  'es': {
    messages: {
      confirmed: 'Las contraseñas no coinciden.',
      date: 'Formato de fecha inválido.',
      email: 'El correo electrónico debe tener un formato válido.',
      max: 'Este campo no puede tener más de 0:{max} caracteres.',
      min: 'Este campo debe tener más de 0:{min} caracteres.',
      password: 'La confirmación de la contraseña no coincide.',
      required: 'Este campo es requerido.',
    },
  },
  'pt-br': {
    messages: {
      confirmed: 'As senhas não conferem.',
      date: 'Formato de data inválido.',
      email: 'O email deve estar em um formato válido.',
      max: 'Este campo não pode ter mais de 0:{max} caracteres.',
      min: 'Este campo precisa ter mais de 0:{min} caracteres.',
      password: 'A confirmação da senha não corresponde.',
      required: 'Este campo é obrigatório.',
    },
  },
});

setLocale(defaultLocale);

defineRule("required", (value) => {
  if (!value || !value.length) {
    return false;
  }
  return true;
});

defineRule("min", (value, [limit]) => {
  if (!value || !value.length) {
    return true;
  }
  if (value.length < limit) {
    return false
    // return `Este campo precisa ter mais de ${limit} caracteres.`
  }
  return true;
});

defineRule("date", (value, [format]) => {
  if (!value || value == '')  {
    return true
  }

  return moment(value, format, true).isValid()
  // return moment(value, format, true).isValid() ? moment(value, format, true).isValid() : this.$i18n.t('validate.date');
});

defineRule('email', value => {
  if (!value || !value.length) {
    return true;
  }

  if (!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value)) {  
    return false
  }

  return true;
});


defineRule('confirmed', (value, [target], ctx) => {
  if (value === ctx.form[target]) {
    return true;
  }
  return false
});
