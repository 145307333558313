<template>
  <div class="" v-loader.opacity.tab="loading">
    <div id="template-list" v-if="!editTemplate">
      <div class="action-buttons rounded text-end">
        <button class="btn btn-outline-success" type="button" name="button" @click="editTemplate = !editTemplate">
          <i class="fa fa-plus"></i>
          <span>{{ $t('clinic.newTemplate') }}</span>
        </button>
      </div>

      <BaseTable class="white-table" v-if="tableTemplates" :tabledata="tableTemplates" @item-opened="toDetails">
      </BaseTable>
    </div>

    <div v-else>
      <ClinicTemplateEdit :template_id="template ? template.id : null" @cancel-edit="cancelEditTemplate"></ClinicTemplateEdit>
    </div>

  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 400px) {
  .vtable-mob > ul > li p > span:first-child {
    display:                         none;
  }
}
</style>

<script>
import ClinicTemplateEdit from './ClinicTemplateEdit.vue'

export default {
  name: 'ClinicTemplateTab',
  components: {
    ClinicTemplateEdit,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableTemplates: null,
    editTemplate: false,
    template: null,
  }),
  mounted: async function() {
    this.template = null
    this.fetchData();
  },
  methods: {
    fetchData: async function() {
      this.alert.message = undefined;

      this.loading = true
      NProgress.start()
      try {
        let params = {
          // page: this.currentPage,
        }

        let response = await this.$clinicService.listTemplates(params);
        console.log(">>>>>> fetch Templates >> ", response)

        this.tableTemplates = {
          'items': response.result,
          'columns': [
            {name: this.$i18n.t('name'), type: 'string', value: 'name', width: '35%'},
            {name: this.$i18n.t('description'), type: 'string', value: 'description', width: '65%'},
          ]
        }
      } catch (err) {
        this.alert = showAlert('request_error', 'alert-danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toDetails(item) {
      this.template = item;
      this.editTemplate = true;
    },
    cancelEditTemplate() {
      this.template = null
      this.editTemplate = false;
    }
  }
}
</script>
