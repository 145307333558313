<template>
  <div class="main-box" v-loader.opacity="loading">
    <div class="title-box rounded clear">
      <h2 class="title">{{ $t('device.deviceList') }}</h2>

      <button class="btn btn-outline-primary" type="button" name="button" @click="newDevice">
        {{ $t('add') }}
      </button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable v-if="tableDevice" :tabledata="tableDevice" @item-opened="toDetails">
    </BaseTable>
  </div>
</template>

<style lang="scss" scoped>
  .title-box,
  .title-box .btn {
    margin-bottom:                 0;
  }

  .page-row,
  .page-row p,
  .table {
    margin-bottom:                 0 !important;
  }

  .page-row {
    margin-top:                    20px;
    p,
    .pagination {
      padding-right:               10px;
      padding-left:                10px;
    }
  }
</style>

<script>
export default {
  name: 'device-list',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    tableDevice: {},
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
  }),
  mounted() {
    this.$activeLink.value = 'device'
    this.fetchData()
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    fetchData: async function() {
      this.alert = {}
      let params = {}

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$deviceService.getList(params)
        this.tableDevice = {
          'items': response.result,
          'total': response.totalCount,
          'columns': [
            {name: 'Serial', type: 'string', value: 'serialNumber', width: '100%'}
          ]
        };
        this.totalPages = Math.ceil(response.totalCount / this.perPage);

        if (response.result.length === 0) {
          this.tableDevice = {};
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        console.log("error", err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toDetails(item) {
      this.$router.push({name: 'device-show', params: {deviceID: item.id}, query: {tab: 'data'}})
    },
    newDevice() {
      this.$router.push({name: 'device-create'})
    }
  }
}
</script>
