<template>
  <nav aria-label="">
    <ul class="pagination justify-content-end">
      <li class="page-item" :class="atFirstPage ? 'disabled' : ''">
        <a class="page-link" tabindex="-1" aria-disabled="true" @click="onClickFirstPage">{{ $t('pagination.first') }}</a>
      </li>
      <li class="page-item" :class="atFirstPage ? 'disabled' : ''">
        <a class="page-link" tabindex="-1" aria-disabled="true" @click="onClickPreviousPage">&laquo;</a>
      </li>

      <li class="page-item" v-for="page in pages">
        <a class="page-link" @click="onClickPage(page.name)" :disabled="page.isDisabled" :class="{ active: isPageActive(page.name) }">
          {{ page.name }}
        </a>
      </li>

      <li class="page-item" :class="atLastPage ? 'disabled' : ''">
        <a class="page-link" tabindex="-1" aria-disabled="true" @click="onClickNextPage">&raquo;</a>
      </li>
      <li class="page-item" :class="atLastPage ? 'disabled' : ''">
        <a class="page-link" tabindex="-1" aria-disabled="true" @click="onClickLastPage">{{ $t('pagination.last') }}</a>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
  .pagination {
    margin:                            0;
    .page-item {
      cursor:                          pointer;
      .page-link {
        color:                         $steel-blue;
        background-color:              $gainsboro;
        padding:                       6px 9px;
        border:                        1px solid $gainsboro;
        line-height:                   1.4;
        &:focus,
        &:hover {
          @include                     box_shadow(unset);
          color:                       $bdazzled;
          background-color:            $bright-gray;
          outline:                     0;
        }
        &.active {
          &,
          &:focus,
          &:hover {
            color:                     white;
            background-color:          $bdazzled;
          }
        }
      }
      &.disabled {
        &,
        &:focus,
        &:hover {
          color:                       $taupe-gray;
          pointer-events:              unset;
          cursor:                      not-allowed;
          opacity:                     0.4;
        }
      }
    }
  }

  .white-pagination {
    .page-link {
      background-color:                white;
    }
  }

  @media (max-width: 550px) {
    .pagination {
      .page-link {
        font-size:                     14px;
      }
    }
  }
</style>

<script>
  export default {
    name: 'BasePagination',
    props: {
      maxVisibleButtons: {type: Number, required: false, default: 5},
      totalPages: {type: Number, required: true},
      total: {type: Number, required: true},
      perPage: {type: Number, required: true},
      currentPage: {type: Number, required: true},
    },
    computed: {
      startPage() {
        if (this.currentPage === 1)
          return 1;

        if (this.currentPage === this.totalPages)
          return this.totalPages - this.maxVisibleButtons + 1;

        return this.currentPage - 1;
      },
      endPage() {
        return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
      },
      pages() {
        const range = [];

        for (let i = this.startPage; i <= this.endPage; i+= 1 ) {
          if (i > 0)
            range.push({name: i, isDisabled: i === this.currentPage});
        }

        return range;
      },
      atFirstPage() {
        if (this.currentPage === 1) return true;
      },
      atLastPage() {
        if (this.currentPage === this.totalPages) return true;
      },
    },
    methods: {
      onClickFirstPage() {
        this.$emit('pagechanged', 1);
      },
      onClickPreviousPage() {
        this.$emit('pagechanged', this.currentPage - 1);
      },
      onClickPage(page) {
        this.$emit('pagechanged', page);
      },
      onClickNextPage() {
        this.$emit('pagechanged', this.currentPage + 1);
      },
      onClickLastPage() {
        this.$emit('pagechanged', this.totalPages);
      },
      isPageActive(page) {
        return this.currentPage === page;
      },
    }
  }
</script>
