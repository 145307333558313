<template>
  <div class="modal fade" id="modal_cropper"
       data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="modalCropperLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalCropperLabel">{{ title }}</h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="loading-eye" v-show="imgSrc.loading"></div>
          <cropper ref="imgCrop" id="imgCrop" class="cropper" :class="{'min-height': imgSrc.loading}" :src="imgSrc.src" background-class="cropper__background"
                   :stencil-props="cropperOptions" :minWidth="minWidth" :minHeight="minHeight" @ready="readyImg" @change="refreshCropper"/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="modal" v-show="cancelButton" @click="doCancel">{{ cancelButton }}</button>
          <div v-show="!modalType">
            <label for="file-upload" class="btn btn-success">
              <i class="glyphicon glyphicon-upload"></i>
              {{ $t('clinic.selectFile') }}
            </label>
            <input id="file-upload" type="file" @change="changeImg"/>
          </div>
          <button type="button" class="btn btn-danger" name="button" data-bs-dismiss="modal" @click="doRemove" v-show="removeButton && imgSrc.src">{{ $t('remove') }}</button>
          <button type="button" class="btn btn-primary" :class="okButtonClass" data-bs-dismiss="modal" @click="doConfirm">{{ okButton }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-header {
      background-color:               $phelcom-light-blue;
    }
    .modal-footer {
      > button {
        margin:                       5px;
      }
      div {
        margin:                       5px 10px;
        display:                      inline-block;
      }
      input[type="file"] {
        display:                      none;
      }
      label {
        margin:                       0;
      }
    }
    .modal-body {
      .loading-eyer {
        width:                        200px;
        height:                       200px;
        margin:                       0 auto;
        display:                      block;
      }
      .min-height {
        height:                       1px;
      }
    }
  }

  @media (max-width: 500px) {
    .modal-footer {
      text-align:                     center;
    }
  }
</style>

<script>
export default {
  name: 'BaseCropperModal',
  data: () => ({
    modalType: null,
    title: null,
    imgSrc: {
      name: null,
      src: null,
      type: null,
      loading: false,
    },
    okButton: null,
    okButtonClass: null,
    removeButton: false,
    cancelButton: null,
    cancelButtonClass: null,
    cropperOptions: {
      class: 'cropper-background',
      aspectRatio: 2/1,
    },
    minWidth: 0,
    minHeight: 0,
    modal_cropper: null,
  }),
  watch: {
		'imgSrc.src'(value) {
      console.log('watch', value);
			if (value) {
				this.imgSrc.loading = true;
        console.log('this.imgSrc.loading watch', this.imgSrc.loading);
        // let cropWidth = document.getElementById('imgCrop').innerWidth
        // console.log('cropWidth', cropWidth);
        // setTimeout(() => {
        //   this.imgSrc.loading = false
        // }, 1000)
			}
		}
	},
  mounted() {
  },
  methods: {
    show(options = {}) {
      this.modalType = options.modalType
      this.title = options.title
      this.imgSrc.src = options.imgSrc
      // this.loadImage(options.imgSrc)
      // this.imgSrc.src = URL.createObjectURL(options.imgSrc);
      this.okButton = options.okButton
      this.okButtonClass = options.okButtonClass
      this.removeButton = options.removeButton

      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      // if (options.aspectRatio != null)
      this.cropperOptions.aspectRatio = options.aspectRatio == null ? 2/1 : options.aspectRatio

      if (options.minHeight)
        this.minHeight = options.minHeight

      if (options.minWidth)
        this.minWidth = options.minWidth

      this.modal_cropper = new bootstrap.Modal(document.getElementById("modal_cropper")).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.modal_cropper = null;
      this.resolvePromise(this.saveImg())
    },

    doCancel() {
      this.modal_cropper = null;
      this.resolvePromise(false)
    },

    doRemove() {
      this.modal_cropper = null;
      this.removeSignature = true;
      this.resolvePromise(this.saveImg())
    },

    refreshCropper() {
      this.loading = false
      this.removeSignature = false
      if (this.loopCounter > 2) {
        return
      }
      this.loopCounter++;
      this.$refs.imgCrop.refresh();
    },

    changeImg(event) {
      const file = event.target.files[0]
      console.log(file, ' this file ', /^image\/\w+/.test(file.type));

      if (!file) {
        console.log('Please choose an image file.');
        return false;
      }

      if (/^image\/\w+/.test(file.type)) {
        this.imgSrc.name = file.name
        this.imgSrc.type = file.type
        this.imgSrc.src = URL.createObjectURL(file);
      }

      if (!this.showSaveButton)
        this.showSaveButton = !this.showSaveButton;
    },

    saveImg() {
      let vm = this;
      let dataFile = [];

      NProgress.start()
			try {
        const {canvas} = this.$refs.imgCrop.getResult();
        let params = {
          coordinates: this.$refs.imgCrop.getResult().coordinates,
          canvas: canvas,
          removeSignature: this.removeSignature ? 'yes' : null
        }

        return params
      } catch (e) {
        console.log(e);
      }
      NProgress.done(true)

      this.refreshCropper()
		},

    readyImg() {
			console.log('READYYYYYYYYYYYYYY Image is successfully loaded');
			this.imgSrc.loading = false;
      console.log('this.imgSrc.loading ready', this.imgSrc.loading);
		}
  },
  unmounted() {
    this.cropperOptions.aspectRatio = 2/1
    this.imgSrc.src = null
    this.loopCounter = 0
  }
}
</script>
