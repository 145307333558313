<template>
  <div class="">
    <div class="mb-3 row">
      <label for="firstName" class="col-md-3 col-form-label">{{ $t('createAccount.name') }}*</label>
      <div class="col-md-6">
        <vee-field name="firstName" v-model="account.firstName" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="firstName" id="firstName">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="lastName" class="col-md-3 col-form-label">{{ $t('createAccount.surname') }}*</label>
      <div class="col-md-6">
        <vee-field name="lastName" v-model="account.lastName" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" id="lastName">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="cpf" class="col-md-3 col-form-label">{{ $t('user.document') }}*</label>
      <div class="col-md-6">
        <vee-field name="document" v-model="account.document" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" id="document" v-maska :data-maska="$t('inputMask.document2')">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="crm" class="col-md-3 col-form-label">{{ $t('report.npi') }}</label>
      <div class="col-md-6">
        <input class="form-control" type="text" name="crm" v-model="account.npi">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationUserFormStepOne',
  props: ['account'],
  mounted() {
    this.$nextTick(() => {
      this.$refs.firstName.focus()
    })
  }
}
</script>
