<template>
  <div class="create-clinic-box" v-loader.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(doSave)" @keydown.enter="$event.preventDefault()">
        <div class="action-buttons rounded text-end">
          <button class="btn btn-outline-danger" type="button" name="button" @click="$router.back()">
            <i class="fa fa-ban"></i>
            {{ $t('cancel') }}
          </button>
          <button class="btn btn-outline-primary" type="submit" name="button">
            <i class="fa fa-check"></i>
            {{ $t('save') }}
          </button>
        </div>

        <PatientFormData v-if="patient != null"
          :patient="patient"
          :rightDiopter="rightDiopter"
          :leftDiopter="leftDiopter"
          :underlying="underlying"
          :ophthalmic="ophthalmic"
          :mandatoryDocument="mandatoryDocument"
          :disabled="false">
        </PatientFormData>
      </form>
    </vee-form>

  </div>
</template>

<style lang="scss" scoped>
.action-buttons {
  padding-top:                   10px;
}
</style>

<script>
import PatientFormData from '../PatientFormData.vue'

export default {
  name: 'PatientCreateTab',
  components: {
    PatientFormData
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    patient: {
      id: null,
      firstName: '',
      lastName: '',
      mrn: '',
      birthday: '',
      birthdayAux: '',
      document0: '',
      document1: '',
      document2: '',
      document3: '',
      mothersName: '',
      gender: 'M',
      weight: '',
      height: '',
      telephone1: '',
      telephone2: '',
      email: '',
      address: '',
      addressNumber: '',
      district: '',
      postalCode: '',
      city: '',
      state: '',
      country: '',
      other: '',
    },
    rightDiopter: {
      spherical: '',
      cylindrical: '',
      axis: '',
    },
    leftDiopter: {
      spherical: '',
      cylindrical: '',
      axis: '',
    },
    underlying: {
      diabetes: false,
      hypertension: false,
      cholesterol: false,
      smoker: false,
      nwn_underlying: false,
    },
    ophthalmic: {
      diabeticRetinopathy: false,
      dmri: false,
      glaucoma: false,
      cataract: false,
      pterygium: false,
      lowVisualAcuity: false,
      nwn_ophthalmic: false,
    },
    mandatoryDocument: null,
  }),
  mounted() {
  },
  methods: {
    async doSave() {
      try {
        this.alert.message = null;

        // TODO FIX check empty mandatory document on clinic configuration (match Eyer attribute)
        const mandatoryDocumentWasSet = !_.isEmpty(this.mandatoryDocument) && this.mandatoryDocument != 'none'
        if (mandatoryDocumentWasSet) {
          if (_.isEmpty(this.patient[this.mandatoryDocument]))
            return
        }

        this.patient.birthday = this.patient.birthdayAux

        let anamnesis = {}
        Object.assign(anamnesis, this.underlying)
        Object.assign(anamnesis, this.ophthalmic)

        let params = {
          patient: this.patient,
          anamnesis: anamnesis,
          rightDiopter: this.rightDiopter,
          leftDiopter: this.leftDiopter,
        }

        this.loading = true
        NProgress.start()
        let response = await this.$patientService.save(params)
        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')

        let data = this.$utils.dataQuery({
          patientName: response.result.rawName
        })

        this.$router.push({name: 'patient-show', params: {patientID: response.result.id}, query: {tab: 'data', data: data}})
      } catch(err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
  }
}
</script>
