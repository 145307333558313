<template>
  <div class="modal fade" id="modal_permissions" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="modalInvitationLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalInvitationLabel">{{ $t('user.changePermission') }}</h5>
          <button id="closeBtnPermissions" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </div>
        <div class="modal-body">
          <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
            <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
          </div>

          <form class="">
            <div class="row">
              <div class="col-md-6">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="permissionType" id="adminPermission" value="admin" v-model="permissionType">
                  <label class="form-check-label" for="adminPermission">
                    {{ $t('admin') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="permissionType" id="physicianPermission" value="physician" v-model="permissionType">
                  <label class="form-check-label" for="physicianPermission">
                    {{ $t('physician') }}
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="permissionType" id="technicianPermission" value="technician" v-model="permissionType">
                  <label class="form-check-label" for="technicianPermission">
                    {{ $t('technician') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="permissionType" id="specialistPermission" value="specialist" v-model="permissionType">
                  <label class="form-check-label" for="specialistPermission">
                    {{ $t('specialist') }} <span>*</span>
                  </label>
                </div>
              </div>
              <div class="col-12">
                <small>{{ $t('user.informNonAccessibleData') }}</small>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="close()">{{ $t('user.cancel') }}</button>
          <button type="submit" class="btn btn-primary" @click="doChange">{{ $t('save') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-dialog {
      .modal-header {
        background-color:                  $phelcom-light-blue;
      }
      .modal-body {
        small,
        .form-check label span {
          color:                           $pastel-red;
        }
        .form-check {
          label {
            font-weight:                   normal;
            color:                         unset;
            text-align:                    left;
          }
          input {
            margin-top:                    3px;
          }
        }
        h5 {
          font-size:                      15px;
          &:first-child {
            margin:                       5px 0 15px;
          }
        }
      }
    }
  }
</style>

<script>
export default {
  name: 'UserPermissionsModal',
  props: ['user'],
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    permissionType: 'admin',
  }),
  mounted() {
    if (this.user) {
      this.permissionType = this.user.permissions
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async doChange() {
      NProgress.start()
      try {
        await this.$userService.changePermission(this.user.id, this.permissionType);
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')


        this.$nextTick(() => {
          $('#closeBtnPermissions').click();
        });
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
  },
  unmounted() {
    this.permissionType = 'admin'
  }
}
</script>
