<template>
  <div class="">
    <div class="mb-3 row">
      <label for="email" class="col-md-3 col-form-label">{{ $t('user.email') }}</label>
      <div class="col-md-6">
        <input class="form-control" type="text" id="email" name="email" v-model="currentEmail" readonly>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="password" class="col-md-3 col-form-label">{{ $t('user.password') }}*</label>
      <div class="col-md-6">
    <!--
        <vee-field name="password" v-model="account.password" rules="required|min:8" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="password" class="form-control" id="password" ref="password">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
    -->
        <vee-field name="password" v-model="account.password" rules="required|min:4" v-slot="{ field, errorMessage }">
            <input v-bind="field" :type="showPassword ? 'text' : 'password'"
                new-password class="form-control" ref="password" id="password" @keyup="checkPassword(account.password)">

            <button class="no-btn" type="button" name="button" @click="showPassword = !showPassword">
                <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
            <small class="strength-password" :class="strengthInfo" v-show="strengthInfo">{{ strengthInfo ? $t(`strengthPassword.${strengthInfo}`) : "" }}</small>

            <span class="vee-error">{{ errorMessage ? errorMessage : $t(`${strengthPasswordMessage}`) }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="confirmPassword" class="col-md-3 col-form-label">{{ $t('user.passwordConfirmation') }}*</label>
      <div class="col-md-6">    
    <!--
        <vee-field name="confirmPassword" v-model="account.confirmPassword" rules="required|confirmed:password" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="password" class="form-control" id="confirmPassword">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
    -->
        <vee-field name="confirmPassword" v-model="account.confirmPassword" rules="required|confirmed:password" v-slot="{ field, errorMessage }">
            <input v-bind="field" :type="showConfirmPassword ? 'text' : 'password'"
                   class="form-control" ref="confirmPassword" id="confirmPassword">
            <button class="no-btn" type="button" name="button" @click="showConfirmPassword = !showConfirmPassword">
              <i class="fa" :class="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
            </button>
            <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="pin" class="col-md-3 col-form-label">{{ $t('user.devicePIN') }}*</label>
      <div class="col-md-6">
        <vee-field name="pin" v-model="account.pin" rules="required|min:4" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="pin" id="pin" v-only-numbers>
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-control {
  position:                     relative;
}

.no-btn {
  position:                     absolute;
  font-size:                    1.4em;
  top:                          3px;
  right:                        27px;
  padding:                      0;
}

.strength-password {
  position:                     absolute;
  color:                        white;
  font-size:                    0.7em;
  text-align:                   center;
  height:                       12px;
  width:                        calc(100% - 24px);
  top:                          -10px;
  &.danger {
    background:                 $phelcom-red;
  }
  &.warning {
    background:                 $yellow-phelcom;
  }
  &.success {
    background:                 $phelcom-green;
  }
}
</style>

<script>
import utilService from '../../../../services/util-service.js';

export default {
  name: 'RegistrationUserFormStepThree',
  props: ['account', 'email'],
  data: () => ({
    currentEmail: "",
    showPassword: false,
    showConfirmPassword: false,
    password: "",
    confirmPassword: "",
    strengthPasswordMessage: 'zxcvbnMessages.default',
    strengthInfo: null,
  }),
  mounted(){
     this.disableCopyPaste();
  },
  created() {
    this.currentEmail = this.email
    this.$nextTick(() => {
      this.$refs.password.focus()
    })
  },
  methods: {
    async checkPassword(password) {
      console.log('this.account = ', this.account)
      //console.log('password= ', password)
      this.account.password = password
      this.account.email = this.email
      let params = {
        account: this.account
      }

      let isValidPassword = await utilService.validatePassword(params.account)

      switch (isValidPassword.score) {
        case 0:
        case 1:
        case 2:
          this.strengthInfo = 'danger'
          break;
        case 3:
          this.strengthInfo = 'warning'
          break;
        case 4:
          this.strengthInfo = 'success'
          break;
      }

      if (isValidPassword.valid) {
        this.strengthPasswordMessage = 'zxcvbnMessages.default'
      } else {
        this.strengthPasswordMessage = isValidPassword.warning ? 'zxcvbnMessages.warnings.' + isValidPassword.warning :
                                                            'zxcvbnMessages.suggestions.' + isValidPassword.suggestion[0]
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirm() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    disableCopyPaste() {
      let inputArray = [this.$refs.password, this.$refs.confirmPassword, this.$refs.pin]
      inputArray.forEach((element) => {
        element.addEventListener('contextmenu', (e) => e.preventDefault()); // Disable right-click menu
        element.addEventListener('cut', (e) => e.preventDefault()); // Disable cut
        element.addEventListener('copy', (e) => e.preventDefault()); // Disable copy
        element.addEventListener('paste', (e) => e.preventDefault()); // Disable paste
      })
    },
  }
}
</script>
