<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form ref="patientForm" @submit.prevent.stop="handleSubmit(doUpdate)">
        <div class="action-buttons rounded" :class="isPowerfull ? 'text-end': ''">
          <BaseFeature feature="iaAdvancedFunctions">
            <button class="btn btn-outline-primary" :class="isPowerfull ? 'float-start': ''" type="button" name="button" @click="toPatientProgression">
              <span>{{ $t("exam.examTypeName_patient_progression") }}</span>
            </button>
          </BaseFeature>

          <!-- <BaseFeature feature="patientAccess"> -->
            <button class="btn btn-outline-primary" :class="isPowerfull ? 'float-start': ''" type="button" name="button" @click="shareInformation" v-show="!isSpecialist">
              <span>{{ $t('externalPatient.share') }}</span>
            </button>
          <!-- </BaseFeature> -->

          <button class="btn btn-outline-danger" v-show="isPowerfull" type="button" name="button" @click="openModalDelete">
            <i class="fa fa-trash-o"></i>
            <span>{{ $t("remove") }}</span>
          </button>
          <button class="btn btn-outline-danger" v-show="!disabled" type="button" name="button" @click="doCancel">
            <i class="fa fa-ban"></i>
            <span>{{ $t("cancel") }}</span>
          </button>
          <button class="btn btn-outline-warning" v-show="disabled && isPowerfull" type="button" name="button" @click="disabled = !disabled">
            <i class="fa fa-pencil"></i>
            <span>{{ $t("edit") }}</span>
          </button>
          <button class="btn btn-outline-success" v-show="!disabled" type="submit" name="button">
            <i class="fa fa-check"></i>
            <span>{{ $t("updateAction") }}</span>
          </button>
        </div>

        <PatientFormData v-if="patient != null"
          :patient="patient"
          :rightDiopter="rightDiopter"
          :leftDiopter="leftDiopter"
          :underlying="underlying"
          :ophthalmic="ophthalmic"
          :disabled="disabled">
        </PatientFormData>
      </form>
    </vee-form>

    <teleport to="body">
      <BaseGenericModal :modal_id="'modalPatientInfoGeneric'" ref="modalGeneric"></BaseGenericModal>
      <BasePatientAccessInfoModal :patient="patient" v-if="showAccessInfoModal" @close="showAccessInfoModal = false"></BasePatientAccessInfoModal>
    </teleport>
  </div>
</template>

<script>
import { useUserStore } from '@/store/user'
import PatientFormData from '../PatientFormData.vue'

export default {
  name: 'patient-create',
  props: ['patient_id'],
  components: {
    PatientFormData
  },
  data: () => ({
    loading: false,
    disabled: false,
    alert: {
      message: null,
      class: null,
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isSpecialist: null,
    isPowerfull: null,
    isEditionMode: false,
    patient: {
      id: null,
      firstName: '',
      lastName: '',
      mrn: '',
      birthday: '',
      birthdayAux: '',
      document0: '',
      document1: '',
      document2: '',
      document3: '',
      mothersName: '',
      gender: 'M',
      weight: '',
      height: '',
      telephone1: '',
      telephone2: '',
      email: '',
      address: '',
      addressNumber: '',
      district: '',
      postalCode: '',
      city: '',
      state: '',
      country: '',
      other: '',
    },
    rightDiopter: {
      spherical: '',
      cylindrical: '',
      axis: '',
    },
    leftDiopter: {
      spherical: '',
      cylindrical: '',
      axis: '',
    },
    underlying: {
      diabetes: false,
      hypertension: false,
      cholesterol: false,
      smoker: false,
      nwn_underlying: false,
    },
    ophthalmic: {
      diabeticRetinopathy: false,
      dmri: false,
      glaucoma: false,
      cataract: false,
      pterygium: false,
      lowVisualAcuity: false,
      nwn_ophthalmic: false,
    },
    mandatoryDocument: null,
    showAccessInfoModal: false,
    loadedPatient: null
  }),
  watch: {
    patient_id: {
      immediate: true,
      handler: function (patient_id) {
        if (patient_id) {
          this.disabled = true
          this.isEditionMode = true
          this.fetchData()
        }
    }, deep: true }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    // console.log('this.session', this.session);
    this.isSpecialist = this.session.isSpecialist
    this.isPowerfull = this.session.isPowerfull

    this.mandatoryDocument = this.session.currentClinic.mandatoryDocument
  },
  methods: {
    doCancel() {
      if (!this.disabled) {
        this.disabled = !this.disabled;
        // temporary solution to clear fields that have been changed
        this.fetchData()
      } else {
        this.$router.push({name: 'patient-list'})
      }
    },
    async fetchData() {
      this.alert.message = null

      let params = {
        id: this.patient_id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getPatient(params)
        this.patient = response.patient
        this.loadedPatient = response.patient

        const DATE_FORMAT  = this.$i18n.t('dateFormat')
        this.patient.birthdayAux = this.patient.birthday ? moment.utc(this.patient.birthday).format(DATE_FORMAT) : ""
        console.log('DATE_FORMAT', DATE_FORMAT);

        this.rightDiopter = response.diopter.right
        this.leftDiopter = response.diopter.left

        let anamnesis = response.patient.anamnesis ? response.patient.anamnesis : {}

        for (let key of Object.keys(this.ophthalmic)) {
          if (key in anamnesis)
            this.ophthalmic[key] = anamnesis[key]
        }

        for (let key of Object.keys(this.underlying)) {
          if (key in anamnesis)
            this.underlying[key] = anamnesis[key]
        }

        let rawName = this.patient.firstName + ' ' + this.patient.lastName
        this.$emit('patient_name', rawName)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doUpdate() {
      try {
        this.alert.message = null;

        // TODO FIX check empty mandatory document on clinic configuration (match Eyer attribute)
        const mandatoryDocumentWasSet = !_.isEmpty(this.mandatoryDocument) && this.mandatoryDocument != 'none'
        if (mandatoryDocumentWasSet) {
          if (_.isEmpty(this.patient[this.mandatoryDocument]))
            return
        }

        this.patient.birthday = this.patient.birthdayAux

        let anamnesis = {}
        Object.assign(anamnesis, this.underlying)
        Object.assign(anamnesis, this.ophthalmic)

        let params = {
          patient: this.patient,
          anamnesis: anamnesis,
          rightDiopter: this.rightDiopter,
          leftDiopter: this.leftDiopter,
        }

        this.loading = true
        NProgress.start()
        let response = await this.$patientService.updatePatient(params)
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
        this.disabled = true
        this.isEditionMode = true
        this.fetchData()

        let data = this.$utils.dataQuery({
          patientName: response.result.rawName
        })

        this.$router.push({name: 'patient-show', params: {patientID: response.result.id}, query: {tab: 'data', data: data}})
      } catch(err) {
        console.log(err, 'err catc');
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async openModalDelete() {
      const ok = await this.$refs.modalGeneric.show({
        modalType: 'danger',
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('patient.removalConfirmation'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.doDelete()
      }
    },
    async doDelete() {
      this.alert.message = null

      let params = {
        id: this.patient_id
      }
      this.loading = true
      NProgress.start()
      try {
        await this.$patientService.deletePatient(params)
        this.$message.popup(this, 'general.removedSuccess', 'operationSuccess', 'success')

        this.$router.push({name: 'patient-list', params: {patientID: this.patient_id}})
      } catch (err) {
        this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.removedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toPatientProgression() {
      this.$router.push({name: 'patient-progression', params: {patientID: this.patient_id}})
    },
    shareInformation() {
      this.showAccessInfoModal = true

      setTimeout(function() {
        let access_info_modal = document.getElementById('access_info_modal');
        new bootstrap.Modal(access_info_modal).show();
      }, 100);
    }
  }
}
</script>
