<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="action-buttons rounded text-end">
      <button class="btn btn-outline-success" type="button" name="button" @click="doSave">
        <i class="fa fa-check"></i>
        <span>{{ $t("save") }}</span>
      </button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h3 class="sub-title">{{ $t('clinicConfig.preAssessment') }}</h3>

        <div class="form-group">
          <label for="selectedTrigger">{{ $t('clinicConfig.trigger') }}</label>
          <v-select
            class="v-select form"
            v-model="preAnalysisOptions.method"
            :reduce="x => x.value"
            :options="triggerOptions">
          </v-select>
        </div>


        <div class="form-group" v-if="preAnalysisOptions.method == 'threshold'">
          <label for="ruleSelected">{{ $t('clinicConfig.rule') }}</label>
          <v-select
            class="v-select form"
            v-model="preAnalysisOptions.rule"
            :reduce="x => x.value"
            :options="ruleOptions">
          </v-select>
        </div>

        <div class="form-group" v-if="preAnalysisOptions.method == 'mcRAS'">
          <label for="">{{ $t('clinicConfig.mcRAS') }}</label>
          <v-select
            multiple
            class="v-select form grown"
            v-model="preAnalysisOptions.classes"
            :reduce="x => x.value"
            :options="mcRASOptions">
          </v-select>
        </div>

        <div class="form-group" v-if="preAnalysisOptions.method">
          <label for="alteredEye" class="">{{ $t('clinicConfig.threshold') }}</label>
          <input class="form-control" type="text" id="alteredEye" v-model="preAnalysisOptions.threshold" :disabled="disabled">
        </div>

        <hr>

        <div class="form-group">
          <label for="forwardedSelected" class="">{{ $t('clinicConfig.referred') }}</label>
          <v-select
            class="v-select form"
            v-model="preAnalysisOptions.autoReferrerMode"
            :reduce="x => x.value"
            :options="forwardedOptions">
          </v-select>
        </div>
        <div class="form-group">
          <label for="nextScreen" class="">{{ $t('clinicConfig.screenFlow') }}</label>
          <v-select
            class="v-select form"
            v-model="preAnalysisOptions.nextScreen"
            :reduce="x => x.value"
            :options="flowOptions">
          </v-select>
        </div>
        <div class="form-group">
          <label for="alertMode" class="">{{ $t('clinicConfig.alert') }}</label>
          <v-select
            class="v-select form"
            v-model="preAnalysisOptions.alertMode"
            :reduce="x => x.value"
            :options="alertOptions">
          </v-select>
        </div>
      </div>
      <div class="col-md-6">
        <h3 class="sub-title flex-between">
          {{ $t('clinicConfig.externalNotification') }}
          <button class="btn btn-outline-primary btn-sm" type="button" name="button" @click="openModalNotification">
            <i class="fa fa-plus"></i>
          </button>
        </h3>
        <template v-if="notifications.length > 0">
          <BaseAccordion v-for="(item, index) in notifications" :key="index">
            <template v-slot:header>
              <h4>{{ item.name }}</h4>
            </template>
            <template v-slot:content>
              <div class="action-buttons rounded text-end">

                <button class="btn btn-outline-danger" type="button" name="button" @click="confirmNotificationRemoval(item)">
                  <i class="fa fa-ban"></i>
                  <span>{{ $t('remove') }}</span>
                </button>
                <button class="btn btn-outline-warning" type="button" name="button" @click="openModalNotification(item)">
                  <i class="fa fa-pencil"></i>
                  <span>{{ $t('edit') }}</span>
                </button>
              </div>
              <p>
                {{ $t('clinicConfig.type') }}:
                <span>{{ item.type }}</span>
              </p>
              <p>
                {{ $t('clinicConfig.trigger') }}:
                <span class="no-wrap">{{ item.trigger.type }} | {{ item.trigger.rule ? item.trigger.rule : item.trigger.classes.join(',') }} | {{ item.trigger.value }}</span>
              </p>

              <hr>

              <BaseTable class="white-table small" v-if="item.table" :tabledata="item.table" :key="index+1">
                <template #actiontwo="{item}">
                  <button class="btn btn-danger btn-sm" type="button" name="button" data-title="Delete" @click.stop="$emit('open-danger', item.id)">
                    <i class="fa fa-trash-o"></i>
                  </button>
                </template>
              </BaseTable>
            </template>
          </BaseAccordion>
        </template>
      </div>
    </div>

    <teleport to="body">
      <BaseGenericModal :modal_id="'modalClinicConfigGeneric'" ref="modalGeneric"></BaseGenericModal>

      <NewNotificationModal
        v-if="showModalNotification && clinic.id"
        :clinic="clinic"
        :selected_notification="selectedNotification"
        @close="closeModalNewNotification">
      </NewNotificationModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
  .accordion {
    .accordion-content {
      p {
        font-weight:              bold;
        display:                  inline-block;
        padding:                  5px 10px;
        margin-bottom:            0;
        span {
          font-weight:            normal;
          margin-left:            5px;
          float:                  right;
        }
      }
    }
    .action-buttons .btn {
      font-size:                  9px;
      padding:                    5px 10px 3px;
    }
    .accordion-header {
      h4 {
        font-size:                18px;
        display:                  inline-block;
        margin:                   0;
        line-height:              1.5;
        // vertical-align:           middle;
      }
    }
  }

  .col-md-6 > .sub-title .btn {
    font-size:                    12px;
    margin-bottom:                2px;
    padding:                      3px 10px;
  }
</style>

<script src="./ClinicConfigurationTab.js"></script>
