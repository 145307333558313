<template>
  <div class="medical-record-tabs">
    <div class="form-group">
      <label for="">{{ $t("medicalRecord.s4_e1") }}</label>
      <BaseQLEditor :content="medicalRecordData.s4.e1" @content-changed="setTextForS4E1"></BaseQLEditor>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'DiagnosticHypothesisSection',
  props: ["medicalRecordData"],
  data: () => ({
  }),
  mounted() {

  },
  methods: {
    setTextForS4E1(value) {
      this.medicalRecordData.s4.e1 = value
    }
  }
}
</script>
