<template>
  <div class="outside-box" :class="{full: step === 0}" v-loader.opacity="loading">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-10 col-lg-7">
        <div class="registration-form">
          <h2 class="registration-title">
            {{ $t('createAccount.clinicRegistration') }}
            <small>{{ $t('createAccount.firstAccessTitle') }}</small>
          </h2>

          <hr>

          <h4>{{ firstName }}</h4>
          <p>{{ $t('createAccount.completeTheSequence') }}</p>

          <ul class="wizard-step">
            <li :class="{active: step === 0, past: step > 0, full: step > 1}">
              <p>1</p>
              <small>{{ $t('createAccount.identification') }}</small>
            </li>
            <li :class="{active: step === 1, past: step > 1}">
              <p>2</p>
              <small>{{ $t('createAccount.localization') }}</small>
            </li>
            <li :class="{active: step === 2, past: step > 2}">
              <p>3</p>
              <small>{{ $t('createAccount.contact') }}</small>
            </li>
          </ul>

          <vee-form v-slot="{ handleSubmit }" as="div">
            <form @submit.prevent.stop="handleSubmit(doSave)">
              <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                <span v-html="alert.message"></span>
                <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
              </div>

              <div :key="0" v-if="step === 0">
                <h2>{{ $t('createAccount.identification') }}</h2>
                <RegistrationClinicStepOne :clinic="clinic"></RegistrationClinicStepOne>
              </div>

              <div :key="1" v-if="step === 1">
                <h2>{{ $t('createAccount.localization') }}</h2>
                <RegistrationClinicStepTwo :clinic="clinic"></RegistrationClinicStepTwo>
              </div>

              <div :key="2" v-if="step === 2">
                <h2>{{ $t('createAccount.contact') }}</h2>
                <RegistrationClinicStepThree :clinic="clinic"></RegistrationClinicStepThree>
              </div>

              <hr>

              <div class="action-buttons">
                <button class="btn btn-default" type="button" name="button" v-if="step > 0" @click="step -= 1">{{ $t('pagination.previous') }}</button>
                <button class="btn btn-primary" type="submit" name="button" v-if="step < 2">{{ $t('pagination.next') }}</button>
                <button class="btn btn-success" type="submit" name="button" v-if="step === 2" :disabled="loading">{{ $t('pagination.finish') }}</button>
              </div>
            </form>
          </vee-form>
        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<script>
import AuthFooter from '../../auth/AuthFooter.vue'
import AuthHeader from '../../auth/AuthHeader.vue'
import RegistrationClinicStepOne from './RegistrationClinicStepOne.vue'
import RegistrationClinicStepTwo from './RegistrationClinicStepTwo.vue'
import RegistrationClinicStepThree from './RegistrationClinicStepThree.vue'

export default {
  name: 'registration-clinic',
  components: {
    AuthFooter,
    AuthHeader,
    RegistrationClinicStepOne,
    RegistrationClinicStepTwo,
    RegistrationClinicStepThree,
  },
  data: () => ({
    alert: {
      message: null,
      class: null,
    },
    loading: false,
    step: 0,
    firstName: "",
    ticket: {},
    clinic: {
      clinicName: "",
      document: "",
      mandatoryDocument: 'none',
      locale: "pt-br",
      street: "",
      city: "",
      state: "",
      country: "",
      telephone: "",
      email: "",
      mobile: "",
      telephone3: ""
    }
  }),
  mounted() {
    try {
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      this.firstName = data.firstName
      this.ticket = data.ticket
    } catch(err) {
      // TODO show error asking for "checkURL and try again..."
    }
  },
  methods: {
    async doSave() {
      let params = {
        clinic: this.clinic,
        ticket: this.ticket
      }

      try {
        if (this.step != 2) {
          this.step += 1;
          return
        }

        this.loading = true
        NProgress.start()
        await this.$registrationService.createClinic(params)
        let data = this.$utils.dataQuery({
          firstName: this.firstName
        })
        this.$router.push({name: 'registration-clinic-created', query: { data: data} })
      } catch (err) {
        console.log(err) // TODO ENH EC-291 (for production is a must)
        let errorMessage = err.response.data.errorMessage == 'consumedTicket' ?
          err.response.data.errorMessage : 'general.createdError'

        this.$message.popup(this, errorMessage, 'operationFail', 'error')
        // this.alert = this.$message.alert(this, errorMessage, 'danger')
      }
      NProgress.done(true)
      this.loading = false
    }
  }
}
</script>
