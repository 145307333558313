<template>
  <div class="diagnosis-box" v-loader.opacity.tab="loading">
    <div class="title-box rounded">
      <h2 class="title">{{ $t('exam.reportList') }}</h2>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message || alert_msg.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <ul id="diagnosisList" class="report-list timeline" v-if="tableReport">
      <li v-for="(item, index) in tableReport" :key="index">
        <div class="tags-box">
          <span class="tag">{{ $filters.date(item.date) }}</span>

          <div class="tags">
            <button class="no-btn" type="button" name="button" @click="downloadPDF(item)">
              <i class="fa fa-file-pdf-o"></i>
              PDF
            </button>
          </div>
        </div>

        <div class="content-box">
          <div class="">
            <h2 class="title">
              <span v-if="item.isQuickReport"><i class='fa fa-rocket'></i> | </span>
              <span v-if="item.type == 'auto'"><i class='fa fa-magic'></i> | </span>
              {{ $t('exam.diagnosticHypothesis') }}
            </h2>
            <em>
              {{ $filters.time(item.createdAt) }} {{ $t('by') }}
              <span v-if="item.responsible">{{ item.responsible.lastName }}, {{ item.responsible.firstName }}</span>
              <span v-else>{{ $t('exam.notInformed') }}</span>
            </em>

            <div v-if="item.diagnosisReferences.length > 0 || item.diagnosis">
              <h5 class="bold">{{ $t('exam.diagnosis') }}</h5>
              <p v-if="item.diagnosisReferences.length > 0">
                <span class="label label-open" v-for="(diagnosis, indexD) in item.diagnosisReferences" :key="indexD">{{ diagnosis.type.key }} - {{ diagnosis.type.description }}</span>
              </p>
              <p v-if="item.diagnosis">{{ item.diagnosis }}</p>
            </div>

            <div v-if="item.observation">
              <h5 class="bold">{{ $t('exam.observations') }}</h5>
              <p class="no-wrap">{{ item.observation }}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.diagnosis-box {
  .title-box {
    border-bottom:                     2px solid $bright-gray;
    padding:                           1px 5px 6px;
    margin-bottom:                     10px;
    .title {
      width:                           auto;
      margin-right:                    20px;
    }
  }
}
</style>

<script>
export default {
  name: 'ExternalExamReportTab',
  props: ['exam_id', 'alert_msg'],
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableReport: null,
    report: null,
  }),
  watch: {
    alert_msg: function(newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
      } else {
        this.alert.message = null
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    setAlert(val) {
      this.alert = {
        message: val.message,
        class: val.class
      };
    },
    async fetchData() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$externalService.getReportList({id: this.exam_id});
        console.log('response', response);
        if (response.result.length === 0) {
          this.$message.popup(this, 'request_empty', 'operationWarning', 'warn')
          this.$emit('alertmsg', this.alert)
        }

        this.tableReport = response.result
      } catch(err) {
        console.log('err', err);
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
        // this.$emit('alertmsg', this.alert)
      }
      NProgress.done(true)
      this.loading = false
    },
    downloadPDF(item) {
      this.$externalService.generateReport(item.id);
    },
  },
  // unmounted() {
  //   this.alert.message = null
  // }
}
</script>
