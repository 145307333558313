<template>
  <div class="">
    <div class="row">
      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('patient.generalData') }}</h3>
        <div class="form-group">
          <label for="firstName">{{ $t('patient.firstName') }}*</label>
          <vee-field name="firstName" v-model="patient.firstName" rules="required" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="firstName" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="lastName">{{ $t('patient.lastName') }}*</label>
          <vee-field name="lastName" v-model="patient.lastName" rules="required" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="lastName" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="mrn">
            {{ $t('mrn') }}
            <span v-if="mandatoryDocument === 'mrn'">*</span>
          </label>
          <vee-field name="mrn" v-model="patient.mrn" :rules="`${mandatoryDocument === 'mrn' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="mrn" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="birthday">{{ $t('patient.birthday') }}</label>
          <vee-field name="birthday" v-model="patient.birthdayAux" :rules="`date:${$t('dateFormat')}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control"  v-maska :data-maska="patient.birthdayAux ? $t('inputMask.date') : ''" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
      </div>

      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('patient.documents') }}</h3>
        <div class="form-group">
          <label for="document0">
            {{ $t('document0') }}
            <span v-if="mandatoryDocument === 'document0'">*</span>
          </label>
          <vee-field name="document0" v-model="patient.document0" :rules="`${mandatoryDocument === 'document0' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document0" v-maska :data-maska="$t('inputMask.document0')" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="document1">
            {{ $t('document1') }}
            <span v-if="mandatoryDocument === 'document1'">*</span>
          </label>
          <vee-field name="document1" v-model="patient.document1" :rules="`${mandatoryDocument === 'document1' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document1" v-maska :data-maska="$t('inputMask.document1')" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="document2">
            {{ $t('document2') }}
            <span v-if="mandatoryDocument === 'document2'">*</span>
          </label>
          <vee-field name="document2" v-model="patient.document2" :rules="`${mandatoryDocument === 'document2' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document1" v-maska :data-maska="$t('inputMask.document2')" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
        <div class="form-group">
          <label for="document3">
            {{ $t('document3') }}
            <span v-if="mandatoryDocument === 'document3'">*</span>
          </label>
          <vee-field name="document3" v-model="patient.document3" :rules="`${mandatoryDocument === 'document3' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document3" v-maska :data-maska="$t('inputMask.document3')" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('patient.otherInfo') }}</h3>
        <div class="form-group">
          <label for="mothersName">{{ $t('patient.mothersName') }}</label>
          <input type="text" class="form-control" id="mothersName" v-model="patient.mothersName" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="gender">{{ $t('patient.gender') }}</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="patientGender" id="genderM" value="M" v-model="patient.gender">
            <label class="form-check-label" for="genderM">{{ $t('patient.male') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="patientGender" id="genderF" value="F" v-model="patient.gender">
            <label class="form-check-label" for="genderF">{{ $t('patient.female') }}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="weight">{{ $t('patient.weight') }}</label>
              <input type="text" class="form-control" id="weight" :placeholder="`(${$t('weightUnit')})`" v-model="patient.weight" v-only-numbers :disabled="disabled">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="height">{{ $t('patient.height') }}</label>
              <input type="text" class="form-control" id="height" :placeholder="`(${$t('heightUnit')})`" v-model="patient.height" v-only-numbers :disabled="disabled">
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="row">
          <!-- TODO componente -> dioptria: map{foo: bar, foo1: bar1}-->
          <div class="col-lg-6">
            <h3 class="sub-title">{{ $t('patient.rightEyeDiopter') }}</h3>
            <div class="form-group">
              <label for="right_spherical">{{ $t('spherical') }}</label>
              <input type="text" class="form-control" id="right_spherical" placeholder="0.00" v-maska maska-diopter v-model="rightDiopter.spherical" :disabled="disabled">
              <!-- <input type="text" class="form-control" id="right_spherical" placeholder="0.00" v-maska :data-maska="$t('inputMask.diopter')" v-model="rightDiopter.spherical" :disabled="disabled"> -->
            </div>
            <div class="form-group">
              <label for="right_cylindrical">{{ $t('cylindrical') }}</label>
              <input type="text" class="form-control" id="right_cylindrical" placeholder="0.00" v-maska maska-diopter v-model="rightDiopter.cylindrical" :disabled="disabled">
            </div>
            <div class="form-group">
              <label for="right_axis">{{ $t('axis') }}</label>
              <input type="text" class="form-control" id="right_axis" placeholder="0.00" v-maska :data-maska="$t('inputMask.axis')" v-model="rightDiopter.axis" :disabled="disabled">
            </div>
          </div>

          <div class="col-lg-6">
            <h3 class="sub-title">{{ $t('patient.leftEyeDiopter') }}</h3>
            <div class="form-group">
              <label for="left_spherical">{{ $t('spherical') }}</label>
              <input type="text" class="form-control" id="left_spherical" placeholder="0.00" v-maska maska-diopter v-model="leftDiopter.spherical" :disabled="disabled">
            </div>
            <div class="form-group">
              <label for="left_cylindrical">{{ $t('cylindrical') }}</label>
              <input type="text" class="form-control" id="left_cylindrical" placeholder="0.00" v-maska maska-diopter v-model="leftDiopter.cylindrical" :disabled="disabled">
            </div>
            <div class="form-group">
              <label for="left_axis">{{ $t('axis') }}</label>
              <input type="text" class="form-control" id="left_axis" placeholder="0.00" v-maska :data-maska="$t('inputMask.axis')" v-model="leftDiopter.axis" :disabled="disabled">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('patient.contact') }}</h3>
        <div class="form-group">
          <label for="telephone1">{{ $t('patient.telephone1') }}</label>
          <input type="text" class="form-control" id="telephone1" v-model="patient.telephone1" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="telephone2">{{ $t('patient.telephone2') }}</label>
          <input type="text" class="form-control" id="telephone2" v-model="patient.telephone2" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="email">{{ $t('createClinic.email') }}</label>
          <vee-field name="email" v-model="patient.email" rules="email" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" ref="email" id="email" :disabled="disabled">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="row">
          <PatientCreateUnderlyingDiseases :disabled="disabled" :underlying="underlying"></PatientCreateUnderlyingDiseases>
          <PatientCreateOphthalmicDiseases :disabled="disabled" :ophthalmic="ophthalmic"></PatientCreateOphthalmicDiseases>
          <div class="form-group">
            <label for="other">{{ $t('patient.other') }}</label>
            <input type="text" class="form-control" id="other" v-model="patient.otherDisease" :disabled="disabled">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('patient.localization') }}</h3>
        <div class="form-group">
          <label for="address">{{ $t('patient.address') }}</label>
          <input type="text" class="form-control" id="address" v-model="patient.address" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="addressNumber">{{ $t('patient.addressNumber') }}</label>
          <input type="text" class="form-control" id="addressNumber" v-model="patient.addressNumber" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="district">{{ $t('patient.district') }}</label>
          <input type="text" class="form-control" id="district" v-model="patient.district" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="postalCode">{{ $t('patient.postalCode') }}</label>
          <input type="text" class="form-control" id="postalCode" v-model="patient.postalCode" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="city">{{ $t('patient.city') }}</label>
          <input type="text" class="form-control" id="city" v-model="patient.city" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="state">{{ $t('patient.state') }}</label>
          <input type="text" class="form-control" id="state" v-model="patient.state" :disabled="disabled">
        </div>
        <div class="form-group">
          <label for="country">{{ $t('patient.country') }}</label>
          <input type="text" class="form-control" id="country" v-model="patient.country" :disabled="disabled">
        </div>
      </div>
      <div class="col-lg-6">
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.action-buttons {
  min-height:                  39px;
}

@media screen and (min-width: 992px) and (max-width: 1050px) {
  .sub-title {
    font-size:                 1em;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .panel-content .col-lg-6 .col-lg-6 .form-group label {
    width:                     100px;
  }
  .panel-content .col-lg-6 .col-lg-6 .form-group textarea,
  .panel-content .col-lg-6 .col-lg-6 .form-group input[type="text"] {
    width:                     calc(100% - 103px) !important;
  }
  .form-check-inline {
    width:                     100px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .panel-content .form-group label {
    width:                     110px;
  }
  .panel-content .form-group textarea,
  .panel-content .form-group input[type="text"],
  .vee-error {
    width:                     calc(100% - 110px) !important;
  }
}

@media (min-width: 1601px) {
  .vee-error {
    width:                     calc(100% - 190px) !important;
  }
}
</style>

<script>
  import { useUserStore } from '@/store/user'
  import {MaskInput} from 'maska'
  import PatientCreateOphthalmicDiseases from './PatientCreateOphthalmicDiseases.vue'
  import PatientCreateUnderlyingDiseases from './PatientCreateUnderlyingDiseases.vue'

  export default {
    name: 'PatientFormData',
    props: ['patient',
            'rightDiopter',
            'leftDiopter',
            'underlying',
            'ophthalmic',
            'disabled'
          ],
    components: {
      PatientCreateOphthalmicDiseases,
      PatientCreateUnderlyingDiseases,
    },
    data: () => ({
      mandatoryDocument: null,
      session: {
        collaborator: {},
        currentClinic: {}
      },
      // disabled: false,
    }),
    mounted() {
      const userStore = useUserStore()
      this.session = userStore.sessionInfo

      this.mandatoryDocument = this.session.currentClinic.mandatoryDocument
      // if (this.patient.id) {
        // this.disabled = true
      // }
      new MaskInput("[maska-diopter]", {
        mask: (val) => {
          if (val.includes('-')) {
            return val.length == 4 ? `-${this.$i18n.t('inputMask.shortDiopter')}` : `-${this.$i18n.t('inputMask.diopter')}`
          } else {
            return val.length == 3 ? this.$i18n.t('inputMask.shortDiopter') : this.$i18n.t('inputMask.diopter')
          }
          // return val.includes('-') ? `-${this.$i18n.t('inputMask.diopter')}` : this.$i18n.t('inputMask.diopter')
        }
      })
    },
    methods: {
    }
  }
</script>
