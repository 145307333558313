<template>
  <div class="" :key="examId" v-loader-eyerweb.opacity="loading">
    <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title sub" v-if="exam">{{ $t("exam.examData") }} |
        <span @click="toPatient">{{ patient.fullName }}</span>
      </h2>
    </div>

    <div>
      <div v-if="alert.message" class="alert alert-dismissible fade show" :class="alert.class" role="alert">
        <span v-html="alert.message"></span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="clearMessage"></button>
      </div>

      <div class="">
        <div class="text-end mb-2">
          <button type="button" name="button" class="btn btn-primary" :disabled="buttonEnable" @click="openModalConfirm">{{ $t('eyerweb.downloadImages') }}</button>
        </div>

        <div v-if="examImages">
          <lightgallery-vue class="image-info-box grid-wrap-between ease-all" ref="lGallery" id="lightgallery"
            :settings="lightGallerySettings" :onAfterOpen="extraCustomization" :onInit="onInit">
            <template v-for="(item, index) in examImages" :key="index">

              <a v-if="item.type.endsWith('VIDEO')" onclick="return false" class="thumbnail-box loading-eye"
                :class="item.type" :key="`video_${index}`" data-lg-size="1280-720" :data-video="getVideoSource(item)"
                data-download-url="" data-sub-html="<h4></h4><p></p>">
                <img class="lg-selector ease-all" src="@/assets/images/default-video2.png" alt=""
                  @load="removeLoading($event)" />
                <div class="thumb-actions">
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="doDownload(item)">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </a>

              <a v-else class="thumbnail-box loading-eye" onclick="return false" :class="item.type" :href="item.name">
                <img class="lg-selector ease-all" :src="item.name" alt="" @load="removeLoading($event)" />
                <div class="thumb-actions">
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="doDownloadItem(item)">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </a>
            </template>
          </lightgallery-vue>
        </div>
      </div>
    </div>

    <teleport to="body">
      <BaseGenericModal :modal_id="'modalDownloadGeneric'" ref="modalGeneric"></BaseGenericModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
  .title-box {
    .btn-back {
      margin-right:              20px;
    }
    .title.sub {
      cursor:                    pointer;
    }
  }
</style>

<script>
import { useEyerwebStore } from '@/store/eyerweb'

import EyerwebExamImageTab from './EyerwebExamImageTab.vue'
import examImagesResponse from '../../../../assets/eyerweb-data/exam-images-response.js'

import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from '@/assets/dependencies/lightGallery/plugins/zoom';
import isTestEnv from '@/assets/eyerweb-data/env';

const TAG = '[EyerwebExamShow]'

export default {
  name: 'eyerweb-exam-show',
  components: {
    EyerwebExamImageTab,
  },
  data: () => ({
    loading: true,
    alert: {
      message: null,
      class: null
    },
    buttonEnable: true,
    msgError: null,
    user: null,
    tabActive: 'images',
    tabs: ['images'],
    deleteID: null,
    exam: null,
    examImages: [],
    patient: {},
    selectedImages: [],
    lightGallerySettings: {
      showZoomInOutIcons: true,
      actualSize: false,
      autoplayVideoOnSlide: true,
      speed: 300,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [],
      download: false
    },
    lightGallery: null,
    examUuid: '',
    previous: null,
  }),
  watch: {
    examImages(newVal, oldVal) {
      this.$nextTick(() => {
        window.addEventListener("resize", this.getAndSetWidth);
        this.lightGallery.refresh();
      });
    },
  },
  computed:  {
    examId() {
      return this.$route.params.examID;
    },
    tab() {
      return this.$route.query.tab;
    },
  },
  created() {

    // watch param exam id coming from notifications to force reload data
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo
    this.$eyerwebService.imageSubject.subscribe(this.imageCallback)
    this.$eyerwebService.downloadSubject.subscribe(this.downloadCallback)
    window.addEventListener("resize", this.getAndSetWidth);
    this.lightGallerySettings.plugins = [lgAutoplay, lgFullscreen, lgThumbnail, lgVideo, lgZoom]

    this.examUuid = this.$route.params.examID

    this.$watch( // TODO REFACTOR KISS
      () => this.$route.params.examID,
      (toParams, previousParams) => {
        if (toParams != previousParams && this.$route.name == 'eyerweb-exam-show')
          this.fetchData()
      }
    )
  },
  async mounted() {
    NProgress.start()

    this.$activeLink.value = 'exam'
    await this.fetchData();

    if (this.tab) {
      this.switchTab(this.tab)
    }
  },
  methods: {
    onInit(detail) {
      this.lightGallery = detail.instance;
    },
    goBack() {

      if (this.$route.query.data) {
        let data = this.$utils.parseDataQuery(this.$route.query.data)
        this.previous = data.previous
        this.patient.fullName = data.patientName
        this.patient.patientID = data.patientID
      }

      if (this.previous == 'eyerweb-patient') {
        let data = this.$utils.dataQuery({
          patientName: this.patient.fullName
        })

        this.$router.push({name: 'eyerweb-patient-show', params: {patientID: this.patient.patientID}, query: {tab: 'exams', data: data}})
      } else {
        let data = this.$route.query.data
        this.$router.push({name: 'eyerweb-exam-list', query: {data: data}})
      }
      // let patientId = this.$route.params.patientID
    },
    switchTab(tab) {
      this.tabActive = tab;
      if (tab === 'images') {
        this.$router.push({name: 'eyerweb-exam-show', params: {examID: this.examId, tab: tab}})
      }
    },
    fetchData: async function() {
      this.loading = true

      this.$nextTick(() => {
        this.disableButtons()
      })

      try {
        if (isTestEnv)
          setTimeout(() => {
            this.examImages = examImagesResponse
          }, 3000)
        else {
          if (this.examUuid)
            this.$eyerwebService.getExam(this.examUuid);
        }
      } catch(err) {
        console.log('err: ', err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }

    },
    disableButtons(){
      this.buttonEnable = true
      document.querySelector('.menu-links li:first-child').classList.add('disabled')
      document.querySelector('.menu-links li:nth-child(2)').classList.add('disabled')
      document.querySelector('.btn-back').classList.add('disabled')
    },
    enableButtons(){
      this.buttonEnable = false
      document.querySelector('.menu-links li:first-child').classList.remove('disabled')
      document.querySelector('.menu-links li:nth-child(2)').classList.remove('disabled')
      document.querySelector('.btn-back').classList.remove('disabled')
    },
    imageCallback({ blob, fileType, fileName, isLast }) {
      NProgress.start()

      const uri = URL.createObjectURL(blob)
      const type = fileType === 'mp4' ? 'VIDEO' : 'IMAGE'

      this.examImages.push({ name: uri, type, blob, fileType, fileName })

      // depois da primeira imagem, cancelar o loading.
      this.loading = false

      if (isLast){
        NProgress.done(true) 
        this.enableButtons()
      }

      this.$nextTick(() => {
        window.addEventListener("resize", this.getAndSetWidth);
        this.lightGallery.refresh();
      })

    },
    downloadCallback(result) {
      console.log('downloadCallback')
      this.enableButtons()
      NProgress.done(true)
      this.loading = false

    },
    async doDownload(item) {
      console.log('doDownload')
      this.loading = true
      NProgress.start()

      try {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(item.blob);
        a.href = url;
        a.download = `${item.fileName}.${item.fileType}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove()

      } catch (err) {
        console.log('err: ', err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      //NProgress.done(true)
      //this.loading = false

    },
    async doDownloadItem(item) {
      console.log('doDownloadItem')
      try {
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(item.blob);
        a.href = url;
        a.download = `${item.fileName}.${item.fileType}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove()
      } catch (err) {
        console.log('err: ', err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    },
    extraCustomization() {
      //let self = this
      //$(".lg-download").on("click", function (event) {
      //  event.preventDefault();
      //  let examDataUUID = this.href.split('/').pop()
      //  let item = { uuid: examDataUUID }
      //  self.doDownload(item)
      //})

      $('.lg-fullscreen.lg-icon').click();
    },
    getAndSetWidth() {
      let element = document.querySelectorAll('.image-info-box > .thumbnail-box');
      if (element.length) {
        let cw = element[0].offsetWidth;
        element.forEach(o => {
          o.style.height = cw + 'px';
        });
      }
    },
    toPatient() {
      this.$router.push({name: 'eyerweb-patient-show', params: {
        info: true,
        patientID: this.patient.id,
        patientName: this.patient.rawName,
        tab: 'data'
      }})
    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;

      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
        this.getAndSetWidth();
      });
    },
    getVideoSource(item) {
      let result = {
        "source": [
          {
            "src": item.name,
            "type": "video/mp4"
          }
        ],
        "attributes": {
          "preload": false,
          "playsinline": false,
          "controls": true,
          "loop": true
        }
      }

      return JSON.stringify(result)
    },
    async downloadImages() {
      console.log('call DownloadImages')
      this.disableButtons()
      this.loading = true
      NProgress.start()

      try {
        //const eyerwebStore = useEyerwebStore()
        this.$eyerwebService.downloadExamsFiles([this.examUuid]);
      } catch (err) {
        console.log('err: ', err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
      //this.loading = false
      //NProgress.done(true)
    },
    locale() {
      let locale = this.session.currentClinic.locale
      if (locale){
        if (locale == 'pt-br') {
          return 'pt-BR'
        } else if (locale == 'es') {
          return 'es'
        } else {
          return 'en-US'
        }
      }
      return 'en-US'
    },
    async openModalConfirm() {
      const ok = await this.$refs.modalGeneric.show({
        modalType: 'warning',
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('exam.confirmImageDownload'),
        okButton: this.$i18n.t('yes'),
        okButtonClass: 'btn-primary',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.downloadImages()
      }
    }
  },
  unmounted() {
    // this.tabActive = 'info'
    window.removeEventListener("resize", this.getAndSetWidth);
    this.$eyerwebService.imageSubject.unsubscribe(this.imageCallback)
    this.$eyerwebService.imageSubject.unsubscribe(this.downloadCallback)
  }
}
</script>
