<template>
  <div class="" v-loader.opacity.tab="loading">
    <ul id="diagnosisList" class="report-list timeline" v-if="tableReport">
      <li v-for="(report, index) in tableReport" :key="index">
        <div class="tags-box">
          <span class="tag">{{ $filters.date(report.date) }}</span>

          <div class="tags">
            <button class="no-btn" type="button" name="button" v-show="report.type != 'general'" @click="openModalImages(report)">
              <i class="fa fa-picture-o"></i>
              {{ $t('images') }}
            </button>
            <button class="no-btn" type="button" name="button" @click="downloadPDF(report)">
              <i class="fa fa-file-pdf-o"></i>
              PDF
            </button>
            <!-- <button class="no-btn pdf" type="button" name="button" @click="generateUrlPDF(report)">
              <i class="fa fa-file-pdf-o"></i>
              Preview
            </button> -->
          </div>
        </div>

        <div class="content-box">
          <div class="">
            <h2 class="title">
              <span v-if="report.isQuickReport"><i class='fa fa-rocket'></i> | </span>
              <span v-if="report.type == 'auto'"><i class='fa fa-magic'></i> | </span>
              {{ $t('exam.diagnosticHypothesis') }}
            </h2>
            <em>
              {{ $filters.time(report.createdAt) }} {{ $t('by') }}
              <span v-if="report.responsible">{{ report.responsible.lastName }}, {{ report.responsible.firstName }}</span>
              <span v-else>{{ $t('exam.notInformed') }}</span>
            </em>

            <div v-if="report.diagnosisReferences.length > 0 || report.diagnosis">
              <h5 class="bold">{{ $t('exam.diagnosis') }}</h5>
              <p v-if="report.diagnosisReferences.length > 0">
                <span class="label label-open" v-for="(diagnosis, indexD) in report.diagnosisReferences" :key="indexD">{{ diagnosis.type.key }} - {{ diagnosis.type.description }}</span>
              </p>
              <p v-if="report.diagnosis">{{ report.diagnosis }}</p>
            </div>

            <div v-if="report.observation">
              <h5 class="bold">{{ $t('exam.observations') }}</h5>
              <p class="no-wrap">{{ report.observation }}</p>
            </div>
          </div>

          <button class="btn btn-danger btn-sm" type="button" name="button" data-title="Delete" @click="openModalDelete(report.id)">
            <i class="fa fa-trash-o"></i>
          </button>

          <button @click="editReport(report)" class="btn btn-info btn-sm" :data-title="$t('editAction')">
            <i role="link" class="fa fa-pencil"></i>
          </button>
        </div>
      </li>
    </ul>

    <teleport to="body">
      <ExamReportImagesModal v-if="showModalImages" :reportid="report.id" :selectedimages="report.images" :key="report"
                           @close="closeModalImages"></ExamReportImagesModal>

      <PreviewPdfModal v-if="showModalPreview" :pdf_url="urlPdf" :pdf_id="urlPdf.id" @close="showModalPreview = !showModalPreview"></PreviewPdfModal>

      <BaseGenericModal :modal_id="'modalReportGeneric'" ref="modalGeneric"></BaseGenericModal>
    </teleport>
  </div>
</template>

<script>
import ExamReportImagesModal from './ExamReportImagesModal.vue'
import PreviewPdfModal from '@/components/PreviewPdfModal.vue'

export default {
  name: 'ExamReportList',
  props: ['patient', 'exam'],
  components: {
    PreviewPdfModal,
    ExamReportImagesModal,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    showModalImages: false,
    showModalPreview: false,
    tableReport: null,
    report: null,
    urlPdf: {
      url: null,
      id: null,
    },
  }),
  mounted: async function() {
    this.fetchData();
  },
  methods: {
    fetchData: async function() {
      this.alert.message = null;

      let params = {
        id: this.exam.id,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$reportService.getList(params);
        console.log('response', response);
        if (response.result.length === 0) {
          // this.$message.popup(this, 'request_empty', 'operationWarning', 'warn')
          this.alert = this.$message.alert(this, 'request_empty', 'warning')
          this.$emit('alertmsg', this.alert)
        }

        this.tableReport = response.result
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
        this.$emit('alertmsg', this.alert)
      }
      NProgress.done(true)
      this.loading = false
    },
    openModalImages(item) {
      this.report = [];
      this.showModalImages = true;
      this.report.id = item.id;
      this.report.images = item.images.split(',');

      setTimeout(function() {
        let modalreportimages = document.getElementById("modalreportimages");
        new bootstrap.Modal(modalreportimages).show();
      }, 100);
    },
    closeModalImages() {
      this.showModalImages = false
      this.report = []
      this.fetchData();
    },
    downloadPDF(item) {
      let params = {
        id: item.id
      }
      this.$reportService.generateReport(params);
    },
    async generateUrlPDF(item) {
      this.urlPdf = null

      let params = {
        id: item.id
      }

      this.loading = true
      NProgress.start()
      try {
        // TODO Ajustar retorno para gerar o preview do pdf
        let response = await this.$reportService.generateUrlPDF(params);
        console.log('url pdf', response);

        this.urlPdf = response
        this.urlPdf.url = response
        this.urlPdf.id = item.id
        this.showModalPreview = true

        setTimeout(function() {
          let modal_preview_pdf = document.getElementById("modal_preview_pdf");
          new bootstrap.Modal(modal_preview_pdf).show();
        }, 100);
      } catch (err) {
        console.log('erro >>>', err);
      }
      NProgress.done(true)
      this.loading = false
    },
    async openModalDelete(deleteId) {
      const ok = await this.$refs.modalGeneric.show({
        modalType: 'danger',
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('exam.confirmDiagnosisRemoval'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.doDelete(deleteId)
      }
    },
    async doDelete(deleteId) {
      this.alert.message = null

      let params = {
        id: deleteId
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$reportService.deleteReport(params);
        this.$message.popup(this, 'general.removedSuccess', 'operationSuccess', 'success')
        this.fetchData()
      } catch (err) {
        this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.removedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    editReport(report) {
      let data = {}
      if (this.$route.query.data) {
        data = this.$route.query.data
      }

      console.log('report', report);
      report.type != 'general' ?
        this.$router.push({name: 'report', params: {id: report.id, examID: this.exam.id}, query: {data: data}})
          : this.$router.push({name: 'report-general', params: {id: report.id, examID: this.exam.id, patientID: this.patient.id}, query: {data: data}})
    },
  },
}
</script>
