<template>
  <div class="">
    <ul class="menu-links">
      
      <li v-if="false" :class="active === 'dashboard' ? 'active' : ''">
        <a @click="dashboardMenu()">
          <i class="icon-dashboard"></i>
          <span>{{ $t('menu.dashboard') }}</span>
        </a>
      </li>
      <div v-show="allowSynchronization">
        <li :class="active === 'exam' ? 'active' : ''">
          <a @click="examMenu()">
            <i class="icon-exam"></i>
            <span>{{ $t('menu.exams') }}</span>
          </a>
        </li>
        <li :class="active === 'patient' ? 'active' : ''">
          <a @click="patientMenu()">
            <i class="icon-pacientes"></i>
            <span>{{ $t('menu.patients') }}</span>
          </a>
        </li>
      </div>
      <li><hr class="dropdown-divider"></li>
      <li :class="active === 'clinic' ? 'active' : ''">
        <a @click="clinicsMenu()">
          <i class="icon-clinicas"></i>
          <span>{{ $t('menu.clinics') }}</span>
        </a>
      </li>
      <li v-show="isPowerfull" :class="active === 'users' ? 'active' : ''">
        <a @click="usersMenu()">
          <i class="icon-usuarios"></i>
          <span>{{ $t('menu.users') }}</span>
        </a>
      </li>
      <li v-show="!isSpecialist" :class="active === 'device' ? 'active' : ''">
        <a @click="deviceMenu()">
          <i class="icon-dispositivos"></i>
          <span>{{ $t('menu.devices') }}</span>
        </a>
      </li>
      <li v-show="isPowerfull" :class="active === 'audit' ? 'active' : ''">
        <a @click="auditMenu()">
          <i class="icon-audit">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
            <span class="path5"></span>
            <span class="path6"></span>
            <span class="path7"></span>
            <span class="path8"></span>
          </i>
          <span>{{ $t('menu.audit') }}</span>
        </a>
      </li>
      <li :class="active === 'faq' ? 'active' : ''">
        <a :href="helpcenterUrl" target="_blank">
          <i class="icon-faq"></i>
          <span>HELPCENTER</span>
        </a>
      </li>
      <BaseFeature feature="backoffice">
        <li v-show="!isSpecialist" :class="active === 'backoffice' ? 'active' : ''">
          <a @click="backofficeMenu()">
            <i class="icon-dashboard"></i>
            <span>Backoffice</span>
          </a>
        </li>
      </BaseFeature>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .menu-links {
    list-style:                         none;
    margin-top:                         10px;
    padding:                            unset;
    li {
      &:hover,
      &:focus {
        a, i:before {
         color:                         $pale-green;
       }
      }
      &:active,
      &.active {
        a {
          font-weight:                  bold;
          &, i:before {
            color:                      white;
          }
        }
      }
      a {
        font-size:                      13px;
        font-weight:                    400;
        color:                          $eucalyptus;
        text-transform:                 uppercase;
        letter-spacing:                 3px;
        display:                        block;
        padding:                        10px 15px;
        text-decoration:                none;
        cursor:                         pointer;
      }
    }
    img {
      width:                            32px;
    }
    i {
      font-size:                        2em;
      vertical-align:                   middle;
      margin-right:                     10px;
    }
    img,
    svg {
      margin-right:                     5px;
    }
  }

  .main.retract {
    .menu {
      .menu-links {
        a > span {
          display:                      none;
        }
        li a {
          padding:                      10px !important;
        }
      }
      .level-box {
        a {
          display:                      block !important;
        }
        p {
          display:                      none !important;
        }
      }
      .wizard-level {
        width:                          auto !important;
        margin-bottom:                  10px !important;
        li {
          &.active {
            display:                    block !important;
            margin:                     0 auto;
          }
          &,
          &.past,
          &:before,
          & small {
            display:                    none !important;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .menu-links {
      a span {
        display:                        none;
      }
      li a {
        padding:                        10px;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1500px) {
    .menu-links {
      li a {
        padding:                        7px 15px;
      }
      img, svg {
        max-height:                     22px;
      }
    }
  }

  @media screen and (min-width: 800px) and (max-width: 991px) {
    @media screen and (min-height: 600px) and (max-height: 768px) {
      .menu-links {
        li a {
          font-size:                    10px;
          padding:                      10px 15px;
        }
        img, svg {
          max-height:                   18px;
        }
      }
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'MenuDesktopLinks',
  data: () => ({
    active: null,
    showModalWarning: false,
    allowSynchronization: true,
    isSpecialist: null,
    isPowerfull: null,
    session: null,
    locale: null,
  }),
  watch: {
    '$activeLink.value': {
      handler: function () {
        this.active = this.$activeLink.value
    }, deep: true }
  },
  computed: {
    helpcenterUrl() {
      if (this.locale == 'pt-br')
        return 'https://phelcom.com/pt-br/helpcenter/'

      return 'https://phelcom.com/helpcenter/'
    }
  },
  mounted: function() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.isSpecialist = this.session.isSpecialist
    this.isPowerfull = this.session.isPowerfull
    this.active = this.$activeLink.value ? this.$activeLink.value : null
    this.locale = this.session.currentClinic.locale.toLowerCase()

    this.allowSynchronization = this.session.currentClinic.synchronizePatientData
    if (this.allowSynchronization == undefined)
      this.allowSynchronization = true
  },
  methods: {
    auditMenu: async function() {
      this.goToLink(this, 'audit', 'audit-list')
    },
    clinicsMenu: async function() {
      this.goToLink(this, 'clinic', 'clinic-list')
    },
    dashboardMenu: async function() {
      this.goToLink(this, 'dashboard', 'dashboard')
    },
    deviceMenu: async function() {
      this.goToLink(this, 'device', 'device-list')
    },
    examMenu: async function() {
      this.session.examCurrentPage = 1
      this.goToLink(this, 'exam', 'exam-list')
    },
    patientMenu: async function() {
      this.session.patientCurrentPage = 1
      this.goToLink(this, 'patient', 'patient-list')
    },
    usersMenu: async function() {
      this.goToLink(this, 'users', 'user-list')
    },
    backofficeMenu() {
      const BASE_URL = process.env.VUE_APP_API_URL
      let BO2_URL = "https://bo2.eyercloud.com"
      if (BASE_URL.includes('localhost'))
        BO2_URL = "http://localhost:8081"
      if (BASE_URL.includes('dev'))
        BO2_URL = "https://devbo2.eyercloud.com"
      
      window.location = `${BO2_URL}`
    },
    goToLink(self, active, link) {
      self.active = active

      self.$router.push({name: 'proxy-router', params: {
        target: link,
        data: JSON.stringify({})
      }})
    }
  }
}
</script>
