<template>
  <div class="modal fade" id="modal_invitation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="modalInvitationLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalInvitationLabel">{{ $t('user.inviteCollaborator') }}</h5>
          <button type="button" class="btn-close btn-close-white" id="closeBtn" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </div>
        <vee-form v-slot="{ handleSubmit }" as="div">
          <form @submit.stop.prevent="handleSubmit(doInvite)">
            <div class="modal-body">
              <h5>{{ $t('user.chooseTheAppropriatePermission') }}</h5>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="permissionType" id="adminPermission" value="admin" v-model="permissionType">
                    <label class="form-check-label" for="adminPermission">
                      {{ $t('admin') }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="permissionType" id="physicianPermission" value="physician" v-model="permissionType">
                    <label class="form-check-label" for="physicianPermission">
                      {{ $t('physician') }}
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="permissionType" id="technicianPermission" value="technician" v-model="permissionType">
                    <label class="form-check-label" for="technicianPermission">
                      {{ $t('technician') }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="permissionType" id="specialistPermission" value="specialist" v-model="permissionType">
                    <label class="form-check-label" for="specialistPermission">
                      {{ $t('specialist') }} <span>*</span>
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <small>{{ $t('user.informNonAccessibleData') }}</small>
                </div>
              </div>

              <hr>
              <h5>{{ $t('user.askForCollaboratorEmail') }}</h5>
              <div class="form-group">
                <label class="control-label mb-1" for="email">{{ $t('user.email') }}</label>
                <vee-field name="email" v-model="email" rules="required|email" v-slot="{ field, errorMessage }">
                  <input v-bind="field" class="form-control" type="email" id="email" ref="email" autocomplete="off">
                  <span class="vee-error">{{ errorMessage }}</span>
                </vee-field>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="close()">{{ $t('user.cancel') }}</button>
              <button type="submit" class="btn btn-primary">{{ $t('user.send') }}</button>
            </div>
          </form>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-dialog {
      .modal-header {
        background-color:                 $phelcom-light-blue;
      }
      .modal-body {
        small,
        .form-check label span {
          color:                          $pastel-red;
        }
        .form-check {
          label {
            font-weight:                  normal;
            color:                        unset;
            text-align:                   left;
          }
          input {
            margin-top:                   3px;
          }
        }
        h5 {
          font-size:                      15px;
          &:first-child {
            margin:                       5px 0 15px;
          }
        }
        .form-group {
          display:                        block;
        }
        .vee-error,
        .form-group label,
        .form-group input {
          width:                          100% !important;
        }
      }
    }
  }
</style>

<script>
export default {
  name: 'UserInvitationModal',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    email: null,
    permissionType: 'admin',
  }),
  mounted() {
    setTimeout(() => {
      email.focus()
    }, 1000)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    doInvite: async function() {
      let params = {
        email: this.email,
        permissionType: this.permissionType
      }

      NProgress.start()
      try {
        let response = await this.$userService.invitation(params);
        this.$message.popup(this, 'user.sentInvitiationMessage', 'operationSuccess', 'success')
      } catch (err) {
        this.$message.popup(this, 'operationFail', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'operationFail', 'danger')
      }
      NProgress.done(true)
      this.$emit('alertmsg', this.alert)
      $('#closeBtn').click();
    }
  },
  unmounted() {
    this.email = null
    this.permissionType = 'admin'
  }
}
</script>
