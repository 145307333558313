<template>
  <div class="patient-box">
    <div class="row">
      <div class="col-lg-6">
        <h3 class="sub-title">{{ $t('patient.patientInfo') }}</h3>
        <div class="form-group">
          <label for="firstname">{{ $t('patient.firstName') }}</label>
          <input type="text" class="form-control" id="firstname" v-model="patient.firstName" readonly>
        </div>
        <div class="form-group">
          <label for="surname">{{ $t('patient.lastName') }}</label>
          <input type="text" class="form-control" id="surname" v-model="patient.lastName" readonly>
        </div>
        <div class="form-group">
          <label for="medicalReconrdNumber">{{ $t('mrn') }}</label>
          <input type="text" class="form-control" id="medicalReconrdNumber" v-model="patient.mrn" readonly>
        </div>
        <div class="form-group">
          <label for="birthday">{{ $t('patient.birthday') }}</label>
          <input type="text" class="form-control" id="birthday" v-model="birthday" v-maska :data-maska="birthday ? $t('inputMask.date') : ''" readonly>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="row">
          <div class="col-md-6">
            <h3 class="sub-title">{{ $t('patient.underlyingDisease') }}</h3>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="diabetes" v-model="patient.anamnesis.diabetes" disabled>
              <label class="form-check-label" for="diabetes">
                {{ $t('disease.diabetesMellitus') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="hypertension" v-model="patient.anamnesis.hypertension" disabled>
              <label class="form-check-label" for="hypertension">
                {{ $t('disease.hypertension') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="cholesterol" v-model="patient.anamnesis.cholesterol" disabled>
              <label class="form-check-label" for="cholesterol">
                {{ $t('disease.highCholesterol') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="smoker" v-model="patient.anamnesis.smoker" disabled>
              <label class="form-check-label" for="smoker">
                {{ $t('disease.smoker') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="nwn_underlying" v-model="patient.anamnesis.nwn_underlying" disabled>
              <label class="form-check-label" for="nwn_underlying">
                {{ $t('disease.nwn_underlying') }}
              </label>
            </div>
          </div>

          <div class="col-md-6">
            <h3 class="sub-title">{{ $t('patient.ophthalmicDiseases') }}</h3>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="diabeticRetinopathy" v-model="patient.anamnesis.diabeticRetinopathy" disabled>
              <label class="form-check-label" for="diabeticRetinopathy">
                {{ $t('disease.diabeticRetinopathy') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="dmri" v-model="patient.anamnesis.dmri" disabled>
              <label class="form-check-label" for="dmri">
                {{ $t('disease.amd') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="glaucoma" v-model="patient.anamnesis.glaucoma" disabled>
              <label class="form-check-label" for="glaucoma">
                {{ $t('disease.glaucoma') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="cataract" v-model="patient.anamnesis.cataract" disabled>
              <label class="form-check-label" for="cataract">
                {{ $t('disease.cataract') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="pterygium" v-model="patient.anamnesis.pterygium" disabled>
              <label class="form-check-label" for="pterygium">
                {{ $t('disease.pterygium') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="lowVisualAcuity" v-model="patient.anamnesis.lowVisualAcuity" disabled>
              <label class="form-check-label" for="lowVisualAcuity">
                {{ $t('disease.lowVisualAcuity') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="nwn_ophthalmic" v-model="patient.anamnesis.nwn_ophthalmic" disabled>
              <label class="form-check-label" for="nwn_ophthalmic">
                {{ $t('disease.nwn_ophthalmic') }}
              </label>
            </div>
          </div>
        </div>

        <h3 class="sub-title">{{ $t('patient.other') }}</h3>
        <div class="form-group">
          <label for="otherObservation">{{ $t('patient.observation') }}</label>
          <textarea name="name" class="form-control" id="otherObservation" v-model="patient.observation" readonly></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-check input[type="checkbox"] {
  margin-top:                    3px;
}

@media screen and (min-width: 500px) and (max-width: 991px) {
  .col-lg-6 > .row > .col-md-6 {
    width:                       50%;
  }
}
</style>

<script>
export default {
  name: 'ExamInfoPatient',
  props: ['patient'],
  data: () => ({
    birthday: ''
  }),
  mounted() {
    const DATE_FORMAT  = this.$i18n.t('dateFormat')
    this.birthday = this.patient.birthday ? moment.utc(this.patient.birthday).format(DATE_FORMAT) : ""
  },
  methods: {
    dateFormat(val) {
      console.log(val);
      console.log(moment(val).format('L'));
      return moment(val).format('L')
    }
  },
}
</script>
