<template>
  <div class="filter-box">
    <BaseDateFilter class="d-inline-block" @change-date="setDates"></BaseDateFilter>

    <v-select
      class="v-select form filter"
      v-model="filterSelected"
      :reduce="x => x.value"
      :options="filterOptions">
    </v-select>

    <button class="btn btn-success" type="button" name="button" @click="doFilter(true)">{{ $t('filter') }}</button>
    <button class="btn btn-primary" type="button" name="button" @click="openModalDownload">{{ $t('download') }}</button>

    <teleport to="body">
      <ExamDownloadModal v-if="showModalDownload" @close="showModalDownload = false"></ExamDownloadModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
  .filter-box {
    .form {
      width:                      100px !important;
    }
    .form,
    .d-inline-block {
      vertical-align:             bottom;
    }
    button {
      margin-bottom:              0 !important;
    }
  }

  @media (max-width: 445px) {
    .filter-box .form {
      display:                    block !important;
      margin:                     0 auto 10px;
    }
  }

  @media (max-width: 555px) {
    .filter-box .d-inline-block {
      display:                    block !important;
      margin-bottom:              5px;
    }
  }

  @media (min-width: 446px) {
    .filter-box .form {
      display:                    inline-block !important;
      margin-right:               5px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1230px) {
    .title-box > div {
      text-align:                 right;
    }
    .filter-box .d-inline-block {
      display:                    block !important;
      margin-bottom:              5px;
    }
  }
</style>

<script>
import ExamDownloadModal from './ExamDownloadModal.vue'

export default {
  name: 'ExamFilter',
  components: {
    ExamDownloadModal,
  },
  data: () => ({
    showModalDownload: false,
    filterOptions: [],
    filterSelected: 'all',
    filterDates: {
      startDate: null,
      endDate: null
    },
  }),
  mounted: async function() {
    this.filterOptions = [
      {value: 'all', label: this.$i18n.t('exam.all')},
      {value: 'open', label: this.$i18n.t('exam.open')},
      {value: 'closed', label: this.$i18n.t('exam.closed')}
    ];

    // this.debounce()
  },
  methods: {
    openModalDownload() {
      this.showModalDownload = true

      setTimeout(function() {
        let modaldownload = document.getElementById("modaldownload");
        new bootstrap.Modal(modaldownload).show();
      }, 100);
    },
    setDates(val) {
      this.filterDates = val;

      if (this.$route.query.data) {
        let data = this.$utils.parseDataQuery(this.$route.query.data)
        if (data.filterSelected) {
          this.filterSelected = data.filterSelected
        }

        this.debounce()
      }
    },
    debounce: _.debounce(function() {
      this.doFilter()
    }, 250, { 'maxWait': 100 }),
    doFilter(newRequest) {
      let filter = {
        startDate: this.filterDates.startDate,
        endDate: this.filterDates.endDate,
        filterSelected: this.filterSelected,
        newRequest: newRequest ? newRequest : false
      };
      // console.log('filterDates', filter);

      this.$emit('do-filter', filter);
    },
  },
  // unmounted() {
  //   this.filterSelected = 'all'
  //   this.filterDates = undefined
  // },
}
</script>
