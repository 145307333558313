<template>
  <footer class="footer">
    <p class="information" v-show="is_connected">
      <strong>{{ $t('eyerweb.disclaimer') }}</strong>
      {{ $t('eyerweb.disclaimerInfo') }}
    </p>

    <a href="https://phelcom.com/" class="logo" target="_blank">
      <i class="icon-phelcom_logo_horizontal_fit">
        <span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
        <span class="path4"></span>
        <span class="path5"></span>
        <span class="path6"></span>
        <span class="path7"></span>
        <span class="path8"></span>
        <span class="path9"></span>
        <span class="path10"></span>
        <span class="path11"></span>
      </i>
    </a>
    <!-- <img src="/images/phelcom/phelcom_logo_horizontal_fit.svg" alt="Logo Phelcom" width="150px"> -->
    <!-- <div class="terms-box">
      <p>
        <span>{{ $t('terms.allRights') }}</span> <span>-</span> {{ $t('terms.meetOur') }}
        <a href="javascript:;" @click="$emit('open-terms')">{{ $t('terms.privacyPolicy') }}</a>
        {{ $t('terms.and') }}
        <a href="javascript:;" @click="$emit('open-terms')">{{ $t('terms.termsOfUse') }}</a>
      </p>
    </div> -->
    <p class="version">J {{ appVersion }}</p>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  .icon-phelcom_logo_horizontal_fit {
    font-size:                            1.85em;
  }
  .terms-box {
    position:                             relative;
    display:                              flex;
    justify-content:                      center;
    align-items:                          center;
    background:                           transparent;
    bottom:                               unset;
    height:                               100%;
    min-height:                           45px;
    padding:                              0;
    z-index:                              0;
    /* z-index:                              3; */
    p {
      font-size:                          1em;
      margin:                             0;
      &,
      a {
        color:                            white;
      }
      a {
        text-decoration:                  underline;
      }
    }
  }
  > .logo {
    padding-top:                          20px;
    text-decoration:                      none;
  }
  .information {
    @include                              border-radius(10px);
    font-size:                            15px;
    border:                               1px solid white;
    margin:                               0 auto;
    padding:                              10px;
    color:                                white;
    max-width:                            60%;
    // position:                             absolute;
    // bottom:                               25%;
    // left:                                 0;
    // right:                                0;
  }
}

@media (max-width: 500px) {
  .footer {
    .icon-phelcom_logo_horizontal_fit {
      font-size:                          1.5em;
    }
    > .logo {
      padding-top:                        15px !important;
    }
  }
}

@media (max-width: 991px) {
  .footer {
    .information {
      font-size:                          12px;
      padding:                            8px;
      max-width:                          95%;
    }
    .terms-box {
      text-align:                         center;
      padding:                            0 10px 5px;
      p {
        font-size:                        0.8em;
      }
    }
  }
}

@media (min-width: 992px) {
  .footer .terms-box {
    max-height:                           45px;
  }
}
</style>

<script>
export default {
  name: 'AuthFooter',
  props: ['is_connected'],
  data: () => ({
    appVersion: process.env.VUE_APP_VERSION,
  }),
}
</script>
