<template>
  <div class="main-box" v-loader-eyerweb.opacity="loading">
    <div class="title-box clear">
      <h2 class="title">{{ $t("exam.examsList") }}</h2>

      <div class="filter-box">
        <EyerwebExamDateFilter class="d-inline-block" @change-date="setDates"></EyerwebExamDateFilter>
        <button class="btn btn-success" type="button" name="button" @click="doFilter()">{{ $t('filter') }}</button>
      </div>
    </div>

    <div class="text-end">
      <button class="btn btn-primary" type="button" name="button" :disabled="selectedExams.length < 1"
        @click="downloadSelectedExams">{{ $t('eyerweb.downloadExams') }}</button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable class="eyerweb" v-if="tableExam" :tabledata="tableExam" @item-opened="examSelected">
      <template v-slot:column_check="{ item }">
        <div class="position-relative" :id="`box_${item.uuid}`">
          <BaseCheckBox :id="item.uuid" :value="item.uuid" :item="item" @checked="doCheck"></BaseCheckBox>
        </div>
      </template>
    </BaseTable>

    <div v-show="tableExam" class="row align-items-center page-row">
      <div class="col">
        <p>{{ $t("pagination.totalEntries") }} {{ tableExam.total }}</p>
      </div>
      <div class="col-auto">
        <BasePagination :total-pages="totalPages" :total="tableExam.total" :per-page="perPage" :current-page="currentPage"
          @pagechanged="onPageChange" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table {
  margin-bottom:                0;

  >tfoot>tr>td,
  >tfoot>tr>th,
  >thead>tr>td,
  >thead>tr>th,
  >tbody>tr>td,
  >tbody>tr>td .fa,
  >tbody>tr>th {
    vertical-align:             middle;
  }
}

.label.label-blue-phelcom {
  margin-right:                 5px;
}

.no-btn {
  padding:                      0 4px;
  margin:                       0;
}

.m-0 {
  font-size:                    0.9em;
  i {
    vertical-align:             top;
  }
}

.filter-box {
  .form {
    width:                      100px !important;
  }
  .form,
  .d-inline-block {
    vertical-align:             bottom;
  }
  button {
    margin-bottom:              0 !important;
  }
}

.base-check-box {
  margin-top:                   0 !important;
  &.disabled {
    cursor:                     crosshair;
    pointer-events:             none;
    opacity:                    0.7;
  }
  &.checked:after {
    top:                        4px !important;
  }
}

@media (max-width: 445px) {
  .filter-box .form {
    display:                    block !important;
    margin:                     0 auto 10px;
  }
}

@media (max-width: 555px) {
  .filter-box .d-inline-block {
    display:                    block !important;
    margin-bottom:              5px;
  }
}

@media (min-width: 446px) {
  .filter-box .form {
    display:                    inline-block !important;
    margin-right:               5px;
  }
}

@media (max-width: 767px) {
  .vtable-mob {
    >ul>li>p {
      // &:first-child {
      margin-bottom:            25px;
      // }
    }

    p .no-btn {
      margin-top:               2px;
      vertical-align:           middle;
    }
  }
}
</style>

<script>
import { ref, watch } from 'vue'
import { useEyerwebStore } from '@/store/eyerweb'
import EyerwebExamDateFilter from './EyerwebExamDateFilter.vue'

export default {
  name: 'eyerweb-exam-list',
  components: {
    EyerwebExamDateFilter,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableExam: {
      items: [],
      total: 0,
      columns: []
    },
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    session: null,
    filter: {
      startDate: null,
      endDate: null,
    },
    selectedExams: [],
    examDate: '',
    examName: ''
  }),
  watch: {
    selectedExams: {
      deep: true,
      handler(value) {
        if (value.length > 4) {
          let _self = this
          let elements = document.querySelectorAll(`.base-check-box:not(.checked)`)
          elements.forEach(o => {
            this.enableDisableCheckBox(o, 'add')
          })
        } else if (value.length == 4) {
          let elements = document.querySelectorAll(`.base-check-box.disabled`)
          elements.forEach(o => {
            this.enableDisableCheckBox(o)
          })
        }
      }
    },
  },
  created() {
    this.$activeLink.value = 'exam'
    this.examDate = this.$i18n.t('exam.date')
    this.examName = this.$i18n.t('exam.name')
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo
    this.currentPage = this.session.examCurrentPage ? this.session.examCurrentPage : 1;
    //this.currentPage > 1 ? this.doFilter(null) : this.fetchData()
    this.$eyerwebService.examListSubject.subscribe(this.examListCallback)
    this.fetchData()
    this.$eyerwebService.downloadSubject.subscribe(this.downloadCallback)
  },
  unmounted() {
    this.$eyerwebService.examListSubject.unsubscribe(this.examListCallback)
    this.$eyerwebService.downloadSubject.unsubscribe(this.downloadCallback)
  },
  mounted() {
    this.loading = true
  },
  methods: {
    enableDisableCheckBox(element, action) {
      let _self = this

      if (action) {
        let parentElement = element.parentElement
        element.classList.add('disabled', 'pe-none')

        parentElement.addEventListener('click',
          function (event) {
            event.preventDefault()
            event.stopPropagation()
            _self.$message.popup(_self, 'eyerweb.maxExamsSelected', 'operationFail', 'error')
          }, false
        );
      } else {
        let parentElement = element.parentElement
        element.classList.remove('disabled', 'pe-none')
        parentElement.removeEventListener('click', function () {}, false);
      }
    },
    examListCallback(result) {
      this.loadTableData(result)
      // this.loading = false
      NProgress.done(true)
    },
    downloadCallback(result) {
      // this.loading = false
      NProgress.done(true)
      if (result == "error") {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.session.examCurrentPage = page;
      this.doFilter(null);
    },
    async fetchData() {
      this.alert.message = null;
      this.loading = true

      let params = {
        page: this.currentPage
      }
      NProgress.start()
      try {
        this.$eyerwebService.filteredListExam(params)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }

    },
    doFilter() {
      this.alert.message = null;

      let params = {
        page: this.currentPage,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
      }

      this.loading = true
      NProgress.start()
      try {
        this.$eyerwebService.filteredListExam(params)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
    },

    loadTableData(response) {
      response.result.forEach(o => {
        try {
          o.scoreResult = this.$aiService.alterationScore(o.metadata);
        } catch (error) {
          o.scoreResult = 'error'
        }
      });

      this.tableExam = {
        'items': response.result,
        'total': response.totalLenght,
        'columns': [
          { key: "check", name: '', type: 'custom', value: 'actions', width: '2%' },
          //YAMA: Temporay fix
          //{name: this.$i18n.t('exam.name'), type: 'string', value: 'patient.fullName', width: '75%'},
          { name: this.examName, type: 'string', value: 'patient.rawName', width: '75%' },
          { name: this.examDate, type: 'date', value: 'date', width: '20%' },
        ]
      };
      this.totalPages = Math.ceil(response.totalLenght / this.perPage);

      if (response.result.length === 0) {
        this.tableExam = {};
        this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    formatDate(date) {
      return moment(date).valueOf();
    },
    doCheck: async function (value) {
      let examUuid = value.uuid
      const index = this.selectedExams.indexOf(examUuid)

      if (index !== -1) {
        document.querySelector(`#box_${examUuid} > .base-check-box`).classList.remove('checked')
        this.selectedExams.splice(index, 1);
      } else {
        if (this.selectedExams.length == 5) {
          this.$message.popup(this, 'eyerweb.maxExamsSelected', 'operationFail', 'error')
          return
        }
        document.querySelector(`#box_${examUuid} > .base-check-box`).classList.add('checked')
        this.selectedExams.push(examUuid);
      }
      //this.selectedExams.push({value.img.patient.uuid, value.uuid})
    },
    async downloadSelectedExams() {
      try {
        // this.loading = true
        NProgress.start()
        this.$eyerwebService.downloadExamsFiles(this.selectedExams);
      } catch (err) {
        // this.loading = false
        NProgress.done(true)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    },
    setDates(val) {
      this.filter = val;
    },
    examSelected(item) {
      if (!item) {
        return
      }

      this.$router.push({name: 'eyerweb-exam-show', params: {examID: item.uuid, previous: 'eyerweb-exam-list'}})
    },
  },
  destroyed() {
    this.alert.message = null;
    this.tableExam = [];
  }
}
</script>
