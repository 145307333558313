<template>
  <div v-show="tabledata">
    <div class="table-responsive desk">
      <table class="table table-striped vtable">
        <thead>
          <tr>
            <th v-show="!column.hide" :class="column.align" :width="column.width" v-for="(column, index) in tabledata.columns" v-html="column.name" :key="index"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in tabledata.items">
            <tr v-if="checkJokerPatient(item)" @click="selectRow(item)" :class="`tr_${item.id}`">
              <td :class="column.align" v-for="(column, indexColumn) in tabledata.columns" :key="indexColumn">
                <slot v-if="column.type == 'custom'" :name="`column_${column.key}`" :item="item"/>
                <span v-if="column.type == 'date'">{{ formatDate(item, column.value) }}</span>
                <span v-if="column.type == undefined || column.type == 'string'" v-html="getValue(item, column.value)"></span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="vtable-mob mob">
      <ul class="">
        <template v-for="(item, index) in tabledata.items">
          <li v-if="checkJokerPatient(item)" @click="selectRow(item)">
            <p v-for="(column, indexColumn) in tabledata.columns" :key="indexColumn">
              <span v-html="column.name"></span>
              <slot v-if="column.type == 'custom'" :name="`column_${column.key}`" :item="item"/>
              <span v-if="column.type == 'date'">{{ formatDate(item, column.value) }}</span>
              <span v-if="column.type == undefined || column.type == 'string'" v-html="getValue(item, column.value)"></span>
            </p>
          </li>
        </template>
      </ul>
    </div>

  </div>
</template>

<style lang="scss" scoped>
  .mob {
    display:                        none;
  }

  .vtable-mob {
    ul {
      margin:                       0.5em 0;
      li {
        p {
          display:                  block;
          margin-bottom:            10px;
          // span:last-child:not(:first-child) {
          span:not(:first-child), button {
            float:                  right;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .desk {
      display:                      none;
    }
    .mob {
      display:                      block;
    }
    .vtable-mob .label {
      line-height:                  1.4;
    }
    .eyerweb {
      .vtable-mob {
        > ul > li p {
          &:first-child {
            margin-bottom:          25px;
          }
        }
      }
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'BaseTable',
  props: ['tabledata', 'table_page'],
  data: () => ({
    dateFormat: "",
    session: {},
  }),
  created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  mounted: function() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    this.dateFormat = this.$i18n.t('dateTimeFormat')
  },
  methods: {
    getValue(data, key) {
      return _.get(data, key)
    },
    formatDate(data, key) {
      let value = _.get(data, key)
      let result = moment(value).format(this.dateFormat);
      return result
    },
    // checkColumn(column){
      // if (column !== 'createdAt' && column !== 'updatedAt' && column !== 'id') return true;
    // },
    selectRow(item) {
      this.$emit('item-opened', item);
    },
    // @deprecated
    // checkStatus(status) {
    //   if (status === 'Closed' || status === 'Fechado' || status === 'closed' || status === 'fechado')
    //     return 'label-closed';
    //
    //   return 'label-open';
    // },
    checkJokerPatient(item) {
      if (!this.session.currentClinic)
        return true

      let sessionJokerUuid = this.session.currentClinic.jokerPatientUuid

      if (!sessionJokerUuid)
        return true

      let uuid = this.table_page == 'exams' ? item.patient.uuid : item.uuid

      return uuid != sessionJokerUuid ? true : false
    }
  }
}
</script>
