<template>
  <div class="" :key="examId" v-loader.opacity="loading">
    <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title sub" v-if="exam">{{ $t("exam.examData") }} |
        <span @click="toPatient">{{ patient.fullName }}</span>
      </h2>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-images>
        {{ $t("exam.images") }}
      </template>
      <template v-slot:images>
        <ExamImageTab v-if="tabActive === 'images' && exam" :exam_id="exam.id" :patient="patient" :alert_msg="alert ? alert : null"></ExamImageTab>
      </template>

      <template v-slot:tab-head-data>
        {{ $t("exam.information") }}
      </template>
      <template v-slot:data>
        <ExamInfoTab v-if="tabActive === 'data' && exam" :exam="exam" :patient="patient" :alert_msg="alert ? alert : null"></ExamInfoTab>
      </template>

      <template v-slot:tab-head-report>
        {{ $t("exam.report") }}
      </template>
      <template v-slot:report>
        <ExamReportTab v-if="tabActive === 'report' && exam" :patient="patient" :exam="exam" :alert_msg="alert ? alert : null"></ExamReportTab>
      </template>
    </BaseTab>

  </div>
</template>

<style lang="scss" scoped>
  .title-box {
    .btn-back {
      margin-right:              20px;
    }
    .title.sub {
      cursor:                    pointer;
    }
  }
</style>

<script>
import ExamImageTab from './ExamImageTab.vue'
import ExamInfoTab from './ExamInfoTab.vue'
import ExamReportTab from './ExamReportTab.vue'

export default {
  name: 'exam-show',
  components: {
    ExamReportTab,
    ExamImageTab,
    ExamInfoTab,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    loading: null,
    msgError: null,
    user: null,
    tabActive: 'images',
    tabs: ['images', 'data', 'report'],
    deleteID: null,
    exam: null,
    patient: null,
    previous: null,
  }),
  computed:  {
    examId() {
      return this.$route.params.examID;
    },
    tab() {
      return this.$route.query.tab;
    },
  },
  created() {
    // watch param exam id coming from notifications to force reload data
    this.$watch( // TODO REFACTOR KISS
      () => this.$route.params.examID,
      (toParams, previousParams) => {
        if (toParams != previousParams && this.$route.name == 'exam-show')
          this.fetchData()
      }
    )
  },
  mounted: function() {
    this.$activeLink.value = 'exam'

    // let data = this.$utils.parseDataQuery(this.$route.query.data)
    // if (data)
    //   this.previous = data.previous

    this.fetchData();

    if (this.tab) {
      this.switchTab(this.tab)
    }
  },
  methods: {
    goBack() {
      if (this.$route.query.data) {
        let data = this.$utils.parseDataQuery(this.$route.query.data)
        this.previous = data.previous
      }

      if (this.previous == 'patient') {
        let data = this.$utils.dataQuery({
          patientName: this.patient.rawName
        })

        this.$router.push({name: 'patient-show', params: {patientID: this.patient.id}, query: {tab: 'exams', data: data}})
      } else {
        let data = this.$route.query.data
        this.$router.push({name: 'exam-list', query: {data: data}})
      }
    },
    switchTab(tab) {
      this.tabActive = tab;
      // this.$router.push({name: 'exam-show', params: {examID: this.examId, tab: tab}})
    },
    fetchData: async function() {
      this.loading = true
      NProgress.start()
      try {
        let params = {
          id: this.examId,
        }

        let response = await this.$examService.get(params);
        console.log('response fetch', response);
        this.exam = response.exam
        this.patient = response.patient
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toPatient() {
      let data = this.$utils.dataQuery({
        patientName: this.patient.rawName
      })

      this.$router.push({name: 'patient-show', params: {patientID: this.patient.id}, query: {tab: 'exams', data: data}})
    }
  },
  unmounted() {
    this.tabActive = 'images'
  }
}
</script>
