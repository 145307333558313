<template>
  <div class="modal fade" id="change_password_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="changePasswordLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="changePasswordLabel">
            <span>{{ $t('user.changePassword') }}</span>
          </h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </div>
        <vee-form v-slot="{ handleSubmit }" as="div">
          <form @submit.prevent.stop="handleSubmit(changePassword)">
            <div class="modal-body">
              <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                <span v-html="alert.message"></span>
                <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
              </div>

              <div class="form-group">
                <label for="currentPassword">{{ $t('user.currentPassword') }}</label>
                <vee-field name="currentPassword" v-model="currentPassword" rules="required" v-slot="{ field, errorMessage }">
                  <input v-bind="field" type="password" class="form-control" ref="currentPassword" id="currentPassword" :disabled="disabled">
                  <span class="vee-error">{{ errorMessage }}</span>
                </vee-field>
              </div>
              <div class="form-group">
                <label for="newPassword">{{ $t('user.newPassword') }}</label>
                <vee-field name="newPassword" v-model="newPassword" rules="required|min:4" v-slot="{ field, errorMessage }">
                  <input v-bind="field" :type="showNewPassword ? 'text' : 'password'"
                         class="form-control" ref="newPassword" id="newPassword"
                         @keyup="checkPassword(newPassword)" :disabled="disabled">

                  <button class="no-btn" type="button" name="button" @click="showNewPassword = !showNewPassword">
                    <i class="fa" :class="showNewPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                  </button>
                   <small class="strength-password" :class="strengthInfo" v-show="strengthInfo">{{ strengthInfo ? $t(`strengthPassword.${strengthInfo}`) : "" }}</small>
                   <span class="vee-error">{{ errorMessage ? errorMessage : $t(`${strengthPasswordMessage}`) }}</span>
                </vee-field>
              </div>
              <div class="form-group">
                <label for="passwordConfirmation">{{ $t('user.passwordConfirmation') }}</label>
                <vee-field name="confirmNewPassword" v-model="newPasswordConfirmation" rules="required|confirmed:newPassword" v-slot="{ field, errorMessage }">
                  <input v-bind="field" :type="showConfirmNewPassword ? 'text' : 'password'"
                           class="form-control" ref="passwordConfirmation" id="passwordConfirmation" :disabled="disabled">
                  <button class="no-btn" type="button" name="button" @click="showConfirmNewPassword = !showConfirmNewPassword">
                      <i class="fa" :class="showConfirmNewPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                  </button>
                  <span class="vee-error">{{ errorMessage }}</span>
                </vee-field>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="close()">{{ $t('user.cancel') }}</button>
              <button type="submit" class="btn btn-primary" :disabled="disabled">{{ $t('user.modify') }}</button>
            </div>
          </form>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-dialog {
      .modal-header {
        background-color:         $phelcom-light-blue;
      }
      .modal-body {
        .form-group {
          &,
          .form-control {
            display:              block;
          }
          .form-control {
            position:             relative;
          }
          label,
          .form-control {
            width:                100% !important;
          }
        }
        .vee-error {
          width:                  100% !important;
        }
      }
    }
  }

  .no-btn {
    position:                     absolute;
    font-size:                    1.4em;
    top:                          22px;
    right:                        16px;
    padding:                      0;
  }

  .strength-password {
    position:                     absolute;
    color:                        white;
    font-size:                    0.7em;
    text-align:                   center;
    height:                       12px;
    width:                        100%;
    top:                          15px;
    &.danger {
      background:                 $phelcom-red;
    }
    &.warning {
      background:                 $yellow-phelcom;
    }
    &.success {
      background:                 $phelcom-green;
    }
  }
</style>

<script>
import utilService from '../../../../services/util-service.js';
import { useUserStore } from '@/store/user'

export default {
  name: 'UserChangePasswordModal',
  props: ['external'],
  data: () => ({
    alert: {
      message: null,
      class: null,
    },
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
    showNewPassword: false,
    showConfirmNewPassword: false,
    strengthPasswordMessage: 'zxcvbnMessages.default',
    strengthInfo: null,
    password: "",
    confirmPassword: "",
    disabled: false,
    account: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      pin: "",
    },
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    if (this.session) {
      this.account.firstName = this.session.collaborator.firstName
      this.account.lastName = this.session.collaborator.lastName
      this.account.email = this.session.collaborator.email
    }

    if (this.external) {
      this.checkEmail()
    }

    setTimeout(() => {
      currentPassword.focus()
    }, 1000)
    
    this.disableCopyPaste();   
  },
  methods: {
    close() {
      let values = {
        alert: this.alert,
        type: 'password'
      }
      this.$emit('close', values)
    },
    async checkPassword(password) {

      this.account.password = password

      let params = {
        account: this.account
      }

      let isValidPassword = await utilService.validatePassword(params.account)

      switch (isValidPassword.score) {
        case 0:
        case 1:
        case 2:
          this.strengthInfo = 'danger'
          break;
        case 3:
          this.strengthInfo = 'warning'
          break;
        case 4:
          this.strengthInfo = 'success'
          break;
      }

      if (isValidPassword.valid) {
        this.strengthPasswordMessage = 'zxcvbnMessages.default'
      } else {
        this.strengthPasswordMessage = isValidPassword.warning ? 'zxcvbnMessages.warnings.' + isValidPassword.warning :
                                                            'zxcvbnMessages.suggestions.' + isValidPassword.suggestion[0]
      }
    },
    async changePassword() {

      try {
        let params = {
          account: this.account,
        }
        let responseValidate = await utilService.validatePassword(params.account)

        if (responseValidate.valid == false){
          console.log("errror")
          this.$message.popup(this, 'zxcvbnMessages.error', 'operationFail', 'error')
          return
        }

        NProgress.start()

        let result = await this.$userService.changePassword(this.newPassword, this.currentPassword)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.$nextTick(() => {
          $('#closeBtn').click();
        });
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
    async checkEmail() {
      try {
        let result = await this.$externalService.checkEmail()
        console.log('result check email', result);

        if (!result.isEmailValid) {
          this.disabled = true;
          this.alert = {
            message: 'Você precisa registrar um email válido para poder alterar a senha.',
            class: 'alert-warning'
          }
        }
      } catch (err) {
        console.log('erro check email', err);
      }
    },
    toggleShow() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleShowConfirm() {
      this.showConfirmNewPassword = !this.showConfirmNewPassword;
    },
    disableCopyPaste() {
      let inputArray = [this.$refs.newPassword, this.$refs.passwordConfirmation]
      inputArray.forEach((element) => {
        element.addEventListener('contextmenu', (e) => e.preventDefault()); // Disable right-click menu
        element.addEventListener('cut', (e) => e.preventDefault()); // Disable cut
        element.addEventListener('copy', (e) => e.preventDefault()); // Disable copy
        element.addEventListener('paste', (e) => e.preventDefault()); // Disable paste
      })
    },
  }
}
</script>
