<template>
  <div class="main-img imgTab" v-loader.opacity.tab="loading">
    <div v-if="examImages">
      <div class="action-box">
        <div v-if="showMutirao">
          <ExamImagePreAnalysis v-if="exam" :patient="patient" :exam="exam" @alertmsg="setAlert"></ExamImagePreAnalysis>
        </div>
      </div>

      <div class="action-buttons rounded mb-2" v-show="!isSpecialist">
        <!-- <BaseFeature feature="patientAccess"> -->
        <button class="btn btn-outline-primary" type="button" name="button" @click="shareInformation">
          <span>{{ $t('externalPatient.share') }}</span>
        </button>
        <!-- </BaseFeature> -->
      </div>

      <div class="">
        <div class="toolbar">
          <div class="filters" v-if="filterOptions">
            <span>{{ $t('selectPicker.filter') }}: </span>

            <select class="selectpicker" data-width="css-width" multiple data-live-search="false" data-allow-clear="false" v-model="filterSelected" @change="doFilter()">
              <option v-for="(item, index) in filterOptions" :value="item.value" :key="index">{{ item.name }}</option>
            </select>
          </div>


          <BaseFeature feature="iaAdvancedFunctions">
            <div class="btn-group">
              <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                      :disabled="selectedImages.length < 1">
                {{ $t('aipo') }}
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" :class="{'disabled': !isValidForHeatmap}" @click="openModalGeneric('heatmap')">{{ $t('imageType.heatmap') }}</a></li>
                <li><a class="dropdown-item" :class="{'disabled': selectedImages.length < 2}" @click="openModalGeneric('panoramic')">{{ $t('imageType.panoramic') }}</a></li>
                <li><a class="dropdown-item" :class="{'disabled': selectedImages.length < 2}" @click="openModalGeneric('stereo')">{{ $t('imageType.stereo') }}</a></li>
              </ul>
            </div>
          </BaseFeature>
        </div>

        <div v-if="alert.message" class="alert alert-dismissible fade show" :class="alert.class" role="alert">
          <span v-html="alert.message"></span>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="clearMessage"></button>
        </div>

        <div v-if="examImages.length">
          <lightgallery-vue
            class="image-info-box grid-wrap-between ease-all" ref="lGallery" id="lightgallery"
            :settings="lightGallerySettings" :onAfterOpen="extraCustomization" :lgBeforeClose="addEventResize">
            <template v-for="(item, index) in examImages" :key="index">

              <a v-if="item.type.endsWith('VIDEO')" onclick="return false" class="thumbnail-box loading-eye" :class="item.type" :key="`video_${index}`" data-lg-size="1280-720"
                :data-video="getVideoSource(item)" data-download-url="" data-sub-html="<h4></h4><p></p>">
                <img class="lg-selector ease-all" src="@/assets/images/default-video2.png" alt="" @load="removeLoading($event)"/>
                <div class="thumb-actions">
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="openModalDelete(item.id)">
                    <i class="fa fa-trash-o"></i>
                  </button>
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="doDownload(item, item.type.endsWith('VIDEO'))">
                    <i class="fa fa-download"></i>
                  </button>
                </div>
              </a>

              <a v-else class="thumbnail-box loading-eye" onclick="return false" :class="item.type" :href="dataPath +'/'+ item.name">
                <img class="lg-selector ease-all" :src="thumbDataPath +'/'+ item.name" alt="" @load="removeLoading($event)"/>
                <BaseFeature feature="iaAdvancedFunctions">
                  <div class="checkbox" v-show="allowEdition(item.type)">
                    <BaseCheckBox :id="item.name" :value="item.uuid" :item="item" @checked="doCheck"></BaseCheckBox>
                    <!-- <input class="form-check-input" type="checkbox" :id="'checkImg_'+item.name" :value="item.uuid" aria-label=""
                    @click.stop="doCheck(item, 'checkImg_'+item.name)"> -->
                  </div>
                </BaseFeature>
                <div class="thumb-actions">
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="openModalDelete(item.id)">
                    <i class="fa fa-trash-o"></i>
                  </button>
                  <button class="no-btn" type="button" name="button" @click.stop.prevent="doDownload(item, item.type == 'STEREO')">
                    <i class="fa fa-download"></i>
                  </button>
                  <BaseFeature feature="imageEditor">
                    <button v-show="allowEdition(item.type)" class="no-btn" type="button" name="button" @click.stop.prevent="doEdit(item)">
                      <i class="fa fa-edit"></i>
                    </button>
                  </BaseFeature>
                </div>
              </a>
            </template>
          </lightgallery-vue>
        </div>
      </div>
    </div>

    <teleport to="body">
      <BaseCropperModal ref="modalCropper"></BaseCropperModal>

      <BaseGenericModal :modal_id="'modalImageGeneric'" ref="modalGeneric"></BaseGenericModal>

      <BasePatientAccessInfoModal :patient="patient" v-if="showAccessInfoModal" @close="showAccessInfoModal = false"></BasePatientAccessInfoModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.main-img {
  min-height:                          60vh;
  .action-box {
    display:                           flex;
    justify-content:                   space-between;
    align-items:                       center;
    margin-bottom:                     20px;
    > div {
      display:                         inline-block;
    }
  }
  .toolbar {
    display:                           flex;
    justify-content:                   space-between;
    margin-bottom:                     10px;
    span {
      margin-right:                    10px;
    }
    .filters {
      max-width:                       calc(100% - 170px);
    }
    .btn-group {
      .dropdown-item {
        cursor:                        pointer;
      }
    }
  }
}

@media (max-width: 480px) {
  .main-img {
    .text-end.mb-2, .toolbar {
      text-align:                      center !important;
    }
    .toolbar {
      .filters {
        max-width:                     100% !important;
      }
      &, .btn-group {
        display:                       block;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .main-img {
    .toolbar {
      .filters, .btn-group {
        display:                       inline-block;
      }
      .btn-group {
        text-align:                    right;
      }
    }
  }
}

@media (max-width: 767px) {
  .main-img {
    .toolbar {
      margin-bottom:                   10px;
      span {
        margin-right:                  10px;
      }
      .filters,
      .btn-group {
        margin-bottom:                 10px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .main-img {
    min-height:                        100%;
    .action-box {
      > div {
        width:                         auto;
      }
    }
    .bootstrap-select.show-tick.bs3 {
      margin-bottom:                   0;
    }
  }
}

@media (min-width: 1500px) {
  .main-img {
    min-height:                        100%;
  }
}
</style>

<script src="./ExamImageTab.js"></script>
