<template>
  <div class="modal fade" id="modal_change_pin" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="modalPassPinLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalPassPinLabel">
            <span>{{ $t('user.changeDevicePIN') }}</span>
          </h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </div>
        <vee-form v-slot="{ handleSubmit }" as="div">
          <form @submit.prevent.stop="handleSubmit(changePin)">
            <div class="modal-body">
              <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                <span v-html="alert.message"></span>
                <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
              </div>

              <div class="form-group">
                <label for="loginPassword">{{ $t('user.loginPassword') }}</label>
                <vee-field name="loginPassword" v-model="loginPassword" rules="required" v-slot="{ field, errorMessage }">
                  <input v-bind="field" type="password" class="form-control" ref="loginPassword" id="loginPassword">
                  <span class="vee-error">{{ errorMessage }}</span>
                </vee-field>
              </div>
              <div class="form-group">
                <label for="newPin">{{ $t('user.newPIN') }}</label>
                <vee-field name="newPin" v-model="newPin" rules="required|min:4" v-slot="{ field, errorMessage }">
                  <input v-bind="field" type="text" class="form-control" ref="newPin" id="newPin" v-only-numbers>
                  <span class="vee-error">{{ errorMessage }}</span>
                </vee-field>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="close()">{{ $t('user.cancel') }}</button>
              <button type="submit" class="btn btn-primary">{{ $t('user.modify') }}</button>
            </div>
          </form>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-dialog {
      .modal-header {
        background-color:               $phelcom-light-blue;
      }
      .modal-body {
        .form-group {
          display:                      block;
          label {
            width:                      100%;
          }
        }
        .vee-error, .form-control {
          width:                        100% !important;
        }
      }
    }
  }
</style>

<script>
export default {
  name: 'UserChangePinModal',
  data: () => ({
    alert: {
      message: null,
      class: null,
    },
    loginPassword: "",
    newPin: "",
  }),
  mounted() {
    setTimeout(() => {
      loginPassword.focus()
    }, 1000)
  },
  methods: {
    close() {
      let values = {
        alert: this.alert,
        type: 'pin'
      }
      this.$emit('close', values)
    },
    async changePin() {
      NProgress.start()
      try {
        await this.$userService.changePin(this.loginPassword, this.newPin)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.$nextTick(() => {
          $('#closeBtn').click();
        });
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    }
  }
}
</script>
