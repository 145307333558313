<template>
  <div class="">
    <div class="quick-report">
      <ul class="nav nav-tabs">
        <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#OD">{{ $t('quickReport.rightEye') }}</a></li>
        <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#OE">{{ $t('quickReport.leftEye') }}</a></li>
      </ul>

      <div class="tab-content" v-if="hasData">
        <div id="OD" class="tab-pane fade in active show">
          <ReportQuickDiagnosisSelectionTab
            :laterality="'right'"
            :exam="exam"
            :entries="selectedEntries.right"
            @selected-entries="rightEyeSelection">
          </ReportQuickDiagnosisSelectionTab>
        </div>
        <div id="OE" class="tab-pane fade">
          <ReportQuickDiagnosisSelectionTab
            :laterality="'left'"
            :exam="exam"
            :entries="selectedEntries.left"
            @selected-entries="leftEyeSelection">
          </ReportQuickDiagnosisSelectionTab>
        </div>
      </div>
    </div>

    <div class="quick-report">
      <div class="form-group">
        <label for="">{{ $t('quickReport.observation') }}</label>
        <textarea v-model="observationValue" @change="entriesChanged"
          class="form-control" name="observations" rows="4">
        </textarea>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quick-report {
  padding:                             15px;
  border:                              1px solid $chinese-silver;
  margin-top:                          15px;
  > div h2 {
    display:                           inline-block;
    width:                             calc(100% - 304px);
  }
  > .tab-content {
    display:                           block;
    padding:                           15px 0;
    .form-box {
      display:                         flex;
      flex-wrap:                       wrap;
    }
    .form-group {
      display:                         inline-block;
      margin:                          5px 0;
    }
  }
  > .form-group {
    position:                          unset;
    &,
    label,
    .form-control {
      display:                         block;
    }
    .form-control {
      width:                           100% !important;
    }
  }
  .nav-tabs .nav-link {
    color:                             $ucla;
    padding:                           13px 15px 12px;
  }
  textarea.form-control {
    height:                            unset !important;
  }
}

@media (max-width: 991px) {
  .quick-report {
    > .tab-content .form-group {
      margin:                          5px 0;
    }
  }
}
</style>

<script>
import ReportQuickDiagnosisSelectionTab from './ReportQuickDiagnosisSelectionTab.vue'

export default {
  name: 'ReportQuick',
  props: ['exam', 'observation', 'loaded_entries'],
  components: {
    ReportQuickDiagnosisSelectionTab,
  },
  data: () => ({
    loading: true,
    hasData: false,
    selectedEntries: {
      right: ['000_1'],
      left: ['000_1']
    },
    observationValue: ""
  }),
  mounted () {
    const hasEntries = !_.isEmpty(this.loaded_entries.right) || !_.isEmpty(this.loaded_entries.left)
    if (hasEntries) {
      this.selectedEntries = this.loaded_entries
    }
    this.hasData = true
    this.entriesChanged() // force parent update
    this.observationValue = this.observation ? this.observation : ""
  },
  methods: {
    rightEyeSelection(entries) {
      this.selectedEntries.right = entries.filter(x => !_.isEmpty(x)).sort()
      this.entriesChanged()
    },
    leftEyeSelection(entries) {
      this.selectedEntries.left = entries.filter(x => !_.isEmpty(x)).sort()
      this.entriesChanged()
    },
    entriesChanged() {
      let quickReportValues = {
        selectedEntries: this.selectedEntries,
        observation: this.observationValue,
      }

      this.$emit('entries-changed', quickReportValues);
    }
  }
}
</script>
