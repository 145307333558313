import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  try {
    console.log(`Navigating to: ${to.name}`);
    next();
  } catch(err) {
    console.log("Navigation error:", err)
  }
});

export default router
