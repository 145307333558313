<template>
  <div class="">
    <div class="mb-3 row">
      <label for="address" class="col-md-3 col-form-label">{{ $t('createClinic.address') }}*</label>
      <div class="col-md-6">
        <vee-field name="street" v-model="clinic.street" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="street" id="street">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="city" class="col-md-3 col-form-label">{{ $t('createClinic.city') }}*</label>
      <div class="col-md-6">
        <vee-field name="city" v-model="clinic.city" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="city" id="city">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="state" class="col-md-3 col-form-label">{{ $t('createClinic.state') }}*</label>
      <div class="col-md-6">
        <vee-field name="state" v-model="clinic.state" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="state" id="state">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="country" class="col-md-3 col-form-label">{{ $t('createClinic.country') }}*</label>
      <div class="col-md-6">
        <vee-field name="country" rules="required" v-slot="{ field, errorMessage }">
          <v-select
            v-bind="field"
            class="v-select form"
            v-model="clinic.country"
            label="label"
            :reduce="x => x.value"
            :options="countryOptions"
            @option:selected="setCountry">
            <template #search="{attributes, events}">
              <input
                class="vs__search"
                :required="!clinic.country"
                v-bind="attributes"
                v-on="events"
              />
            </template>
          </v-select>
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RegistrationClinicStepTwo',
  props: ['clinic'],
  data: () => ({
    countryOptions: []
  }),
  created() {
    let locale = this.$utils.getCurrentLanguage()
    const countryCodeList = this.$utils.countryCodeList(locale)
    this.countryOptions = []
    for (let code of countryCodeList) {
      this.countryOptions.push({
        value: code,
        label: this.$i18n.t(`country.${code}`)
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.street.focus()
    })
  },
  methods: {
    setCountry(country) {
      this.clinic.country = country.value
    }
  }
}
</script>
