<template>
  <div class="modal fade" id="change_password_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="changePasswordLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="changePasswordLabel">
            <span>{{ $t('user.changePassword') }}</span>
          </h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </div>
        <vee-form v-slot="{ handleSubmit }" as="div">
          <form @submit.prevent.stop="handleSubmit(changePassword)">
            <div class="modal-body">
              <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                <span v-html="alert.message"></span>
                <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
              </div>

              <div class="form-group">
                <label for="currentPassword">{{ $t('user.currentPassword') }}</label>
                <vee-field name="currentPassword" v-model="currentPassword" rules="required" v-slot="{ field, errorMessage }">
                  <input v-bind="field" type="password" class="form-control" ref="currentPassword" id="currentPassword" :disabled="disabled">
                  <span class="vee-error">{{ errorMessage }}</span>
                </vee-field>
              </div>
              <div class="form-group">
                <label for="newPassword">{{ $t('user.newPassword') }}</label>
                <vee-field name="newPassword" v-model="newPassword" rules="required|min:8" v-slot="{ field, errorMessage }">
                  <input v-bind="field" type="password" class="form-control" id="newPassword" ref="newPassword" :disabled="disabled">
                  <span class="vee-error">{{ errorMessage }}</span>
                </vee-field>
              </div>
              <div class="form-group">
                <label for="passwordConfirmation">{{ $t('user.passwordConfirmation') }}</label>
                <vee-field name="confirmPassword" v-model="passwordConfirmation" rules="required|confirmed:newPassword" v-slot="{ field, errorMessage }">
                  <input v-bind="field" type="password" class="form-control" id="passwordConfirmation" :disabled="disabled">
                  <span class="vee-error">{{ errorMessage }}</span>
                </vee-field>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" @click="close()">{{ $t('user.cancel') }}</button>
              <button type="submit" class="btn btn-primary" :disabled="disabled">{{ $t('user.modify') }}</button>
            </div>
          </form>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-dialog {
      .modal-header {
        background-color:               $phelcom-light-blue;
      }
      .modal-body {
        .form-group {
          display:                      block;
          label {
            width:                      100%;
          }
        }
        .vee-error {
          width:                        100% !important;
        }
      }
    }
  }
</style>

<script>
export default {
  name: 'ExternalPatientChangePasswordModal',
  data: () => ({
    alert: {
      message: null,
      class: null,
    },
    currentPassword: "",
    newPassword: "",
    passwordConfirmation: "",
    disabled: false,
  }),
  mounted() {
    this.checkEmail()

    setTimeout(() => {
      currentPassword.focus()
    }, 1000)
  },
  methods: {
    close() {
      let values = {
        alert: this.alert,
        type: 'password'
      }
      this.$emit('close', values)
    },
    async changePassword() {
      NProgress.start()
      try {
        const params = {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword
        }
        let result = await this.$externalService.changePassword(params)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.$nextTick(() => {
          $('#closeBtn').click();
        });
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
    async checkEmail() {
      try {
        let result = await this.$externalService.checkEmail()

        if (!result.isEmailValid) {
          this.disabled = true;
          this.alert = this.$message.alert(this, 'email.registerEmail', 'warning')
          this.$message.popup(this, 'email.registerEmail', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
    }
  }
}
</script>
