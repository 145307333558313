<template>
  <div class="">
    <div class="quill-editor">
      <quill-editor
        ref="quillEditor"
        class="editor"
        v-model:content="newContent"
        :options="editorOption"
        content-type="html"
        @update:content="editorChanged()"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .quill-editor {
    max-height:                    660px;
    height:                        100%;
    margin-top:                    6px;
  }
</style>

<script>
const toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }]
];

export default {
  name: 'ReportForm',
  props: ['content', 'selectedTemplate'],
  data: () => ({
    loading: true,
    editorOption: {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions,
        clipboard: {
          matchVisual: false
        },
      },
    },
    newContent: null
  }),
  computed: {
    editor() {
      return this.$refs.quillEditor.quill
    },
  },
  mounted() {
    if (this.content) {
      this.newContent = this.content
      this.$refs.quillEditor.setHTML(this.newContent)
    }
  },
  methods: {
    editorChanged() {
      this.$emit('custom-template', this.newContent);
    },
    reloadEditorContent(newContent) {
      this.$refs.quillEditor.setHTML(newContent)
    }
  }
}
</script>
