<template>
  <div v-loader.opacity="loading">
    <div class="title-box clear">
      <button class="btn-back" type="button" name="button" @click="goBack">
        <i class="icon-back"></i>
      </button>

      <h2 class="title sub">
        {{ $t("exam.examTypeName_patient_progression") }} |
        <span>{{ patientName }}</span>
      </h2>
    </div>

    <div class="tab-content">
      <div class="action-buttons rounded text-end">
        <button class="btn btn-outline-primary" type="button" name="button" @click="openModalGeneric">
          <span>{{ $t('examImages.generateProgression') }}</span>
        </button>
      </div>

      <div v-if="alert.message" class="alert alert-dismissible fade show" :class="alert.class" role="alert">
        <span v-html="alert.message"></span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="alert.message = null"></button>
      </div>

      <div class="exam-item" :ref="`exam_item_${index}`" v-for="(item, index) in exams" v-show="item.examData.length">
        <div class="section-title">
          <h4>{{ $filters.dateTime(item.date) }}</h4>
        </div>
        <lightgallery-vue
           class="image-info-box grid-wrap-between ease-all" :ref="`refLg_${index}`"
           :settings="lightGallerySettings">
           <template v-for="(examItem, examIndex) in item.examData" :key="examIndex">
             <a v-if="isValidForImageProcessing(examItem.type)"
                class="thumbnail-box loading-eye" :href="this.dataPath +'/'+ examItem.name">
               <img class="lg-selector ease-all" alt="" :src="this.thumbDataPath +'/'+ examItem.name" @load="removeLoading($event)"/>
               <div class="checkbox">
                 <BaseCheckBox :id="examItem.name" :value="examItem.uuid" :item="examItem" @checked="doCheck"></BaseCheckBox>
                 <!-- <input class="form-check-input" type="checkbox" :id="`checkImg_${examItem.name}`"
                        :value="examItem.uuid" aria-label="" @click.stop="doCheck(examItem, 'checkImg_'+examItem.name)"> -->
               </div>
             </a>
           </template>
        </lightgallery-vue>
      </div>
    </div>

    <teleport to="body">
      <BaseCropperModal ref="modalCropper"></BaseCropperModal>

      <BaseGenericModal :modal_id="'modalPatientProgressionGeneric'" ref="modalGeneric"></BaseGenericModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.btn-back {
  margin-right:                          20px;
}

.tab-content {
  .exam-item {
    .section-title {
      margin-bottom:                     20px;
      line-height:                       inherit;
      border-bottom:                     1px solid #e6e6e6;
      h4 {
        display:                         inline-block;
        color:                           #333333;
        font-size:                       21px;
      }
      .btn {
        font-size:                       14px;
        padding:                         0 10px;
      }
    }
  }
}
</style>

<script>
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from '@/assets/dependencies/lightGallery/plugins/zoom';
// import lgZoom from 'lightgallery/plugins/zoom';

const IS_PRODUCTION = process.env.NODE_ENV == 'production'
const TAG = "[patient-progression]"

export default {
  name: 'patient-progression',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    lightGallerySettings: {
      showZoomInOutIcons: true,
      actualSize: false,
      speed: 300,
      controls: true,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [],
    },
    patientID: null,
    patientName: null,
    dataPath: null,
    thumbDataPath: null,
    exams: [],
    examData: null,
    examID: null,
    selectedImages: []
  }),
  created() {
    window.addEventListener("resize", this.getAndSetWidth);
    this.lightGallerySettings.plugins = [lgFullscreen, lgThumbnail, lgZoom]
  },
  async mounted() {
    this.patientID = this.$route.params.patientID
    this.webSocketInitialization()
    this.fetchData()
  },
  unmounted() {
    window.removeEventListener("resize", this.getAndSetWidth);
  },
  methods: {
    async fetchData() {
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examService.listExamsForProgression(this.patientID)
        this.exams = response.result
        this.patientName = response.patient.fullName

        console.log('this.exams = ', this.exams)

        this.dataPath = response.dataPath
        this.thumbDataPath = response.thumbDataPath

        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    goBack() {
      let data = this.$utils.dataQuery({
        patientName: this.patientName
      })

      this.$router.push({name: 'patient-show', params: {patientID: this.patientID}, query: {tab: 'data', data: data}})
    },
    doCheck(img, id) {
      let hasImg = _.find(this.selectedImages, function(o) { return o.uuid == img.uuid; });
      console.log('hasImg', hasImg);

      if (hasImg) {
        this.selectedImages = _.remove(this.selectedImages, function(n) {
          return n.uuid != img.uuid;
        });
      } else {
        this.selectedImages.push(img);
      }

      console.log(this.selectedImages);
    },

    // TODO REFACTOR move to service
    isValidForImageProcessing(image) {
      return image.endsWith('COLOR') || image.endsWith('REDFREE') || image.endsWith('TEXTURE') || image.endsWith('image')
    },

    isImagesValid(examDataList) {
      if (examDataList.length < 2) {
        this.$message.popup(this, 'examImages.invalidImages', 'operationWarning', 'warn')
        return false
      }

      let laterality = examDataList[0].imageLaterality;
      for (let examData of examDataList) {
        if (examData.imageLaterality != laterality) {
          this.$message.popup(this, 'examImages.notSameLaterality', 'operationWarning', 'warn')
          return false
        }
      }

      return true
    },
    async previewForPatientProgression() {
      let params = {
        images: this.selectedImages.map(i => i.uuid),
        examData: this.selectedImages[0]
      }

      this.loading = true
      NProgress.start()
      try {
        this.$message.popup(this, 'examImages.generating_preview', 'operationWarning', 'warn')
        let response = await this.$examDataService.createPreview(params)
        this.openModalCropper(response.data)
      } catch (err) {
        let res_error = err.response.data.error
        this.$message.popup(this, 'examImages.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, `examImages.createdError` , 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async openModalCropper(image) {
      this.alert.message = null;

      const ok = await this.$refs.modalCropper.show({
        modalType: 'aipo',
        title: this.$i18n.t('aipo'),
        imgSrc: this.dataPath +'/'+ image,
        aspectRatio: 0,
        minWidth: 100,
        minHeight: 100,
        okButton: this.$i18n.t('save'),
        okButtonClass: 'btn-primary',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        console.log(ok.coordinates);
        this.doCreatePatientProgression(ok.coordinates)
      }
    },
    doCreatePatientProgression: async function(value) {
      const processID = this.$utils.uuidv4() // TODO use it on the general notification system
      let params = {
        processID: processID,
        images: [],
        examData:  this.selectedImages[0],
        coordinates: value,
        patientID: this.patientID
      }


      this.selectedImages.forEach(o => {
        console.log("O >>>", o)
        let img = {
          id: o.uuid,
          date: moment(Number(o.img.date)).format('DD/MM/YYYY HH:mm') // BEWARE number is required due to weirdness on sails disk
        }
        params.images.push(img)
      })

      try {
        this.loading = true
        NProgress.start()
        let response = await this.$examDataService.createPatientProgression(params)

        this.$message.popup(this, 'examImages.requestProcessed', 'operationWarning', 'warn')
      } catch (err) {
        this.$message.popup(this, 'examImages.lambdaError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'examImages.lambdaError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    getExamData(item) {
      let examData = this.$utils.raw(this.exams)
      this.examData = examData[0].examData
      this.examID = examData[0].id
    },
    getAndSetWidth() {
      let element = document.querySelectorAll('.image-info-box > .thumbnail-box');
      if (element.length) {
        let cw = element[0].offsetWidth;
        element.forEach(o => {
          o.style.height = cw + 'px';
        });
      }
    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;
      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
        this.getAndSetWidth();
      });
    },
    async openModalGeneric() {
      if (!this.isImagesValid(this.selectedImages))
        return

      if (this.selectedImages.length > 10) {
        const ok = await this.$refs.modalGeneric.show({
          modalType: 'warning',
          title: this.$i18n.t('examImages.title_timelapse'),
          text: 'Limite máximo de 10 imagens atingido, favor revisar.',
          okButton: 'OK',
          okButtonClass: 'btn-primary',
        })
        if (ok) {
          return
        }
      }

      let text = this.$i18n.t('examImages.confirmTotalImages', { image_length: this.selectedImages.length })
      const ok = await this.$refs.modalGeneric.show({
        modalType: 'warning',
        title: this.$i18n.t('examImages.title_timelapse'),
        text: text,
        okButton: 'OK',
        okButtonClass: 'btn-primary',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.previewForPatientProgression()
      }
    },
    webSocketInitialization() {
      console.log(`${TAG} webSocketInitialization()`)
      const io = this.$io
      this.isWebSocketConnected = io.socket.isConnected()
      if (this.isWebSocketConnected)
        this.webSocketForGallery()

      io.socket.on('connect', () => {
        this.isWebSocketConnected = true
        this.webSocketForGallery()
      })

      io.socket.on('disconnect', () => {
        this.isWebSocketConnected = false
      })
    },
    webSocketForGallery() {
      console.log(`${TAG} webSocketForGallery()`)
      const io = this.$io

      for (let e of ['general_listening', 'general_event']) {
        console.log('[app] unlisten to ws event:', e)
        io.socket.off(e);
      }

      // TODO one listen function for clinic?
      io.socket.post('/api/v2/eyercloud/ws/listen', {}, function gotResponse(body, response) {
        console.log(`${TAG} ws/listen:`, body);
      })

      io.socket.on('general_listening', function(data) {
        console.log(`${TAG} general_listening`, data);
      })

      io.socket.on('general_event', data => {
        console.log("general_event >>> >> >", data)
        NProgress.done(true)
        this.loading = false

        if (data.successfull) {
          this.$message.popup(this, `examImages.createdSuccess`, 'operationSuccess', 'success')
        } else {
          this.$message.popup(this, `examImages.createdError`, 'operationFail', 'error')
          // this.alert = this.$message.alert(this, `examImages.createdError`, 'danger')
        }
      })
    },
  },
}
</script>
