<template>
  <div class="external-home">
    <nav class="navbar navbar-expand-lg external-menu">
      <div class="container-fluid">
        <img src="@/assets/images/phelcom/phelcom_logo_horizontal_fit.svg" alt="Phelcom Logo Horizontal"/>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#externalMenu"
                aria-controls="externalMenu" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </button>
        <div class="collapse navbar-collapse" id="externalMenu">
          <div class="navbar-nav">
            <a class="nav-link" :class="[isRegistered ? 'disabled' : '', active === 'exams' ? 'active' : '']"
               data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-current="page" @click="examsMenu">
              <i class="icon-exam"></i>
              {{ $t('menu.exams') }}
            </a>
            <a class="nav-link" :class="[isRegistered ? 'disabled' : '', active === 'patient' ? 'active' : '']"
               data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="patientMenu">
              <i class="icon-pacientes"></i>
              {{ $t('externalPatient.patient') }}
            </a>
            <a class="nav-link" @click="logout">
              <i class="icon-logout"></i>
              {{ $t('audit.logout') }}
            </a>
          </div>
        </div>
      </div>
    </nav>


    <div class="content ease-all">
      <router-view class="panel-content"></router-view>
    </div>

    <ExternalPatientFooter></ExternalPatientFooter>

    <teleport to="body">
      <ExternalTermsModal v-if="showModalTerms" @close="showModalTerms = !showModalTerms"></ExternalTermsModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
  .external-menu {
    @include                          background_cover('@/assets/images/erjart/bg_menu.jpg');
    @include                          box_shadow(0 3px 5px rgb(108 108 108));
    // height:                           50px;
    width:                            100%;
    display:                          block;
    overflow:                         hidden;
    img {
      width:                          180px;
    }
    .navbar-toggler {
      @include                        box_shadow(unset);
      i {
        color:                        white;
        font-size:                    1.35em;
      }
    }
    .navbar-nav {
      display:                        flex;
      justify-content:                space-around;
      .nav-link {
        font-weight:                  400;
        color:                        $eucalyptus;
        text-transform:               uppercase;
        letter-spacing:               3px;
        display:                      block;
        padding:                      7px 15px;
        text-decoration:              none;
        cursor:                       pointer;
        &.disabled {
          opacity:                    0.8;
          cursor:                     not-allowed;
          &, i:before {
            color:                    $gray !important;
          }
        }
        i {
          font-size:                  1.2em;
          vertical-align:             text-top;
        }
        &:hover,
        &:focus {
          &, i:before {
            color:                    $pale-green;
          }
        }
        &:active,
        &.active {
          font-weight:                bold;
          &, i:before {
            color:                    white;
          }
        }
      }
    }
  }

  .footer {
    width:                            100% !important;
  }

  @media (max-width: 991px) {
    .external-home {
      margin-top:                     65px;
    }
    .external-menu {
      position:                       fixed;
      top:                            0;
      z-index:                        9999999;
      .navbar-nav {
        .nav-link {
          line-height:                32px;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .external-menu {
      .navbar-collapse {
        width:                        100%;
        display:                      block !important;
      }
    }
  }
</style>

<script>
import { useExternalStore } from '@/store/external'
import ExternalPatientFooter from './ExternalPatientFooter.vue'
import ExternalTermsModal from '@/components/ExternalTermsModal.vue'

export default {
  name: 'external-home',
  components: {
    ExternalPatientFooter,
    ExternalTermsModal
  },
  data: () => ({
    isRegistered: false,
    active: 'exams',
    showModalTerms: false,
    session: {
      collaborator: {},
      currentClinic: {},
      patient: {}
    },
  }),
  mounted() {
    const externalStore = useExternalStore()
    this.session = externalStore.sessionInfo
    console.log('this.session', this.session);

    // if (this.session && this.session.patient) {
    //   this.$i18n.locale = this.session.patient.locale
    //   console.log("[app] setting locale (from patient):", this.session.patient.locale)
    // }

    this.examsMenu()

    if (!this.session.patient.acceptanceTerm) {
      this.openModalTerms()
    }
  },
  methods: {
    examsMenu: async function() {
      this.goToLink(this, 'exams', 'external-exam-list')
    },
    patientMenu: async function() {
      this.goToLink(this, 'patient', 'external-patient-show')
    },
    async logout() {
      await this.$externalService.accessLogout(this)
      window.open('/acesso', '_self');
    },
    goToLink(self, active, link) {
      self.active = active
      self.$router.push({name: link, query: { ...self.$route.query, t: Date.now() }})
    },
    openModalTerms() {
      this.showModalTerms = true;

      setTimeout(function() {
        let modal_external_terms = document.getElementById('modal_external_terms');
        new bootstrap.Modal(modal_external_terms).show();
      }, 100);
    },
  }
}
</script>
