import eyerCloud from '@/config/eyercloud'

class DashboardService {
  async getClinicUsage(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/usage/get`, params)
    return response.data
  }

  getPercentageValue(clinicUsage) {
    
    let imageTotal = clinicUsage.imageTotal
    let quotaAvailable = clinicUsage.quotaAvailable
    
    let percentage = Math.floor((imageTotal / quotaAvailable) * 100)
    //console.log('value: ', value,' - PAID_THRESHOLD: ', PAID_TRESHOLD, ' - percentage: ', percentage)
    return percentage > 100 ? 100 : percentage
  }

  getPercentageClass(value) {
    
    if (value > 60 && value < 80)
      return 'warning'
    if (value >= 80)
      return 'danger'
  }

}
const dashboardService = new DashboardService();
export default dashboardService;
