<template>
  <div class="">
    <div class="mb-3 row">
      <label for="phone" class="col-md-3 col-form-label">{{ $t('createAccount.telephone') }}*</label>
      <div class="col-md-6">
        <vee-field name="phone" v-model="clinic.telephone" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="phone" id="phone">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="email" class="col-md-3 col-form-label">{{ $t('createAccount.emailContact') }}*</label>
      <div class="col-md-6">
        <vee-field name="email" v-model="clinic.email" rules="required" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="email" id="email">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="mobile" class="col-md-3 col-form-label">{{ $t('createAccount.mobile') }}</label>
      <div class="col-md-6">
        <vee-field name="mobile" v-model="clinic.mobile" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="mobile" id="mobile">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="telephone3" class="col-md-3 col-form-label">{{ $t('createAccount.telephone3') }}</label>
      <div class="col-md-6">
        <vee-field name="telephone3" v-model="clinic.telephone3" v-slot="{ field, errorMessage }">
          <input v-bind="field" type="text" class="form-control" ref="telephone3" id="telephone3">
          <span class="vee-error">{{ errorMessage }}</span>
        </vee-field>
      </div>
    </div>
  </div>
</template>

<script>
module.exports = {
  name: 'RegistrationClinicStepThree',
  props: ['clinic'],
  mounted() {
    this.$nextTick(() => {
      this.$refs.phone.focus()
    })
  }
}
</script>
