<template>
  <div class="tab-exam-box" v-loader.opacity.tab="loading">
    <div class="title-box rounded">
      <h2 class="title">{{ $t('exam.examsList') }}</h2>

      <button class="btn btn-outline-success rounded" type="button" name="button" @click="openModalNewExam">
        <i class="fa fa-plus"></i>
        <span>{{ $t('exam.newExam') }}</span>
      </button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable class="white-table" v-if="tableExams" :tabledata="tableExams" @item-opened="examSelected">
      <template v-slot:column_content="{item}">
        <span v-show="item.examType.key == 'patient_progression'" class="label label-blue-phelcom" :data-title="$t(`exam.examTypeName_${item.examType.key}`)">{{ $t(`exam.examType_${item.examType.key}`) }}</span>
        <i v-if="item.patient.referred > 0" class="fa" :class="item.patient.referred == 1 ? 'fa-tint' : 'fa-check-circle-o'" aria-hidden="true"></i>
        <button class="no-btn" type="button" name="button" data-title="Download" @click.stop.prevent="doDownload(item)">
          <i class="fa fa-download"></i>
        </button>
        <button v-if="item.hasImage" class="no-btn" type="button" name="button" :data-title="$t('tooltips.image')">
          <i class="fa fa-image"></i>
        </button>
        <button v-if="item.hasReport" class="no-btn" type="button" name="button" :data-title="$t('tooltips.report')">
          <i class="fa fa-file-pdf-o"></i>
        </button>
        <span v-if="item.scoreResult" :data-title="`${$t('od')}: ${item.scoreResult.odText} ${$t('od')}: ${item.scoreResult.osText}`">
          <i class="icon-ai" :class="item.scoreResult.status" :tooltip="`${$t('od')}: ${item.scoreResult.odText} ${$t('od')}: ${item.scoreResult.osText}`"></i>
          <!-- {{ $t('od') }}: {{ item.scoreResult.odText }} {{ $t('od') }}: {{ item.scoreResult.osText }} -->
        </span>
        <span v-for="c of this.activeAiClasses(item)" :key="c" :data-title="$t(`phelcomAI.title_${c}`)">
          <i class="icon-phelcom-ai" :class="`phelcomAI_${c}`">{{$t(`phelcomAI.icon_${c}`)}}</i>
        </span>
      </template>
      <template v-slot:column_status="{item}">
        <span class="label" :class="item.status == 'closed' ? 'label-closed' : 'label-open'">
          {{ item.status }}
        </span>
      </template>
    </BaseTable>

    <teleport to="body">
      <PatientExamNewModal v-if="showModalNewExam" :patient_id="patient_id" @close="closeModal"></PatientExamNewModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.tab-exam-box .title-box .btn {
  margin-bottom:                     0;
}

.white-table {
  .table {
    > thead > tr > th {
      border-top:                    0;
    }
  }
  .vtable {
    > tbody > tr {
      > td {
        .no-btn {
          margin:                    0;
          padding:                   0 7px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .tab-exam-box .title-box {
    .title {
      text-align:                    center;
      width:                         100%;
      max-width:                     unset;
    }
    .btn {
      display:                       block;
      margin-right:                  auto !important;
      margin-left:                   auto !important;
    }
  }
}

@media screen and (min-width: 401px) and (max-width: 767px) {
  .tab-exam-box .title-box .title {
    text-align:                      left;
    width:                           calc(100% - 152px);
  }
}

@media (min-width: 767px) {
  .icon-phelcom-ai {
    vertical-align:                  top;
    margin-top:                      1px;
  }
}
</style>

<script>
import PatientExamNewModal from './PatientExamNewModal.vue'

export default {
  name: 'PatientExamTab',
  props: ['patient_id', 'force_fetch'],
  components: {
    PatientExamNewModal,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    showModalNewExam: false,
    tableExams: null,
  }),
  mounted: async function() {
    if (this.$route.query.data) {
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      if (data.patientName)
        this.$emit('patient_name', data.patientName)
    }

    this.fetchData();
  },
  methods: {
    fetchData: async function() {
      this.alert = {}

      this.loading = true
      NProgress.start()
      try {
        let params = {
          id: this.patient_id
          // page: this.currentPage,
        }

        let response = await this.$patientService.getExamList(params);
        response.result.forEach(o => {
          try {
            o.status = this.$i18n.t(o.status);
            o.scoreResult = this.$aiService.alterationScore(o.metadata);
          } catch(error) {
            o.scoreResult = 'error'
          }
        });

        this.tableExams = {
          'items': response.result,
          'columns': [
            {name: this.$i18n.t('exam.date'), type: 'date', value: 'date', width: '55%'},
            {key:"content", name: this.$i18n.t('exam.content'), type: 'custom', value: 'content', width: '30%'},
            {key: 'status', name: this.$i18n.t('exam.status'), type: 'custom', value: 'status', width: '15%'},
          ]
        }

        if (response.result.length === 0) {
          this.tableExams = null;
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    examSelected(item) {
      if (!item) {
        return
      }

      let data = this.$utils.dataQuery({
        previous: 'patient'
      })

      this.$router.push({name: 'exam-show', params: {examID: item.id}, query: {tab: 'images', data: data}})
    },
    doDownload(exam) {
      try {
        this.loading = true
        NProgress.start()
        this.$examService.download(exam.id)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    openModalNewExam() {
      this.showModalNewExam = true

      setTimeout(function() {
        new bootstrap.Modal(modal_new_exam).show();
      }, 100);
    },
    closeModal() {
      this.showModalNewExam = false
      this.fetchData()
    },
    activeAiClasses(exam) {
      return this.$aiService.mcRASClasses(exam)
    }
  }
}
</script>
